import React from 'react';
import DonutChart from '../DonutChart/DonutChart';
import moment from 'moment';

interface DeadlineDonutProps {
  deadlineDate: string;
  createdAt: string;
}

const donutColor = (percentage: number) => {
  if (percentage >= 80) {
    return 'high';
  }

  if (percentage >= 60) {
    return 'medium';
  }

  return 'low';
};

const DeadlineDonut: React.FC<DeadlineDonutProps> = ({
  deadlineDate,
  createdAt,
}) => {
  const today = moment();
  const daysFromToday = Math.abs(
    moment.duration(today.diff(moment(createdAt))).asDays(),
  );
  const daysFromCreation = Math.abs(
    moment.duration(moment(createdAt).diff(moment(deadlineDate))).asDays(),
  );

  let percentage = (daysFromToday * 100) / daysFromCreation;

  if (
    createdAt > deadlineDate ||
    daysFromToday <= 0 ||
    percentage < 0 ||
    percentage > 100
  )
    percentage = 100;
  return (
    <>
      <DonutChart
        value={percentage}
        size={24}
        strokeColor={donutColor(percentage)}
        strokeWidth={4}
      />
      <div className="ml-1">
        {moment().to(new Date(Date.parse(deadlineDate)).toDateString())}
      </div>
    </>
  );
};

export default DeadlineDonut;
