/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Columns } from './ComparativeMapData.style';
import { ReactComponent as RetryIcon } from '../../assets/retry-icon.svg';
import { ReactComponent as HelpBallon } from '../../assets/help-ballon-icon.svg';
import { ReactComponent as RewardIcon } from '../../assets/reward-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock-icon.svg';
import { format, parseISO } from 'date-fns';
import removeZerosLeft from '../../utils/removeZerosLeft';
import ComparativeMapHistoricItem from '../../components/ComparativeMapHistoricItem/ComparativeMapHistoricItem';
interface HistoricDataProps {
  historicData: HistoricData[];
}

interface HistoricData {
  cod_quotation: string;
  deadline: string;
  id: number;
  num_quotation: number;
  quotation: HistoricQuotationItems[];
}

interface HistoricQuotationItems {
  supplier_code: string;
  supplier_name: string;
  supplier_nickname: string;
  payment_condition: string;
  incoterm: Inconterm;
  total_freight_rate: number;
  total_rfq_value: number;
  total_value: number;
  items: Items[];
}

interface Inconterm {
  id: number;
  name: string;
}
interface Items {
  item_id: number;
  item_name: string;
  quantity: number;
  unit_price: number;
  was_awarded: boolean;
  target: number;
  net_price: number;
  answer_code_AI: string;
  ia_final_decision: string;
  qto: number | null;
  vuo: number | null;
  ipi: number | null;
  ipp: number | null;
  icm: number | null;
  icp: number | null;
  pzo: string | null;
  vto: string | null;
  dsc: number | null;
  vup: number | null;
  mar: string | null;
  nc1: number | null;
  nc2: number | null;
  iva: number | null;
  vtf: number | null;
  ent: string | null;
  por: number | null;
  vut: number | null;
  unm: number | null;
  lte: number | null;
  vtp: number | null;
  dtr: string | null;
}
interface Suppliers {
  supplier_name: string;
  supplier_code: string;
  supplier_nickname: string;
  total_values: number[];
  payment_descriptions: PaymentDescription[];
  items: SupplierItems[];
}
interface PaymentDescription {
  payment_condition: string;
  incoterm: string;
  freight_value: number;
  total_value_rfq: number;
}
interface SupplierItems {
  item_id: number;
  name: string;
  values: string[];
  was_awarded: boolean[];
  items_params: ItemParams[];
}
interface ItemParams {
  qto: number | null;
  vuo: number | null;
  ipi: number | null;
  ipp: number | null;
  icm: number | null;
  icp: number | null;
  pzo: string | null;
  vto: string | null;
  dsc: number | null;
  vup: number | null;
  vtf: number | null;
  mar: string | null;
  nc1: number | null;
  nc2: number | null;
  iva: number | null;
  ent: string | null;
  por: number | null;
  vut: number | null;
  unm: number | null;
  lte: number | null;
  vtp: number | null;
  dtr: string | null;
}

const ComparativeMapData: React.FC<HistoricDataProps> = ({ historicData }) => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<Suppliers[]>([]);

  const addItems = useCallback((items: Items[], supplier: Suppliers) => {
    items.forEach(item => {
      const findItem = supplier.items.find(
        supItem => supItem.item_id === item.item_id,
      );
      const params = {
        qto: item.qto,
        vuo: item.vuo,
        ipi: item.ipi,
        ipp: item.ipp,
        icm: item.icm,
        icp: item.icp,
        pzo: item.pzo,
        vto: item.vto,
        dsc: item.dsc,
        vup: item.vup,
        vtf: item.vtf,
        mar: item.mar,
        nc1: item.nc1,
        nc2: item.nc2,
        iva: item.iva,
        ent: item.ent,
        por: item.por,
        vut: item.vut,
        unm: item.unm,
        lte: item.lte,
        vtp: item.vtp,
        dtr: item.dtr,
      };
      if (!findItem) {
        supplier.items.push({
          item_id: item.item_id,
          name: item.item_name,
          values: [item.vto || '0'],
          items_params: [params],
          was_awarded: [item.was_awarded],
        });
      } else {
        findItem.values.push(item.vto || '0');
        findItem.items_params.push(params);
        findItem.was_awarded.push(item.was_awarded);
      }
    });
  }, []);

  useEffect(() => {
    async function loadHistoricData(): Promise<void> {
      const suppliers: Suppliers[] = [];
      historicData.forEach(data => {
        data.quotation.forEach(quote => {
          const findSupplier = suppliers.find(
            sup => sup.supplier_code === quote.supplier_code,
          );
          const paymentDescription = {
            payment_condition: quote.payment_condition,
            incoterm: quote.incoterm.name,
            freight_value: quote.total_freight_rate,
            total_value_rfq: quote.total_rfq_value,
          };
          if (!findSupplier) {
            suppliers.push({
              supplier_name: quote.supplier_name,
              supplier_code: quote.supplier_code,
              supplier_nickname: quote.supplier_nickname,
              total_values: [quote.total_rfq_value],
              payment_descriptions: [paymentDescription],
              items: [],
            });
            addItems(quote.items, suppliers[suppliers.length - 1]);
          } else {
            findSupplier.total_values.push(quote.total_rfq_value);
            findSupplier.payment_descriptions.push(paymentDescription);
            addItems(quote.items, findSupplier);
          }
        });
      });
      setSuppliers(suppliers);
    }
    loadHistoricData();
  }, [addItems, historicData]);

  return (
    <Container className="w-full flex flex-col">
      <div className="w-full flex flex-col overflow-auto inline-grid">
        <div className="flex mt-2">
          <Columns className="flex flex-col">
            <div className="bg-bluejeans-500 px-4 py-2 rounded-t-lg">
              <span className="font-default font-semibold text-sm text-white">
                {t('ai_suggestions_rounds')}
              </span>
            </div>
            <div className="flex flex-col border-2 border-t-0 pb-4 justify-center h-full">
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <RewardIcon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('reward')}
                </span>
              </div>
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <RetryIcon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('new_round')}
                </span>
              </div>
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <HelpBallon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('help_buyer')}
                </span>
              </div>
            </div>
          </Columns>
          {historicData.map((item, key) => (
            <Columns
              key={`round-${key}`}
              className="flex flex-col border rounded-md w-52 pt-4 pb-2"
            >
              <div className="flex justify-between border-b-2 pb-5 pt-4 px-4">
                <span className="text-base text-yaleblue-800 font-semibold">
                  {`${t('round')} ${removeZerosLeft(item.num_quotation)}`}
                </span>
              </div>
              <div className="flex px-4 mt-3.5 items-center w-full">
                <ClockIcon />
                <span className="text-xs font-light text-supplier-accordion-gray pl-2 w-full">
                  {`${t('closing')}
                ${
                  item.deadline
                    ? format(parseISO(item.deadline), 'dd/MM/yyyy')
                    : '-'
                }`}
                </span>
              </div>
            </Columns>
          ))}
        </div>
        <ComparativeMapHistoricItem suppliers={suppliers} />
      </div>
    </Container>
  );
};

export default ComparativeMapData;
