export const formatAICodeRFQ = (code: string | null): string => {
  const values: { [index: string]: string } = {
    '01': 'rfq_ia_code_reward_automatic',
    '02': 'rfq_ia_code_new_round_automatic',
    '03': 'rfq_ia_code_buyer_help',
    '04': 'rfq_ia_code_reward_manual',
    '05': 'rfq_ia_code_new_round_manual',
    '06': 'rfq_ia_code_extend_rfq_deadline',
  };

  return values[code || ''] || '-';
};
