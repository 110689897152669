/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  InputHTMLAttributes,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import api from '../../services/api';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputLoading from '../../components/InputLoading/Loading';

import { Container } from './MultiInput.style';

type RefReturn =
  | string
  | ((instance: HTMLSelectElement | null) => void)
  | React.RefObject<HTMLSelectElement>
  | null
  | undefined;

interface OptionsProps {
  id: number;
  value: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: OptionsProps[];
  selectedOptions: OptionsProps[];
  className?: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  register: ({ required }: { required?: boolean }) => RefReturn;
  handleSelectOption?: Function;
  showSelectedItems?: boolean;
  loadOptions?: Function;
  handleSelectedOptions?: Function;
  url?: string;
  hasError?: boolean;
  typedOption?: string;
  setTypedOption?: Function;
  currentList?: any[];
}

const MultiInput: React.FC<InputProps> = ({
  name,
  options,
  className,
  icon: Icon,
  register,
  required,
  showSelectedItems,
  selectedOptions,
  loadOptions,
  handleSelectOption,
  handleSelectedOptions,
  url,
  hasError,
  typedOption: typedOptionProp,
  setTypedOption: setTypedOptionProp,
  currentList,
  ...rest
}) => {
  const [items, setItems] = useState<OptionsProps[]>([]);
  const [selectedItems, setSelectedItems] = useState<OptionsProps[]>([]);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputNode = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [totalData, setTotalData] = useState(0);
  const [currentData, setCurrentData] = useState(0);
  const [typedOptionInternal, setTypedOptionInternal] = useState('');

  const typedOption =
    typedOptionProp !== undefined ? typedOptionProp : typedOptionInternal;
  const setTypedOption =
    setTypedOptionProp !== undefined
      ? setTypedOptionProp
      : setTypedOptionInternal;

  const searchValues = async () => {
    let inList = false;
    if (currentList) {
      currentList.forEach(item => {
        if (
          typedOption
            .toLowerCase()
            .split(',')
            .some(subValue => item.toLowerCase().includes(subValue.trim()))
        ) {
          inList = true;
        }
      });
    }
    if (typedOption.length > 2 && !inList) {
      try {
        await api.get(`${url}${typedOption}`).then(response => {
          setCurrentData(0);
          loadOptions && loadOptions(response.data.results);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const debounce = useCallback(_.debounce(searchValues, 700), [typedOption]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent instanceof InputEvent &&
      e.nativeEvent.inputType !== 'insertFromPaste'
    ) {
      setTypedOption(e.target.value);
    }
  };

  useEffect(() => {
    debounce();

    return debounce.cancel;
  }, [typedOption, debounce]);

  const fetchData = async () => {
    if (totalData < currentData) return;
    await api
      .get(`/${url}${typedOption}&limit=15&offset=${currentData + 15}`)
      .then(response => {
        const results = response.data.results;
        setCurrentData(prev => prev + 15);
        setTotalData(response.data.count);
        if (results.length > 0) {
          loadOptions && loadOptions(results, items);
        } else {
          setTotalData(0);
        }
      });
  };

  const handleClick = (e: Event) => {
    if (node.current.contains(e.target as Node)) {
      setDropdownIsOpen(true);
    } else {
      setDropdownIsOpen(false);
    }
  };

  useEffect(() => {
    setItems(options);
    setSelectedItems([...selectedOptions]);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [options, selectedOptions]);

  const handleSelect = (item: OptionsProps) => {
    setDropdownIsOpen(false);
    const findIndex = selectedItems.findIndex(x => x.value === item.value);
    if (findIndex < 0) {
      const newSelectedItems = selectedItems.slice();
      newSelectedItems.push({ id: item.id, value: item.value });
      setSelectedItems([...newSelectedItems]);
      handleSelectOption && handleSelectOption(item.value);
      handleSelectedOptions && handleSelectedOptions(newSelectedItems, name);
    }
  };

  const handleRemoveSelected = (value: number) => {
    const newSelectedItems = selectedItems.filter(item => item.id !== value);
    setSelectedItems([...newSelectedItems]);
    handleSelectedOptions && handleSelectedOptions(newSelectedItems, name);
  };

  const handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('Text');
    let cleanedValue = pastedText.replace(/[\n\t]+/g, ',');
    if (cleanedValue.slice(-1) === ',') {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    setTypedOption(typedOption + cleanedValue);
  };

  return (
    <div className={`${className}`}>
      <div
        className={`w-full rounded-lg ${
          dropdownIsOpen &&
          'border-basic-gray-transparent border-2 focus-within:border-primary focus:within:border-2 bg-white '
        }
        ${hasError ? ' border border-red-salsa-500 ' : ''}
        `}
        ref={node}
      >
        <Container
          className={`flex h-10 items-center px-4 justify-between ${
            !dropdownIsOpen
              ? 'border border-color-yaleblue bg-whitegray-100 rounded-lg'
              : 'border-basic-gray-transparent'
          }`}
        >
          <input
            ref={inputNode}
            name={name}
            id={name}
            className="text-yaleblue-900 text-sm focus:outline-none rounded-lg "
            onChange={e => onChange(e)}
            onPaste={handleInputPaste}
            onFocus={() => setDropdownIsOpen(true)}
            value={typedOption}
            readOnly={false}
            {...rest}
          />
          {Icon && <Icon className="cursor-pointer" type="reset" />}
        </Container>
        {items.length > 0 && (
          <InfiniteScroll
            dataLength={currentData}
            hasMore={true}
            next={fetchData}
            loader={
              dropdownIsOpen && totalData > currentData ? (
                <InputLoading />
              ) : (
                <></>
              )
            }
            scrollableTarget="scrollableSelect"
          >
            <select
              id="scrollableSelect"
              className="bg-white py-2 w-full"
              ref={register({ required })}
              multiple
              name={name}
              value={items.map(item =>
                selectedItems.filter(x => x.id === item.id).length > 0
                  ? item.value
                  : '',
              )}
              hidden={!dropdownIsOpen}
            >
              {items.map((item, index) => (
                <option
                  key={index}
                  className={`px-4 py-2 cursor-pointer text-basic-gray-400 text-sm border-basic-gray-transparent border-t-2 rounded-lg `}
                  value={item.value}
                  onClick={() => handleSelect(item)}
                >
                  {item.value}
                </option>
              ))}
            </select>
          </InfiniteScroll>
        )}
      </div>
      {selectedItems.length > 0 && showSelectedItems && (
        <div className="flex mr-2 flex-wrap">
          {selectedItems.map((item, index) => (
            <div
              key={index}
              className="flex rounded-full bg-secondary-button px-4 py-1 ml-2 items-center bg-bluejeans-100 mt-4"
            >
              <span className="font-bold text-xs text-bluejeans-1000">
                {item.value}
              </span>
              <CloseIcon
                className="h-2 w-2 ml-2 cursor-pointer"
                onClick={() => handleRemoveSelected(item.id)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiInput;
