import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardContainer, LineContent } from './SupplierCard.style';
import { ReactComponent as CheckBoxChecked } from '../../assets/checkbox-checked-icon.svg';
import removeZerosLeft from '../../utils/removeZerosLeft';

export interface cardContent {
  id?: number;
  nickname: string;
  code: string;
  social_name: string;
  document_number: string;
  uf: string;
  country: string;
  score_serasa: number;
  payment_deadline: number;
  fqi: number;
  saving_percent: number;
}

interface SupplierCardProps {
  cardContent: cardContent;
  handleAction?: Function;
  isBlue?: boolean;
}

const SupplierCard: React.FC<SupplierCardProps> = ({
  cardContent,
  handleAction,
  isBlue,
}) => {
  const { t } = useTranslation();
  return (
    <CardContainer className="w-72 rounded border mr-6 mb-4">
      <div
        className={`${
          isBlue ? 'bg-bluejeans-300' : 'bg-basic-gray-400'
        } flex h-8 items-center px-4 rounded-tl rounded-tr text-sm text-white w-full`}
      >
        {handleAction && (
          <CheckBoxChecked
            className="mr-2 cursor-pointer w-10"
            onClick={() => handleAction(cardContent)}
          />
        )}
        <span className="truncate">{`${removeZerosLeft(cardContent.code)}: ${
          cardContent.nickname
        }`}</span>
      </div>
      {Object.entries(cardContent).map((item, key) => (
        <div key={key}>
          {item[0] !== 'code' && item[0] !== 'nickname' && item[0] !== 'id' && (
            <LineContent className={`flex py-2 pl-4 ${key === 0 && 'pt-5'}`}>
              <div className="text-xs text-yaleblue-900 font-light">{`${t(
                item[0],
              )}:`}</div>
              <div className="text-xs text-yaleblue-900 pl-1 font-light">
                {item[1]}
              </div>
            </LineContent>
          )}
        </div>
      ))}
    </CardContainer>
  );
};

export default SupplierCard;
