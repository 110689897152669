import React, { useEffect, useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Button, Columns } from './ComparativeMapData.style';
import ModalButton from '../Button/Button';
import ComparativeMapItem from '../ComparativeMapItem/ComparativeMapItem';
import Modal from 'react-modal';
import { ReactComponent as PinIcon } from '../../assets/pin-icon.svg';
import { ReactComponent as ChavronUpIcon } from '../../assets/icon-chavron-down.svg';
import { ReactComponent as ChavronDownIcon } from '../../assets/icon-chavron-up.svg';
import { ReactComponent as CheckBoxChecked } from '../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { ReactComponent as RetryIcon } from '../../assets/retry-icon.svg';
import { ReactComponent as HelpBallon } from '../../assets/help-ballon-icon.svg';
import { ReactComponent as RewardIcon } from '../../assets/reward-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { Context } from '../../Context/ComparativeMap';
import { Rfq } from '../../pages/Quotation/ShowQuotation/ShowQuotation';
import { userHasAnyPermissionOf } from '../../services/permission-service';
import api from '../../services/api';
import { ST_CONTROL } from '../../utils/rfqStatusControl';

interface ComparativeMapDataProps {
  items: FilterItemsProps[];
  suppliers: FilterSupplierProps[];
  rfq: Rfq;
  round?: string;
}

interface SupplierProps {
  id: number;
  checked?: boolean;
  name: string;
  nickname: string;
  code: string;
  cod_quotation: string;
  incoterm: { id: number; name: string };
  payment_condition: { id: number; code: string };
  reduction_percentage: number | null;
  total_freight_rate: number;
  total_rfq_value: number;
}

interface QuotationProps {
  id: number;
  supplier: SupplierProps;
  quotation_items: QuotationItemProps[];
  quotation_id: number;
}

interface QuotationItemProps {
  item_id: number;
  item_rfq_id: number;
  answer_code_AI: string;
  ia_final_decision: string;
  ia_final_decision_msg: string;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_rfq_st_control: number;
  item_st_control: number;
  vuo: number;

  [key: string]: number | string | null | Object;
}

interface ItemsProps {
  item_id: number;
  item_name: string;
  last_purchase: LastPurchaseProps;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_branch_region: string;
  item_cod_material: string;
  item_st_control: number;
  item_num_gp: string;
}

interface LastPurchaseProps {
  date: string;
  order_number: string;
  unit_value: number;
  supplier_code: string;
  supplier_name: string;
}

interface IQuotation {
  quotation_items: QuotationItemProps | undefined;
  selected: boolean;
  supplier: SupplierProps;
}

interface FilterItemsProps extends Object {
  id: number;
  code: string;
  name: string;
  material: string;
  region: string;
}

interface FilterSupplierProps extends Object {
  id: number;
  code: string;
  name: string;
  nickname: string;
  total_value: number;
}

interface QuotationItemSuppliersProps {
  supplier: number;
  items: number[];
}

const ComparativeMapData: React.FC<ComparativeMapDataProps> = ({
  items: searchedItems,
  suppliers: searchedSuppliers,
  rfq,
  round,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [suppliersIsOpen, setSuppliersIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemsCancelModalIsOpen, setItemsCancelModal] = useState(false);
  const [selecteds, setSelecteds] = useState<IQuotation[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierProps[]>([]);
  const [rfqId, setRfqId] = useState<number>();
  const history = useHistory();
  const wasCheckedAll = verifyCheckAll();

  const {
    comparativeMap,
    lines,
    setLines,
    handleSelectLine,
    isCurrentQuote,
  } = useContext(Context);
  function handleSelectSupplier(supplier: SupplierProps) {
    const newlines = lines.slice();
    let wasSelected = false;
    newlines.forEach(line =>
      line.quotations.forEach(q => {
        if (q.supplier === supplier) {
          const item = rfq.awarded_items.find(awarded =>
            awarded.quotation_item.find(
              qi => qi.item.id === q.quotation_items?.item_id,
            ),
          );
          if (!item) {
            let allowSelect = true;
            if (q.quotation_items) {
              if (
                q.quotation_items.item_st_control ===
                ST_CONTROL.RESOLVED_EXTERNALLY
              ) {
                allowSelect = false;
              }
            }

            if (allowSelect) {
              q.selected = !supplier.checked;
            }
            wasSelected = true;
          }
        }
      }),
    );

    if (wasSelected) supplier.checked = !supplier.checked;
    setLines(newlines);
  }

  function handleSelectAllSupplier(check: boolean) {
    suppliers.forEach(supplier => {
      // eslint-disable-next-line eqeqeq
      if (supplier.checked != check) {
        handleSelectSupplier(supplier);
      }
    });
  }

  function verifyCheckAll() {
    let missingCheck = false;

    suppliers.forEach(supplier => {
      if (!supplier.checked) {
        missingCheck = true;
      }
    });

    if (missingCheck) {
      return false;
    }

    return true;
  }

  function handleRewardItems() {
    openModal();
  }

  function openModal() {
    setModalIsOpen(true);
    const selecteds: IQuotation[] = [];
    lines.forEach(line =>
      line.quotations.forEach(q => {
        if (q.selected) selecteds.push(q);
      }),
    );
    setSelecteds(selecteds);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function openItemsCancelModal() {
    setItemsCancelModal(true);
  }

  function closeItemsCancelModal() {
    setItemsCancelModal(false);
  }

  function confirmRewardItems() {
    setModalIsOpen(false);
    history.push('/reward-items', {
      items: selecteds,
      quotationId: id,
      rfqId: rfqId,
    });
  }

  const isRewardable = (): boolean => {
    if (!lines) return false;

    if (
      lines.filter(line => line.quotations.find(q => q.selected)).length === 0
    )
      return false;

    return !lines.some(line => {
      const selecteds = line.quotations.filter(q => q.selected);

      return (
        selecteds.length > 1 ||
        selecteds.some(
          q =>
            q.quotation_items?.item_rfq_st_control !==
            ST_CONTROL.WAITING_DECISION,
        )
      );
    });
  };

  useEffect(() => {
    async function loadComparativeMapData(): Promise<void> {
      try {
        const { items, quotation_items_supplier } = comparativeMap;

        let filteredItems = items;
        let filteredQuotations = quotation_items_supplier;
        if (searchedItems.length > 0) {
          filteredItems = searchedItems.map(si => {
            const index = items.findIndex(i => i.item_id === si.id);
            return items[index];
          });
        }
        if (searchedSuppliers.length > 0) {
          filteredQuotations = searchedSuppliers.map(ss => {
            const index = quotation_items_supplier.findIndex(
              qs => qs.supplier.code === ss.code,
            );
            return quotation_items_supplier[index];
          });
        }
        const newLines = filteredItems.map((item: ItemsProps) => {
          const quotations = filteredQuotations.filter(
            (quote: QuotationProps) =>
              quote.quotation_items.filter(qi => qi.item_id === item.item_id),
          );
          return {
            item: item,
            quotations: quotations.map((quote: QuotationProps) => ({
              quotation_items: quote.quotation_items.find(
                qi => qi.item_id === item.item_id,
              ),
              selected: false,
              supplier: quote.supplier,
              quotation_id: quote.quotation_id,
            })),
          };
        });
        const suppliers = filteredQuotations.map(
          (fq: QuotationProps) => fq.supplier,
        );
        setLines(newLines);
        setSuppliers(suppliers);
        setRfqId(comparativeMap.rfq_id);
      } catch (error) {}
    }

    loadComparativeMapData();
  }, [searchedItems, searchedSuppliers, comparativeMap, setLines]);

  function checkSelectedItems() {
    if (!lines || !isCurrentQuote) return false;

    return lines.some(line => line.quotations.some(q => q.selected));
  }

  const handleNewRound = () => {
    let ids: number[] = [];
    let suppliers: number[] = [];

    lines.forEach(line => {
      line.quotations.forEach(q => {
        if (q.selected && q.quotation_items) {
          suppliers.push(q.supplier.id);
          ids.push(q.quotation_items.item_id);
        }
      });
    });

    // REMOVE DUPLICATE ITEMS
    suppliers = suppliers.filter((n, i) => suppliers.indexOf(n) === i);
    ids = ids.filter((n, i) => ids.indexOf(n) === i);
    history.push(`/quote/${id}/requote`, {
      items: ids,
      suppliers: suppliers,
    });
  };

  const handleItemsCancel = async () => {
    let quotationItemSuppliers: QuotationItemSuppliersProps[] = [];

    lines.forEach(line => {
      line.quotations.forEach(q => {
        if (!q.selected || !q.quotation_items) return;

        let quotationItemSupplier = quotationItemSuppliers[q.supplier.id];
        if (!quotationItemSupplier) {
          quotationItemSupplier = { supplier: q.supplier.id, items: [] };
        }
        quotationItemSupplier.items.push(q.quotation_items.item_id);
        quotationItemSuppliers[q.supplier.id] = quotationItemSupplier;
      });
    });

    quotationItemSuppliers = quotationItemSuppliers.filter(qis => qis !== null);

    const payload = {
      rfq: rfqId,
      quotation_item_suppliers: quotationItemSuppliers,
    };

    await api.post(`quotations/cancel_quotation_items/`, payload).then(() => {
      window.location.reload();
    });
  };

  const checkStatus = (): boolean => {
    if (!lines) return false;
    return !lines.some(line =>
      line.quotations
        .filter(q => q.selected)
        .some(
          selected =>
            selected.quotation_items?.item_rfq_st_control !==
            ST_CONTROL.WAITING_DECISION,
        ),
    );
  };

  const isCancelable = (): boolean => {
    if (!lines || !isCurrentQuote) return false;
    const linesSelected = lines.filter(line =>
      line.quotations.some(q => q.selected),
    );
    if (!linesSelected.length) return false;

    let isPossibleCancelable = true;

    linesSelected.forEach(line => {
      if (isPossibleCancelable) {
        isPossibleCancelable = !line.quotations.some(q => !q.selected);
      }
    });
    return isPossibleCancelable;
  };

  return (
    <Container className="w-full flex flex-col">
      <div className="w-full flex flex-col overflow-auto inline-grid">
        <div className="flex mt-2">
          <Columns className="flex flex-col">
            <div className="bg-bluejeans-500 flex px-4 py-3 rounded-t-lg">
              <div className="w-6 h-6">
                {wasCheckedAll ? (
                  <CheckBoxChecked
                    onClick={() => handleSelectAllSupplier(false)}
                    className="cursor-pointer"
                  />
                ) : (
                  <CheckBoxUnchecked
                    onClick={() => handleSelectAllSupplier(true)}
                    className="cursor-pointer"
                  />
                )}
              </div>
              <span className="font-default font-semibold text-sm text-white ml-2">
                {`${t('ai_suggestions')} ${round || '-'}° ${t('round')}`}
              </span>
            </div>
            <div className="flex flex-col border-2 border-t-0 pb-4 justify-center h-full">
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <RewardIcon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('reward')}
                </span>
              </div>
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <RetryIcon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('new_round')}
                </span>
              </div>
              <div className="flex mt-2 px-4 items-center">
                <div className="flex w-5 h-5">
                  <HelpBallon />
                </div>
                <span className="font-default font-normal text-sm text-yaleblue-900 ml-4">
                  {t('help_buyer')}
                </span>
              </div>
            </div>
          </Columns>
          {suppliers.map((item, index) => (
            <Columns
              key={`supplier-${index}`}
              className="flex flex-col border rounded-md w-52 pt-4 pb-2"
            >
              <div className="flex px-4">
                <div className="w-6 h-6">
                  {item.checked ? (
                    <CheckBoxChecked
                      onClick={() => handleSelectSupplier(item)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <CheckBoxUnchecked
                      onClick={() => handleSelectSupplier(item)}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                <span className="font-default text-sm text-yaleblue-800 ml-2 font-semibold break-words truncate">
                  {item.nickname}
                </span>
                <PinIcon />
              </div>
              <div className="ml-6 mt-2 px-4">
                <span className="font-default text-sm text-yaleblue-900 font-normal underline">
                  {item.cod_quotation}
                </span>
              </div>
              {suppliersIsOpen ? (
                <div className="flex px-4 justify-between mt-4">
                  <div className="flex flex-col ml-2">
                    <span className="font-light text-xs text-supplier-accordion-gray">
                      {t('payment_condition')}
                    </span>
                    <span className="font-bold text-xs text-bluejeans-500">
                      {item.payment_condition
                        ? `${item.payment_condition.code}`
                        : '-'}
                    </span>
                    <span className="font-light text-xs text-supplier-accordion-gray">
                      {t('incoterm')}
                    </span>
                    <span className="font-bold text-xs text-bluejeans-500">
                      {item.incoterm.name || '-'}
                    </span>
                    <span className="font-light text-xs text-supplier-accordion-gray">
                      {t('shipping_total_value')}
                    </span>
                    <span className="font-bold text-xs text-bluejeans-500">
                      {item.total_freight_rate}
                    </span>
                    <span className="font-light text-xs text-supplier-accordion-gray">
                      {t('rfq_total_value')}
                    </span>
                    <span className="font-bold text-xs text-bluejeans-500">
                      {item.total_rfq_value}
                    </span>
                  </div>
                  <div className="w-6 h-6">
                    <ChavronDownIcon
                      className="cursor-pointer"
                      onClick={() => setSuppliersIsOpen(false)}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-end mt-2 px-4">
                  <div className="w-6 h-6">
                    <ChavronUpIcon
                      className="cursor-pointer"
                      onClick={() => setSuppliersIsOpen(true)}
                    />
                  </div>
                </div>
              )}

              <div className="flex border-t items-center px-4 py-2 mt-2">
                <span className="font-default font-bold text-lg text-yaleblue-800">
                  {item.reduction_percentage || '0'}%
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800 ml-2">
                  {t('reduction')}
                </span>
              </div>
            </Columns>
          ))}
        </div>
        {lines &&
          lines.map((line, index) => (
            <ComparativeMapItem
              rfq={rfq}
              item={line}
              key={`comparativemap-item-${index}`}
              onSelect={item => handleSelectLine(item, index)}
            />
          ))}
      </div>

      <div className="flex justify-end mt-4 items-center">
        {userHasAnyPermissionOf(['add_quotation', 'change_quotation']) ? (
          <>
            {isCancelable() ? (
              <div
                onClick={openItemsCancelModal}
                className="text-bluejeans-800 underline font-bold text-sm mr-4 cursor-pointer"
              >
                {t('cancel_selecteds')}
              </div>
            ) : (
              <span className="text-basic-gray-400 underline font-bold text-sm mr-4 cursor-pointer">
                {t('cancel_selecteds')}
              </span>
            )}
            {checkSelectedItems() && checkStatus() ? (
              <div onClick={handleNewRound}>
                <span className="text-bluejeans-800 underline font-bold text-sm mr-4 cursor-pointer">
                  {t('new_selecteds_round')}
                </span>
              </div>
            ) : (
              <span className="text-basic-gray-400 underline font-bold text-sm mr-4 cursor-pointer">
                {t('new_selecteds_round')}
              </span>
            )}
            {isRewardable() ? (
              <Button
                type="button"
                className={`px-4 h-10 flex items-center justify-center text-sm text-bluejeans-800 font-bold`}
                onClick={handleRewardItems}
              >
                {t('reward_selecteds')}
              </Button>
            ) : (
              <Button
                type="button"
                className={`px-4 h-10 flex items-center justify-center text-sm text-basic-gray-400 font-bold`}
              >
                {t('reward_selecteds')}
              </Button>
            )}
          </>
        ) : (
          <>
            <span className="text-basic-gray-400 underline font-bold text-sm mr-4 cursor-pointer">
              {t('cancel_selecteds')}
            </span>
            <span className="text-basic-gray-400 underline font-bold text-sm mr-4 cursor-pointer">
              {t('new_selecteds_round')}
            </span>
            <Button
              type="button"
              className={`px-4 h-10 flex items-center justify-center text-sm text-basic-gray-400 font-bold`}
            >
              {t('reward_selecteds')}
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <ConfirmationIcon />
          <div className="my-4">
            <span className="font-default font-normal text-sm text-yaleblue-1000">
              <Trans
                i18nKey="confirmation_selected_reward_items"
                count={selecteds.length}
              />
            </span>
          </div>
          <div className="flex items-center justify-evenly">
            <span
              onClick={() => closeModal()}
              className="underline text-sm text-bluejeans-800 font-bold mr-4 cursor-pointer select-none"
            >
              {t('cancel')}
            </span>
            <ModalButton onClick={() => confirmRewardItems()}>
              {t('continue')}
            </ModalButton>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={itemsCancelModalIsOpen}
        onRequestClose={closeItemsCancelModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <DeleteIcon />
          <div className="my-4 mt-10 mb-7">
            <div className="font-default font-normal text-sm text-yaleblue-1000">
              {t('confirmation_cancel_items')}
            </div>
          </div>
          <div className="flex items-center ">
            <span
              className="underline text-sm text-bluejeans-800 mr-5 font-semibold cursor-pointer mr-4"
              onClick={closeItemsCancelModal}
            >
              {t('No')}
            </span>
            <Button className="h-12 px-8 text-base" onClick={handleItemsCancel}>
              {t('Yes')}
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default ComparativeMapData;
