import React, { FC, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import ListSuppliers from '../pages/Supplier/ListSuppliers/ListSuppliers';
import NewVendorList from '../pages/VendorList/NewVendorList/NewVendorList';
import EditVendorList from '../pages/VendorList/EditVendorList/EditVendorList';
import NewQuotation from '../pages/Quotation/NewQuotation/NewQuotation';
import Requote from '../pages/Quotation/Requote/Requote';
import ShowQuotation from '../pages/Quotation/ShowQuotation/ShowQuotation';
import Login from '../pages/Login/Login';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ShowRfq from '../pages/PurchasePanel/ShowRfq/ShowRfq';
import RewardItems from '../pages/RewardItems/RewardItems';
import UserForm from '../pages/UserForm/UserForm';
import { Context } from '../Context/Auth';
import { DomainContext } from '../Context/Subdomain/Domain';
import PurchasePanel from '../pages/PurchasePanel/PurchasePanel';
import { RouteProps } from 'react-router';
import ShowSupplier from '../pages/Supplier/ShowSupplier/ShowSupplier';
import SettingsPanel from '../pages/Settings/SettingsPanel';
import HomePanel from '../pages/HomePanel/HomePanel';
import { userHasAnyPermissionOf } from '../services/permission-service';
import Error403 from '../pages/error/403';
import Error404 from '../pages/error/404';
import authService from '../services/auth-service';
import { parseISO } from 'date-fns';

interface PrivateRouteProps {
  isPrivate: boolean;
  exact: boolean;
  path: string | string[];
  component: FC<{}>;
  permissionsNeeded: Array<string>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { isPrivate, permissionsNeeded, ...rest } = props;
  const { authLoading, user } = useContext(Context);
  const now = new Date();
  const [response, setResponse] = useState<boolean | null>(null);

  useEffect(() => {
    if (parseISO(user.expiryDate) < now) {
      if (parseISO(user.refreshTokenExpiryDate) < now || response === false) {
        user.isAuthenticated = false;
        authService.logout();
      } else {
        authService.refreshToken(user.refreshToken).then(resp => {
          setResponse(resp);
        });
      }
    }
  }, [user, response]);

  if (authLoading) {
    return null;
  }
  if (isPrivate && !user.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!userHasAnyPermissionOf(permissionsNeeded)) {
    authService.logout();
    return (
      <Redirect to={{ pathname: '/403', state: { unauthorized: true } }} />
    );
  }

  return <Route {...rest} />;
};

const WithoutSessionRoute = (props: RouteProps) => {
  const { path, ...rest } = props;
  const { user } = useContext(Context);

  if (user.isAuthenticated && path !== '/403') {
    return <Redirect to={{ pathname: '/home' }} />;
  }

  if (
    !user.isAuthenticated &&
    path !== '/login' &&
    path !== undefined &&
    !path.includes('passwordreset') &&
    !path.includes('forgot-password')
  ) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return <Route {...rest} />;
};

const Routes: React.FC = () => {
  const { createSubdomain, subDomain } = useContext(DomainContext);

  useEffect(() => {
    createSubdomain();
  }, [createSubdomain]);

  if (subDomain === '') {
    return (
      <BrowserRouter basename={subDomain}>
        <Switch>
          <Route exact path="/" component={Error404} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <div>
      {subDomain !== '' && (
        <BrowserRouter basename={subDomain}>
          <Switch>
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_rfq']}
              path="/home"
              component={HomePanel}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_supplier', 'view_vendorlist']}
              path="/suppliers"
              component={ListSuppliers}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_supplier']}
              path="/supplier/show/:id"
              component={ShowSupplier}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['add_vendorlist']}
              path="/vendor-lists/new"
              component={NewVendorList}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['change_vendorlist']}
              path="/vendor-lists/edit/:id"
              component={EditVendorList}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={[
                'view_purchaserequisition',
                'view_item',
                'view_rfq',
              ]}
              path="/purchase-panel"
              component={PurchasePanel}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['add_quotation']}
              path="/quote/new"
              component={NewQuotation}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_quotation']}
              path="/quote/show/:id"
              component={ShowQuotation}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['add_quotation']}
              path="/quote/:id/requote"
              component={Requote}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_rfq']}
              path="/rfq/show/:id"
              component={ShowRfq}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['add_quotation', 'change_quotation']}
              path="/reward-items"
              component={RewardItems}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_historicalquotation']}
              path="/settings-panel"
              component={SettingsPanel}
            />
            <PrivateRoute
              isPrivate
              exact
              permissionsNeeded={['view_historicalquotation']}
              path={['/new-user', '/new-user/:id']}
              component={UserForm}
            />
            <WithoutSessionRoute exact path="/login" component={Login} />
            <WithoutSessionRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <WithoutSessionRoute
              exact
              path="/passwordreset/confirm"
              component={ChangePassword}
            />
            <WithoutSessionRoute path="/403" component={Error403} />
            <WithoutSessionRoute path="/" />
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
};

export default Routes;
