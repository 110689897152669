import styled from 'styled-components';

export const Container = styled.div``;

export const ModalButton = styled.button`
  border: 1px solid #9ba8bb;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const Content = styled.div`
  max-height: 36rem;
  width: 36rem;
`;

export const ScrollableContent = styled.div`
  max-height: 30rem;
`;
