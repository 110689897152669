import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.form`
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
`;

export const ContentContainer = styled.div`
  min-height: 6rem;
  border-right: 2px solid #e7eaef;
  justify-content: flex-start;
  width: 155px;
  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    width: 185.5px;
    border-right: 0px;
  }
`;

type ErrorBoxType = {
  error: boolean;
};

export const ErrorBox = styled.div<ErrorBoxType>`
  border: 1px solid ${({ error }) => (error ? 'red' : '#ffffff')};
  display: flex;
  border-radius: 0.5rem;
  padding: 8px 0 8px 8px;
  align-items: center;
`;

export const BoxAlert = styled.div`
  margin-right: 8px;
`;
