import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, TabHeader } from './PageTab.style';

interface PageTabProps {
  activeTab: string;
  tabs: string[];
  backgroundColor: string;
  toggleAction: Function;
  isFilterComponent?: boolean;
  className?: string;
  count?: number[];
}

const PageTab: React.FC<PageTabProps> = ({
  activeTab,
  tabs,
  backgroundColor,
  toggleAction,
  children,
  isFilterComponent,
  className = '',
  count,
}) => {
  const { t } = useTranslation();
  const [pageTabs] = useState(tabs);

  return (
    <Container className={`flex w-full flex-col h-full ${className}`}>
      <div className={`flex flex-row ${isFilterComponent ? 'pl-4' : 'px-12'}`}>
        {pageTabs.map((tab, index) => (
          <TabHeader
            className={`bg-white cursor-pointer select-none flex h-15 justify-center items-end ml-20 px-6
             ${tab === activeTab ? 'bg-white' : backgroundColor}`}
            key={index}
            isActive={tab === activeTab}
            onClick={() => toggleAction(tab)}
          >
            <span
              title={t(tab)}
              className={`truncate text-sm ${
                tab === activeTab
                  ? 'font-semibold text-bluejeans-900'
                  : 'font-regular text-whitegray-100'
              } `}
            >
              {t(tab)}
            </span>
            {count !== undefined && (
              <span
                className={`font-default text-sm ml-2 ${
                  tab === activeTab
                    ? 'font-semibold text-bluejeans-800'
                    : 'font-regular text-whitegray-100'
                }`}
              >{` (${count[index]})`}</span>
            )}
          </TabHeader>
        ))}
      </div>
      <div className="bg-white flex h-full">{children}</div>
    </Container>
  );
};

export default PageTab;
