import React, { useState, useContext, useEffect } from 'react';
import {
  FirstContainer,
  SecondContainer,
  Logo,
  LoginTitle,
  TextNotFound,
  Label,
  WrapperButtonMicrosoft,
} from './Login.style';
import { ReactComponent as LogoHarpi } from '../../assets/logo-harpi.svg';
import { ReactComponent as CheckeboxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckeboxChecked } from '../../assets/checkbox-checked-icon.svg';
import { useTranslation } from 'react-i18next';
import { Context } from '../../Context/Auth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { parse } from 'query-string';
import Loading from '../../components/Loading/Loading';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState();
  const [didCheckAccessToken, setDidCheckAccessToken] = useState(false);
  const { handleSubmit } = useForm();
  const { handleLogin, tenant, authLoading } = useContext(Context);
  const history = useHistory();
  const BACKEND_PATH = process.env.REACT_APP_WEBAPI;
  const BACKEND_PATH_PROTOCOL = process.env.REACT_APP_WEBAPI_PROTOCOL;
  const BACKEND_ENVIRONMENT = process.env.REACT_APP_BACKEND_ENVIRONMENT;

  const toggleAction = () => {
    setIsChecked(!isChecked);
  };
  const handleEmail = (email: string) => {
    setEmail(email);
  };

  const onSubmit = () => {
    setLoading(true);
    handleLogin(email, password, isChecked, '').then(response => {
      setLoading(false);
      if (response === 200) {
        history.push('/home');
      } else if (response === 307) {
        // do nothing
      } else {
        setLoginError(response);
      }
    });
  };

  const handleForgotMyPassword = () => {
    history.push('/forgot-password');
  };

  const handleClickMS = () => {
    location.href = `${BACKEND_PATH_PROTOCOL}://${tenant}.${BACKEND_PATH}/oauth2/login`;
  };

  useEffect(() => {
    let isMounted = true;
    const queryParams = parse(location.search);
    const timer = setTimeout(() => {
      if (queryParams.token && !didCheckAccessToken) {
        setDidCheckAccessToken(true);
        handleLogin('', '', true, '' + queryParams.token).then(response => {
          if (isMounted) {
            if (response === 200) {
              history.push('/home');
            } else {
              setLoginError(response);
            }
          }
        });
      }
    }, 300);
    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [handleLogin, history, didCheckAccessToken, BACKEND_ENVIRONMENT]);

  return (
    <div className="h-screen flex h-1/6 w-full">
      {loading && <Loading />}
      <Logo className="absolute pl-8 z-10 hidden md:block" />
      <FirstContainer className="w-1/2 h-full justify-center pt-52 hidden md:flex">
        <LogoHarpi className="mr-10 z-20" />
      </FirstContainer>
      {!authLoading ? (
        <SecondContainer className="md:w-1/2 w-full h-full pb-11">
          {tenant ? (
            <>
              <form
                className="items-center justify-center flex flex-col h-full w-full lg:px-20"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Label
                  style={{
                    marginTop: 40,
                  }}
                >
                  <LoginTitle>
                    <div className="font-bold text-lg text-yaleblue-1000">
                      {t('start_session')}
                    </div>
                  </LoginTitle>
                </Label>
                <WrapperButtonMicrosoft>
                  <button
                    type="button"
                    className="button bg-primary text-lg text-white font-semibold rounded w-2/3 h-10 mt-6 z-20"
                    onClick={handleClickMS}
                  >
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.5 4L11.5 0L17.5 1.5V18.5L11.5 20L0.5 16L11.5 17.5V3L4.5 4.5V14.5L0.5 16V4Z"
                        fill="#E64A19"
                      />
                    </svg>
                    {t('microsoft_account')}
                  </button>
                </WrapperButtonMicrosoft>
                <Label
                  style={{
                    marginTop: 20,
                  }}
                >
                  {t('or')}
                </Label>

                {loginError && (
                  <span className="mt-4 text-error text-xs">
                    {t('invalid_credencial_error')}
                  </span>
                )}
                <input
                  type="text"
                  className={`bg-white border h-10 w-2/3 rounded shadow-sm text-basic-gray-500 mt-6 text-sm px-4 z-20 ${
                    loginError === 403
                      ? 'border-input-error'
                      : 'border-whitegray-100'
                  }`}
                  placeholder={t('email')}
                  onChange={e => handleEmail(e.target.value)}
                />
                <input
                  type="password"
                  autoComplete="off"
                  className={`bg-white border h-10 w-2/3 mt-6 rounded shadow-sm text-basic-gray-500 text-sm px-4 z-20 ${
                    loginError === 403
                      ? 'border-input-error'
                      : 'border-whitegray-100'
                  }`}
                  placeholder={t('password')}
                  onChange={e => setPassword(e.target.value)}
                />
                <button
                  type="submit"
                  className="button bg-primary text-lg text-white font-semibold rounded w-2/3 h-10 mt-6 z-20"
                >
                  {t('login')}
                </button>
                <div className="flex justify-between w-2/3 mt-6 z-50">
                  <div className="flex items-center">
                    {isChecked ? (
                      <CheckeboxChecked
                        onClick={toggleAction}
                        className="cursor-pointer"
                      />
                    ) : (
                      <CheckeboxUnchecked
                        onClick={toggleAction}
                        className="cursor-pointer"
                      />
                    )}
                    <div className="text-sm text-bluejeans-800 font-semibold underline ml-2 cursor-pointer z-20">
                      {t('keep_me_logged')}
                    </div>
                  </div>
                  <div
                    onClick={handleForgotMyPassword}
                    className="text-sm text-bluejeans-800 font-semibold underline cursor-pointer z-10"
                  >
                    {t('forgot_my_password')}
                  </div>
                </div>
                <div className="w-full flex justify-center mt-10">
                  <span className="text-xs text-bluejeans-800 font-light cursor-pointer z-20">
                    {`${t('need_help')}?`}
                  </span>
                </div>
              </form>
              <div className="flex items-end justify-center">
                <div className="text-xs text-bluejeans-800 font-light mr-4 cursor-pointer z-20">
                  {t('terms_of_use')}
                </div>
                <div className="text-xs text-bluejeans-800 font-light cursor-pointer z-20">
                  {t('privacy_policy')}
                </div>
              </div>
            </>
          ) : (
            <div className="items-center justify-center flex flex-col h-full w-full lg:px-20">
              <TextNotFound className="text-9xl">404</TextNotFound>
            </div>
          )}
        </SecondContainer>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Login;
