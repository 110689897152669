import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ButtonContainer } from './ListButton.style';
import { CSVLink } from 'react-csv';
import { userHasAnyPermissionOf } from '../../services/permission-service';
import { ListParams } from '../../services/base-service';
interface ListButtonProps {
  keyButtonText: string;
  className?: string;
  csvData?: string[][];
  permissionsNeeded: Array<string>;
  toogleAction?(params: ListParams | undefined): Promise<string[][]>;
  onClick?: () => void;
  upload?: boolean;
  params?: ListParams;
}

const ListButton: React.FC<ListButtonProps> = ({
  keyButtonText = '',
  className,
  permissionsNeeded,
  toogleAction,
  onClick,
  upload = false,
  params,
}) => {
  const { t } = useTranslation();
  const [csv, setCsv] = useState<string[][]>([]);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  async function handleCsvData(params: ListParams | undefined): Promise<void> {
    if (!toogleAction) return;
    const csvData = await toogleAction(params);
    setCsv(csvData);
    csvLinkRef?.current?.link.click();
  }

  return (
    <>
      {userHasAnyPermissionOf(permissionsNeeded) ? (
        <>
          <ButtonContainer
            className={`px-4 h-8 border-2 flex justify-between px-2.5 items-center cursor-pointer ${className}`}
            onClick={
              !upload ? () => handleCsvData(params) : () => onClick && onClick()
            }
          >
            <span className="text-bluejeans-800 text-sm mr-2">
              {t(keyButtonText)}
            </span>
            <DownloadIcon />
          </ButtonContainer>
          <CSVLink
            filename={csv.length > 0 ? `${Date.now()}.csv` : 'nodata.csv'}
            data={csv}
            asyncOnClick={true}
            ref={csvLinkRef}
            className="hidden"
          />
        </>
      ) : null}
    </>
  );
};

export default ListButton;
