import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  height: 60vh;
`;

export const Button = styled.button`
  border-radius: 4px;
  &:disabled {
    cursor: not-allowed;
    color: #1e40af;
    border-color: #1e40af;
    --tw-text-opacity: 1;
  }
`;

export const ContentContainer = styled.div`
  border-right: 2px solid #e7eaef;
  justify-content: flex-start;
  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    border-right: 0px;
  }
`;

export const ContentLastPurchase = styled.div`
  &:first-child {
    padding-left: 1rem;
    border-left: 2px solid #e7eaef;
  }
`;
