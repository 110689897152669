import styled from 'styled-components';

export const CustomComponent = styled.div`
  padding-left: 4rem;
  margin-bottom: 0.25rem;
`;
export const CustomFilterPosition = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
  height: 2.5rem;
`;
