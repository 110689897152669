import styled from 'styled-components';

export const NavbarContainer = styled.div`
  background-color: #32424f;
`;

export const ProfileCard = styled.div`
  border: 1px solid #32424f;
  border-radius: 6px 0px 6px 6px;
`;

export const TextHome = styled.div`
  font-family: 'Segoe UI';
`;
