/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Container, Content, Form, Row, Button } from './UserForm.style';
import Navbar from '../../components/Navbar/navbar';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import Select from '../../components/Select';
import { ReactComponent as IconBreadcrumb } from '../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import Input from '../../components/Input/Input';
import Loading from '../../components/Loading/Loading';

import api from '../../services/api';

import { userSchema } from '../../utils/schema';
import MultiInput from '../../components/MultiInput/MultiInput';
import { ReactComponent as AddIcon } from '../../assets/add-icon.svg';
import ListSelectItem from '../../components/ListSelectItem/ListSelectItem';
import { useForm } from 'react-hook-form';

enum Severity {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

interface Snackbar {
  severty: Severity;
  open: boolean;
  message: string;
}

interface OptionType {
  label: string;
  value: string;
}

interface BuyerGroup {
  id: number;
  name: string;
  description: string;
}

interface PostUserRequest {
  email: string;
  first_name: string;
  sap_number?: string;
  team?: string;
  buyer_group?: number;
  certificate_identity?: number;
  general_registration_number?: number;
  is_active?: boolean;
}

interface User {
  id: number;
  email: string;
  first_name: string;
  sap_number: number | null;
  team: string | null;
  certificate_identity: number | null;
  general_registration_number: number | null;
  is_active: boolean;
  buyer_group: number | null;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SNACKBAR_DEFAULT: Snackbar = {
  severty: Severity.info,
  open: false,
  message: '',
};

interface MultiInputOptions {
  id: number;
  value: string;
}

interface ObjectProps {
  id: number;
  description: string;
}

interface BuyerGroupProps extends ObjectProps {
  name: string;
  selected: boolean;
}

const UserForm: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [responseError, setResponseError] = useState<AxiosError | null>();
  const [loader, showLoader] = useState<boolean>();
  const [buyerGroupSelectList, setBuyerGroupSelectList] = useState<
    OptionType[]
  >([]);

  const [snackbar, setSnackbar] = useState<Snackbar>(SNACKBAR_DEFAULT);

  const closeErrorPage = () => setResponseError(null);

  const getBuyerGroup = async () => {
    try {
      showLoader(true);
      const response = await api.get('/buyer_groups/?limit=99999');
      if (response.status === 200) {
        setBuyerGroupSelectList(
          response.data.results.map((item: BuyerGroup) => ({
            value: item.id,
            label: item.name,
          })),
        );
      }
    } catch (_error) {
      setSnackbar({
        open: true,
        severty: Severity.error,
        message: t('get_gp_buyer'),
      });
    } finally {
      showLoader(false);
    }
  };

  const setForm = (user: User) => {
    formik.setFieldValue('name', user.first_name);
    formik.setFieldValue('email', user.email);
    formik.setFieldValue('team', user.team);
    formik.setFieldValue('sapNumber', user.sap_number);
    formik.setFieldValue('buyerGroup', user.buyer_group || '');
  };

  const getUserByIdI = async () => {
    try {
      showLoader(true);
      const response = await api.get(`/users/${id}/`);
      if (response.status === 200) {
        setForm(response.data);
      }
    } catch (_error) {
      setSnackbar({
        open: true,
        severty: Severity.error,
        message: t('get_user_error'),
      });
    } finally {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUserByIdI();
    }
  }, [id]);

  useEffect(() => {
    getBuyerGroup();
  }, []);

  const tryAgainFunction = () => {
    window.location.reload();
  };

  const updateUser = async () => {
    try {
      showLoader(true);
      setSnackbar(SNACKBAR_DEFAULT);
      const request: PostUserRequest = {
        email: formik.values.email,
        first_name: formik.values.name,
        sap_number: formik.values.sapNumber,
        team: formik.values.team,
        buyer_group: parseInt(formik.values.buyerGroup, 10),
        is_active: true,
      };
      const response = await api.put(`/users/${id}/`, request);
      if (response.status === 200) {
        setSnackbar({
          open: true,
          severty: Severity.success,
          message: t('update_user_success_message'),
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severty: Severity.error,
        message: t('update_user_error_message'),
      });
    } finally {
      showLoader(false);
      setTimeout(() => history.push('/settings-panel'), 3000);
    }
  };

  const createUser = async () => {
    try {
      showLoader(true);
      setSnackbar(SNACKBAR_DEFAULT);
      const request: PostUserRequest = {
        email: formik.values.email,
        first_name: formik.values.name,
        sap_number: formik.values.sapNumber,
        team: formik.values.team,
        buyer_group: parseInt(formik.values.buyerGroup, 10),
        is_active: true,
      };
      const response = await api.post('/users/', request);
      if (response.status === 201) {
        setSnackbar({
          open: true,
          severty: Severity.success,
          message: t('add_user_success_message'),
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severty: Severity.error,
        message: t('add_user_error_message'),
      });
    } finally {
      showLoader(false);
      setTimeout(() => history.push('/settings-panel'), 3000);
    }
  };

  const handleSubmit = () => {
    if (id) {
      updateUser();
    } else {
      createUser();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      team: '',
      sapNumber: '',
      profile: '',
      buyerGroup: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    validationSchema: () => userSchema,
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(SNACKBAR_DEFAULT);
  };

  const { register } = useForm();
  const [buyerGroups, setBuyerGroups] = useState<BuyerGroupProps[]>([]);
  const [buyerGroupList, setBuyerGroupList] = useState<BuyerGroupProps[]>([]);

  const [buyerGroupOptions, setBuyerGroupOptions] = useState<
    MultiInputOptions[]
  >([]);

  const [selectedBuyerGroups, setSelectedBuyerGroups] = useState<
    MultiInputOptions[]
  >([]);

  const handleSelectBuyerGroup = (option: string) => {
    const index = buyerGroups.findIndex(bg => bg.name === option);
    buyerGroupList.push(buyerGroups[index]);
    selectedBuyerGroups.push({
      id: buyerGroups[index].id,
      value: buyerGroups[index].name,
    });
    setSelectedBuyerGroups([...selectedBuyerGroups]);
    setBuyerGroupList([...buyerGroupList]);
  };

  const handleLoadedBgGroups = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }

    items.forEach((item: { name: string; id: number }) => {
      const newBuyerGroup = {
        id: item.id,
        value: item.name,
      };
      options.push(newBuyerGroup);
    });
    setBuyerGroupOptions(options);
    setBuyerGroups([...items]);
  };

  const removeBuyerGroup = (id: number) => {
    setSelectedBuyerGroups([
      ...selectedBuyerGroups.filter(function (bg) {
        return bg.id !== id;
      }),
    ]);
    setBuyerGroupList([
      ...buyerGroupList.filter(function (bg) {
        return bg.id !== id;
      }),
    ]);
  };

  const handleSelectedOptions = (
    selectedOptions: MultiInputOptions[],
    input: string,
  ) => {
    switch (input) {
      case 'buyer_group':
        setSelectedBuyerGroups([...selectedOptions]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {(responseError && (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      )) || (
        <Container className="flex-1 flex-col bg-primary">
          {loader && <Loading />}
          <Navbar pageTitle={''} selectedMenu="configurations" />
          <div className="flex items-center px-12 xl:px-20">
            <span className="font-default font-normal text-white text-sm">
              {t('user_list')}
            </span>
            <IconBreadcrumb className="h-2 w-2 mx-4" />
            <span className="font-default font-bold text-white text-sm">
              {t(id ? 'edit_user' : 'registering_user')}
            </span>
          </div>
          <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
            <Content className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6 mb-6">
              <div
                className="flex justify-between"
                style={{ marginBottom: 30 }}
              >
                <span className="font-default text-2xl text-yaleblue-800 font-semibold">
                  {t(id ? 'edit_user' : 'registering_user')}
                </span>
                <Link to="/settings-panel">
                  <CloseIcon className="h-3 w-3" />
                </Link>
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Input
                    controlled
                    label={`${t('name')}*`}
                    name="name"
                    id="name"
                    placeholder={`${t('userName')}*`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    autoComplete="off"
                    hasError={formik.touched.name && !!formik.errors.name}
                    messageError={
                      (formik.touched.name && formik.errors.name) || ''
                    }
                  />
                  <Input
                    controlled
                    label={`${t('email')}*`}
                    name="email"
                    id="email"
                    placeholder={`${t('emailUser')}*`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    autoComplete="off"
                    hasError={formik.touched.email && !!formik.errors.email}
                    messageError={
                      (formik.touched.email && formik.errors.email) || ''
                    }
                  />
                  <Input
                    controlled
                    label={t('team')}
                    name="team"
                    id="team"
                    placeholder={t('teamUser')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.team}
                    autoComplete="off"
                    hasError={formik.touched.team && !!formik.errors.team}
                    messageError={
                      (formik.touched.team && formik.errors.team) || ''
                    }
                  />
                  <Input
                    controlled
                    label={t('sapNumber')}
                    name="sapNumber"
                    id="sapNumber"
                    placeholder={t('sapNumberUser')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sapNumber}
                    autoComplete="off"
                    hasError={
                      formik.touched.sapNumber && !!formik.errors.sapNumber
                    }
                    messageError={
                      (formik.touched.sapNumber && formik.errors.sapNumber) ||
                      ''
                    }
                  />
                </Row>
                <Row>
                  <div className="div-select">
                    <Select
                      id="buyerGroup"
                      value={formik.values.buyerGroup}
                      options={buyerGroupSelectList}
                      defaultOptionText={t('select')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        (formik.touched.buyerGroup &&
                          formik.errors.buyerGroup) ||
                        ''
                      }
                      labelText={`${t('buyer_group_id')}*`}
                    />
                  </div>
                </Row>

                <div
                  className="flex flex-col mt-10"
                  style={{ display: 'none' }}
                >
                  <span className="font-default font-semibold text-lg text-yaleblue-800">
                    {t('buyer_group')}
                  </span>
                  <div className="flex w-full">
                    <MultiInput
                      type={'select'}
                      register={register}
                      icon={AddIcon}
                      options={buyerGroupOptions}
                      placeholder={t('select_warehouse')}
                      name={'warehouses'}
                      className="w-2/5 mt-4"
                      autoComplete="off"
                      showSelectedItems={true}
                      url={'buyer_groups/list_to_filter/?name='}
                      loadOptions={handleLoadedBgGroups}
                      handleSelectedOptions={handleSelectedOptions}
                      selectedOptions={selectedBuyerGroups}
                    />
                  </div>
                </div>

                <Row style={{ flexDirection: 'row-reverse' }}>
                  <Button id="add" onClick={formik.handleSubmit}>
                    {`${id ? t('update') : t('add')} ${t('user')}`}
                  </Button>
                </Row>
              </Form>
            </Content>
          </div>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackbar.severty}
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      )}
    </>
  );
};

export default UserForm;
