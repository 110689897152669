/* eslint-disable import/no-duplicates */
import React, { useState, useContext } from 'react';

import { Container, QuotationItems, Columns } from './ComparativeMapItem.style';
import { ReactComponent as ChavronUpIcon } from '../../assets/icon-chavron-down.svg';
import { ReactComponent as ChavronDownIcon } from '../../assets/icon-chavron-up.svg';
import { ReactComponent as CheckBoxChecked } from '../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import { ReactComponent as RetryIcon } from '../../assets/retry-icon.svg';
import { ReactComponent as HelpBallon } from '../../assets/help-ballon-icon.svg';
import { ReactComponent as RewardIcon } from '../../assets/reward-icon.svg';
import { useTranslation } from 'react-i18next';
import { parseISO, format, isValid, isDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Context } from '../../Context/ComparativeMapFilters';
import { Rfq } from '../../pages/Quotation/ShowQuotation/ShowQuotation';
import formatValue from '../../utils/formatValue';
import {
  AI_ANSWER_CODE,
  AI_ANSWER_CODE_ICONS,
  ST_CONTROL,
} from '../../utils/rfqStatusControl';
import './ComparativeMapItem.css';
interface LineProps {
  item: ItemsProps;
  quotations: IQuotation[];
}

interface IQuotation {
  quotation_items: QuotationItemProps | undefined;
  props: QuotationItemProps | undefined;
  selected: boolean;
  supplier: SupplierProps;
  quotation_id: number;
}

interface ItemsProps {
  item_id: number;
  item_name: string;
  last_purchase: LastPurchaseProps;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_branch_region: string;
  item_cod_material: string;
  item_num_gp: string;
}

interface LastPurchaseProps {
  date: string;
  order_number: string;
  unit_value: number;
  item_req: string;
  supplier_code: string;
  supplier_name: string;
}

interface QuotationItemProps {
  item_id: number;
  item_rfq_id: number;
  answer_code_AI: string;
  ia_final_decision_msg: string;
  ia_final_decision: string;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_rfq_st_control: number;
  item_st_control: number;
  item_branch_region: string;
  item_cod_material: string;
  vuo: number;

  [key: string]: number | string | null | Object;
}

interface SupplierProps {
  id: number;
  checked?: boolean;
  name: string;
  code: string;
  cod_quotation: string;
  nickname: string;
  incoterm: { id: number; name: string };
  payment_condition: { id: number; code: string };
  reduction_percentage: number | null;
  total_freight_rate: number;
  total_rfq_value: number;
}

interface Params {
  [index: string]: string;
}
interface ComparativeMapItemProps {
  item: LineProps;
  rfq: Rfq;

  onSelect?(item: IQuotation): void;
}

const ComparativeMapItem: React.FC<ComparativeMapItemProps> = ({
  item: line,
  rfq,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { params } = useContext(Context);
  const ComparativeMapParams: Params = {
    qto: '-',
    unm: '-',
    vuo: '-',
    vup: '-',
    vut: '-',
    icm: '-',
    icp: '-',
    ipi: '-',
    ipp: '-',
    vto: '-',
    vtp: '-',
    vtf: '-',
    dsc: '-',
    lte: '-',
    ent: '-',
    dtr: '-',
    nc1: '-',
    nc2: '-',
    iva: '-',
    mar: '-',
    por: '-',
    vendor_note: '-',
  };

  function isValidDate(date: string): Boolean {
    if (!isDate(date)) return false;
    const parsedDate = parseISO(date);
    return isValid(parsedDate);
  }

  function handleSelectItem(item: IQuotation) {
    if (onSelect) onSelect(item);
  }

  function renderCheckBox(quotation: IQuotation) {
    let isAwarded = false;

    rfq.awarded_items.forEach(award => {
      const item = award.quotation_item.find(
        qi => qi.item.id === quotation.quotation_items?.item_id,
      );
      if (
        item
        //   &&
        // AI_ANSWER_CODE.REWARD_MANUAL !== item.item.ia_final_decision
      ) {
        isAwarded = true;
      }
    });

    if (isAwarded) {
      let item = null;
      rfq.awarded_items.forEach(award => {
        let searchedItem = false;
        if (award.supplier.id === quotation.supplier.id) {
          searchedItem = !!award.quotation_item.find(
            itemSupplier =>
              itemSupplier.id === quotation.quotation_items?.item_rfq_id,
          );
        }

        if (searchedItem) {
          item = searchedItem;
        }
      });
      if (item) {
        return <CheckBoxChecked />;
      } else {
        return null;
      }
    }

    if (
      quotation.quotation_items &&
      quotation.quotation_items.item_st_control ===
        ST_CONTROL.RESOLVED_EXTERNALLY
    ) {
      return null;
    }

    return (
      quotation.quotation_items &&
      (quotation.selected ? (
        <CheckBoxChecked
          className="cursor-pointer"
          onClick={() => handleSelectItem(quotation)}
        />
      ) : (
        <CheckBoxUnchecked
          className="cursor-pointer"
          onClick={() => handleSelectItem(quotation)}
        />
      ))
    );
  }

  const verifyParams = (field: string) => {
    const showParams = params.find(pr => pr.field === field);
    if (showParams) {
      return true;
    }
    return false;
  };

  const isAPercentageField = (field: string) => {
    const percentageFields = ['ICM', 'ICP', 'IPI', 'IPP', 'DSC'];
    return percentageFields.includes(field.toUpperCase());
  };

  const getAiIcon = (
    answerCode: string | undefined,
    iaFinalDecision: string | undefined,
  ) => {
    if (!answerCode || !iaFinalDecision) return;
    const code = answerCode;

    if (answerCode !== iaFinalDecision) {
      if (
        AI_ANSWER_CODE_ICONS.REWARD.includes(iaFinalDecision) &&
        AI_ANSWER_CODE_ICONS.REWARD.includes(answerCode)
      ) {
        return <RewardIcon />;
      } else if (
        AI_ANSWER_CODE_ICONS.RETRY.includes(answerCode) ||
        AI_ANSWER_CODE_ICONS.RETRY.includes(iaFinalDecision)
      ) {
        return <RetryIcon />;
      } else if (AI_ANSWER_CODE_ICONS.HELP.includes(iaFinalDecision)) {
        return <HelpBallon />;
      } else {
        return;
      }

      // code = iaFinalDecision;
    }

    if (AI_ANSWER_CODE_ICONS.REWARD.includes(code)) {
      return <RewardIcon />;
    }
    if (AI_ANSWER_CODE_ICONS.RETRY.includes(code)) return <RetryIcon />;
    if (AI_ANSWER_CODE_ICONS.HELP.includes(code)) return <HelpBallon />;
  };
  return (
    <Container className="flex flex-col">
      <div className="bg-white flex">
        <Columns className="flex items-center py-4 pl-5 w-52 justify-between">
          <div>
            {line.item && line.item.item_name ? (
              <span
                className="font-default font-bold text-sm text-yaleblue-900 limit-nickname"
                title={line.item.item_name}
              >
                {line.item.item_name.substring(0, 50) +
                  (line.item.item_name.length > 49 ? '...' : '')}
              </span>
            ) : (
              <span></span>
            )}
            <br></br>
            {line.item &&
            line.item.item_cod_material &&
            line.item.item_branch_region ? (
              <span className="font-default font-bold text-sm text-yaleblue-900">
                {parseInt(line.item.item_cod_material).toString() +
                  ' - ' +
                  parseInt(line.item.item_branch_region).toString()}
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className="w-4 h-3 ml-2.5">
            {isOpen ? (
              <ChavronDownIcon
                className="cursor-pointer"
                onClick={() => setIsOpen(false)}
              />
            ) : (
              <ChavronUpIcon
                className="cursor-pointer"
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
        </Columns>
        {line.quotations.map((quotation, index) => (
          <Columns key={`quotation-items-${index}`} className="px-5">
            <QuotationItems className="flex h-full items-center w-40 justify-between px-2">
              <div className="w-5 h-5 ml-2">{renderCheckBox(quotation)}</div>
              <span className="ml-2 font-default font-semibold text-sm text-yaleblue-800">
                {quotation.quotation_items
                  ? formatValue(quotation.quotation_items.vuo)
                  : '-'}
              </span>
              <div
                className="ml-2 w-8"
                title={String(quotation.quotation_items?.ia_final_decision_msg)}
              >
                {quotation.quotation_items &&
                quotation.quotation_items.item_st_control !==
                  ST_CONTROL.RESOLVED_EXTERNALLY
                  ? getAiIcon(
                      quotation.quotation_items?.answer_code_AI,
                      quotation.quotation_items?.ia_final_decision,
                    )
                  : null}
              </div>
            </QuotationItems>
          </Columns>
        ))}
      </div>
      {isOpen && (
        <div className="border-t-2 rounded-b">
          <div className="flex">
            <Columns className="flex flex-col">
              <div className="border-b-2 flex flex-col px-5 pt-7 pb-4">
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {t('num_gp')}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {line.item.item_num_gp || '-'}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {t('item_rc_evaluation_value')}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  R$ {line.item.item_rc_evaluation_value}
                </span>
              </div>
              <div className="border-b-2 flex flex-col px-5 py-4">
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {t('item_baseline_value')}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  R$ {line.item.item_baseline_value}
                </span>
              </div>
              <div className="border-b-2 flex flex-col px-5 py-4">
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {t('target_value')}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {line.item.item_target_value
                    ? `R$ ${line.item.item_target_value}`
                    : '-'}
                </span>
              </div>
              <div className="flex flex-col px-5 py-4">
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {t('last_purchase')}:
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {`${t('order')} ${
                    line.item.last_purchase.order_number || '-'
                  }`}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {`${t('item')} ${line.item.last_purchase.item_req || '-'}`}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {`${t('date')} ${
                    line.item.last_purchase.date
                      ? format(
                          parseISO(line.item.last_purchase.date),
                          'dd/MM/yyyy',
                        )
                      : '-'
                  }`}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {`${t('unit_value')} ${
                    line.item.last_purchase.unit_value || '-'
                  }`}
                </span>
                <span className="font-default font-normal text-sm text-yaleblue-800">
                  {`${t('supplier')} ${
                    line.item.last_purchase.supplier_code || '-'
                  }`}
                </span>
                {line.item &&
                line.item.last_purchase &&
                line.item.last_purchase.supplier_name ? (
                  <span
                    className="font-default font-bold text-sm text-yaleblue-900 limit-nickname"
                    title={line.item.last_purchase.supplier_name}
                  >
                    {line.item.last_purchase.supplier_name.substring(0, 50) +
                      (line.item.last_purchase.supplier_name.length > 49
                        ? '...'
                        : '')}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
            </Columns>
            {line.quotations.map((supp, index) => {
              const props = supp.quotation_items;

              if (props) {
                Object.entries(props).forEach(item => {
                  const key = item[0].toString();
                  let value = item[1]?.toString() || '-';

                  if (isValidDate(value as string)) {
                    value = format(new Date(value as string), 'P', {
                      locale: ptBR,
                    });
                  }
                  if (Object.keys(ComparativeMapParams).includes(key)) {
                    Object.assign(ComparativeMapParams, { [key]: value });
                  }
                  if (key === 'custom_params' && Array.isArray(item[1])) {
                    item[1].forEach(val => {
                      if (
                        Object.keys(ComparativeMapParams).includes(val.field)
                      ) {
                        Object.assign(ComparativeMapParams, {
                          [val.field]: val.value || '-',
                        });
                      }
                    });
                  }
                });
              }

              return (
                <Columns key={`props-content-${index}`} className="px-5">
                  <QuotationItems className="flex h-full flex-col w-40">
                    {Object.entries(ComparativeMapParams).map((param, key) => (
                      <>
                        {verifyParams(param[0]) && (
                          <div key={`value-${key}`} className="flex">
                            <span
                              title={t(param[0])}
                              className="ml-5 text-sm text-yaleblue-800 font-light w-1/5"
                            >
                              {t(param[0].toUpperCase())}
                            </span>
                            <span className="ml-4 text-sm text-yaleblue-800 font-normal">
                              {isAPercentageField(param[0])
                                ? param[1] + '%'
                                : param[1]}
                            </span>
                          </div>
                        )}
                      </>
                    ))}
                  </QuotationItems>
                </Columns>
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
};

export default ComparativeMapItem;
