import styled from 'styled-components';

export const FilterDropdown = styled.div`
  border: 2px solid rgba(42, 78, 118, 0.08);
  box-sizing: border-box;
  border-radius: 4px;
`;

export const SelectBox = styled.div`
  border-color: rgba(42, 78, 118, 0.08);
  border-top-width: 2px;
  border-top-width: 2px;
`;
