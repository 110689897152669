import React, { useState, InputHTMLAttributes } from 'react';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface CurrencyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string | number;
  handleSetValue(value: string): void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  handleSetValue,
  ...rest
}) => {
  const [val, setVal] = useState(String(value).replace('.', ','));
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value);
    handleSetValue(event.target.value);
  };
  const defaultMaskOptions = {
    prefix: 'R$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 10, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  return (
    <MaskedInput
      value={val}
      onChange={onChange}
      mask={currencyMask}
      inputMode="numeric"
      {...rest}
    />
  );
};

export default CurrencyInput;
