import React, { useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-modal';
import ModalButton from '../Button/Button';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { Rfq } from '../../pages/Quotation/ShowQuotation/ShowQuotation';
import { Context } from '../../Context/ComparativeMap';
import { userHasAnyPermissionOf } from '../../services/permission-service';
import { AI_ANSWER_CODE } from '../../utils/rfqStatusControl';
import api from '../../services/api';

export interface IaSuggestion {
  description: string[];
  suppliersNumber: number;
  closingDateRfq: String;
  priority: string | undefined;
  resumeSuggestioAi: string;
}

interface IaSuggestionComponentProps {
  iaSuggestion: IaSuggestion;
  quotationRound: number;
  rfq: Rfq;
}

const IaSuggestionComponent: React.FC<IaSuggestionComponentProps> = ({
  iaSuggestion,
  quotationRound,
  rfq,
}) => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { comparativeMap } = useContext(Context);

  function confirmSuggestion() {
    async function acceptIaSuggestion(): Promise<void> {
      await api.post(`rfqs/${rfq.id}/accept_ia_suggestion/`).finally(() => {
        window.location.reload();
      });
    }
    acceptIaSuggestion();
    closeModal();
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const isAcceptable = (): boolean => {
    if (!comparativeMap.quotation_items_supplier) return false;

    return comparativeMap.quotation_items_supplier.some(qi =>
      qi.quotation_items.some(q =>
        [
          AI_ANSWER_CODE.REWARD_MANUAL,
          AI_ANSWER_CODE.NEW_ROUND_MANUAL,
        ].includes(q.answer_code_AI),
      ),
    );
  };

  return (
    <div>
      <div className="mt-12 mb-12">
        <div className="w-full rounded-xl border-2 border-basic-gray-transparent">
          <div className="flex p-6 border-b-2 border-basic-gray-transparent">
            <div className="text-sm text-bluejeans-800 font-semibold w-1/3">
              {`${t('quotation_round')} ${quotationRound}`}
            </div>
            <div className="text-sm text-bluejeans-800 font-semibold w-1/3">
              {t('suppliers_number')}
            </div>
            <div className="text-sm text-bluejeans-800 font-semibold w-1/3">
              {t('closing_date_rfq')}
            </div>
            <div className="text-sm text-bluejeans-800 font-semibold w-1/12">
              {t('priority')}
            </div>
          </div>
          <div className="flex p-6 border-b-2 border-basic-gray-transparent">
            <div className="w-1/3">
              {iaSuggestion.description.map((item, key) => (
                <div key={key} className="text-sm text-yaleblue-800">
                  {iaSuggestion.description.length > 1 ? `${item};` : item}
                </div>
              ))}
            </div>
            <div className="text-sm text-yaleblue-800 w-1/3">
              {iaSuggestion.suppliersNumber}
            </div>
            <div className="text-sm text-yaleblue-800 w-1/3">
              {iaSuggestion.closingDateRfq || '-'}
            </div>
            <div className="w-1/12">
              <div className="bg-maximum-yellowred-500 flex py-1 px-4 justify-center rounded-full">
                <span className="flex text-sm text-white whitespace-nowrap">
                  {iaSuggestion.priority || t('undefined')}
                </span>
              </div>
            </div>
          </div>
          <div className="p-6">
            <div className="text-sm text-bluejeans-800 font-semibold">
              {t('resume_suggestion_ai')}
            </div>
            <div className="text-sm text-yaleblue-800 mt-3.5">
              {iaSuggestion.resumeSuggestioAi}
            </div>
            <div className="mt-4 flex justify-end">
              {isAcceptable() &&
              userHasAnyPermissionOf(['add_quotation', 'change_quotation']) ? (
                <div
                  className="bg-primary h-12 flex items-center px-4 rounded text-white tex-sm cursor-pointer font-bold"
                  onClick={() => setModalIsOpen(true)}
                >
                  {t('accept_suggestion_ai')}
                </div>
              ) : (
                <div className="bg-basic-gray-400 h-12 flex items-center px-4 rounded text-white tex-sm font-bold">
                  {t('accept_suggestion_ai')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <ConfirmationIcon />
          <div className="my-4">
            <span className="font-default font-normal text-sm text-yaleblue-1000">
              <Trans i18nKey="ia_suggestion_reward" />
            </span>
          </div>
          <div className="flex items-center justify-evenly">
            <span
              onClick={() => closeModal()}
              className="underline text-sm text-bluejeans-800 font-bold mr-4 cursor-pointer select-none"
            >
              {t('cancel')}
            </span>
            <ModalButton onClick={confirmSuggestion}>
              {t('continue')}
            </ModalButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IaSuggestionComponent;
