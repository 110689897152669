import React, { ReactElement } from 'react';
import ReactLoading from 'react-loading';
import { LoadContainer } from './Loading.style';

interface LoadingProps {
  height?: string;
}

const Loading = ({ height = '' }: LoadingProps): ReactElement => (
  <LoadContainer
    className="flex justify-center absolute items-center z-50"
    style={{ height: height }}
  >
    <ReactLoading type={'spin'} color={'#3D6F9B'} height={100} width={60} />
  </LoadContainer>
);

export default Loading;
