import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 120px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const TableHead = styled.div`
  display: flex;
  width: 100%;
  padding-left: 14px;
`;

export const TableHeadItem = styled.div<{ size: number }>`
  user-select: none;
  display: flex;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #105d8a;
  flex: 1;
  margin-right: 20px;
  min-width: ${({ size }) => size}px;
`;

export const CardTable = styled.div<{ active: boolean }>`
  display: flex;
  width: 100%;
  height: 3.5rem;
  border: 2px solid rgba(42, 78, 118, 0.08);
  border-radius: 10px;
  align-items: center;
  border-left: 8px solid ${({ active }) => (active ? '#00ab84' : '#9BA8BB')};
  margin-top: 8px;
  padding-left: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #234263;

  span {
    flex: 1;
  }
`;

export const LinkRow = styled.span<{ size: number }>`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #105d8a;
  cursor: pointer;
  margin-right: 20px;
  min-width: ${({ size }) => size}px;
`;

export const CellValue = styled.span<{ size: number }>`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #234263;
  margin-right: 20px;
  min-width: ${({ size }) => size}px;
  padding: 5px;
`;

export const Button = styled.div`
  width: 120px;
  height: 32px;
  background: #105d8a;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
`;
