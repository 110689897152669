import React, { useContext, useEffect, useRef, useState } from 'react';

import { ChartContainer, Container, StyledSVG } from './ShowQuotation.style';
import { useTranslation } from 'react-i18next';

import Navbar from '../../../components/Navbar/navbar';
import FilterButton from '../../../components/FilterButton/FiltterButton';
import ComparativeMapData from '../../../components/ComparativeMapData/ComparativeMapData';
import ComparativeMapHistoricData from '../../../components/ComparativeMapHistoricData/ComparativeMapHistoricData';
import IaSuggestionResume, {
  IaSuggestion,
} from '../../../components/IaSuggestion/IaSuggestion';
import LineChart from '../../../components/LineChart/LineChart';
import FilterComparativeMap from '../../../components/FilterComparativeMap/FilterComparativeMap';
import { Context } from '../../../Context/ComparativeMapFilters';
import { Context as ComparativeMapContext } from '../../../Context/ComparativeMap';

import { ReactComponent as ViewMoreIcon } from '../../../assets/view-more-icon.svg';
import { ReactComponent as CheckboxChecked } from '../../../assets/checkbox-checked-icon.svg';
import { ReactComponent as SuppliersIcon } from '../../../assets/suppliers-chart-icon.svg';
import { ReactComponent as ItemsIcon } from '../../../assets/items-chart-icon.svg';
import { ReactComponent as TimeIcon } from '../../../assets/time-chart-icon.svg';
import { ReactComponent as SupplierIcon } from '../../../assets/suppliers-icon.svg';
import { ReactComponent as ListIcon } from '../../../assets/list-ordered-icon.svg';
import { ReactComponent as BoxIcon } from '../../../assets/box-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';

import api from '../../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import CustomDropdown from '../../../components/CustomDropdown/CustomDropdown';
import CustomSelectCheckbox from '../../../components/CustomSelectCheckbox/CustomSelectCheckbox';
import Loading from '../../../components/Loading/Loading';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import { format, parseISO } from 'date-fns';
import { AxiosError } from 'axios';
import _ from 'lodash';

interface ChartData {
  type: string;
  supplierId?: number;
  supplierName?: string;
  data: Array<number | null>;
  order: number;
  backgroundColor: string[] | string;
  borderColor: string;
  borderWidth: number;
  pointBackgroundColor?: string;
  radius?: number;
  hidden: boolean;
}

interface Supplier {
  supplierName: string;
  supplierId: number;
  checked: boolean;
  color: string;
}

interface QuotationSupplier {
  id: number;
  supplier_id: number;
  supplier_social_name: string;
  supplier_nickname: string;
  items: Items[];
  item: Items;
  total_value: number;
  total_vto: string;
  total_target: number;
}
interface Items {
  id: number;
  name: string;
  quantity: number;
  unit_price: number;
}

interface QuotationDetail {
  roundNumber: number;
  closingDateRfq: string;
  buyer: string;
  rfq_number: string;
  suppliers: number;
  items: number;
  deadline: string;
}

interface DropdownContent {
  label: string;
}

interface ParametersProps extends Object {
  field: string;
  description: string;
}

interface FilterItemsProps extends Object {
  id: number;
  code: string;
  name: string;
  material: string;
  region: string;
  st_control: string;
}

interface FilterSupplierProps extends Object {
  id: number;
  code: string;
  name: string;
  nickname: string;
  total_value: number;
}

interface HistoricData {
  cod_quotation: string;
  deadline: string;
  id: number;
  num_quotation: number;
  quotation: HistoricDataQuotation[];
}
interface HistoricDataQuotation {
  supplier_code: string;
  supplier_name: string;
  supplier_nickname: string;
  payment_condition: string;
  incoterm: Incoterm;
  total_freight_rate: number;
  total_rfq_value: number;
  total_value: number;
  items: Items[];
}

interface Incoterm {
  id: number;
  name: string;
}
interface Items {
  item_name: string;
  item_id: number;
  quantity: number;
  unit_price: number;
  was_awarded: boolean;
  target: number;
  net_price: number;
  answer_code_AI: string;
  ia_final_decision: string;
  qto: number | null;
  vuo: number | null;
  ipi: number | null;
  ipp: number | null;
  icm: number | null;
  icp: number | null;
  pzo: string | null;
  prz: number | null;
  vto: string | null;
  dsc: number | null;
  vup: number | null;
  mar: string | null;
  pcd: string | null;
  nc1: number | null;
  nc2: number | null;
  rec: number | null;
  iva: number | null;
  vtf: number | null;
  ent: string | null;
  por: number | null;
  vut: number | null;
  unm: number | null;
  lte: number | null;
  vtp: number | null;
  dtr: string | null;
}

export interface Rfq {
  id: number;
  st_control: number;
  num_rfq: string;
  awarded_items: AwardedItems[];
  item_req_list: Object[];
  user_creator: User;
}

interface User {
  first_name: string;
  buyer_group: BuyerGroup;
}

interface BuyerGroup {
  name: string;
}
interface AwardedItems {
  quotation_item: QuotationSupplier[];
  supplier: FilterSupplierProps;
}

interface Performance {
  performance_by_round: PerformanceRound[];
  quotation: Quotation[];
}

interface PerformanceRound {
  quotation_id: number;
  num_quotation: number;
  quotation: Quotation[];
  total_round_target: number;
}

interface Quotation {
  supplier_id: number;
  supplier_social_name: string;
  supplier_nickname: string;
  items: Items[];
  total_value: number;
  total_target: number;
  total_vto: string;
}

const ShowQuotation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setParams } = useContext(Context);

  const { id } = useParams<{ id: string }>();
  const [quoteId, setQuoteId] = useState();
  const [rfqDetails, setRfqDetails] = useState<Rfq>({} as Rfq);
  const [suggestedMax, setSuggestedMax] = useState(0);
  const suggestedMin = 0;
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [performance, setPerformance] = useState<Performance>();
  const [changedPerformance, setChangedPerformance] = useState<Performance>();
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [quotationDetail, setQuotationDetail] = useState<QuotationDetail>();
  const [itemsOptions, setItemsOptions] = useState<DropdownContent[]>([]);
  const [filterType, setFilterType] = useState('');
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [isHistoricData, setIsHistoricData] = useState(false);
  const [roundItems, setRoundItems] = useState<DropdownContent[]>([]);

  const [viewMore, setViewMore] = useState(false);
  const [historicData, setHistoricData] = useState<HistoricData[]>([]);
  const [loading, setLoading] = useState(true);
  const [quotationRound, setQuotationRound] = useState(0);

  const [filterData, setFilterData] = useState<Object[]>([]);
  const [parameters, setParameters] = useState<ParametersProps[]>([]);
  const [filterItems, setFilterItems] = useState<FilterItemsProps[]>([]);
  const [searchedItems, setSearchedItems] = useState<FilterItemsProps[]>([]);
  const [filterSupplier, setFilterSuppliers] = useState<FilterSupplierProps[]>(
    [],
  );
  const [searchedSuppliers, setSearchedSuppliers] = useState<
    FilterSupplierProps[]
  >([]);
  const [quotations, setQuotations] = useState<DropdownContent[]>([]);
  const [selectedRound, setSelectedRound] = useState<string>();
  const [isReloadingQuotation, setIsReloadingQuotation] = useState(false);

  const { setComparativeMap, comparativeMap, setIsCurrentQuote } = useContext(
    ComparativeMapContext,
  );

  const [iaSuggestion, setIaSuggestion] = useState<IaSuggestion>(
    {} as IaSuggestion,
  );

  const [responseError, setResponseError] = useState<AxiosError | null>();

  const closeShowQuote = () => {
    history.push('/purchase-panel');
  };

  function openFilter(filterType: string) {
    setFilterIsOpen(true);
    setFilterType(filterType);
    switch (filterType) {
      case 'parameters':
        setFilterData(parameters);
        break;
      case 'items':
        setFilterData(filterItems);
        break;
      case 'suppliers':
        setFilterData(filterSupplier);
        break;
    }
  }

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    async function loadQuoteId(): Promise<void> {
      await api
        .get(`/rfqs/${id}/`)
        .then(rfqResponse => {
          const rfq = rfqResponse.data;
          setRfqDetails(rfq);
          setQuoteId(rfq.last_quotation_id);
          let merchandiseGroup: string[] = [];
          rfq.item_req_list.forEach(
            (item: { merchandise_group: { erp_id: string } }) => {
              if (!merchandiseGroup.includes(item.merchandise_group.erp_id)) {
                merchandiseGroup.push(item.merchandise_group.erp_id);
              }
            },
          );
          merchandiseGroup = merchandiseGroup.filter(
            (n, i) => merchandiseGroup.indexOf(n) === i,
          );
          const suggestion = {
            description: merchandiseGroup,
            suppliersNumber: rfq.suppliers.length,
            closingDateRfq: format(parseISO(rfq.deadline), 'dd/MM/yyyy'),
            priority: rfq.highest_urgency,
            resumeSuggestioAi:
              'A escolha da IA contempla o fornecedor com a maior redução de custos, na comparação de propostas vigentes, e também os itens que se adequam ao menor valor e melhor cálculo de tempo de negociação.',
          };
          setIaSuggestion(suggestion);
        })
        .catch(setResponseError);
    }

    loadQuoteId();
  }, [id, comparativeMap]);

  useEffect(() => {
    if (!quoteId || !rfqDetails || chartData.length !== 0) return;
    async function loadGraphicData(): Promise<void> {
      await api
        .get(`/quotations/${quoteId}/performance/`)
        .then(response => {
          const results = response.data;
          const chartContent: ChartData[] = [];
          const labels: string[] = [];
          const totalVto: number[] = [];
          let options: DropdownContent[] = [];
          const colors: string[] = [];
          const round = results.performance_by_round;
          round.forEach(
            (
              performance: {
                quotation: QuotationSupplier[];
                total_round_target: number;
              },
              key: number,
            ) => {
              labels.push(`${t('round')} ${(key + 1).toString()}`);
              performance.quotation.forEach((quotationSupplier, quoteKey) => {
                options = handleItemsOptions(quotationSupplier.items, options);
                totalVto.push(parseInt(quotationSupplier.total_vto));
                const color = createColor(quoteKey);
                colors.push(color);
                const parsedVto = parseFloatValue(quotationSupplier.total_vto);
                quotationSupplier.total_vto = parsedVto.toString();
                insertLineChart(quotationSupplier, color, chartContent, false);
                handleSuppliersOptions(quotationSupplier, color);
              });
              insertBubbleChart(
                chartContent,
                parseFloatValue(performance.total_round_target),
                round.length,
              );
            },
          );
          const totalValueMax = Math.max(...totalVto);
          setChartData(chartContent);
          setSuggestedMax(totalValueMax);
          setChartLabels(labels);
          setItemsOptions([...options]);
          setRoundItems([...options]);
          setPerformance(results);
          setQuotationDetail({
            roundNumber: round[round.length - 1].num_quotation,
            rfq_number: rfqDetails.num_rfq || '-',
            buyer: `${rfqDetails.user_creator?.first_name} - ${
              rfqDetails.user_creator?.buyer_group?.name || ''
            }`,
            closingDateRfq: results.deadline.date,
            suppliers: results.suppliers_count,
            items: rfqDetails.item_req_list.length,
            deadline:
              results.deadline.days > 0 ? `${results.deadline.days}D` : 'Today',
          });
        })
        .catch(setResponseError);
    }

    const handleItemsOptions = (
      items: Items[],
      newOptions: DropdownContent[],
    ) => {
      items.forEach(option => {
        const found = newOptions?.find(
          options => options.label === option.name,
        );
        if (!found) {
          newOptions.push({
            label: option.name,
          });
        }
      });
      return newOptions;
    };

    function handleSuppliersOptions(
      qtSupplier: QuotationSupplier,
      color: string,
    ) {
      const hasSupplier = suppliers.find(
        sup => sup.supplierId === qtSupplier.supplier_id,
      );
      if (!hasSupplier) {
        suppliers.push({
          supplierName: qtSupplier.supplier_nickname,
          supplierId: qtSupplier.supplier_id,
          checked: true,
          color: color,
        });
      }
    }

    async function loadHistoricData(): Promise<void> {
      await api
        .get(`/quotations/${quoteId}/history/`)
        .then(response => {
          setHistoricData(response.data);
        })
        .catch(setResponseError);
    }

    loadGraphicData();
    loadHistoricData();
  }, [chartData.length, quoteId, rfqDetails, suppliers, t]);

  function insertLineChart(
    quotation: Quotation,
    color: string,
    chartContent: ChartData[],
    hidden: boolean,
  ) {
    const chartIndex = chartContent.findIndex(
      sup => sup.supplierId === quotation.supplier_id,
    );
    const parsedVto = parseFloat(quotation.total_vto);
    if (chartIndex < 0) {
      chartContent.push({
        type: 'line',
        supplierId: quotation.supplier_id,
        supplierName: quotation.supplier_nickname,
        data: [parsedVto],
        order: 1,
        backgroundColor: ['transparent'],
        borderColor: color,
        borderWidth: 1,
        pointBackgroundColor: color,
        hidden: hidden,
      });
    } else {
      chartContent[chartIndex].data.push(parsedVto);
    }
  }

  function insertBubbleChart(
    chartContent: ChartData[],
    target: number,
    performanceLength: number,
  ) {
    if (performanceLength > 1) {
      const bubbleType = chartContent.find(chart => chart.type === 'bubble');
      if (!bubbleType) {
        chartContent.push({
          data: [null],
          type: 'bubble',
          borderColor: '#49AEEA',
          borderWidth: 2,
          backgroundColor: 'white',
          order: 2,
          radius: 8,
          hidden: false,
        });
      } else {
        bubbleType.data.push(target);
      }
    }
  }

  function createColor(quoteKey: number) {
    const goldenAngle = 180 * (3 - Math.sqrt(5));
    const color = `hsl(${quoteKey * goldenAngle + 60}, 100%, 50%)`;
    return color;
  }

  const toogleItemOption = (option: DropdownContent, action: string) => {
    let newItems = roundItems;
    if (action === 'select') {
      newItems.push(option);
    } else {
      newItems = newItems.filter(item => item !== option);
    }
    setRoundItems([...newItems]);
    handleSelectedRoundItems(newItems);
  };

  const handleSelectedRoundItems = (items: DropdownContent[]) => {
    const newPerformance = JSON.parse(JSON.stringify(performance));
    performance?.performance_by_round.forEach((item, key) => {
      item.quotation.forEach((quote, quoteKey) => {
        newPerformance.performance_by_round[key].quotation[
          quoteKey
        ].items = items.map(selectedItem => {
          return quote.items.find(
            (item: { name: string }) => item.name === selectedItem.label,
          );
        });
      });
    });
    recalculateChartValues(newPerformance);
  };

  const parseFloatValue = (value: number | string) => {
    let newValue = value.toString().replaceAll('.', '');
    newValue = newValue.replaceAll(',', '.');
    return parseFloat(newValue);
  };

  const recalculateChartValues = (newPerformanceData: Performance) => {
    newPerformanceData.performance_by_round.forEach(round => {
      round.quotation.forEach(quote => {
        let totalVto = 0;
        let targetValue = 0;
        quote.items.forEach(item => {
          if (item) {
            totalVto +=
              (parseFloatValue(item.net_price) * item.quantity) /
              item.unit_price;
            targetValue += parseFloatValue(item.target) * item.quantity;
          }
        });
        quote.total_target = _.round(targetValue, 2);
        quote.total_vto = totalVto.toString();
      });
    });
    setChangedPerformance(newPerformanceData);
    rebuildLineChart(newPerformanceData);
  };

  const rebuildLineChart = (performanceData: Performance) => {
    const chartContent: ChartData[] = [];
    const filteredSuppliers = suppliers.filter(sup => !sup.checked);
    const round = performanceData.performance_by_round;
    round.forEach(
      (performance: { quotation: Quotation[]; total_round_target: number }) => {
        performance.quotation.forEach((quotationSupplier, key) => {
          const color = createColor(key);
          const hiddenSupplier = filteredSuppliers.some(
            sup => sup.supplierId === quotationSupplier.supplier_id,
          );
          insertLineChart(
            quotationSupplier,
            color,
            chartContent,
            hiddenSupplier,
          );
        });
        insertBubbleChart(
          chartContent,
          parseFloatValue(performance.total_round_target),
          round.length,
        );
      },
    );
    setChartData([...chartContent]);
  };

  const handleCheckbox = (key: number) => {
    suppliers[key].checked = !suppliers[key].checked;
    setSuppliers([...suppliers]);
    const newPerformance = changedPerformance || performance;
    if (newPerformance) rebuildLineChart(newPerformance);
  };

  useEffect(() => {
    if (!quoteId) return;

    function loadParameters(): Promise<void> {
      return api
        .get(`/quotations/${quoteId}/comparative_map/parameters/`)
        .then(response => {
          setParameters(response.data);
          setParams(response.data as ParametersProps[]);
        })
        .catch(setResponseError);
    }

    function loadSuppliersAndItems(): Promise<void> {
      return api
        .get(`/quotations/${quoteId}/suppliers_and_items/`)
        .then(response => {
          const { suppliers, items } = response.data;
          setFilterItems(items);
          setSearchedItems(items);
          setFilterSuppliers(suppliers);
          setSearchedSuppliers(suppliers);
        })
        .catch(setResponseError);
    }

    function loadComparativeMap(): Promise<void> {
      return api
        .get(`/quotations/${quoteId}/comparative_map/`)
        .then(response => {
          const comparativeMap = response.data;
          setComparativeMap(comparativeMap);
          const quotations = comparativeMap.rfq_quotations
            .slice()
            .reverse()
            .map((rfq: { quotation_order: number }) => ({
              label: rfq.quotation_order,
            }));
          const rfqQuotations = comparativeMap.rfq_quotations;
          const quotation = rfqQuotations[rfqQuotations.length - 1];
          setQuotationRound(quotation.quotation_order);
          if (quotation.quotation_id === quoteId) {
            setIsCurrentQuote(true);
          } else {
            setIsCurrentQuote(false);
          }
          setQuotations(quotations);
          setSelectedRound(quotation.quotation_order);
        })
        .catch(setResponseError);
    }
    (async () => {
      showLoader(true);
      await Promise.all([
        loadParameters(),
        loadSuppliersAndItems(),
        loadComparativeMap(),
      ]);
      showLoader(false);
    })();
  }, [quoteId, setComparativeMap, setIsCurrentQuote, setParams]);

  const FilterButtons = [
    {
      button: 'viewByRound',
      keyText: t('view_by_round'),
      icon: null,
      iconSelec: null,
    },
    {
      button: 'historyView',
      keyText: t('history_view'),
      icon: null,
      iconSelec: null,
    },
  ];

  function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setViewMore(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function applyFilter(selecteds: number[]) {
    const filtereds = selecteds.map(index => filterData[index]);
    let newItems = [];
    switch (filterType) {
      case 'parameters':
        setParams(filtereds as ParametersProps[]);
        break;
      case 'suppliers':
        if (filtereds.length === 0) {
          setSearchedSuppliers(filterSupplier);
          break;
        }
        newItems = (filtereds as FilterSupplierProps[]).map(item =>
          filterSupplier.find(i => i.code === item.code),
        );
        setSearchedSuppliers(newItems as FilterSupplierProps[]);
        break;
      case 'items':
        if (filtereds.length === 0) {
          setSearchedItems(filterItems);
          break;
        }
        newItems = (filtereds as FilterItemsProps[]).map(item =>
          filterItems.find(i => i.id === item.id),
        );
        setSearchedItems(newItems as FilterItemsProps[]);
        break;
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef);

  const toogleQuoteOption = (option: string) => {
    async function reloadComparativeMap(quoteNumber: number) {
      setIsReloadingQuotation(true);
      await api
        .get(`/quotations/${quoteNumber}/comparative_map/`)
        .then(response => {
          setComparativeMap(response.data);
          if (
            comparativeMap.rfq_quotations[
              comparativeMap.rfq_quotations.length - 1
            ].quotation_id === quoteNumber
          ) {
            setIsCurrentQuote(true);
          } else {
            setIsCurrentQuote(false);
          }
        })
        .catch(error => {
          setIsReloadingQuotation(false);
          setResponseError(error);
        });
      await api
        .get(`/quotations/${quoteNumber}/suppliers_and_items/`)
        .then(response => {
          const { suppliers, items } = response.data;
          setFilterItems(items);
          setSearchedItems(items);
          setFilterSuppliers(suppliers);
          setSearchedSuppliers(suppliers);
        })
        .catch(error => {
          setIsReloadingQuotation(false);
          setResponseError(error);
        });
      setSelectedRound(option);
      setIsReloadingQuotation(false);
    }

    const quoteIndex = comparativeMap.rfq_quotations.findIndex(
      rfq => rfq.quotation_order === parseInt(option),
    );

    const quoteNumber = comparativeMap.rfq_quotations[quoteIndex].quotation_id;
    reloadComparativeMap(quoteNumber);
  };

  const toogleHistoricData = () => {
    setIsHistoricData(!isHistoricData);
  };

  const closeErrorPage = () => setResponseError(null);

  const tryAgainFunction = () => {
    window.location.reload();
  };

  return (
    <div>
      {isReloadingQuotation && <Loading />}
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary">
          <Navbar pageTitle={''} selectedMenu="panel" />
          {loading ? (
            <Loading />
          ) : (
            <div className="w-full px-28 pt-5 bg-white pb-4">
              <div className="flex mb-2 justify-between">
                <div>
                  <span className="text-2xl text-yaleblue-800 font-light">
                    {`${quotationRound}° ${t('round')} -`}
                  </span>
                  <span className="text-2xl text-yaleblue-800 font-semibold pr-72">
                    {`${t('rfq')} ${quotationDetail?.rfq_number}`}
                  </span>
                </div>
                <div className="mt-4">
                  <CloseIcon
                    className="h-3 w-3 cursor-pointer"
                    onClick={() => closeShowQuote()}
                  />
                </div>
              </div>
              <div className="flex">
                <span className="text-xl text-yaleblue-800 font-light">
                  {`${t('responsible_buyer')}:`}
                </span>
                <span className="text-xl text-yaleblue-800 font-semibold pl-2">
                  {quotationDetail?.buyer}
                </span>
              </div>
              <div className="flex mb-8">
                <span className="text-xl text-yaleblue-800 font-light">
                  {`${t('closing_date_rfq')}:`}
                </span>
                <span className="text-xl text-yaleblue-800 font-semibold pl-2">
                  {quotationDetail?.closingDateRfq}
                </span>
              </div>
              <div className="flex w-full">
                <div className="border-2 border-basic-gray-transparent flex flex-col items-center py-12 rounded-xl w-44 mb-12">
                  <SuppliersIcon />
                  <div className="font-bold mt-2 text-xl">
                    {quotationDetail?.suppliers}
                  </div>
                  <div className="text-lg mb-6">{t('suppliers')}</div>
                  <ItemsIcon />
                  <div className="font-bold mt-2 text-xl">
                    {quotationDetail?.items}
                  </div>
                  <div className="text-lg mb-6">{t('items')}</div>
                  <TimeIcon />
                  <div className="font-bold mt-2 text-xl">
                    {quotationDetail?.deadline === 'Today'
                      ? t('today')
                      : quotationDetail?.deadline}
                  </div>
                  <div className="text-lg">{t('remaining')}</div>
                </div>
                <div className="flex mb-12 ml-8 overflow-hidden w-full">
                  <div className="border-2 border-basic-gray-transparent py-6 relative rounded-xl w-full">
                    <div className="absolute flex justify-between pb-4 px-3.5 w-full">
                      <div className="font-semibold text-lg text-yaleblue-1000">
                        {t('performance_by_round')}
                      </div>
                      <div className="z-10">
                        <CustomSelectCheckbox
                          options={itemsOptions}
                          title={t('select_item')}
                          toogleItemOption={toogleItemOption}
                          selectedItems={roundItems}
                        />
                      </div>
                    </div>
                    <ChartContainer className="border-basic-gray-transparent border-t-2 mt-14 overflow-x-auto whitespace-nowrap">
                      <div className="px-3.5 pt-4 w-full">
                        <LineChart
                          datasets={chartData}
                          chartLabels={chartLabels}
                          suggestedMin={suggestedMin}
                          suggestedMax={suggestedMax}
                        />
                      </div>
                      <div className="flex px-16 mt-6 mb-8">
                        {suppliers.map((item, key) => (
                          <div key={key}>
                            {item.checked ? (
                              <div className="flex h-10 w-full justify-start pl-1">
                                <StyledSVG
                                  color={item.color}
                                  className="w-8"
                                  onClick={() => handleCheckbox(key)}
                                >
                                  <CheckboxChecked />
                                </StyledSVG>
                                <div className="text-basic-gray-400 text-xs">
                                  {item.supplierName}
                                </div>
                              </div>
                            ) : (
                              <div className="flex w-full pl-1">
                                <div
                                  className="w-5 h-5 bg-yaleblue-transparent mr-1"
                                  onClick={() => handleCheckbox(key)}
                                />
                                <div className="text-basic-gray-400 text-xs">
                                  {item.supplierName}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </ChartContainer>
                  </div>
                </div>
              </div>
              <FilterButton
                buttons={FilterButtons}
                toogleAction={toogleHistoricData}
              />
              {!isHistoricData && (
                <IaSuggestionResume
                  iaSuggestion={iaSuggestion}
                  quotationRound={quotationRound}
                  rfq={rfqDetails}
                />
              )}
              <div className="mt-12 border-top">
                <div className="flex items-center justify-between relative">
                  {viewMore && (
                    <div
                      ref={wrapperRef}
                      className="flex flex-col shadow bg-white absolute bottom-14 rounded-lg border"
                    >
                      <div
                        className="flex cursor-pointer py-2 px-4 items-center border-b"
                        onClick={() => openFilter('parameters')}
                      >
                        <div className="w-5 flex">
                          <ListIcon />
                        </div>
                        <span className="font-default text-sm text-bluejeans-800 font-semibold ml-2">
                          {t('define_parameters')}
                        </span>
                      </div>
                      <div
                        className="flex cursor-pointer py-2 px-4 items-center border-b"
                        onClick={() => openFilter('items')}
                      >
                        <div className="w-5 flex">
                          <BoxIcon />
                        </div>
                        <span className="font-default text-sm text-bluejeans-800 font-semibold ml-2">
                          {t('define_items')}
                        </span>
                      </div>
                      <div
                        className="flex cursor-pointer py-2 px-4 items-center"
                        onClick={() => openFilter('suppliers')}
                      >
                        <div className="w-5 flex">
                          <SupplierIcon />
                        </div>
                        <span className="font-default text-sm text-bluejeans-800 font-semibold ml-2">
                          {t('define_suppliers')}
                        </span>
                      </div>
                    </div>
                  )}
                  {!isHistoricData && (
                    <>
                      <div className="h-6 w-6">
                        <ViewMoreIcon
                          className="cursor-pointer "
                          onClick={() => setViewMore(true)}
                        />
                      </div>
                      <CustomDropdown
                        options={quotations}
                        title={t('select_quotations')}
                        toggleAction={toogleQuoteOption}
                        selectedItem={selectedRound}
                      />
                    </>
                  )}
                </div>
                {isHistoricData ? (
                  <ComparativeMapHistoricData historicData={historicData} />
                ) : (
                  <ComparativeMapData
                    items={searchedItems}
                    suppliers={searchedSuppliers}
                    rfq={rfqDetails}
                    round={selectedRound}
                  />
                )}
              </div>
            </div>
          )}
          {filterIsOpen && (
            <FilterComparativeMap
              closeModal={() => setFilterIsOpen(false)}
              isOpen={filterIsOpen}
              items={filterData}
              type={filterType}
              round={1}
              handleSelectItems={applyFilter}
            />
          )}
        </Container>
      )}
    </div>
  );
};

export default ShowQuotation;
