import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId, findIndex } from 'lodash';
import {
  Container,
  WrapperRadioStatus,
  RadioStatus,
  WrapperFilterButton,
  WrapperFormModal,
  FormModal,
  FormModalHeader,
  IconButton,
  HeaderTitle,
  FormContainer,
  FormHeader,
  FooterModal,
  LinkButton,
  Chip,
  WrapperChip,
} from './Filters.style';
import { FilterProps, Filters, ListFilter } from './Filters.types';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { ReactComponent as IconPlus } from '../../../assets/plus-icon.svg';
import { ReactComponent as IconClose } from '../../../assets/close-icon-blue.svg';

const VALUES_DEFAULT: Filters = {
  name: '',
  email: '',
  team: '',
};

export const Filter: FC<FilterProps> = ({
  selected,
  onFilterStatus,
  onApplyFilter,
}) => {
  const { t } = useTranslation();
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [values, setValues] = useState<Filters>(VALUES_DEFAULT);
  const [listFilter, setListFilter] = useState<ListFilter>({
    name: [],
    email: [],
    team: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const emptyVales = () => {
    setValues(VALUES_DEFAULT);
    setListFilter({ name: [], email: [], team: [] });
  };

  const handleAddSearch = (key: 'name' | 'email' | 'team', value: string) => {
    if (value) {
      const newListFilter = { ...listFilter };
      newListFilter[key].push(value);
      setListFilter(newListFilter);
      setValues({ ...values, [key]: '' });
    }
  };

  const handleCloseChip = (key: 'name' | 'email' | 'team', value: string) => {
    const newListFilter = { ...listFilter };
    const idx = findIndex(newListFilter[key], item => item === value);
    if (idx !== -1) {
      newListFilter[key].splice(idx, 1);
      setListFilter(newListFilter);
    }
  };

  const handleApplyFilter = () => {
    onApplyFilter(listFilter);
    setOpenModalForm(false);
  };

  return (
    <Container>
      <WrapperRadioStatus>
        <RadioStatus
          onClick={() => onFilterStatus('ACTIVE')}
          selected={selected === 'ACTIVE'}
          style={{ borderRight: '1px solid rgba(42, 78, 118, 0.24)' }}
        >
          <div />
          <span>Ativo</span>
        </RadioStatus>
        <RadioStatus
          onClick={() => onFilterStatus('INATIVE')}
          selected={selected === 'INATIVE'}
          style={{ marginLeft: 16 }}
        >
          <div />
          <span>Inativos</span>
        </RadioStatus>
      </WrapperRadioStatus>
      <WrapperFilterButton onClick={() => setOpenModalForm(!openModalForm)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33333 12.4444L2.88889 5.77778H2V4H18V5.77778H17.1111L12.6667 12.4444V20H7.33333V12.4444ZM5.02539 5.77843L9.11072 11.9064V18.2229H10.8885V11.9064L14.9738 5.77843H5.02539Z"
            fill="#105D8A"
          />
        </svg>
      </WrapperFilterButton>
      <WrapperFormModal open={openModalForm}>
        <FormModal>
          <FormModalHeader>
            <IconButton
              style={{ margin: '0 10px' }}
              onClick={() => setOpenModalForm(false)}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3136 11.096L18.5043 5.90527L19.9871 7.38803L14.7964 12.5787L19.9871 17.7694L18.5043 19.2522L13.3136 14.0615L8.1229 19.2522L6.64014 17.7694L11.8308 12.5787L6.64014 7.38803L8.1229 5.90527L13.3136 11.096Z"
                  fill="#F6F7F9"
                />
              </svg>
            </IconButton>
            <IconButton onClick={() => setOpenModalForm(false)}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.23779 11.5293H19.9186V13.6266H5.23779V11.5293Z"
                  fill="#F6F7F9"
                />
              </svg>
            </IconButton>
            <HeaderTitle>
              <svg
                width="140"
                height="32"
                viewBox="0 0 140 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M110 0H30C21.715 0 15 7.164 15 16C15 24.836 8.285 32 0 32H140C131.715 32 125 24.836 125 16C125 7.164 118.285 0 110 0Z"
                  fill="#f6f7f9"
                />
              </svg>
              <span>Usuários</span>
            </HeaderTitle>
          </FormModalHeader>
          <FormContainer>
            <FormHeader>
              <h3>Filtros aplicados</h3>
            </FormHeader>
            <Input
              controlled
              placeholder={t('users')}
              name="name"
              onChange={handleChange}
              value={values?.name}
              showContainerError={false}
              className="w-full rounded-lg"
              autoComplete="off"
              icon={() => (
                <IconPlus
                  onClick={() => handleAddSearch('name', values?.name || '')}
                  style={{ cursor: 'pointer' }}
                />
              )}
            />
            <WrapperChip>
              {listFilter.name.map(item => (
                <Chip key={uniqueId()}>
                  <span>{item}</span>
                  <IconClose
                    onClick={() => handleCloseChip('name', item)}
                    style={{ marginLeft: 8, width: 10, cursor: 'pointer' }}
                  />
                </Chip>
              ))}
            </WrapperChip>
            <Input
              controlled
              placeholder={t('email')}
              name="email"
              onChange={handleChange}
              value={values?.email}
              showContainerError={false}
              className="w-full rounded-lg"
              autoComplete="off"
              icon={() => (
                <IconPlus
                  onClick={() => handleAddSearch('email', values?.email || '')}
                  style={{ cursor: 'pointer' }}
                />
              )}
            />
            <WrapperChip>
              {listFilter.email.map(item => (
                <Chip key={uniqueId()}>
                  <span>{item}</span>
                  <IconClose
                    onClick={() => handleCloseChip('email', item)}
                    style={{ marginLeft: 8, width: 10, cursor: 'pointer' }}
                  />
                </Chip>
              ))}
            </WrapperChip>
            <Input
              controlled
              placeholder={t('team')}
              name="team"
              onChange={handleChange}
              value={values?.team}
              showContainerError={false}
              className="w-full rounded-lg"
              autoComplete="off"
              icon={() => (
                <IconPlus
                  onClick={() => handleAddSearch('team', values?.team || '')}
                  style={{ cursor: 'pointer' }}
                />
              )}
            />
            <WrapperChip>
              {listFilter.team.map(item => (
                <Chip key={uniqueId()}>
                  <span>{item}</span>
                  <IconClose
                    onClick={() => handleCloseChip('team', item)}
                    style={{ marginLeft: 8, width: 10, cursor: 'pointer' }}
                  />
                </Chip>
              ))}
            </WrapperChip>
          </FormContainer>
        </FormModal>
        <FooterModal>
          <Button type="button" onClick={handleApplyFilter}>
            {t('apply')}
          </Button>
          <LinkButton onClick={() => emptyVales()}>
            {t('clear_filters')}
          </LinkButton>
        </FooterModal>
      </WrapperFormModal>
    </Container>
  );
};
