import React, { useState, useEffect } from 'react';

import { Container } from './PurchasePanel.style';
import Navbar from '../../components/Navbar/navbar';
import PageTab from '../../components/PageTab/PageTab';
import ListPurchaseRequisition from './ListPurchaseRequisition/ListPurchaseRequisition';
import ListRfq from './ListRfq/Listrfq';
import HistoricData from './HistoricData/HistoricData';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import api from '../../services/api';
import { userHasAnyPermissionOf } from '../../services/permission-service';
import { AxiosError } from 'axios';

const PurchasePanel: React.FC = () => {
  const [tabs, setTabs] = useState<string[]>([]);

  const [activeTab, setActiveTab] = useState('');

  const [totalRfqs, setTotalRfqs] = useState<number>(0);

  const [totalPurchase, setTotalPurchase] = useState(0);

  const [totalHistoricData, setTotalHistoricData] = useState(0);

  const [responseError, setResponseError] = useState<AxiosError | null>();

  const toggleAction = (tab: string) => {
    setActiveTab(tab);
    localStorage.setItem('last_tab_purchase_panel', tab);
  };

  useEffect(() => {
    const lastTab = localStorage.getItem('last_tab_purchase_panel');
    if (lastTab) setActiveTab(lastTab);

    async function loadTabsTotals(): Promise<void> {
      await api
        .get('/purchase_panel_totals/')
        .then(tabsTotals => {
          setTotalPurchase(tabsTotals.data.requisitions_count);
          setTotalRfqs(tabsTotals.data.rfqs_count);
          setTotalHistoricData(tabsTotals.data.history_count);
        })
        .catch(setResponseError);
    }

    async function setTabsWithPermission(): Promise<void> {
      const tabs = [];
      if (userHasAnyPermissionOf(['view_purchaserequisition']))
        tabs.push('purchase_requisition');
      if (userHasAnyPermissionOf(['view_rfq']))
        tabs.push('rfq', 'historic_data');

      setTabs(tabs);

      let activeTab = null;
      if (!lastTab || !tabs.includes(lastTab)) {
        activeTab = tabs[0];
      } else {
        activeTab = lastTab;
      }
      setActiveTab(activeTab);
    }

    setTabsWithPermission();
    loadTabsTotals();
  }, []);

  const closeErrorPage = () => setResponseError(null);

  const tryAgainFunction = () => {
    window.location.reload();
  };

  return (
    <>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary">
          <Navbar pageTitle={'purchase_panel'} selectedMenu="panel" />
          <PageTab
            key={activeTab}
            tabs={tabs}
            activeTab={activeTab}
            toggleAction={toggleAction}
            backgroundColor="bg-primary"
            className=""
            count={[totalPurchase, totalRfqs, totalHistoricData]}
          >
            {activeTab === 'purchase_requisition' && (
              <ListPurchaseRequisition errorPage={setResponseError} />
            )}
            {activeTab === 'rfq' && (
              <ListRfq totalRfqs={totalRfqs} errorPage={setResponseError} />
            )}
            {activeTab === 'historic_data' && (
              <HistoricData
                totalHistoricData={totalHistoricData}
                errorPage={setResponseError}
              />
            )}
          </PageTab>
        </Container>
      )}
    </>
  );
};

export default PurchasePanel;
