import styled from 'styled-components';

export const SelectWrapper = styled.div`
  background-color: #ffffff;
  display: block;
  position: relative;
  width: 100%;

  &:before {
    background-color: #ffffff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    content: '';
    height: 46px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 46px;
    z-index: 2;
    background-color: transparent;
  }
`;

export const Select = styled.select`
  appearance: none;
  background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
  border: 1.5px solid rgb(229, 231, 235);
  border-radius: 0.25rem;
  color: #1d3550;
  font-size: 14px;
  line-height: 14px;
  min-height: 46px;
  padding: 10px 16px 10px 12px;
  position: relative;
  width: 100%;

  &:disabled {
    background-color: #f9f5ef;
    border-color: #8e8e8e;
    cursor: default;
  }

  &:focus,
  &:active {
    border-color: rgba(52, 144, 220);
    outline: none;
  }

  &.error,
  &.error:focus {
    border-color: #f06466;
    color: #f06466;
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(252, 41, 87);
  margin-bottom: 11px;
  height: 20px;
`;
export const LabelSpan = styled.span<{ hasError?: boolean }>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ hasError }) => (hasError ? 'rgba(252, 41, 87)' : '#1d3550')};
  margin-bottom: 11px;
`;

export const Container = styled.div`
  width: 100%;
`;
