import { differenceInHours, format } from 'date-fns';

interface BuyerGroup {
  id: number;
  name: string;
  description: string;
}

interface Material {
  id: number;
  cod_material: string;
  description: string;
}

interface LastPurchaseSupplier {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
}
interface ItemPurchaseRequisition {
  id: number;
  num_req: string;
  is_vendor_list: boolean;
  dt_lib_rc: string;
  created_on: string;
}

interface Items {
  id: number;
  quantity: number;
  material: Material;
  dt_delivery: Date;
  dt_lib_rc: string;
  item_purchase_requisition: ItemPurchaseRequisition;
  item_req: string;
  warehouse: string;
  short_description: string;
  long_description: string;
  purchase_requisition_number: string;
  sla_deadline: string;
  created_on: string;
  is_active: boolean;
  is_vendor_list: boolean;
  urgency_status: string;
  mercadory_category: string;
  classification: string;
  status: string;
  branch_region: string;
  dt_shipping: string;
  last_purchase_price: string;
  last_purchase_id: string;
  last_purchase_date: string;
  last_purchase_nick_name: string;
  last_purchase_name: string;
  last_purchase_supplier: LastPurchaseSupplier;
  last_purchase_minimum_lot: string;
  last_purchase_observation: string;
  last_purchase_order_number: string;
  last_purchase_vl_priceunit: string;
  buyer_group: BuyerGroup;
  vendor_list: string;
  [key: string]:
    | number
    | string
    | Material
    | BuyerGroup
    | Date
    | boolean
    | ItemPurchaseRequisition
    | LastPurchaseSupplier;
}

interface LineItems {
  observation: string;
  urgency: string;
  dt_lib_rc: string;
  dt_delivery: Date;
  sla_deadline_raw: string;
  buyer_group: BuyerGroup;
  vendor_list: string;
  [index: string]:
    | number
    | string
    | Material
    | ItemPurchaseRequisition
    | Date
    | BuyerGroup;
}

interface ContentItems {
  [index: string]:
    | string
    | number
    | boolean
    | BuyerGroup
    | Material
    | undefined
    | LastPurchaseSupplier;
}

export const formatLineItems = (result: Items[]): LineItems[] => {
  const lineItems: LineItems[] = [];

  result.forEach(item => {
    const newLineItems = {
      id: item.id,
      requisition: item.item_purchase_requisition.num_req,
      item: item.item_req,
      material: item.material.cod_material,
      warehouse: item.warehouse,
      description: item.short_description || item.long_description,
      center: item.branch_region,
      urgency: item.urgency_status,
      buyer_group: item.buyer_group,
      sla_deadline: `${differenceInHours(
        new Date(),
        new Date(item.sla_deadline),
      )}h`,
      dt_delivery: item.dt_delivery,
      sla_deadline_raw: item.sla_deadline,
      observation: '',
      dt_lib_rc: item.dt_lib_rc || item.item_purchase_requisition.created_on,
      vendor_list: item.vendor_list,
    };
    lineItems.push(newLineItems);
  });
  return lineItems;
};

export const formatContentItems = (result: Items[]): ContentItems[] => {
  const contentItems: ContentItems[] = [];
  result.forEach(item => {
    const creation_date_rc =
      item.dt_lib_rc || item.item_purchase_requisition.created_on;
    const newContentItems = {
      status: item.status,
      creation_date_rc: format(new Date(creation_date_rc), 'P'),
      long_description: item.long_description,
      buyer_group: item.buyer_group?.name || '-',
      quantity: item.quantity,
      last_purchase_number: item.last_purchase_order_number,
      last_purchase_creation: format(new Date(item.last_purchase_date), 'P'),
      last_purchase_nickname: item.last_purchase_supplier?.nickname,
      last_purchase_vl_unit_price: item.last_purchase_price,
      last_purchase_item: item.last_purchase_item?.toString(),
      last_purchase_supplier_name: item.last_purchase_supplier?.code,
      last_purchase_social_name: item.last_purchase_supplier?.social_name,
      last_purchase_minimum: item.last_purchase_vl_priceunit,
      last_purchase_observation: '',
    };
    contentItems.push(newContentItems);
  });
  return contentItems;
};
