import React, { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { uniqueId, findIndex, orderBy, filter, forEach } from 'lodash';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CSVLink } from 'react-csv';
import { Filter } from './Filters/Filters';
import { ListFilter } from './Filters/Filters.types';
import {
  Container,
  Header,
  Table,
  TableHead,
  TableHeadItem,
  CardTable,
  LinkRow,
  CellValue,
  Action,
  Button,
} from './Users.style';
import { UsersProps, UserType } from './Users.types';
import ListButton from '../../components/ListButton/ListButton';
import { ReactComponent as SortDescIcon } from '../../assets/icon-sort-desc.svg';
import Loading from '../../components/Loading/Loading';
import { ReactComponent as SortAscIcon } from '../../assets/icon-sort-asc.svg';
import api from '../../services/api';

interface SortFieldProps {
  name?: string;
  value?: string;
}

interface Columns {
  label?: string;
  field?: string;
  sort?: boolean;
  type?: string;
  value?: string;
  callback?: any;
  size?: number;
}

enum Severity {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

interface Snackbar {
  severty: Severity;
  open: boolean;
  message: string;
}

const SNACKBAR_DEFAULT: Snackbar = {
  severty: Severity.info,
  open: false,
  message: '',
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UsersPanel: FC<UsersProps> = ({ users }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [statusSelected, setStatusSelected] = useState<string>('');
  const [snackbar, setSnackbar] = useState<Snackbar>(SNACKBAR_DEFAULT);
  const [loader, showLoader] = useState<boolean>();
  const [dataTable, setDataTable] = useState<UserType[]>([]);
  const [dataUser, setDataUser] = useState<UserType[]>([]);
  const [listFilterForm, setListFilterForm] = useState<ListFilter>({
    name: [],
    email: [],
    team: [],
  });

  useEffect(() => {
    setDataTable(users);
    setDataUser(users);
  }, [users]);

  const handleSort = async (value: string | undefined) => {
    const sort = sortField.value === 'asc' ? 'desc' : 'asc';
    const newDataTable = [...dataTable];
    const dataUserSorted = orderBy(newDataTable, [value], sort) as UserType[];
    setDataTable(dataUserSorted);
    setSortField({ name: value, value: sort });
  };

  const updateUserStatus = async (userParam: UserType) => {
    try {
      showLoader(true);
      setSnackbar(SNACKBAR_DEFAULT);
      const newDataTable = [...dataTable];
      const response = await api.put(`/users/${userParam.id}/`, {
        ...userParam,
        is_active: !userParam.is_active,
      });
      if (response.status === 200) {
        setSnackbar({
          open: true,
          severty: Severity.success,
          message: t('update_user_success_status'),
        });
      }
      const idx = findIndex(newDataTable, ['id', userParam.id]);
      if (idx !== -1) {
        newDataTable[idx].is_active = !userParam.is_active;
        setDataTable(newDataTable);
        showLoader(false);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severty: Severity.error,
        message: t('update_user_error_status'),
      });
    } finally {
      showLoader(false);
    }
  };

  const handleChangeStatus = (userParam: UserType) => {
    updateUserStatus(userParam);
  };

  const handleEdit = (id: string) => history.push(`/new-user/${id}`);

  const columns: Columns[] = [
    {
      label: 'first_name',
      field: 'first_name',
      sort: true,
      size: 300,
    },
    {
      label: 'email',
      field: 'email',
      sort: true,
      size: 300,
    },
    {
      label: 'team',
      field: 'team',
      sort: true,
      size: 250,
    },
    {
      label: 'buyer_group',
      field: 'buyer_group',
      sort: true,
      size: 250,
    },
    {
      label: 'action',
      value: 'status',
      type: 'action',
      callback: handleChangeStatus,
      size: 50,
    },
    {
      value: 'edit',
      type: 'action',
      callback: handleEdit,
      size: 50,
    },
  ];

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(SNACKBAR_DEFAULT);
  };

  const renderCellValue = (user: any, column: Columns) => {
    if (column.type === 'action') {
      if (column.value === 'status') {
        return (
          <LinkRow
            onClick={() => column.callback(user)}
            size={column?.size || 100}
          >
            {t(user.is_active ? 'inactivate' : 'activate')}
          </LinkRow>
        );
      }
      if (column.value === 'edit') {
        return (
          <LinkRow
            onClick={() => column.callback(user.id)}
            size={column?.size || 100}
          >
            {t(column.value || '')}
          </LinkRow>
        );
      }
      return (
        <LinkRow onClick={column.callback} size={column?.size || 100}>
          {t(column.value || '')}
        </LinkRow>
      );
    }

    return (
      <CellValue size={column?.size || 100}>
        {user[column?.field || '']}
      </CellValue>
    );
  };

  const filterListByStatus = (status: string, data: UserType[]) => {
    if (status) {
      const dataFiltered = filter(data, ['is_active', status === 'ACTIVE']);
      return dataFiltered;
    }
    return data;
  };

  const filterListByForm = (listFilter: ListFilter, data: UserType[]) => {
    let usersName: UserType[] = [];
    let usersEmail: UserType[] = [];
    let usersTeam: UserType[] = [];
    if (listFilter.name.length > 0) {
      usersName = data;
      const filteredName: UserType[] = [];
      forEach(listFilter.name, itemName => {
        const result = data.filter(item => {
          let name = '';
          if (item.first_name) {
            name = item.first_name.toLowerCase();
          }
          return name.includes(itemName && itemName.toLowerCase());
        });
        forEach(result, item => {
          filteredName.push(item);
        });
      });
      usersName = [...filteredName];
    }

    if (listFilter.email.length > 0) {
      usersEmail = data;
      const filteredEmail: UserType[] = [];
      forEach(listFilter.email, itemEmail => {
        const result = data.filter(item => {
          let email = '';
          if (item.email) {
            email = item.email.toLowerCase();
          }
          return email.includes(itemEmail && itemEmail.toLowerCase());
        });
        forEach(result, item => {
          filteredEmail.push(item);
        });
      });
      usersEmail = [...filteredEmail];
    }

    if (listFilter.team.length > 0) {
      usersTeam = data;
      const filteredTeam: UserType[] = [];
      forEach(listFilter.team, itemTeam => {
        const result = data.filter(item => {
          let team = '';
          if (item.team) {
            team = item.team.toLowerCase();
          }
          return team.includes(itemTeam && itemTeam.toLowerCase());
        });
        forEach(result, item => {
          filteredTeam.push(item);
        });
      });
      usersTeam = [...filteredTeam];
    }

    const allUserFiltered = [...usersName, ...usersEmail, ...usersTeam];
    const filteredData = allUserFiltered.filter(
      (value, index, self) => self.findIndex(v => v.id === value.id) === index,
    );

    if (
      listFilter.name.length === 0 &&
      listFilter.email.length === 0 &&
      listFilter.team.length === 0
    ) {
      return data;
    }
    return filteredData;
  };

  const handleApplyFilter = (listFilter: ListFilter) => {
    const dataStatusFiltered = filterListByStatus(statusSelected, dataUser);
    const newDataTable = filterListByForm(listFilter, dataStatusFiltered);
    setListFilterForm(listFilter);
    setDataTable(newDataTable);
  };

  const handleFilterStatus = (status: string) => {
    const dataFormFiltered = filterListByForm(listFilterForm, dataUser);
    const newDataTable = filterListByStatus(
      status === statusSelected ? '' : status,
      dataFormFiltered,
    );
    setDataTable(newDataTable);
    setStatusSelected(status === statusSelected ? '' : status);
  };

  const handleClickExport = () => {
    csvLinkRef?.current?.link.click();
  };

  return (
    <>
      {loader && <Loading />}
      <Container>
        <Header>
          <Filter
            selected={statusSelected}
            countStatus={{
              active: 2,
              inactive: 0,
            }}
            onFilterStatus={handleFilterStatus}
            onApplyFilter={handleApplyFilter}
          />
          <Action>
            <ListButton
              keyButtonText={'export_list'}
              permissionsNeeded={['add_supplier', 'change_supplier']}
              onClick={handleClickExport}
              upload
            />
            <Button onClick={() => history.push('/new-user')}>
              {t('new_user')}
            </Button>
          </Action>
        </Header>
        <Table>
          <TableHead>
            {columns.map(column => (
              <TableHeadItem key={uniqueId()} size={column?.size || 100}>
                {column.sort &&
                  ((sortField.name === column.field &&
                    sortField.value === 'asc' && (
                      <SortAscIcon
                        style={{ marginRight: 8 }}
                        className="cursor-pointer w-3 h-2.5"
                        onClick={() => handleSort(column?.field)}
                      />
                    )) || (
                    <SortDescIcon
                      style={{ marginRight: 8 }}
                      className="cursor-pointer w-3 h-2.5"
                      onClick={() => handleSort(column?.field)}
                    />
                  ))}
                <span>{t(column.label || '')}</span>
              </TableHeadItem>
            ))}
          </TableHead>
          {dataTable.map(user => (
            <CardTable key={uniqueId()} active={user.is_active}>
              {columns.map(column => (
                <React.Fragment key={uniqueId()}>
                  {renderCellValue(user, column)}
                </React.Fragment>
              ))}
            </CardTable>
          ))}
        </Table>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.severty}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <CSVLink
          filename={dataTable.length > 0 ? `${Date.now()}.csv` : 'nodata.csv'}
          data={dataTable}
          asyncOnClick={true}
          ref={csvLinkRef}
          className="hidden"
        />
      </Container>
    </>
  );
};

export default UsersPanel;
