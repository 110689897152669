import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid rgba(42, 78, 118, 0.16);
`;

export const QuotationItems = styled.div`
  background-color: rgba(42, 78, 118, 0.08);
`;

export const Columns = styled.div`
  min-width: 13rem;
`;
