import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { Container } from './SettingsPanel.style';
import Navbar from '../../components/Navbar/navbar';
import PageTab from '../../components/PageTab/PageTab';
import api from '../../services/api';
import Loading from '../../components/Loading/Loading';
import IntegrationPanel from '../../components/IntegrationPanel/IntegrationPanel';
import UsersPanel from '../../components/Users/Users';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { AxiosError } from 'axios';

const SettingsPanel: React.FC = () => {
  const tabs = ['users', 'integration_panel'];
  const [activeTab, setActiveTab] = useState('users');
  const [loading, setLoading] = useState(true);
  const [currentIntegrationList, setCurrentIntegrationList] = useState(0);
  const [integrationPanel, setIntegrationPanel] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalIntegrationPanel, setTotalIntegrationPanel] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [responseError, setResponseError] = useState<AxiosError | null>();

  const toggleAction = (tab: string) => {
    setActiveTab(tab);
  };

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    async function loadAllData(): Promise<void> {
      await api
        .get('/integrations/panel/')
        .then(response => {
          setTotalIntegrationPanel(response.data.count);
          setIntegrationPanel(response.data.results);
        })
        .catch(setResponseError);

      await api
        .get('/users/?limit=99999')
        .then(response => {
          setTotalUsers(response.data.count);
          const userSorted: any = orderBy(response.data.results, 'id', 'desc');
          setUsers(userSorted);
        })
        .catch(setResponseError)
        .finally(() => {
          showLoader(false);
        });
    }
    loadAllData();
  }, []);

  const updateIntegrationPanel = (items: []) => {
    setIntegrationPanel([...items]);
  };

  const fetchIntegrations = async () => {
    try {
      if (totalIntegrationPanel < currentIntegrationList) return;
      showLoader(true);
      const actual_page = currentIntegrationList + 15;
      await api
        .get(`/integrations/panel/?limit=15&offset=${actual_page}`)
        .then(response => {
          const loadIntegrationPanel: never[] = response.data.results;
          setIntegrationPanel(prev => [...prev, ...loadIntegrationPanel]);
          setCurrentIntegrationList(prev => prev + 15);
          showLoader(false);
        });
    } catch (error) {
      showLoader(false);
      console.log(error);
    }
  };

  const closeErrorPage = () => setResponseError(null);

  const tryAgainFunction = () => {
    window.location.reload();
  };

  const errorPage = (error: AxiosError) => {
    setResponseError(error);
  };

  const pageTitle: any = {
    users: 'user_list',
    integration_panel: 'rules',
  };

  return (
    <>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary">
          <Navbar
            pageTitle={pageTitle[activeTab]}
            selectedMenu="configurations"
          />
          <PageTab
            tabs={tabs}
            activeTab={activeTab}
            toggleAction={toggleAction}
            backgroundColor="bg-primary"
            className=""
            count={[totalUsers, totalIntegrationPanel]}
          >
            {loading && <Loading />}
            {activeTab === 'users' && <UsersPanel users={users} />}
            {activeTab === 'integration_panel' && (
              <IntegrationPanel
                integrationPanel={integrationPanel}
                currentIntegrationList={currentIntegrationList}
                fetchIntegrations={fetchIntegrations}
                showLoader={value => setLoading(value)}
                updateIntegrationPanel={updateIntegrationPanel}
                errorPage={errorPage}
              />
            )}
          </PageTab>
        </Container>
      )}
    </>
  );
};

export default SettingsPanel;
