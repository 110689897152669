import React, { useReducer, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Content, ContentContainer } from './RewardItems.style';
import { ReactComponent as IconBreadcrumb } from '../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import { ReactComponent as SortDescIcon } from '../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../assets/icon-sort-asc.svg';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { ReactComponent as DiagramIcon } from '../../assets/icon-diagram.svg';
import { ReactComponent as ChavronIcon } from '../../assets/icon-chavron-down.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as PaperIcon } from '../../assets/paper-clip-icon.svg';
import CustomDropDown from '../../components/CustomDropdown/CustomDropdown';
import { ReactComponent as AlertIcon } from '../../assets/alert-icon.svg';
import ListItem from '../../components/ListItem/ListItem';
import { useHistory, useLocation } from 'react-router-dom';
import SupplierCard, {
  cardContent,
} from '../../components/SupplierCard/SupplierCard';
import api from '../../services/api';
import Modal from 'react-modal';
import Button from '../../components/Button/Button';
import { differenceInHours, format, parseISO, parse } from 'date-fns';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import Navbar from '../../components/Navbar/navbar';
import Loading from '../../components/Loading/Loading';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import removeZerosLeft from '../../utils/removeZerosLeft';
import { AxiosError } from 'axios';

interface SortFieldProps {
  name: string;
  value: string;
}

interface Action {
  field: string;
  value: string;
  key: number | null;
}

interface Form {
  requestType: string;
  approverObservation: string;
  quotationItemsForm: QuotationItemsForm[];
}

interface QuotationItemsForm {
  paymentCondition: string;
  incoterm: string;
  supplierObservation: string;

  [index: string]: string;
}

interface Quotation {
  supplier_id: number;
  quotation_items: QuotationItemsEndpoint[];
}

interface QuotationItemsEndpoint {
  id: number;
  iva: string;
  ncm: string;
  dt_shipping: string;
  mat_origin: string;
}

interface QuotationItemsSupplier {
  quotation_items: QuotationItems;
  supplier: Supplier;
  icms: number;
  ipi: number;
  item_data: ItemData;
  quotation_id: number;

  [index: string]: string | number | QuotationItems | Supplier | ItemData;
}

interface AwardItemsPayload {
  approver_observation: string;
  order_type: string;
  buyer_group?: number;
  quotation_items_supplier: Quotation[];
}

interface Supplier {
  id: number;
  nickname: string;
  code: string;
  social_name: string;
  document_number: string;
  uf: string;
  country: string;
  score_serasa: number;
  payment_deadline: number;
  fqi: number;
  saving_percent: number;
  payment_condition: { id: number; code: string };
  incoterm: { id: number; name: string };
}

interface QuotationItems {
  id: number;
  quotation_id: number;
  cod_quotation: string;
  item_rfq_id: number;
  item_name: string;
  qto: number;
  price_unit: number;
  iva: string;
  ncm: string;
  dt_delivery: Date;
  mat_origin: string;
  item_data: ItemData;
  dt_ent: Date;

  [index: string]: string | number | ItemData | Date;
}

interface ItemData {
  requisition: string;
  cod_quotation: string;
  item_quotation: string;
  material: string;
  urgency: string;
  urgency_code: string;
  center: string;
  item: number;
  short_description: string;
  branch_region: string;
  num_req: string;
  urgency_status: string;
  doc_type: string;
  sla: string;
  dt_delivery: Date;
  target: string;
  lead_time: string;
  status: string;
  buyer_group: string;
  created_on: string;
  ncm: string;
  quantity: string;
  rc_creation_date: string;
  long_description: string;
  last_purchase_date: number;
  last_purchase_vl_priceunit: number;
  last_purchase_item: string;
  last_purchase_supplier_nick: string;
  last_purchase_supplier_code: string;
  last_purchase_supplier_name: string;
  last_purchase_minimum_lot: number;
}

interface AwardadItems {
  quotation_id: number;
  supplier: Supplier;
  quotation_items: QuotationItems[];

  [index: string]: Supplier | QuotationItems[] | number;
}

interface ItemsOptionProps {
  id: number;
  label: string;
}

interface FormErrors {
  ncm: Array<number>;
  payment_condition: Array<number>;
  incoterm: Array<number>;
  buyer_group: string | null;
  request_type: string | null;
  iva: Array<number>;
  dt_delivery: Array<number>;
}

const RewardItems: React.FC = () => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [supplierCard, setSupplierCard] = useState<Array<number | null>>([]);
  const [supplierName, setSupplierName] = useState('');
  const [quotationKey, setQuotationKey] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation<{
    items: QuotationItemsSupplier[];
    quotationId: number;
    rfqId: number;
  }>();
  const [awardItems, setAwardItems] = useState<AwardadItems[]>([]);
  const [quotationId, setQuotationId] = useState<number>();
  const [numRfq, setNumRfq] = useState<string>();
  const [numRound, setNumRound] = useState<string>();
  const [totalAwardedItems, setTotalAwardedItems] = useState<number>(0);
  const history = useHistory();
  const [buyerGroup, setBuyerGroup] = useState<{ id: number; name: string }>();
  const [paymentOptions, setPaymentOptions] = useState<ItemsOptionProps[]>([]);
  const [freights, setFreights] = useState<ItemsOptionProps[]>([]);
  const [buyerGroups, setBuyerGroups] = useState<ItemsOptionProps[]>([]);
  const [formErrors, setformErrors] = useState<FormErrors>();

  const [responseError, setResponseError] = useState<AxiosError | null>();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  const defaultNote = `
  PEDIDO EMITIDO CONFORME COTAÇÃO: ${numRfq}${numRound}
  ** FAVOR DAR O ACEITE DO PEDIDO DE COMPRAS NO PORTAL **
  ** NÃO FATURAR EM DESACORDO COM O PEDIDO **

  Se identificada qualquer divergência entre o pedido de compras e a cotação, o pedido deve ser REJEITADO no portal, com breve
  descrição do motivo da rejeição. Informar os dados corretos para alteração pelo comprador e posterior reenvio ao portal.

  Não faturar em desacordo com o pedido, pois podem gerar devolução das mercadorias, além de problemas de pagamento.

  Obs.: Em casos de devolução devido a materiais faturados em desacordo com o pedido, bem como por materiais avariados, entendemos
  que os custos logísticos serão por conta do fornecedor (Frete ida + Frete retorno).

  ** ARQUIVOS XML DEVEM SER ENVIADOS OBRIGATORIAMENTE PARA
   
   
  invoicereceiver-prod@ansmtp.ariba.com


  ** A SOLICITAÇÃO DE COLETA PARA PEDIDOS FOB DEVE SER REALIZADA PELO FORNECEDOR
  INFORMANDO A QUANTIDADE DE VOLUMES, DIMENSÕES E PESO DE CADA VOLUME, ENDEREÇO E HORÁRIO PARA COLETA.
`;

  useEffect(() => {
    async function loadItems() {
      try {
        setLoading(true);
        const state = location.state;
        const newSuppliers: Supplier[] = [];
        const items: AwardadItems[] = [];
        let total = 0;
        let newAwardItem: AwardadItems;
        const response = await api.get(`/rfqs/${location.state.rfqId}/`);
        if (response.data.grp_purchasing) {
          setBuyerGroup({
            name: response.data.grp_purchasing.name,
            id: response.data.grp_purchasing.id,
          });
        }
        if (response.data.num_rfq) {
          setNumRfq(response.data.num_rfq);
          setNumRound(
            response.data.quotation_num_round.toString().padStart(3, '0'),
          );
        }
        if (!state.items) return;
        state.items.forEach(item => {
          item.supplier.payment_deadline = differenceInHours(
            new Date(),
            new Date(item.supplier.payment_deadline),
          );
          item.quotation_items.dt_ent = parse(
            item.quotation_items.ent as string,
            'dd/MM/yyyy',
            new Date(),
          );

          if (!newSuppliers.includes(item.supplier)) {
            newAwardItem = {
              quotation_id: item.quotation_id,
              supplier: item.supplier,
              quotation_items: [item.quotation_items],
            };
            items.push(newAwardItem);
            newSuppliers.push(item.supplier);
          } else {
            const index = items.findIndex(
              awItem => awItem.supplier === item.supplier,
            );
            items[index].quotation_items.push(item.quotation_items);
          }
          total = total + 1;
        });
        setTotalAwardedItems(total);
        setAwardItems(items);
        setQuotationId(state.quotationId);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadItems();
  }, [location]);

  const fields = [
    { label: 'quotation_number', size: 'w-2/12' },
    { label: 'item' },
    { label: 'quantity' },
    { label: 'un' },
    { label: 'material' },
    { label: 'short_description' },
    { label: 'center' },
    { label: 'net_unitary_price' },
    { label: 'by' },
  ];

  const toggleSort = (field: string, items: QuotationItems[]) => {
    const sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name === field) {
      setSortField({ name: field, value: sort });
    } else {
      setSortField({ name: field, value: 'asc' });
    }
    if (sort === 'asc') {
      items.sort((a, b) => (b[field] > a[field] ? 1 : -1));
    } else {
      items.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    }
  };

  const formContent: Form = {
    requestType: 'ZB',
    approverObservation: '',
    quotationItemsForm: [],
  };

  const reducer = (state: Form, action: Action) => {
    if (action.key !== null) {
      const key: number = action.key;
      const quotationsItems: QuotationItemsForm[] = state.quotationItemsForm;
      const item = quotationsItems[key];
      const newItem: QuotationItemsForm = {
        incoterm: '',
        supplierObservation: '',
        paymentCondition: '',
      };
      if (quotationsItems[key] !== undefined) {
        item[action.field] = action.value;
      } else {
        newItem[action.field] = action.value;
        quotationsItems.push(newItem);
      }
      return {
        ...state,
        quotationItemsForm: [...quotationsItems],
      };
    } else {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
  };

  const [state, dispatch] = useReducer(reducer, formContent);

  const onChange = (name: string, value: string, key: number | null) => {
    dispatch({ field: name, value: value, key: key });
  };

  const editFormQuotationItems = (
    item: QuotationItems,
    field: string,
    value: string,
  ) => {
    item[field] = value;
  };

  const handleDtDelivery = (date: Date, item: QuotationItems) => {
    item.dt_ent = date;
    setAwardItems([...awardItems]);
  };

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  function handlePaymentMethod(option: string, item: AwardadItems) {
    const payment = paymentOptions.find(op => op.label === option);
    if (payment)
      item.supplier.payment_condition = { id: payment.id, code: payment.label };
  }

  const handleLoadedPaymentMethod = (
    items: never[],
    name: string,
    loadedOptions: ItemsOptionProps[] = [],
  ) => {
    items.forEach((item: { description: string; code: string; id: number }) => {
      const newPayment = {
        label: `${item.code} - ${item.description}`,
        id: item.id,
      };
      loadedOptions.push(newPayment);
    });
    setPaymentOptions([...loadedOptions]);
  };

  function handleIncoterm(option: string, item: AwardadItems) {
    const freight = freights.find(op => op.label === option);
    if (freight)
      item.supplier.incoterm = { id: freight.id, name: freight.label };
  }

  const handleLoadedIncoterm = (
    items: never[],
    name: string,
    loadedOptions: ItemsOptionProps[] = [],
  ) => {
    items.forEach((item: { incoterm: string; id: number }) => {
      const newIncoterm = {
        label: item.incoterm,
        id: item.id,
      };
      loadedOptions.push(newIncoterm);
    });
    setFreights([...loadedOptions]);
  };

  function handleBuyerGroup(option: string) {
    const buyer = buyerGroups.find(bg => bg.label === option);
    if (buyer) setBuyerGroup({ name: buyer?.label, id: buyer.id });
  }

  const handleLoadedBuyerGroup = (
    items: never[],
    name: string,
    loadedOptions: ItemsOptionProps[] = [],
  ) => {
    items.forEach((item: { name: string; id: number }) => {
      const newGroup = {
        label: item.name,
        id: item.id,
      };
      loadedOptions.push(newGroup);
    });
    setBuyerGroups([...loadedOptions]);
  };

  const handleSubmitForm = async () => {
    closeModal();
    // const quotations: Quotation[] = [];
    const quotationsByOrderType: {
      [key: string]: Quotation[];
    } = {};

    const errors: FormErrors = {
      ncm: [],
      payment_condition: [],
      incoterm: [],
      buyer_group: null,
      request_type: null,
      iva: [],
      dt_delivery: [],
    };
    let hasErrors = false;
    // if (!state.requestType) {
    //   errors.request_type = 'request_type_null_error';
    //   hasErrors = true;
    // }
    if (!buyerGroup) {
      errors.buyer_group = 'buyer_group_null_error';
      hasErrors = true;
    }

    awardItems.forEach((quotation, key) => {
      // const quotationItems: QuotationItemsEndpoint[] = [];
      const quotationItemsByOrderType: {
        [key: string]: QuotationItemsEndpoint[];
      } = {};

      quotation.quotation_items.forEach(item => {
        const dtDelivery = item.dt_ent;

        if (!item.item_data.ncm) {
          errors.ncm.push(key);
          hasErrors = true;
        }
        if (!dtDelivery) {
          errors.dt_delivery.push(key);
          hasErrors = true;
        }
        if (!item.iva) {
          errors.iva.push(key);
          hasErrors = true;
        }
        const orderType = item.item_data.urgency_code === 'ZU' ? 'ZU' : 'ZB'; // Apenas ZU fica separado dos outros
        const quotationItem = {
          id: item.item_rfq_id,
          iva: item.iva,
          ncm: item.item_data.ncm,
          dt_shipping: dtDelivery ? format(dtDelivery, 'dd/MM/yyyy') : '',
          mat_origin: item.mat_origin,
          urgency: orderType,
        };
        // quotationItems.push(quotationItem);
        if (orderType in quotationItemsByOrderType) {
          quotationItemsByOrderType[orderType].push(quotationItem);
        } else {
          quotationItemsByOrderType[orderType] = [];
          quotationItemsByOrderType[orderType].push(quotationItem);
        }
      });

      if (!quotation.supplier.payment_condition) {
        errors.payment_condition.push(key);
        hasErrors = true;
      }
      if (!quotation.supplier.incoterm.id) {
        errors.incoterm.push(key);
        hasErrors = true;
      }

      let supplier_observation;
      if (key in state.quotationItemsForm) {
        supplier_observation =
          state.quotationItemsForm[key].supplierObservation;
      } else {
        supplier_observation = defaultNote;
      }

      for (const order_type in quotationItemsByOrderType) {
        const quote = {
          quotation_id: quotation.quotation_id,
          supplier_id: quotation.supplier.id,
          quotation_items: quotationItemsByOrderType[order_type],
          payment_condition: quotation.supplier.payment_condition.id,
          delivery_term: quotation.supplier.incoterm.id,
          supplier_observation: supplier_observation,
        };
        // quotations.push(quote);
        if (order_type in quotationsByOrderType) {
          quotationsByOrderType[order_type].push(quote);
        } else {
          quotationsByOrderType[order_type] = [];
          quotationsByOrderType[order_type].push(quote);
        }
      }
      // console.log(quotationsByOrderType);
    });

    if (hasErrors) {
      setformErrors(errors);
      setErrorModalIsOpen(true);
      return;
    }
    const payloadByOrderType: {
      [key: string]: AwardItemsPayload;
    } = {};
    for (const order_type in quotationsByOrderType) {
      if (order_type in payloadByOrderType) {
        payloadByOrderType[
          order_type
        ].quotation_items_supplier = payloadByOrderType[
          order_type
        ].quotation_items_supplier.concat(quotationsByOrderType[order_type]);
      } else {
        payloadByOrderType[order_type] = {
          approver_observation: state.approverObservation,
          order_type: order_type,
          buyer_group: buyerGroup?.id,
          quotation_items_supplier: quotationsByOrderType[order_type],
        };
      }
    }
    // const payload = {
    //   approver_observation: state.approverObservation,
    //   order_type: state.requestType,
    //   buyer_group: buyerGroup?.id,
    //   quotation_items_supplier: quotations,
    // };
    // console.log(payloadByOrderType);
    showLoader(true);
    await api
      .post(`/quotations/${quotationId}/award_items/`, payloadByOrderType)
      .then(response => {
        if (response.status === 201) {
          closeRewardItems();
        }
        showLoader(false);
      })
      .catch(setResponseError)
      .finally(() => {
        showLoader(false);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  const toogleSupplierCard = (key: number) => {
    if (supplierCard.includes(key)) {
      const index = supplierCard.findIndex(item => item === key);
      supplierCard.splice(index, 1);
      setSupplierCard([...supplierCard]);
    } else {
      setSupplierCard([...supplierCard, key]);
    }
  };

  const verifySupplierCard = (key: number) => {
    return supplierCard.findIndex(item => item === key) >= 0;
  };

  const removeSupplier = (key: number) => {
    awardItems.splice(key, 1);
    setAwardItems([...awardItems]);
    closeDeleteModal();
  };

  const openDeleteModal = (key: number, name: string) => {
    setSupplierName(name);
    setQuotationKey(key);
    setDeleteModalIsOpen(!deleteModalIsOpen);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(!deleteModalIsOpen);
    setQuotationKey(null);
    setSupplierName('');
  };

  const closeRewardItems = () => {
    history.push(`/quote/show/${quotationId}`);
  };

  const toCardContent = (supplier: Supplier) => {
    const cardContent: cardContent = {
      id: supplier.id,
      nickname: supplier.nickname || '-',
      code: supplier.code || '-',
      social_name: supplier.social_name || '-',
      document_number: supplier.document_number || '-',
      uf: supplier.uf || '-',
      country: supplier.country || '-',
      score_serasa: supplier.score_serasa || 0,
      payment_deadline: supplier.payment_deadline || 0,
      fqi: supplier.fqi || 0,
      saving_percent: supplier.saving_percent || 0,
    };

    return cardContent;
  };

  const closeErrorPage = () => setResponseError(null);

  function closeErrorModal() {
    setErrorModalIsOpen(false);
  }

  return (
    <div>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={closeErrorPage}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary">
          {loading && <Loading />}
          <Navbar pageTitle={''} selectedMenu="panel" />
          <div className="flex items-center px-12 xl:px-20">
            <span className="font-default font-normal text-white text-sm">
              {t('quotation_detail')}
            </span>
            <IconBreadcrumb className="h-2 w-2 mx-4" />
            <span className="font-default font-bold text-white text-sm">
              {t('rewarding_item')}
            </span>
          </div>
          <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
            <Content className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6">
              <div className="flex justify-between">
                <span className="font-default text-2xl text-yaleblue-700 font-bold">
                  {t('rewarding_items')}
                </span>
                <CloseIcon
                  className="h-3 w-3 cursor-pointer"
                  onClick={() => closeRewardItems()}
                />
              </div>
              <span className="mt-11 text-yaleblue-800 text-lg font-semibold">
                {`1. ${t('items_by_supplier')}`}
              </span>
              {awardItems.map((item, index) => (
                <div key={index}>
                  <div>
                    <div className="mt-10 mb-2.5">
                      <div>
                        <div className="flex items-center">
                          <div className="flex w-full items-center">
                            <DiagramIcon />
                            <span className="ml-3 text-lg font-semibold text-yaleblue-800 ml-8">
                              {item.supplier.nickname}
                            </span>
                            <ChavronIcon
                              className="ml-3 cursor-pointer"
                              onClick={() => toogleSupplierCard(index)}
                            />
                            <div className="text-lg font-normal text-yaleblue-800 ml-8">
                              {`${t('selected_items')}`}
                            </div>
                          </div>
                          <div className="flex justify-end w-full">
                            <CloseIcon
                              className="h-3 w-3 cursor-pointer"
                              onClick={() =>
                                openDeleteModal(index, item.supplier.nickname)
                              }
                            />
                          </div>
                        </div>
                        {verifySupplierCard(index) && (
                          <div className="mt-6">
                            <SupplierCard
                              cardContent={toCardContent(item.supplier)}
                              isBlue={true}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex w-full items-center mt-10 justify-between px-4">
                        {fields.map((field, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${
                              field.size || 'w-1/12'
                            }`}
                          >
                            <div className="w-4 h-4 items-center flex">
                              {sortField.name === field.label ? (
                                sortField.value === 'desc' ? (
                                  <SortDescIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() =>
                                      toggleSort(
                                        field.label,
                                        item.quotation_items,
                                      )
                                    }
                                  />
                                ) : (
                                  <SortAscIcon
                                    className="cursor-pointer w-3 h-2.5"
                                    onClick={() =>
                                      toggleSort(
                                        field.label,
                                        item.quotation_items,
                                      )
                                    }
                                  />
                                )
                              ) : (
                                <SortDescIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() =>
                                    toggleSort(
                                      field.label,
                                      item.quotation_items,
                                    )
                                  }
                                />
                              )}
                            </div>
                            <span
                              title={t(field.label)}
                              className="font-default select-none font-bold text-sm text-bluejeans-800 truncate"
                            >
                              {t(field.label)}
                            </span>
                          </div>
                        ))}
                        <div className="flex h-3 w-8" />
                      </div>
                    </div>
                  </div>
                  <div>
                    {item.quotation_items.map((quotation, key) => {
                      const FeaturedComponent: React.FC = () => {
                        const itemData = quotation.item_data;
                        const labels = [
                          {
                            label: itemData.cod_quotation,
                            size: 'w-2/12',
                          },
                          {
                            label: removeZerosLeft(itemData.item_quotation),
                          },
                          {
                            label: itemData.quantity,
                          },
                          {
                            label: quotation.unm,
                          },
                          {
                            label: `${parseInt(itemData.material)}`,
                          },
                          {
                            label:
                              itemData.short_description ||
                              itemData.long_description,
                          },
                          {
                            label: itemData.center,
                          },
                          {
                            label: quotation.vuo || '-',
                          },
                          {
                            label: quotation.por || '-',
                          },
                        ];

                        return (
                          <>
                            {labels.map((item, key) => (
                              <div
                                key={`label-${key}`}
                                className={`flex ${item.size || 'w-1/12'}`}
                              >
                                <span
                                  title={`${item.label}`}
                                  className={`font-default font-normal text-sm text-yaleblue-900 truncate`}
                                >
                                  {item.label}
                                </span>
                              </div>
                            ))}
                          </>
                        );
                      };
                      const ContentComponent: React.FC = () => {
                        const itemData = quotation.item_data;
                        const contentItems = [
                          {
                            label: 'requisition_number',
                            value: removeZerosLeft(itemData.requisition),
                          },
                          {
                            label: 'requisition_item',
                            value: removeZerosLeft(itemData.item),
                          },
                          {
                            label: 'creation_date_rc',
                            value: format(
                              parseISO(itemData.rc_creation_date),
                              'dd/MM/yyyy',
                            ),
                          },
                          {
                            label: 'urgency',
                            value: itemData.urgency,
                          },
                          {
                            label: 'long_description',
                            value: itemData.long_description,
                          },
                          {
                            label: 'taxes',
                            firstLabel: t('icms'),
                            firstValue: quotation.icm,
                            secondLabel: t('ipi'),
                            secondValue: quotation.ipi,
                          },
                        ];
                        return (
                          <>
                            <div className="flex flex-row pt-4 px-4">
                              <div className="flex w-full">
                                {contentItems.map((item, key) => (
                                  <ContentContainer
                                    className="justify-center items-center h-24 mr-4 w-2/12"
                                    key={key}
                                  >
                                    {item.label === 'taxes' ? (
                                      <div className="flex flex-col">
                                        <span className="font-default font-semibold text-xs text-yaleblue-1000 mt-4">
                                          {t(item.label)}
                                        </span>
                                        <div className="flex mt-2 justify-between">
                                          <span className="w-1/6 font-default font-normal text-xs text-yaleblue-1000">
                                            {item.firstLabel}
                                          </span>
                                          <span className="w-1/6 font-default font-normal text-xs text-yaleblue-1000">
                                            {item.secondLabel}
                                          </span>
                                        </div>
                                        <div className="flex mt-2 justify-between">
                                          <span className="w-1/6 font-default font-normal text-xs text-yaleblue-1000">
                                            {item.firstValue}
                                          </span>
                                          <span className="w-1/6 font-default font-normal text-xs text-yaleblue-1000">
                                            {item.secondValue}
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex flex-col">
                                        <span className="font-default font-normal text-xs text-yaleblue-1000 mt-4">
                                          {t(item.label)}
                                        </span>
                                        <span
                                          title={`${item.value || ''}`}
                                          className="font-default font-normal text-sm text-yaleblue-1000 truncate"
                                        >
                                          {item.value}
                                        </span>
                                      </div>
                                    )}
                                  </ContentContainer>
                                ))}
                              </div>
                            </div>
                            <div className="w-full border-t-2 border-yaleblue-transparent pt-5 pb-12 px-4 flex justify-between">
                              <div className="w-full">
                                <div className="text-sm font-normal text-yaleblue-1000">
                                  {t('value_added_tax')}
                                </div>
                                <input
                                  className={`h-10 w-full border border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 focus:outline-none focus-within:border-primary focus:within:border-2 ${
                                    formErrors?.iva?.includes(index) &&
                                    'border-input-error'
                                  }`}
                                  name={'iva'}
                                  onChange={e =>
                                    editFormQuotationItems(
                                      quotation,
                                      e.target.name,
                                      e.target.value,
                                    )
                                  }
                                  defaultValue={quotation.iva}
                                />
                              </div>
                              <div className="pl-4 w-full">
                                <div className="text-sm font-normal text-yaleblue-1000">
                                  {t('mercosur_common_nomenclature')}
                                </div>
                                <input
                                  className={`h-10 w-full border border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 focus:outline-none focus-within:border-primary focus:within:border-2 ${
                                    formErrors?.ncm?.includes(index) &&
                                    'border-input-error'
                                  }`}
                                  defaultValue={quotation.item_data.ncm}
                                  name={'ncm'}
                                  onChange={e =>
                                    (quotation.item_data.ncm = e.target.value)
                                  }
                                />
                              </div>
                              <div className="pl-4 w-full">
                                <div className="text-sm font-normal text-yaleblue-1000">
                                  {t('remittance_dt')}
                                </div>
                                <div
                                  className={`h-10 w-full border border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 flex items-center ${
                                    formErrors?.dt_delivery?.includes(index) &&
                                    'border-input-error'
                                  }`}
                                >
                                  <CustomDatePicker
                                    selected={quotation.dt_ent}
                                    onChange={(date: Date) =>
                                      handleDtDelivery(date, quotation)
                                    }
                                    minDate={new Date()}
                                    placeholder="dd/mm/aaaa"
                                  />
                                </div>
                              </div>
                              <div className="pl-4 w-full">
                                <div className="text-sm font-normal text-yaleblue-1000">
                                  {t('origin')}
                                </div>
                                <input
                                  className="h-10 w-full border border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 focus:outline-none focus-within:border-primary focus:within:border-2"
                                  defaultValue={quotation.mat_origin}
                                  name={'mat_origin'}
                                  onChange={e =>
                                    editFormQuotationItems(
                                      quotation,
                                      e.target.name,
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        );
                      };

                      return (
                        <div key={key} className="flex items-center w-full">
                          <ListItem
                            FeaturedComponent={FeaturedComponent}
                            ContentComponent={ContentComponent}
                            className="mt-2"
                            item={quotation}
                          />
                          <div className="pl-2.5 w-3.5">
                            {formErrors &&
                              [
                                ...formErrors.ncm,
                                ...formErrors.iva,
                                ...formErrors.dt_delivery,
                              ].includes(index) && <AlertIcon />}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="border-b border-yaleblue-transparent ">
                    <div className="mt-10">
                      <div className="flex items-center">
                        <div className="text-lg text-yaleblue-800 font-semibold mr-2">
                          {`${t('payment_condition')}`}
                        </div>
                        {formErrors?.payment_condition.includes(index) && (
                          <AlertIcon />
                        )}
                      </div>
                      <CustomDropDown
                        toggleAction={(value: string) =>
                          handlePaymentMethod(value, item)
                        }
                        options={paymentOptions}
                        title={t('select')}
                        url={'payment_condition/list_to_filter/?description='}
                        handleOptions={handleLoadedPaymentMethod}
                        selectedItem={item.supplier.payment_condition.code}
                        hasError={formErrors?.payment_condition.includes(index)}
                      />
                    </div>
                    <div className="mt-10">
                      <div className="flex items-center">
                        <div className="text-lg text-yaleblue-800 font-semibold mr-2">
                          {`${t('incoterm')}`}
                        </div>
                        {formErrors?.incoterm.includes(index) && <AlertIcon />}
                      </div>
                      <CustomDropDown
                        toggleAction={(value: string) =>
                          handleIncoterm(value, item)
                        }
                        options={freights}
                        title={t('select')}
                        url={'freight/list_to_filter/?incoterm='}
                        handleOptions={handleLoadedIncoterm}
                        selectedItem={item.supplier.incoterm.name}
                        hasError={formErrors?.incoterm.includes(index)}
                      />
                    </div>
                    <div className="py-10">
                      <div className="text-lg text-yaleblue-800 font-semibold w-full">
                        {`${t('note')}`}
                      </div>
                      <div className="flex items-center">
                        <textarea
                          className=" rounded mt-2 text-sm text-bluejeans-800 px-4 border-basic-gray-transparent border-2 focus:outline-none focus-within:border-primary focus:within:border-2 bg-white"
                          name={'supplierObservation'}
                          rows={8}
                          style={{ width: '100%' }}
                          onChange={e =>
                            onChange(e.target.name, e.target.value, index)
                          }
                          defaultValue={defaultNote}
                        />
                        <button
                          type="button"
                          className={`h-8 px-2 w-40 flex ml-3 items-center justify-between bg-white text-sm text-bluejeans-800 font-normal rounded border-2 mt-1`}
                        >
                          {t('attach_file')}
                          <PaperIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="mt-10">
                <div className="flex items-center">
                  <div className="text-lg text-yaleblue-800 font-semibold pr-2.5">
                    {`2. ${t('buyer_group')}`}
                  </div>
                  {formErrors?.buyer_group && (
                    <div>
                      <AlertIcon />
                    </div>
                  )}
                </div>
                <CustomDropDown
                  toggleAction={handleBuyerGroup}
                  options={buyerGroups}
                  title={t('select')}
                  url={'/buyer_groups/list_to_filter/?name='}
                  handleOptions={handleLoadedBuyerGroup}
                  selectedItem={buyerGroup?.name}
                  hasError={!!formErrors?.buyer_group}
                />
              </div>
              {/* <div className="mt-10">
                <div className="flex items-center">
                  <div className="text-lg text-yaleblue-800 font-semibold pr-2.5">
                    {`3. ${t('request_type')}`}
                  </div>
                  {formErrors?.request_type && (
                    <div>
                      <AlertIcon />
                    </div>
                  )}
                </div>
                <input
                  className={`h-10 w-40 border-2 border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 focus:outline-none focus-within:border-primary focus:within:border-2 ${
                    formErrors?.request_type && 'border-input-error'
                  }`}
                  name={'requestType'}
                  value={state.requestType}
                  onChange={e => onChange(e.target.name, e.target.value, null)}
                />
              </div> */}
              <div className="mt-10 mb-16">
                <div className="text-lg text-yaleblue-800 font-semibold w-full">
                  {`3. ${t('approver_observation')}`}
                </div>
                <textarea
                  className="border-2 border-basic-gray-transparent rounded mt-2 text-sm text-bluejeans-800 px-4 focus:outline-none focus-within:border-primary focus:within:border-2"
                  name={'approverObservation'}
                  rows={8}
                  style={{ width: '100%' }}
                  onChange={e => onChange(e.target.name, e.target.value, null)}
                />
              </div>
              <div className="w-full flex justify-end items-center mb-10">
                <button
                  className="h-12 w-44 cursor-pointer px-2 py-3 bg-bluejeans-800 rounded text-sm text-white font-semibold"
                  onClick={() => setIsOpen(true)}
                >
                  {t('reward_items')}
                </button>
              </div>
            </Content>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="w-full flex justify-end cursor-pointer">
                <CloseIcon onClick={() => closeModal()} />
              </div>
              <ConfirmationIcon />
              <div className="my-4 mt-10 mb-7">
                <div className="font-default font-normal text-sm text-yaleblue-1000">
                  <Trans
                    i18nKey="confirmation_selected_reward_items_list"
                    count={totalAwardedItems}
                  />
                </div>
              </div>
              <div className="flex items-center ">
                <span
                  className="underline text-sm text-bluejeans-800 mr-5 font-semibold cursor-pointer mr-4"
                  onClick={() => closeModal()}
                >
                  {t('cancel')}
                </span>
                <Button
                  className="h-12 px-8 text-base"
                  onClick={handleSubmitForm}
                >
                  {t('reward_items')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={deleteModalIsOpen && quotationKey !== null}
            onRequestClose={closeDeleteModal}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="w-full flex justify-end cursor-pointer">
                <CloseIcon onClick={() => closeDeleteModal()} />
              </div>
              <DeleteIcon />
              <div className="my-4 mt-10 mb-7">
                <div className="font-default font-normal text-sm text-yaleblue-1000">
                  {t('confirmation_delete_supplier', {
                    supplier_name: supplierName,
                  })}
                </div>
              </div>
              <div className="flex items-center ">
                <span
                  className="underline text-sm text-bluejeans-800 mr-5 font-semibold cursor-pointer mr-4"
                  onClick={() => closeDeleteModal()}
                >
                  {t('cancel')}
                </span>
                <Button
                  className="h-12 px-8 text-base"
                  onClick={() =>
                    quotationKey !== null && removeSupplier(quotationKey)
                  }
                >
                  {t('delete')}
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={errorModalIsOpen}
            onRequestClose={closeErrorModal}
            ariaHideApp={false}
            className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
          >
            <div className="flex flex-col justify-center items-center">
              <div className="w-full flex justify-end cursor-pointer">
                <CloseIcon onClick={() => closeErrorModal()} />
              </div>
              <DeleteIcon />
              <div className="my-4 mt-10 mb-7">
                <div className="font-default font-normal text-sm text-yaleblue-1000">
                  {t('form_with_empty_fields')}
                </div>
              </div>
              <div className="flex items-center ">
                <Button
                  className="h-12 px-8 text-base"
                  onClick={() => closeErrorModal()}
                >
                  {t('confirmation_modal')}
                </Button>
              </div>
            </div>
          </Modal>
        </Container>
      )}
    </div>
  );
};

export default RewardItems;
