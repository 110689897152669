import React, { useState, InputHTMLAttributes } from 'react';

import {
  Container,
  WrapperInput,
  LabelSpan,
  ErrorMessage,
} from './Input.style';

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  className?: string;
  label?: string;
  controlled?: boolean;
  value?: string;
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  register?: ({ required }: { required?: boolean }) => RefReturn;
  hasError?: boolean;
  messageError?: string;
  showContainerError?: boolean;
  withoutContainer?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  onChange,
  className,
  icon: Icon,
  register,
  required,
  value,
  defaultValue,
  hasError,
  controlled,
  label,
  messageError,
  showContainerError = true,
  withoutContainer = false,
  ...rest
}) => {
  const [val, setVal] = useState(value);
  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
    if (onChange) onChange(event);
  }
  return (
    <WrapperInput id="input">
      {label && <LabelSpan hasError={hasError}>{label}</LabelSpan>}

      {withoutContainer ? (
        <Container
          className={` px-4 h-6 bg-white
        ${className}`}
        >
          {register ? (
            <input
              name={name}
              value={value}
              className="text-yaleblue-900 placeholder-basic-gray-400 text-sm outline-none placeholder-opacity-30"
              ref={register({ required })}
              {...rest}
            />
          ) : (
            <input
              name={name}
              value={controlled ? value : val}
              className="text-yaleblue-900 placeholder-basic-gray-400 text-sm outline-none placeholder-opacity-30"
              onChange={handleChangeValue}
              defaultValue={defaultValue}
              {...rest}
            />
          )}
          {Icon && <Icon />}
        </Container>
      ) : (
        <Container
          className={`flex  items-center px-4 h-12 rounded border border-color-yaleblue focus-within:border-primary focus:within:border-2 ${
            value ? 'bg-white' : 'bg-whitegray-100'
          }
        ${hasError ? ' border-red-salsa-500 ' : ''}
        ${className}`}
        >
          {register ? (
            <input
              name={name}
              value={value}
              className="text-yaleblue-900 placeholder-basic-gray-400 text-sm outline-none placeholder-opacity-30"
              ref={register({ required })}
              {...rest}
            />
          ) : (
            <input
              name={name}
              value={controlled ? value : val}
              className="text-yaleblue-900 placeholder-basic-gray-400 text-sm outline-none placeholder-opacity-30"
              onChange={handleChangeValue}
              defaultValue={defaultValue}
              {...rest}
            />
          )}
          {Icon && <Icon />}
        </Container>
      )}

      {showContainerError && <ErrorMessage>{messageError}</ErrorMessage>}
    </WrapperInput>
  );
};

export default Input;
