import React, { ReactElement } from 'react';
import ReactLoading from 'react-loading';

const Loading = (): ReactElement => (
  <div className="pl-4">
    <ReactLoading type={'balls'} color={'#9BA8BB'} height={20} width={20} />
  </div>
);

export default Loading;
