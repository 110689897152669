import React from 'react';
import { SvgContainer, CircleTrack, CircleIndicator } from './DonutChart.style';

interface DonutChartProps {
  value: number;
  size: number;
  strokeColor: string;
  strokeWidth: number;
}

const DonutChart: React.FC<DonutChartProps> = ({
  value,
  size,
  strokeColor,
  strokeWidth,
}) => {
  const halfSize = size * 0.5;
  const radius = halfSize - strokeWidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeVal = (value * circumference) / 100;
  const indicatorColor = strokeColor;
  const dashVal = strokeVal + ' ' + circumference;
  const trackStyle = { strokeWidth: strokeWidth };
  const indicatorstyle = { strokeWidth: strokeWidth, strokeDasharray: dashVal };
  const rotateVal = 'rotate(-90 ' + halfSize + ',' + halfSize + ')';
  return (
    <div>
      <SvgContainer width={size} height={size}>
        <CircleTrack
          r={radius}
          cx={halfSize}
          cy={halfSize}
          transform={rotateVal}
          style={trackStyle}
        />
        <CircleIndicator
          r={radius}
          cx={halfSize}
          cy={halfSize}
          transform={rotateVal}
          style={indicatorstyle}
          color={indicatorColor}
        />
      </SvgContainer>
    </div>
  );
};

export default DonutChart;
