import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './ListSelectItem.style';
interface ListItemProps {
  labels: Object;
  toogleAction: Function;
  itemKey: number;
}

const ListItem: React.FC<ListItemProps> = ({
  labels,
  toogleAction,
  itemKey,
}) => {
  const { t } = useTranslation();
  return (
    <Container className="flex px-4 rounded-lg h-14 mt-2">
      <div className="flex flex-row h-14 items-center w-full">
        {Object.values(labels).map((value, key) => (
          <span
            key={key}
            className="font-default font-normal text-sm text-yaleblue-900 pr-24"
          >
            {value}
          </span>
        ))}
      </div>
      <div
        className="flex justify-end items-center cursor-pointer"
        onClick={() => toogleAction(itemKey)}
      >
        <span className="underline text-bluejeans-800 font-bold text-sm">
          {t('remove')}
        </span>
      </div>
    </Container>
  );
};

export default ListItem;
