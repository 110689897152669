import React from 'react';

import { Container } from './TimePicker.style';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBR);

interface DatePickerProps {
  selected: Date | null;
  onChange(
    date: Date | [Date, Date] | /* for selectsRange */ null,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  customInput?: React.ReactNode;
  hasError?: boolean;
}

const TimePicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  customInput: CustomInput,
  hasError,
}) => {
  return (
    <Container
      className={`flex rounded-lg h-10 items-center border px-4 focus-within:border-primary focus:within:border-2 ${
        selected ? 'bg-white' : 'bg-whitegray-100 '
      }
      ${hasError ? ' border-red-salsa-500 ' : ''}
      `}
    >
      <DatePicker
        locale="pt-BR"
        selected={selected}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Hora"
        placeholderText="00:00:00"
        dateFormat="h:mm aa"
        customInput={
          CustomInput || (
            <input
              className="bg-transparent font-normal text-bluejeans-800 placeholder-basic-gray-400 text-sm focus:outline-none"
              placeholder="00:00:00"
            />
          )
        }
      />
    </Container>
  );
};

export default TimePicker;
