const formatValue = (value: number | null | string): string => {
  if (value === null || value === undefined) return '';
  let newValue = value.toString().replaceAll('.', '');
  newValue = newValue.replaceAll(',', '.');
  return formatCurrency(parseFloat(newValue));
};

export const formatStringToFloat = (
  value: number | null | string,
): number | null => {
  if (value === null || value === undefined) return null;
  value = value.toString().replace('R$', '');
  if (value.includes('.') && !value.includes(',')) {
    return parseFloat(value);
  }
  if (value.includes(',') && !value.includes('.')) {
    return parseFloat(value.replace('.', ','));
  }
  value = value.replace('.', '').replace(',', '.');
  return parseFloat(value);
};

export const formatStringToCurrency = (
  value: number | null | string,
): string => {
  return formatCurrency(formatStringToFloat(value));
};

export const formatCurrency = (value: number | null): string => {
  if (value === null || isNaN(value)) return '';
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export const changeDotToComma = (value: string | null | number): string => {
  if (
    value === null ||
    value === undefined ||
    isNaN(parseFloat(value.toString()))
  )
    return '';
  return value.toString().replaceAll('.', ',');
};

export default formatValue;
