import api from './api';

export interface User {
  isAuthenticated: boolean;
  roles: Array<string>;
  permissions: Array<string>;
  token: string;
  expiryDate: string;
  refreshTokenExpiryDate: string;
  refreshToken?: string;
  keepLogged?: boolean;
  name?: string;
}

const USER_KEY = 'user';

export class RefreshToken {
  private static instance: RefreshToken;

  private static refreshToken: Promise<string | undefined> | null;

  public static getInstance(): RefreshToken {
    if (!RefreshToken.instance) {
      RefreshToken.instance = new RefreshToken();
    }
    return RefreshToken.instance;
  }

  public clearRefreshToken(): void {
    RefreshToken.refreshToken = null;
  }

  public async getRefreshToken(): Promise<string | undefined> {
    if (!RefreshToken.refreshToken) {
      RefreshToken.refreshToken = authService()
        .refreshToken()
        .then(() => {
          return authService().getUser()?.token;
        });
    }
    return RefreshToken.refreshToken;
  }
}

const authService = () => {
  function saveUser(user: User) {
    const userJson = JSON.stringify(user);
    localStorage.setItem(USER_KEY, userJson);
    sessionStorage.setItem(USER_KEY, userJson);
  }

  function logout() {
    localStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(USER_KEY);
  }

  function getUser(): User | null {
    const userJson =
      localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY);
    if (!userJson) return null;
    return JSON.parse(userJson);
  }

  function getEmptyUser(): User {
    return {
      isAuthenticated: false,
      roles: [],
      permissions: [],
      token: '',
      expiryDate: '',
      refreshTokenExpiryDate: '',
      refreshToken: '',
      keepLogged: false,
      // name: '',
    };
  }

  async function refreshToken(refreshToken?: string) {
    const user = getUser();
    if (!user) return false;
    const data = { refresh_token: refreshToken || user.refreshToken };
    const response = await api.post('/refreshtoken/', data).catch(logout);

    if (!response) return false;

    const authData = response.data[0];

    user.token = authData.access_token;
    user.expiryDate = authData.expiry_date;
    user.refreshTokenExpiryDate = authData.refresh_token_expiry;
    user.refreshToken = authData.refresh_token;
    saveUser(user);

    return true;
  }

  function getToken() {
    const user = getUser();
    if (!user) return;
    return user.token;
  }

  return {
    saveUser,
    logout,
    getUser,
    refreshToken,
    getEmptyUser,
    getToken,
  };
};

export default authService();
