import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  Content,
  ModalButton,
  ScrollableContent,
} from './FilterComparativeMap.style';
import Button from '../Button/Button';
import Modal from 'react-modal';

import formatValue from '../../utils/formatValue';

import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckBoxChecked } from '../../assets/checkbox-checked-icon.svg';
import { ReactComponent as SortDescIcon } from '../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../assets/icon-sort-asc.svg';

interface FilterComparativeMapProps {
  type: string;
  round: number;
  items: Object[];
  isOpen: boolean;
  closeModal: Function;
  handleSelectItems(selecteds: number[]): void;
}

interface ParametersProps extends Object {
  selected?: boolean;
  field: string;
  description: string;
}

interface ItemsProps extends Object {
  id: number;
  code: string;
  name: string;
  material: string;
  region: string;
  [key: string]: number | string | Object;
}

interface SupplierProps extends Object {
  id: number;
  code: string;
  name: string;
  total_value: number;
  [key: string]: number | string | Object;
}

interface SortFieldProps {
  name: string;
  value: string;
}

const FilterComparativeMap: React.FC<FilterComparativeMapProps> = ({
  type,
  items,
  isOpen,
  closeModal,
  handleSelectItems,
}) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [mainModal, setMainModal] = useState(true);
  const [selecteds, setSelecteds] = useState<number[]>([]);
  const [onlySelecteds, setOnlySelecteds] = useState(false);
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [data, setData] = useState(items);
  const itemFields = [
    { label: 'code', size: 'w-2/12' },
    { label: 'material', size: 'w-5/12' },
    { label: 'region', size: 'w-2/12' },
  ];

  const supplierFields = [
    { label: 'code', size: 'w-2/12' },
    { label: 'suppliers', size: 'w-4/12' },
    { label: 'total_value', size: 'w-3/12' },
  ];

  function closeConfirmationModal() {
    setConfirmationModal(false);
    closeModal();
  }

  function openConfirmationModal() {
    setData([]);
    if (selecteds.length > 0) {
      setMainModal(false);
      setConfirmationModal(true);
    } else {
      closeModal();
      handleSelectItems([]);
    }
  }

  function handleSelectItem(key: number) {
    const selec = selecteds.slice();
    if (selec.includes(key)) {
      const index = selec.findIndex(k => k === key);
      selec.splice(index, 1);
    } else {
      selec.push(key);
    }

    setSelecteds(selec);
  }

  function handleSelectAll() {
    if (selecteds.length === items.length) {
      setSelecteds([]);
    } else {
      const newSelecteds: number[] = Array.from(Array(items.length).keys());
      setSelecteds(newSelecteds);
    }
  }

  const toggleSort = (field: string) => {
    const sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name === field) {
      setSortField({ name: field, value: sort });
    } else {
      setSortField({ name: field, value: 'asc' });
    }
    if (type === 'items') {
      if (sort === 'asc') {
        (data as ItemsProps[]).sort((a, b) => (b[field] > a[field] ? 1 : -1));
      } else {
        (data as ItemsProps[]).sort((a, b) => (a[field] > b[field] ? 1 : -1));
      }
    }

    if (type === 'suppliers') {
      if (sortField.value === 'asc') {
        (data as SupplierProps[]).sort((a, b) =>
          a[field] > b[field] ? 1 : -1,
        );
      } else {
        (data as SupplierProps[]).sort((a, b) =>
          a[field] > b[field] ? -1 : 1,
        );
      }
    }
  };

  useEffect(() => {
    setMainModal(isOpen);
  }, [isOpen]);
  return (
    <>
      <Modal
        isOpen={mainModal}
        onRequestClose={() => {
          setData([]);
          closeModal();
        }}
        ariaHideApp={false}
        className="h-auto bg-white m-auto outline-none flex w-auto z-100 rounded"
      >
        <div className="flex flex-col">
          <div className="h-16 bg-bluejeans-500 flex px-6">
            <div className="flex justify-between items-center w-full">
              {type === 'parameters' && (
                <span className="text-lg font-semibold font-default text-white">
                  {t('parameters_view')}
                </span>
              )}
              {type === 'items' && (
                <span className="text-lg font-semibold text-white">{`${
                  items.length
                } ${items.length > 1 ? t('items') : t('item')}`}</span>
              )}
              {type === 'suppliers' && (
                <span className="text-lg font-semibold text-white">{`${
                  items.length
                } ${items.length > 1 ? t('suppliers') : t('supplier')}`}</span>
              )}

              <div
                className="w-3 flex cursor-pointer"
                onClick={() => {
                  setData([]);
                  closeModal();
                }}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <div
            className={`mt-4 flex flex-col px-6 w-1/2 pb-3 ${
              type === 'parameters' ? 'border-r-2' : ''
            }`}
          >
            {selecteds.length === items.length ? (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => handleSelectAll()}
              >
                <div className="w-4">
                  <CheckBoxChecked />
                </div>
                <span className="ml-4 underline font-bold text-sm font-default text-bluejeans-800">
                  {t('uncheck_all')}
                </span>
              </div>
            ) : (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => handleSelectAll()}
              >
                <div className="w-4">
                  <CheckBoxUnchecked />
                </div>
                <span className="ml-4 underline font-bold text-sm font-default text-bluejeans-800">
                  {t('check_all')}
                </span>
              </div>
            )}
          </div>
          {type === 'parameters' && (
            <Content className="flex flex-col flex-wrap">
              {(items as ParametersProps[]).map((item, index) => (
                <div
                  className="w-1/2 flex items-center h-14 pl-6 border-r-2 border-b-2"
                  key={`param-${index}`}
                >
                  <div
                    className="w-4 cursor-pointer"
                    onClick={() => handleSelectItem(index)}
                  >
                    {selecteds.includes(index) ? (
                      <CheckBoxChecked />
                    ) : (
                      <CheckBoxUnchecked />
                    )}
                  </div>
                  <span className="ml-4 text-sm font-normal font-default text-yaleblue-900 truncate">
                    {`${item.field.toUpperCase()} - ${item.description}`}
                  </span>
                </div>
              ))}
            </Content>
          )}

          {type === 'items' && (
            <Content className="flex flex-col">
              <div className="flex mx-4 px-4 justify-between items-center">
                <div className="w-4" />
                {itemFields.map((field, index) => (
                  <div
                    className={`flex items-center ${field.size}`}
                    key={`item-sort-${index}`}
                  >
                    {sortField.name === field.label ? (
                      sortField.value === 'desc' ? (
                        <SortDescIcon
                          className="cursor-pointer w-3 h-2.5"
                          onClick={() => toggleSort(field.label)}
                        />
                      ) : (
                        <SortAscIcon
                          className="cursor-pointer w-3 h-2.5"
                          onClick={() => toggleSort(field.label)}
                        />
                      )
                    ) : (
                      <SortDescIcon
                        className="cursor-pointer w-3 h-2.5"
                        onClick={() => toggleSort(field.label)}
                      />
                    )}
                    <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2">
                      {t(field.label)}
                    </span>
                  </div>
                ))}
              </div>
              <ScrollableContent className="overflow-y-auto mt-4 mb-4">
                {(data as ItemsProps[]).map((item, index) =>
                  onlySelecteds ? (
                    selecteds.includes(index) && (
                      <div
                        className="flex items-center h-12 mx-4 px-4 justify-between border-2 rounded-lg"
                        key={`param-${index}`}
                      >
                        <div
                          className="w-4 cursor-pointer"
                          onClick={() => handleSelectItem(index)}
                        >
                          {selecteds.includes(index) ? (
                            <CheckBoxChecked />
                          ) : (
                            <CheckBoxUnchecked />
                          )}
                        </div>
                        <div className="w-2/12">
                          <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                            {item.code?.toUpperCase() || '-'}
                          </span>
                        </div>
                        <div className="w-5/12">
                          <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                            {item.material?.toUpperCase() || '-'}
                          </span>
                        </div>
                        <div className="w-2/12">
                          <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                            {item.region?.toUpperCase() || '-'}
                          </span>
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className="flex items-center h-12 mx-4 px-4 justify-between border-2 rounded-lg"
                      key={`param-${index}`}
                    >
                      <div
                        className="w-4 cursor-pointer"
                        onClick={() => handleSelectItem(index)}
                      >
                        {selecteds.includes(index) ? (
                          <CheckBoxChecked />
                        ) : (
                          <CheckBoxUnchecked />
                        )}
                      </div>
                      <div className="w-2/12">
                        <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                          {item.code?.toUpperCase() || '-'}
                        </span>
                      </div>
                      <div className="w-5/12">
                        <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                          {item.material?.toUpperCase() || '-'}
                        </span>
                      </div>
                      <div className="w-2/12">
                        <span className="text-sm font-normal font-default text-yaleblue-900 truncate">
                          {item.region?.toUpperCase() || '-'}
                        </span>
                      </div>
                    </div>
                  ),
                )}
              </ScrollableContent>
            </Content>
          )}

          {type === 'suppliers' && (
            <Content className="flex flex-col">
              <div className="flex mx-4 px-4 justify-between items-center">
                <div className="w-4" />
                {supplierFields.map((field, index) => (
                  <div
                    className={`flex items-center ${field.size}`}
                    key={`item-sort-${index}`}
                  >
                    {sortField.name === field.label ? (
                      sortField.value === 'desc' ? (
                        <SortDescIcon
                          className="cursor-pointer w-3 h-2.5"
                          onClick={() => toggleSort(field.label)}
                        />
                      ) : (
                        <SortAscIcon
                          className="cursor-pointer w-3 h-2.5"
                          onClick={() => toggleSort(field.label)}
                        />
                      )
                    ) : (
                      <SortDescIcon
                        className="cursor-pointer w-3 h-2.5"
                        onClick={() => toggleSort(field.label)}
                      />
                    )}
                    <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2">
                      {t(field.label)}
                    </span>
                  </div>
                ))}
              </div>
              <ScrollableContent className="overflow-y-auto mt-4 mb-4">
                {(data as SupplierProps[]).map((item, index) =>
                  onlySelecteds ? (
                    selecteds.includes(index) && (
                      <div
                        className="flex items-center h-12 mx-4 px-4 justify-between border-2 rounded-lg mb-2"
                        key={`param-${index}`}
                      >
                        <div
                          className="w-4 cursor-pointer"
                          onClick={() => handleSelectItem(index)}
                        >
                          {selecteds.includes(index) ? (
                            <CheckBoxChecked />
                          ) : (
                            <CheckBoxUnchecked />
                          )}
                        </div>
                        <span className="w-2/12 text-sm font-normal font-default text-yaleblue-900 truncate">
                          {item.code?.toUpperCase() || '-'}
                        </span>

                        <span className="w-4/12 text-sm font-normal font-default text-yaleblue-900 truncate">
                          {item.name?.toUpperCase() || '-'}
                        </span>

                        <span className=" w-3/12 text-sm font-normal font-default text-yaleblue-900 truncate">
                          {formatValue(item.total_value)}
                        </span>
                      </div>
                    )
                  ) : (
                    <div
                      className="flex items-center h-12 mx-4 px-4 justify-between border-2 rounded-lg mb-2"
                      key={`param-${index}`}
                    >
                      <div
                        className="w-4 cursor-pointer"
                        onClick={() => handleSelectItem(index)}
                      >
                        {selecteds.includes(index) ? (
                          <CheckBoxChecked />
                        ) : (
                          <CheckBoxUnchecked />
                        )}
                      </div>

                      <span className="w-2/12 text-sm font-normal font-default text-yaleblue-900 truncate">
                        {item.code?.toUpperCase() || '-'}
                      </span>

                      <span className=" w-4/12 text-sm font-normal font-default text-yaleblue-900 truncate">
                        {item.name?.toUpperCase() || '-'}
                      </span>

                      <span className="w-3/12 text-sm font-normal font-default text-yaleblue-900">
                        {formatValue(item.total_value)}
                      </span>
                    </div>
                  ),
                )}
              </ScrollableContent>
            </Content>
          )}

          <div className="flex items-center justify-end">
            {type !== 'parameters' &&
              (selecteds.length > 0 && selecteds.length !== data.length ? (
                <span
                  onClick={() => setOnlySelecteds(!onlySelecteds)}
                  className="underline text-sm text-bluejeans-800 font-bold mr-4 cursor-pointer select-none"
                >
                  {t('remove_unchecked_items')}
                </span>
              ) : (
                <span className="underline text-sm text-gray-400 font-bold mr-4 cursor-pointer select-none">
                  {t('remove_unchecked_items')}
                </span>
              ))}
            <ModalButton
              type="button"
              className={`mr-10 mb-5 px-4 h-10 flex items-center justify-center text-sm text-bluejeans-800 font-bold`}
              onClick={() => openConfirmationModal()}
            >
              {t('show_selecteds')}
            </ModalButton>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={confirmationModal}
        onRequestClose={closeConfirmationModal}
        ariaHideApp={false}
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <ConfirmationIcon />

          <div className="my-4">
            {selecteds.length === items.length ? (
              <span className="font-default font-normal text-sm text-yaleblue-1000">
                {t('confirmation_selected_all_parameters')}
              </span>
            ) : (
              <span className="font-default font-normal text-sm text-yaleblue-1000">
                <Trans
                  i18nKey="confirmation_selected_parameters"
                  count={selecteds.length}
                />
              </span>
            )}
          </div>
          <div className="flex items-center justify-evenly">
            <span
              onClick={() => closeConfirmationModal()}
              className="underline text-sm text-bluejeans-800 font-bold mr-4 cursor-pointer select-none"
            >
              {t('cancel')}
            </span>
            <Button
              onClick={() => {
                closeConfirmationModal();
                handleSelectItems(selecteds);
              }}
            >
              {t('continue')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterComparativeMap;
