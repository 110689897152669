/* eslint-disable func-names */
/* eslint-disable import/no-anonymous-default-export */
import * as Yup from 'yup';

import { validCpf } from './cpf.js';
import { isEmail } from './email.js';

export default {
  Cpf() {
    return Yup.string()
      .required('Você precisa informar o seu CPF')
      .test('cpf', 'CPF inválido', value => {
        if (value) {
          return validCpf(value);
        }
        return true;
      });
  },

  Required() {
    return Yup.string().required('Campo obrigatório.');
  },
  IsTrue() {
    return Yup.bool().oneOf([true], 'Campo obrigatório.');
  },
  Email() {
    return Yup.string()
      .email('E-mail inválido.')
      .required('Você precisa informar o seu e-mail.');
  },
};
