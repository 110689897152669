import React, { createContext, useState } from 'react';

interface iComparativeMap {
  rfq_id: number;
  items: ItemsProps[];
  quotation_items_supplier: QuotationProps[];
  rfq_quotations: [{ quotation_id: number; quotation_order: number }];
}

interface ItemsProps {
  item_id: number;
  item_name: string;
  last_purchase: LastPurchaseProps;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_branch_region: string;
  item_cod_material: string;
  item_st_control: number;
  item_num_gp: string;
}

interface LastPurchaseProps {
  date: string;
  order_number: string;
  unit_value: number;
  item_req: string;
  supplier_code: string;
  supplier_name: string;
}

interface QuotationProps {
  id: number;
  supplier: SupplierProps;
  quotation_items: QuotationItemProps[];
  quotation_id: number;
}

interface SupplierProps {
  id: number;
  name: string;
  code: string;
  cod_quotation: string;
  nickname: string;
  incoterm: { id: number; name: string };
  payment_condition: { id: number; code: string };
  reduction_percentage: number | null;
  total_freight_rate: number;
  total_rfq_value: number;
}

interface QuotationItemProps {
  item_id: number;
  item_rfq_id: number;
  answer_code_AI: string;
  ia_final_decision_msg: string;
  ia_final_decision: string;
  item_rc_evaluation_value: number;
  item_target_value: number | null;
  item_baseline_value: number;
  item_rfq_st_control: number;
  item_st_control: number;
  item_branch_region: string;
  item_cod_material: string;
  vuo: number;
  [key: string]: number | string | null | Object;
}

interface ComparativeMapContextData {
  comparativeMap: iComparativeMap;
  setComparativeMap: Function;
  lines: LineProps[];
  setLines: Function;
  isCurrentQuote: boolean;
  setIsCurrentQuote: Function;
  handleSelectLine(prop: IQuotation, index: number): void;
  awardItems(reward: RewardsProps): void;
}

interface LineProps {
  item: ItemsProps;
  quotations: IQuotation[];
}

interface IQuotation {
  quotation_items: QuotationItemProps | undefined;
  props: QuotationItemProps | undefined;
  selected: boolean;
  supplier: SupplierProps;
  quotation_id: number;
}

interface RewardsProps {
  quoteItem: QuotationItemProps;
  supplier: SupplierProps;
}

const Context = createContext<ComparativeMapContextData>(
  {} as ComparativeMapContextData,
);

const ComparativemapProvider: React.FC = ({ children }) => {
  const [comparativeMap, setComparativeMap] = useState<iComparativeMap>(
    {} as iComparativeMap,
  );
  const [lines, setLines] = useState<LineProps[]>([]);
  const [isCurrentQuote, setIsCurrentQuote] = useState(false);

  function handleSelectLine(prop: IQuotation, index: number) {
    prop.selected = !prop.selected;

    const newLines = lines.slice();
    const line = newLines[index];
    const quoteIndex = line.quotations.findIndex(
      q => q.supplier === prop.supplier,
    );

    line.quotations[quoteIndex] = prop;
    newLines[index] = line;
    setLines(newLines);
  }

  function awardItems(reward: RewardsProps) {
    const newlines = lines.slice();
    if (newlines) {
      newlines.forEach(line => {
        line.quotations.forEach(quote => {
          if (
            quote.props?.item_id === reward.quoteItem.item_id &&
            reward.supplier.code === quote.supplier.code
          ) {
            quote.selected = true;
          }
        });
      });
      setLines(newlines);
    }
  }

  return (
    <Context.Provider
      value={{
        comparativeMap,
        setComparativeMap,
        lines,
        setLines,
        handleSelectLine,
        awardItems,
        isCurrentQuote,
        setIsCurrentQuote,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ComparativemapProvider };
