import authService from './auth-service';

export const userHasAnyPermissionOf = (permissions: Array<string>): boolean => {
  const user = authService.getUser();
  if (!user) return false;
  if (!user?.roles) {
    authService.logout();
    return false;
  }
  if (user?.roles.includes('admin')) return true;

  if (permissions.some(perm => user?.permissions.includes(perm))) {
    return true;
  } else {
    return false;
  }
};
