interface ErrorProps {
  fieldObject:
    | Array<Object>
    | number
    | string
    | Date
    | Object
    | null
    | undefined;
  fieldName: string;
}

export const getEmptyRequiredInputs = (
  fields: Array<ErrorProps>,
  vendor?: boolean,
): Array<string> => {
  let fieldsWithError: Array<string> = [];
  fields.forEach(field => {
    if (!field.fieldObject || field.fieldObject.toString().length === 0) {
      fieldsWithError.push(field.fieldName);
    }

    if (Array.isArray(field.fieldObject) && field.fieldName === 'newItems') {
      field.fieldObject.forEach(newItem => {
        if (!Object(newItem)?.dt_delivery) {
          fieldsWithError.push('newItems');
        }
      });
    }
  });
  if (vendor && !fieldsWithError.includes('selectedSuppliers')) {
    if (fieldsWithError.length < 6) fieldsWithError = [];
  }

  return fieldsWithError;
};
