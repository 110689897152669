import api from '../../services/api';

export async function loadSuggestions(items: any[], setSuggestions: Function) {
  const itemsList: any[] = [];
  items.forEach(item => {
    itemsList.push(item.vendor_list?.replace(' ', ''));
  });
  if (itemsList.length > 0) {
    await api
      .get(`suppliers/list_to_filter/?supplier=${itemsList}&exactly_match`)
      .then(response => {
        if (response.data.results.length > 0) {
          setSuggestions(response.data.results);
        }
      });
  }
}

export const addItem = (
  index: number,
  suggestions: any[],
  selectedItems: any[],
  setSelectedItems: Function,
  cardContent: any[],
  setCardContent: Function,
  handleCard: Function,
  setSuggestions: Function,
) => {
  const suggestion = suggestions[index];
  const selectedSupplier = selectedItems.findIndex(
    sup => sup.id === suggestion.id,
  );
  let addNewItem = true;
  cardContent.forEach(item => {
    if (item.id === suggestion.id) {
      addNewItem = false;
    }
  });
  if (selectedSupplier < 0 && addNewItem) {
    selectedItems.push({
      id: suggestion.id,
      value: suggestion.social_name,
    });
    setSelectedItems([...selectedItems]);
    setCardContent([...cardContent, handleCard(suggestion)]);
  }
  suggestions.splice(index, 1);
  setSuggestions([...suggestions]);
};
