import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import {
  FirstContainer,
  SecondContainer,
  Logo,
  TextNotFound,
  Title,
  Text,
  WrapperForm,
  Action,
} from './ForgotPassword.style';
import ModalButton from '../../components/Button/Button';
import api from '../../services/api';

import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { ReactComponent as LogoHarpi } from '../../assets/logo-harpi.svg';
import { useTranslation } from 'react-i18next';
import { Context } from '../../Context/Auth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading/Loading';
import { isEmail } from './email';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { handleSubmit } = useForm();
  const { tenant, authLoading } = useContext(Context);
  const history = useHistory();

  const onSubmit = () => {
    setLoading(true);
  };

  const handleEmail = (email: string) => {
    setEmail(email);
  };

  const passwordReset = async () => {
    setLoginError('');
    setEmailError('');
    if (isEmail(email)) {
      setLoading(true);
      await api
        .post('/passwordreset/', { email })
        .then(() => {
          setShowModal(true);
          setLoading(false);
        })
        .catch(() => {
          setLoginError('Erro ao enviar email');
          setLoading(false);
        });
    } else {
      setEmailError('Email Inválido!');
    }
  };

  const handleSend = () => passwordReset();

  return (
    <div className="h-screen flex h-1/6 w-full">
      {loading && <Loading />}
      <Logo className="absolute pl-8 hidden md:block" />
      <FirstContainer className="w-1/2 h-full justify-center pt-52 hidden md:flex">
        <LogoHarpi className="mr-10 z-20" />
      </FirstContainer>
      {!authLoading ? (
        <SecondContainer className="md:w-1/2 w-full h-full pb-11">
          {tenant ? (
            <>
              <form
                className="items-center justify-center flex flex-col h-full w-full lg:px-20"
                onSubmit={handleSubmit(onSubmit)}
              >
                <WrapperForm>
                  <Title>{t('forgot_password')}</Title>
                  <Text>{t('forgot_my_password_text')}</Text>
                  {loginError && (
                    <span className="mt-4 text-error text-xs">
                      {loginError}
                    </span>
                  )}
                  <input
                    className={`bg-white border h-10 w-2/3 rounded shadow-sm text-basic-gray-500 mt-6 text-sm px-4 z-20 ${
                      (emailError && 'border-input-error') ||
                      'border-whitegray-100'
                    }`}
                    placeholder={t('email')}
                    onChange={e => {
                      handleEmail(e.target.value);
                      setEmailError('');
                    }}
                  />
                  {emailError && (
                    <span className="mt-4 text-error text-xs">
                      {emailError}
                    </span>
                  )}
                  <Action>
                    <div
                      onClick={() => {
                        history.push('/login');
                      }}
                      id="go_back"
                      className="text-xs text-bluejeans-800 font-light mr-4 cursor-pointer z-20"
                    >
                      {t('go_back_simple')}
                    </div>
                    <button
                      type="button"
                      onClick={handleSend}
                      className="button bg-primary text-lg text-white font-semibold rounded w-2/3 h-10 mt-6 z-20"
                    >
                      {t('send')}
                    </button>
                  </Action>
                  <div className="w-full flex justify-center mt-10">
                    <span className="text-xs text-bluejeans-800 font-light cursor-pointer z-20">
                      {`${t('need_help')}?`}
                    </span>
                  </div>
                </WrapperForm>
              </form>
              <div className="flex items-end justify-center">
                <div className="text-xs text-bluejeans-800 font-light mr-4 cursor-pointer z-20">
                  {t('terms_of_use')}
                </div>
                <div className="text-xs text-bluejeans-800 font-light cursor-pointer z-20">
                  {t('privacy_policy')}
                </div>
              </div>
            </>
          ) : (
            <div className="items-center justify-center flex flex-col h-full w-full lg:px-20">
              <TextNotFound className="text-9xl">404</TextNotFound>
            </div>
          )}
        </SecondContainer>
      ) : (
        <Loading />
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        ariaHideApp={false}
        id="modal"
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-full flex justify-end cursor-pointer">
            <CloseIcon onClick={() => setShowModal(false)} />
          </div>
          <ConfirmationIcon />
          <div className="my-4 mt-10 mb-7">
            <div className="font-default font-normal text-sm text-yaleblue-1000">
              {t('email_send')}
            </div>
          </div>

          <div className="flex items-center justify-evenly">
            <ModalButton
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('go_back_simple')}
            </ModalButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
