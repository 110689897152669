import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const FirstContainer = styled.div`
  background-color: #32424f;
`;

export const TextNotFound = styled.div`
  color: #32424f;
`;

export const SecondContainer = styled.div`
  background: #f6f7f9;
`;

export const Input = styled.input`
  width: 28.75rem;
`;

export const Button = styled.button`
  width: 28.75rem;
`;

export const LoginTitle = styled.div`
  font-family: 'Segoe UI';
  font-size: 22px;
  color: #32424f;
`;

export const Logo = styled.div`
  width: 500vh;
`;

export const Label = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #32424f;
`;

export const WrapperButtonMicrosoft = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    background-color: transparent;
    border: 1px solid #32424f;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: #32424f;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 9px;
  }
`;
