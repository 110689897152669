import React from 'react';
import {
  FirstContainer,
  SecondContainer,
  Logo,
  TextError,
} from './Error.style';
import { ReactComponent as LogoHarpi } from '../../assets/logo-harpi.svg';
import { useTranslation } from 'react-i18next';

const Error404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex h-1/6 w-full">
      <Logo className="absolute pl-8 z-10 hidden md:block" />
      <FirstContainer className="w-1/2 h-full justify-center pt-52 hidden md:flex">
        <LogoHarpi className="mr-10 z-20" />
      </FirstContainer>
      <SecondContainer className="md:w-1/2 w-full h-full pb-11">
        <div className="items-center justify-center flex flex-col h-full w-full lg:px-20">
          <TextError className="text-9xl">404</TextError>
          <TextError className="text-4xl">{t('page_not_found')}</TextError>
        </div>
      </SecondContainer>
    </div>
  );
};

export default Error404;
