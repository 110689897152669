import React, { useState } from 'react';
import { AxiosError } from 'axios';

import { Container } from './HomePanel.style';
import Navbar from '../../components/Navbar/navbar';
import PageTab from '../../components/PageTab/PageTab';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import PurchasesPanel from './PurchasesPanel/PurchasesPanel';
import HomeHistoricData from './HistoricData/HomeHistoricData';
const HomePanel: React.FC = () => {
  const tabs = ['purchase_panel', 'history_data', 'buyer'];
  const [activeTab, setActiveTab] = useState('purchase_panel');
  const [responseError, setResponseError] = useState<AxiosError | null>();

  const toggleAction = (tab: string) => {
    setActiveTab(tab);
  };

  const closeErrorPage = () => setResponseError(null);

  const tryAgainFunction = () => {
    window.location.reload();
  };

  const errorPage = (error: AxiosError) => {
    setResponseError(error);
  };

  return (
    <>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary">
          <Navbar pageTitle={'dashboard'} selectedMenu="home" />
          <PageTab
            tabs={tabs}
            activeTab={activeTab}
            toggleAction={toggleAction}
            backgroundColor="bg-primary"
            className=""
          >
            {activeTab === 'purchase_panel' && (
              <PurchasesPanel errorPage={errorPage} />
            )}
            {activeTab === 'history_data' && (
              <HomeHistoricData errorPage={errorPage} />
            )}
          </PageTab>
        </Container>
      )}
    </>
  );
};

export default HomePanel;
