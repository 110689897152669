import api from './api';
import {
  AI_ANSWER_CODE_STATUS,
  RFQ_STATUS,
  ST_CONTROL,
} from '../utils/rfqStatusControl';
import BaseService, { FilterParam, ListParams } from './base-service';

export interface RfqFilterCounterIaFinalDecision {
  total: number;
  reward: number;
  help: number;
  new_round: number;
  ia_other: number;

  [index: string]: number;
}
export interface RfqFilterCounterStControl {
  answered: RfqFilterCounterIaFinalDecision;
  awaiting_response: RfqFilterCounterIaFinalDecision;
  failure: RfqFilterCounterIaFinalDecision;
  other: RfqFilterCounterIaFinalDecision;
}

export interface RfqFilterCounter {
  total: number;
  st_control: RfqFilterCounterStControl;
}

export interface RfqFilterCounterGroup {
  mine: RfqFilterCounter;
  all: RfqFilterCounter;

  [index: string]: RfqFilterCounter;
}

export class RfqService extends BaseService {
  exportUrl = '/rfqs/export_csv/';
  listUrl = '/rfqs/';

  firstFilterButtons: { [key: string]: FilterParam[] } = {
    mine: [{ key: 'is_mine', value: 'True' }],
    all: [{ key: 'is_mine', value: 'False' }],
  };

  secondFilterButtons: { [key: string]: FilterParam[] } = {
    awaiting_response: [
      { key: 'answered', value: 'False' },
      ...RFQ_STATUS.WAITING_RESPONSE.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    answered: [
      { key: 'answered', value: 'True' },
      ...RFQ_STATUS.PENDING.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    failure: [
      ...RFQ_STATUS.FAILURE.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
  };

  filterButtons = {
    0: {
      mine: [{ key: 'is_mine', value: 'True', label: 'mine' }],
      all: [{ key: 'is_mine', value: 'False', label: 'all' }],
    },
    1: {
      awaiting_response: [
        { key: 'answered', value: 'False' },
        ...RFQ_STATUS.WAITING_RESPONSE.map(st_control => ({
          key: 'st_control',
          value: st_control,
          label: 'awaiting_response',
        })),
      ],
      answered: [
        { key: 'answered', value: 'True' },
        ...RFQ_STATUS.PENDING.map(st_control => ({
          key: 'st_control',
          value: st_control,
          label: 'answered',
        })),
      ],
      failure: [
        ...RFQ_STATUS.FAILURE.map(st_control => ({
          key: 'st_control',
          value: st_control,
          label: 'failure',
        })),
      ],
    },
    2: {
      all: [
        {
          key: 'ia_final_decision',
          value: '',
        },
      ],
      help_buyer: [
        ...AI_ANSWER_CODE_STATUS.HELP.map(ia_final_decision => ({
          key: 'ia_final_decision',
          value: ia_final_decision,
        })),
      ],
      new_round: [
        ...AI_ANSWER_CODE_STATUS.RETRY.map(ia_final_decision => ({
          key: 'ia_final_decision',
          value: ia_final_decision,
        })),
      ],
      reward: [
        ...AI_ANSWER_CODE_STATUS.REWARD.map(ia_final_decision => ({
          key: 'ia_final_decision',
          value: ia_final_decision,
        })),
      ],
    },
  };

  paramsDefault: ListParams = {
    page: 1,
    limit: 30,
    sort: 'num_rfq',
    order: 'asc',
    group_count: 'mine',
    filter: [
      ...this.firstFilterButtons.mine,
      ...this.secondFilterButtons.awaiting_response,
    ],
  };

  filterCountDefault: RfqFilterCounter = {
    total: 0,
    st_control: {
      answered: {
        total: 0,
        reward: 0,
        help: 0,
        new_round: 0,
        ia_other: 0,
      },
      awaiting_response: {
        total: 0,
        reward: 0,
        help: 0,
        new_round: 0,
        ia_other: 0,
      },
      failure: {
        total: 0,
        reward: 0,
        help: 0,
        new_round: 0,
        ia_other: 0,
      },
      other: {
        total: 0,
        reward: 0,
        help: 0,
        new_round: 0,
        ia_other: 0,
      },
    },
  };

  filterCountStControlDefault: RfqFilterCounterIaFinalDecision = {
    total: 0,
    reward: 0,
    help: 0,
    new_round: 0,
    ia_other: 0,
  };

  filterCountGroupDefault: RfqFilterCounterGroup = {
    mine: this.filterCountDefault,
    all: this.filterCountDefault,
  };

  getRfqDashboardTotals = async () => {
    return await api.get(`dashboard/purchases_panel/rfq_totals/`);
  };

  async getFiltersCount(
    filters: FilterParam[],
  ): Promise<{
    all: {
      total: number;
      st_control: {
        total: number;
        other: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        answered: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        failure: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        awaiting_response: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
      };
    };
    mine: {
      total: number;
      st_control: {
        total: number;
        other: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        answered: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        failure: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
        awaiting_response: {
          reward: number;
          help: number;
          total: number;
          new_round: number;
          ia_other: number;
        };
      };
    };
  }> {
    const query = this.buildUrlFromFilters(filters);
    const response = await api.get(`rfqs/filters_count/?${query}`);
    const data = response.data;

    const counters = {
      all: {
        total: 0,
        st_control: {
          total: 0,
          answered: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          awaiting_response: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          failure: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          other: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
        },
      },
      mine: {
        total: 0,
        st_control: {
          total: 0,
          answered: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          awaiting_response: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          failure: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
          other: {
            total: 0,
            reward: 0,
            help: 0,
            new_round: 0,
            ia_other: 0,
          },
        },
      },
    };

    data.forEach(
      (item: {
        total_count: number;
        is_mine: any;
        answered: any;
        item_st_control: any;
        item_ia_final_decision: string;
      }) => {
        const { total_count, is_mine, item_st_control } = item;
        if (
          RFQ_STATUS.PENDING.includes(item_st_control) &&
          item.answered === true
        ) {
          counters.all.total += total_count;
          if (is_mine) {
            counters.mine.total += total_count;
          }
          this.updateIaFinalDecisionCounter(
            item,
            counters.all.st_control.answered,
            counters.mine.st_control.answered,
          );
        } else if (RFQ_STATUS.WAITING_RESPONSE.includes(item_st_control)) {
          counters.all.total += total_count;
          if (is_mine) {
            counters.mine.total += total_count;
          }
          this.updateIaFinalDecisionCounter(
            item,
            counters.all.st_control.awaiting_response,
            counters.mine.st_control.awaiting_response,
          );
        } else if (RFQ_STATUS.FAILURE.includes(item_st_control)) {
          counters.all.total += total_count;
          if (is_mine) {
            counters.mine.total += total_count;
          }
          this.updateIaFinalDecisionCounter(
            item,
            counters.all.st_control.failure,
            counters.mine.st_control.failure,
          );
        }
        // else {
        //   all_counters.st_control.other += total_count;
        // }
      },
    );

    // const general_counters = {
    //   mine: is_mine_counters,
    //   all: all_counters,
    // };

    console.log(counters);
    return counters;
  }

  private updateIaFinalDecisionCounter(
    item: any,
    counters_all: any,
    counters_mine: any,
  ) {
    if (AI_ANSWER_CODE_STATUS.REWARD.includes(item.item_ia_final_decision)) {
      counters_all.reward += item.total_count;
      if (item.is_mine) {
        counters_mine.reward += item.total_count;
      }
    } else if (
      AI_ANSWER_CODE_STATUS.HELP.includes(item.item_ia_final_decision)
    ) {
      counters_all.help += item.total_count;
      if (item.is_mine) {
        counters_mine.help += item.total_count;
      }
    } else if (
      AI_ANSWER_CODE_STATUS.RETRY.includes(item.item_ia_final_decision)
    ) {
      counters_all.new_round += item.total_count;
      if (item.is_mine) {
        counters_mine.new_round += item.total_count;
      }
    } else {
      counters_all.ia_other += item.total_count;
      if (item.is_mine) {
        counters_mine.ia_other += item.total_count;
      }
    }
    counters_all.total += item.total_count;
    if (item.is_mine) {
      counters_mine.total += item.total_count;
    }
  }
}

export default new RfqService();
