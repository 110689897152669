import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
`;

export const ContentContainer = styled.div`
  border-right: 2px solid #e7eaef;
  justify-content: flex-start;
  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    border-right: 0px;
  }
`;
