import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const FirstContainer = styled.div`
  background: #32424f;
`;

export const TextNotFound = styled.div`
  color: #32424f;
`;

export const SecondContainer = styled.div`
  background: #f6f7f9;
`;

export const Input = styled.input`
  width: 28.75rem;
`;

export const Button = styled.button`
  width: 28.75rem;
`;

export const Logo = styled.div`
  width: 100vh;
`;

export const Title = styled.span`
  font-family: 'Segoe UI Light';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #3d6f9b;
  margin-top: 40px;
`;

export const Text = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  color: #1d3550;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;

  input {
    width: 100%;
  }

  li {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1d3550;
    margin-bottom: 8px;
  }

  ul {
    margin-top: 8px;
    list-style-type: disc;
    margin-left: 20px;
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;

  button {
    width: 222px;
    margin: 0;
  }

  #go_back {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #105d8a;
    width: 206px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: underline;
  }
`;
