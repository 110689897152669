import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
`;

export const TableContainer = styled.div`
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    left: 391.23px;
    top: 737px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c8d0da;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
