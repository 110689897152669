import styled from 'styled-components';

export const FilterDropdown = styled.div`
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 10rem;
`;

export const OptionsList = styled.div`
  border-bottom: 2px solid rgba(42, 78, 118, 0.08);
  &:last-child {
    border-bottom: 0px;
  }
`;

export const Search = styled.input`
  border-bottom: 2px solid rgba(42, 78, 118, 0.08);
  &::focus {
    outline: none;
    border-color: #000;
  }
`;
