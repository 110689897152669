import React, { useState, useLayoutEffect } from 'react';

interface ListButtonProps {
  buttons: Buttons[];
  toogleAction?: Function;
  firstButton?: string;
  secondButton?: string;
  setSelectedButton?: Function;
  type?: string;
  order?: number;
  width?: string;
}

interface Buttons {
  button: string;
  keyText: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>> | null;
  iconSelec: React.ComponentType<React.SVGAttributes<SVGElement>> | null;
}

const ListButton: React.FC<ListButtonProps> = ({
  buttons,
  toogleAction,
  type,
  order,
  width,
}) => {
  const [isSelectedButton, setSelectedButton] = useState(0);
  useLayoutEffect(() => {
    let filter_value;
    if (order !== null && order !== undefined) {
      filter_value =
        sessionStorage.getItem(type + '_list_filter_' + order.toString()) ||
        localStorage.getItem(type + '_list_filter_' + order.toString());
    } else {
      filter_value = null;
    }
    if (filter_value) {
      for (const { index, value } of buttons.map((value, index) => ({
        index,
        value,
      }))) {
        if (value.button === filter_value) {
          setSelectedButton(index);
          break;
        }
      }
    }
  }, []);
  const toogleActionButton = (key: number, button: string) => {
    setSelectedButton(key);
    toogleAction && toogleAction(button, order);
  };

  return (
    <div className="flex justify-end">
      {buttons.map((item, key) => (
        <div
          key={key}
          className={`${
            width ? `w-${width}` : 'w-44'
          } h-8 justify-center items-center flex cursor-pointer py-2 ${
            isSelectedButton === key
              ? 'bg-bluejeans-800'
              : 'bg-white border-2 border-yaleblue-transparent'
          } ${key === 0 && 'border-r-0 rounded-tl-lg rounded-bl-lg'} ${
            key === buttons.length - 1 &&
            'border-l-0 rounded-tr-lg rounded-br-lg'
          }`}
          onClick={() => toogleActionButton(key, item.button)}
        >
          {item.icon && <item.icon />}
          {item.iconSelec && isSelectedButton === key && <item.iconSelec />}
          <span
            className={`ml-2 text-sm font-light ${
              isSelectedButton === key ? 'text-white' : 'text-bluejeans-800'
            } `}
          >
            {item.keyText}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ListButton;
