import styled from 'styled-components';

export const Container = styled.div``;

export const QuotationItems = styled.div`
  background-color: rgba(42, 78, 118, 0.08);
`;

export const Button = styled.button`
  border: 1px solid #9ba8bb;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const Columns = styled.div`
  min-width: 13rem;
`;
