import React, { createContext, useState, useEffect, useContext } from 'react';
import { Context } from '../Auth';

interface DomainContextData {
  subDomain: string;
  createSubdomain(): void;
}

const DomainContext = createContext<DomainContextData>({} as DomainContextData);

const DomainProvider: React.FC = ({ children }) => {
  const [domain, setDomain] = useState<string>('');
  const { handleLogout } = useContext(Context);

  useEffect(() => {
    const subdomain = localStorage.getItem('subdomain');
    if (subdomain) {
      setDomain(subdomain);
    }
  }, []);

  function createSubdomain() {
    const parsedData = window.location.pathname.split('/');
    const tenant = parsedData[1];
    const currentSubdomain = localStorage.getItem('subdomain');
    localStorage.setItem('subdomain', tenant);
    setDomain(tenant);
    if (tenant !== currentSubdomain) {
      handleLogout();
    }
  }

  return (
    <DomainContext.Provider value={{ subDomain: domain, createSubdomain }}>
      {children}
    </DomainContext.Provider>
  );
};

export { DomainContext, DomainProvider };
