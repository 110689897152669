import api from './api';
import BaseService, { FilterParam, ListParams } from './base-service';
import {
  RC_LIST_RFQ_STATUS,
  RFQ_STATUS,
  ST_CONTROL,
} from '../utils/rfqStatusControl';
import {
  RfqFilterCounter,
  RfqFilterCounterGroup,
  RfqFilterCounterStControl,
} from './rfq-service';

export interface RcFilterCounterStControl {
  total: number;
  pending: number;
  waiting_response: number;
  failure: number;
  manual_decision: number;
  order_failed: number;
  resolved_externally: number;
  finished: number;
}

export interface RcFilterCounter {
  total: number;
  st_control: RcFilterCounterStControl;
}

export interface RcFilterCounterGroup {
  mine: RcFilterCounter;
  all: RcFilterCounter;
  [index: string]: RcFilterCounter;
}

export class PurchaseRequisitionService extends BaseService {
  exportUrl = '/items/export_csv/';
  listUrl = '/items/';

  firstFilterButtons: { [key: string]: FilterParam[] } = {
    pending: [{ key: 'st_control', value: RC_LIST_RFQ_STATUS.PENDING }],
    all: [],
    waiting_response: [
      ...RC_LIST_RFQ_STATUS.WAITING_RESPONSE.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    failure: [
      ...RC_LIST_RFQ_STATUS.FAILURE.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    manual_decision: [
      ...RC_LIST_RFQ_STATUS.MANUAL_DECISION.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    finished: [
      ...RC_LIST_RFQ_STATUS.FINISHED.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    order_failed: [
      ...RC_LIST_RFQ_STATUS.ORDER_FAILED.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
    resolved_externally: [
      ...RC_LIST_RFQ_STATUS.RESOLVED_EXTERNALLY.map(st_control => ({
        key: 'st_control',
        value: st_control,
      })),
    ],
  };

  secondFilterButtons: { [key: string]: FilterParam[] } = {
    mine: [{ key: 'is_mine', value: 'True' }],
    all: [{ key: 'is_mine', value: 'False' }],
  };

  filterButtons = {
    0: {
      mine: [{ key: 'is_mine', value: 'True' }],
      all: [{ key: 'is_mine', value: 'False' }],
    },
    1: {
      all: [],
      pending: [{ key: 'st_control', value: '0' }],
      waiting_response: [
        ...RC_LIST_RFQ_STATUS.WAITING_RESPONSE.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
      failure: [
        ...RC_LIST_RFQ_STATUS.FAILURE.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
      manual_decision: [
        ...RC_LIST_RFQ_STATUS.MANUAL_DECISION.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
      finished: [
        ...RC_LIST_RFQ_STATUS.FINISHED.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
      order_failed: [
        ...RC_LIST_RFQ_STATUS.ORDER_FAILED.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
      resolved_externally: [
        ...RC_LIST_RFQ_STATUS.RESOLVED_EXTERNALLY.map(st_control => ({
          key: 'st_control',
          value: st_control,
        })),
      ],
    },
  };

  paramsDefault: ListParams = {
    page: 1,
    limit: 15,
    sort: 'id',
    order: 'asc',
    group_count: 'all',
    filter: [...this.secondFilterButtons.mine, ...this.firstFilterButtons.all],
  };

  filterCountDefault: RcFilterCounter = {
    total: 0,
    st_control: {
      total: 0,
      pending: 0,
      waiting_response: 0,
      failure: 0,
      finished: 0,
      order_failed: 0,
      resolved_externally: 0,
      manual_decision: 0,
    },
  };

  filterCountGroupDefault: RcFilterCounterGroup = {
    mine: this.filterCountDefault,
    all: this.filterCountDefault,
  };

  filterCountStControlDefault: RcFilterCounterStControl = {
    total: 0,
    pending: 0,
    waiting_response: 0,
    failure: 0,
    manual_decision: 0,
    order_failed: 0,
    resolved_externally: 0,
    finished: 0,
  };

  getPurchaseRequisitionDashboardTotals = async () => {
    return await api.get(
      `dashboard/purchases_panel/purchase_requisition_totals/`,
    );
  };

  async getFiltersCount(
    filters: FilterParam[],
  ): Promise<{
    all: {
      total: number;
      st_control: {
        waiting_response: number;
        total: number;
        resolved_externally: number;
        order_failed: number;
        failure: number;
        manual_decision: number;
        pending: number;
        finished: number;
      };
    };
    mine: {
      total: number;
      st_control: {
        waiting_response: number;
        total: number;
        resolved_externally: number;
        order_failed: number;
        failure: number;
        manual_decision: number;
        pending: number;
        finished: number;
      };
    };
  }> {
    const query = this.buildUrlFromFilters(filters);
    const response = await api.get(`items/filters_count/?${query}`);
    const data = response.data;

    const counters = {
      all: {
        total: 0,
        st_control: {
          total: 0,
          pending: 0,
          waiting_response: 0,
          failure: 0,
          manual_decision: 0,
          finished: 0,
          resolved_externally: 0,
          order_failed: 0,
        },
      },
      mine: {
        total: 0,
        st_control: {
          total: 0,
          pending: 0,
          waiting_response: 0,
          failure: 0,
          manual_decision: 0,
          finished: 0,
          resolved_externally: 0,
          order_failed: 0,
        },
      },
    };

    data.forEach(
      (item: { total_count: number; is_mine: any; st_control: any }) => {
        const { total_count, is_mine, st_control } = item;
        counters.all.total += total_count;
        if (is_mine) {
          counters.mine.total += total_count;
        }
        if (RC_LIST_RFQ_STATUS.PENDING.includes(st_control)) {
          counters.all.st_control.pending += total_count;
          if (is_mine) {
            counters.mine.st_control.pending += total_count;
          }
        } else if (RC_LIST_RFQ_STATUS.FINISHED.includes(st_control)) {
          counters.all.st_control.finished += total_count;
          if (is_mine) {
            counters.mine.st_control.finished += total_count;
          }
        } else if (RC_LIST_RFQ_STATUS.FAILURE.includes(st_control)) {
          counters.all.st_control.failure += total_count;
          if (is_mine) {
            counters.mine.st_control.failure += total_count;
          }
        } else if (
          RC_LIST_RFQ_STATUS.RESOLVED_EXTERNALLY.includes(st_control)
        ) {
          counters.all.st_control.resolved_externally += total_count;
          if (is_mine) {
            counters.mine.st_control.resolved_externally += total_count;
          }
        } else if (RC_LIST_RFQ_STATUS.MANUAL_DECISION.includes(st_control)) {
          counters.all.st_control.manual_decision += total_count;
          if (is_mine) {
            counters.mine.st_control.manual_decision += total_count;
          }
        } else if (RC_LIST_RFQ_STATUS.ORDER_FAILED.includes(st_control)) {
          counters.all.st_control.order_failed += total_count;
          if (is_mine) {
            counters.mine.st_control.order_failed += total_count;
          }
        } else if (RC_LIST_RFQ_STATUS.WAITING_RESPONSE.includes(st_control)) {
          counters.all.st_control.waiting_response += total_count;
          if (is_mine) {
            counters.mine.st_control.waiting_response += total_count;
          }
        }
      },
    );
    counters.mine.st_control.total = counters.mine.total;
    counters.all.st_control.total = counters.all.total;
    // console.log(counters);
    return counters;
  }
}

export default new PurchaseRequisitionService();
