import React from 'react';
import { ReactComponent as GotoTopIcon } from '../../assets/icon-goto-top.svg';

interface GoTopButtonProps {
  content?: HTMLDivElement;
}

const GoTopButton: React.FC<GoTopButtonProps> = ({ content }) => {
  const goToTop = () => {
    content?.scrollTo(0, 0);
  };

  return <GotoTopIcon className="w-28 mx-2 cursor-pointer" onClick={goToTop} />;
};

export default GoTopButton;
