import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import { ChartContainer } from './LIneChart.style';

interface ChartOptions {
  data: Array<number | null>;
  type: string;
  backgroundColor: string | Array<string>;
  borderColor: string;
  borderWidth: number;
  order: number;
  hidden: boolean;
}

interface LineChartProps {
  datasets: Array<ChartOptions>;
  chartLabels: Array<string>;
  suggestedMin: number;
  suggestedMax: number;
}

const LineChart: React.FC<LineChartProps> = ({
  datasets,
  chartLabels,
  suggestedMin,
  suggestedMax,
}) => {
  const ctx = useRef<HTMLCanvasElement | null>(null);
  const [isRebuildingCanvas, setIsRebuildingCanvas] = useState(false);

  useEffect(() => {
    setIsRebuildingCanvas(true);
  }, [datasets]);

  useEffect(() => {
    if (isRebuildingCanvas) {
      setIsRebuildingCanvas(false);
    }
  }, [isRebuildingCanvas]);

  useEffect(() => {
    const chartCanvas = ctx.current;

    if (isRebuildingCanvas || !chartCanvas) {
      return;
    }
    const label = (tooltipItem: { value: string }) => {
      return tooltipItem.value.replace('.', ',');
    };
    const chartInstance = new Chart(chartCanvas, {
      type: 'line',
      data: {
        datasets: datasets,
        labels: chartLabels,
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
        hover: {
          mode: 'single',
          intersect: false,
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        tooltips: {
          callbacks: {
            label: label,
            title: function () {
              return '';
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: suggestedMin,
                suggestedMax: suggestedMax,
                fontColor: '#052A74',
                fontSize: 12,
                fontStyle: 'bold',
              },
              gridLines: {
                borderDash: [2, 2],
                color: '#E7EAEF',
                lineWidth: 2,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#9BA8BB',
                fontSize: 12,
                fontStyle: 'bold',
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });
    return () => {
      chartInstance?.destroy();
    };
  }, [datasets, chartLabels, isRebuildingCanvas, suggestedMax, suggestedMin]);

  return (
    <div>
      <ChartContainer id="chartContainer">
        <canvas height="70px" id="myChart" ref={ctx} />
      </ChartContainer>
    </div>
  );
};

export default LineChart;
