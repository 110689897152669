import React, { useState, useContext } from 'react';
import { parse } from 'query-string';
import Modal from 'react-modal';
import {
  FirstContainer,
  SecondContainer,
  Logo,
  TextNotFound,
  Title,
  WrapperForm,
  Action,
  Text,
} from './ChangePassword.style';
import ModalButton from '../../components/Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon.svg';
import { useTranslation } from 'react-i18next';
import { Context } from '../../Context/Auth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading/Loading';
import PasswordRules from '../../components/PasswordRules/PasswordRules';
import api from '../../services/api';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fetchError, setFetchError] = useState(false);
  const [passwordRule1, setPasswordRule1] = useState(false);
  const [passwordRule2, setPasswordRule2] = useState(false);
  const [passwordRule3, setPasswordRule3] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [passwordRulesError, setPasswordRulesError] = useState(false);
  const { handleSubmit } = useForm();
  const { tenant, authLoading } = useContext(Context);
  const history = useHistory();
  const queryObj = parse(history.location.search);

  const passwordReset = async () => {
    setLoading(true);
    setFetchError(false);
    await api
      .post('/passwordreset/confirm/', {
        token: queryObj.token,
        password: newPassword,
      })
      .then(() => {
        setShowModal(true);
        setLoading(false);
      })
      .catch(() => {
        setFetchError(true);
        setLoading(false);
      });
  };

  const onSubmit = () => {
    if (passwordRule1 && passwordRule2 && passwordRule3) {
      setConfirmPasswordError(newPassword !== confirmPassword);
      setPasswordRulesError(false);
      passwordReset();
    } else {
      setPasswordRulesError(true);
      setConfirmPasswordError(false);
    }
  };

  const testPasswordRule1 = (password: string) => {
    if (password.length >= 6) {
      setPasswordRule1(true);
    } else {
      setPasswordRule1(false);
    }
  };

  const testPasswordRule2 = (password: string) => {
    const regex = /\W|_/;
    setPasswordRule2(regex.test(password));
  };

  const testPasswordRule3 = (password: string) => {
    const regex = /\d/g;
    setPasswordRule3(regex.test(password));
  };

  const handleNewPassword = (password: string) => {
    testPasswordRule1(password);
    testPasswordRule2(password);
    testPasswordRule3(password);
    setNewPassword(password);
    setPasswordRulesError(false);
  };

  const handleConfirmPassword = (password: string) => {
    setConfirmPassword(password);
  };

  return (
    <div className="h-screen flex h-1/6 w-full">
      {loading && <Loading />}
      <Logo className="absolute pl-8 hidden md:block" />
      <FirstContainer className="w-1/2 h-full justify-center pt-52 hidden md:flex" />
      {!authLoading ? (
        <SecondContainer className="md:w-1/2 w-full h-full pb-11">
          {tenant ? (
            <>
              <form
                className="items-center justify-center flex flex-col h-full w-full lg:px-20"
                onSubmit={handleSubmit(onSubmit)}
              >
                <WrapperForm>
                  <Title>
                    {t(
                      queryObj.token
                        ? queryObj.expired
                          ? 'expired_password'
                          : 'forgot_password'
                        : 'change_password',
                    )}
                  </Title>
                  {!queryObj.token && <Text>{t('change_password_text')}</Text>}
                  {queryObj.token && queryObj.expired && (
                    <Text>{t('expired_password_text')}</Text>
                  )}
                  {confirmPasswordError && (
                    <span className="mt-4 text-error text-xs">
                      {t('confirm_password_rule')}
                    </span>
                  )}
                  {passwordRulesError && (
                    <span className="mt-4 text-error text-xs">
                      {t('password_rules_error')}
                    </span>
                  )}
                  {fetchError && (
                    <span className="mt-4 text-error text-xs">
                      Falha ao criar senha, contacte um administrador do
                      sistema!
                    </span>
                  )}
                  <input
                    type="password"
                    className={`bg-white border h-10 w-2/3 mt-6 rounded shadow-sm text-basic-gray-500 text-sm px-4 z-20 ${
                      confirmPasswordError
                        ? 'border-input-error'
                        : 'border-whitegray-50'
                    }`}
                    placeholder={t('new_password')}
                    onChange={e => handleNewPassword(e.target.value)}
                  />

                  <input
                    type="password"
                    className={`bg-white border h-10 w-2/3 mt-6 rounded shadow-sm text-basic-gray-500 text-sm px-4 z-20 ${
                      confirmPasswordError
                        ? 'border-input-error'
                        : 'border-whitegray-100'
                    }`}
                    placeholder={t('confirm_password')}
                    onChange={e => handleConfirmPassword(e.target.value)}
                  />
                  <Text>{t('password_rules')}</Text>
                  <PasswordRules
                    password={!!newPassword}
                    passwordRule1={passwordRule1}
                    passwordRule2={passwordRule2}
                    passwordRule3={passwordRule3}
                  />
                  <Action>
                    <div
                      onClick={() => {
                        history.push('/login');
                      }}
                      id="go_back"
                      className="text-xs text-bluejeans-800 font-light mr-4 cursor-pointer z-20 "
                    >
                      {t('cancel')}
                    </div>
                    <button
                      type="submit"
                      className="button  bg-primary text-lg text-white font-semibold rounded w-2/3 h-10 mt-6 z-20 "
                    >
                      {t('save')}
                    </button>
                  </Action>
                  <div className="w-full flex justify-center mt-10">
                    <span className="text-xs text-bluejeans-800 font-light cursor-pointer z-20 ">
                      {`${t('need_help')}?`}
                    </span>
                  </div>
                </WrapperForm>
              </form>
              <div className="flex items-end justify-center">
                <div className="text-xs text-bluejeans-800 font-light mr-4 cursor-pointer z-20 ">
                  {t('terms_of_use')}
                </div>
                <div className="text-xs text-bluejeans-800 font-light cursor-pointer ">
                  {t('privacy_policy')}
                </div>
              </div>
            </>
          ) : (
            <div className="items-center justify-center flex flex-col h-full w-full lg:px-20">
              <TextNotFound className="text-9xl">404</TextNotFound>
            </div>
          )}
        </SecondContainer>
      ) : (
        <Loading />
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        ariaHideApp={false}
        id="modal"
        className="h-auto bg-white p-4 m-auto outline-none custom-modal z-100 rounded"
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-full flex justify-end cursor-pointer">
            <CloseIcon onClick={() => setShowModal(false)} />
          </div>
          <ConfirmationIcon />
          <div className="my-4 mt-10 mb-7">
            <div className="font-default font-normal text-sm text-yaleblue-1000">
              {t(
                queryObj.expired
                  ? 'success_change_password'
                  : 'success_create_password',
              )}
            </div>
          </div>

          <div className="flex items-center justify-evenly">
            <ModalButton
              onClick={() => {
                setShowModal(false);
                history.push('/login');
              }}
            >
              {t('go_back_simple')}
            </ModalButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
