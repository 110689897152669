import styled from 'styled-components';

export const WrapperPasswordRules = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 4px;
`;

export const RuleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const RuleText = styled.span<{ error: boolean }>`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ error }) => (error ? '#d2002f' : '#009B70')};
  margin-left: 6px;
`;
