import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const WrapperRadioStatus = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid rgba(42, 78, 118, 0.16);
  border-radius: 8px;
  width: 324px;
  padding: 4px 16px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #105d8a;
  z-index: 10;
`;

export const RadioStatus = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  user-select: none;
  div {
    height: 16px;
    width: 16px;
    background-color: ${({ selected }) => (selected ? '#00AB84' : '#9BA8BB')};
  }

  span {
    text-align: center;
    width: 100%;
  }
  :hover {
    opacity: 0.7;
  }
`;
export const WrapperFilterButton = styled.div`
  cursor: pointer;
  margin-left: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(42, 78, 118, 0.16);
  border-radius: 4px;
  background: #f6f7f9;

  :hover {
    opacity: 0.7;
  }
`;

export const WrapperFormModal = styled.div<{ open: boolean }>`
  background: #f6f7f9;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 488px;
  position: absolute;
  top: 35px;
  left: 370px;
  z-index: 100;
  transition: display 0s, opacity 0.5s linear;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

export const FormModal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const FormModalHeader = styled.div`
  background-color: #32a4e7;
  width: 100%;
  height: 56.49px;
  border-radius: 6px 6px 0px 0px;
  align-items: center;
  display: flex;
  padding: 0 16px;
  flex-direction: row-reverse;
  position: relative;
`;

export const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    opacity: 0.3;
    background-color: #ffffff;

    path {
      fill: #32a4e7;
    }
  }
`;

export const HeaderTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 30px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #105d8a;

  span {
    position: absolute;
    top: 5px;
    left: 38px;
  }
`;

export const FormContainer = styled.div`
  padding: 32px 34px 18px 34px;
  display: flex;
  flex-direction: column;

  .w-full {
    margin-top: 24px;
  }
  input {
    ::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #9ba8bb;
    }
  }

  h4 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1d3550;
  }
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  h3 {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0b3e5c;
  }
`;

export const WrapperFilterDate = styled.div`
  display: flex;

  .w-full {
    width: 84px;
    margin-right: 24px;
  }
`;

export const FooterModal = styled.div`
  border-top: 2px solid rgba(60, 16, 83, 0.08);
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 36px;
  flex-direction: row-reverse;
  margin-top: 24px;
`;

export const LinkButton = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #105d8a;
  width: 168px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Chip = styled.div`
  background-color: rgba(210, 235, 250);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  color: rgba(11, 62, 92);
  font-size: 0.75rem;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  span {
    user-select: none;
  }
`;

export const WrapperChip = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 12px;
    margin-top: 12px;
  }
`;
