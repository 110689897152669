import api from '../services/api';
import { formatOptions } from '../helpers/OptionsHelpers/FormatOptions';

export async function handleFieldsValidate(
  setIsLoading: Function,
  t: Function,
  typedOption: string,
  url: string,
  setErrorMessage: Function,
  setErrorModalIsOpen: Function,
  selected: any[] = [],
  setSelected: Function,
  setList?: Function,
  content?: any[],
  handleSupplierCard?: Function,
  action?: string,
) {
  setIsLoading((action ? action + '_' : '') + url.split('/')[0]);

  let updatedContent: any[] = content ? [...content] : [];
  let list: any[] = [];

  let invalidFields: string[] = [];
  let options: any[] = [];

  try {
    await api.get(`${url}${typedOption}&exactly_match`).then(response => {
      list = response.data.results;
      invalidFields = response.data.invalid_fields;
    });
  } catch (error) {
    console.log(error);
  }

  if (invalidFields.length > 0) {
    setErrorMessage(`${t('invalid_fields')} - ${invalidFields}`);
    setErrorModalIsOpen(true);
  }

  url = url.split('/')[0];

  formatOptions(list, url, []).forEach(item => {
    const newOption = {
      id: item.value,
      value: item.label,
    };
    options.push(newOption);
  });

  switch (url) {
    case 'suppliers':
      options = [];
      if (action) {
        list.forEach(item => {
          if (!selected.includes(item.code + ' - ' + item.social_name)) {
            options.push(item.code + ' - ' + item.social_name);
          } else {
            const index = selected.indexOf(
              item.code + ' - ' + item.social_name,
            );
            selected.splice(index, 1);
            selected.unshift(item.code + ' - ' + item.social_name);
          }
          updatedContent.push(item.code + ' - ' + item.social_name);
        });
        break;
      }
      list.forEach(item => {
        if (handleSupplierCard)
          updatedContent = [...updatedContent, handleSupplierCard(item)];
        const newSupplier = {
          id: item.id,
          value: item.code + ': ' + item.social_name,
        };
        options.push(newSupplier);
      });
      break;
    case 'buyer_groups':
      list.forEach(item => {
        updatedContent.push(item);
      });
      break;
    default:
      break;
  }
  if (setList) setList(updatedContent);
  setSelected([...options, ...selected]);
  setIsLoading('');
}
