import styled from 'styled-components';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  border-right: 2px solid #e7eaef;
  justify-content: flex-start;
  width: 155px;
  height: auto;
  &:first-child {
    justify-content: flex-start;
  }
`;
