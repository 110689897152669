import React from 'react';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';
import { ReactComponent as AlertIcon } from '../../assets/alert-icon.svg';
import { ReactComponent as ConfirmationIcon } from '../../assets/confirmation-icon-green.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning-icon.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import Modal from 'react-modal';

interface SelectionProps {
  selectedLength: number;
  onClick: Function[];
  buttons: { text: string; disabled?: boolean }[];
  alertMessage?: string;
}

interface MessageModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: Function;
  message: string;
  type: string;
}

export const checkScroll = (
  content: HTMLDivElement,
  fetchFunction: Function,
  contentLoaded = true,
) => {
  if (content?.scrollHeight <= content?.clientHeight && contentLoaded) {
    fetchFunction();
  }
};

const SelectionComponent: React.FC<SelectionProps> = ({
  selectedLength,
  onClick,
  buttons,
  alertMessage,
}) => {
  const { t } = useTranslation();

  return selectedLength > 0 ? (
    <div>
      <div className="height pt-8">&nbsp;</div>
      <div className="fixed h-14 w-full bg-yaleblue-700 bottom-0 flex px-28 justify-between items-center">
        <div className="flex items-center">
          <span className="font-default font-normal text-sm text-white mr-4">
            {`${selectedLength} ${t('items_selecteds')}`}
          </span>
          {alertMessage && (
            <span className="font-default font-normal text-sm text-yellow-200">
              {t(alertMessage)}
            </span>
          )}
        </div>
        <div className="flex items-center">
          {onClick.map((click, key) => (
            <Button
              key={key}
              disabled={buttons[key]?.disabled}
              onClick={() => click()}
              className="bg-yaleblue-700 border-2 px-12 py-2 mr-4 font-default font-bold text-sm text-white"
            >
              {t(buttons[key].text)}
            </Button>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

const MessageModal: React.FC<MessageModalProps> = ({
  modalIsOpen,
  setModalIsOpen,
  message,
  type,
}) => {
  const { t } = useTranslation();
  let Icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  let color: string;
  switch (type) {
    case 'success':
      Icon = ConfirmationIcon;
      color = 'green-500';
      break;
    case 'error':
      Icon = AlertIcon;
      color = 'red-600';
      break;
    case 'warning':
      Icon = WarningIcon;
      color = 'yellow-600';
      break;
    case 'info':
      Icon = InfoIcon;
      color = 'yaleblue-800';
      break;
    default:
      Icon = InfoIcon;
      color = 'gray';
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      ariaHideApp={false}
      className={
        'h-auto bg-white p-3 m-auto outline-none custom-modal z-100 rounded'
      }
    >
      <div className="flex flex-col justify-center items-center">
        <div
          className={`w-full flex items-center justify-between border-b border-${color}`}
        >
          <div className="mb-1 flex items-center">
            {Icon && <Icon className="h-5 w-5" />}
            <div
              className={`ml-1 text-lg text-${color}`}
              style={{ fontWeight: 600 }}
            >
              {t(type)}
            </div>
          </div>
          <div className="cursor-pointer">
            <CloseIcon onClick={() => setModalIsOpen(false)} />
          </div>
        </div>
        <div className="my-8">
          <div className="font-bold text-lg text-center text-yaleblue-1000">
            {t(message)}
          </div>
        </div>
        <div className="flex items-center ">
          <Button
            className="h-10 text-base"
            onClick={() => setModalIsOpen(false)}
          >
            {t('confirmation_modal')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectionComponent;
export { MessageModal };
