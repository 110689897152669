import React, { createContext, useEffect, useState } from 'react';
import api, { getBaseUrl, tenantApi } from '../services/api';
import authService, { RefreshToken, User } from '../services/auth-service';

interface AuthContextData {
  user: User;
  authLoading: boolean;
  tenant: String | undefined;

  handleLogin(
    email: string,
    password: string,
    keepLogged: boolean,
    accessToken: string,
  ): Promise<undefined>;

  handleLogout(): void;
}

const Context = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState<User>({} as User);
  const [tenant, setTenant] = useState<String | undefined>();

  const setTenantBySession = () => {
    const tenant = sessionStorage.getItem('tenant');
    if (tenant) setTenant(tenant);
    setAuthLoading(false);
  };

  useEffect(() => {
    tenantApi.interceptors.response.use(
      response => response,
      error => {
        const status = error.response?.status;
        const requestUrl = error.request.responseURL;
        if (requestUrl?.includes('get_tenant')) {
          if (status === 404) {
            sessionStorage.removeItem('tenant');
            setTenant('');
            handleLogout();
            return Promise.reject(error);
          }
        }
      },
    );
    api.interceptors.response.use(
      response => response,
      async error => {
        const status = error.response?.status;
        const data = error.response?.data.detail;

        if (status !== 401) return Promise.reject(error);
        if (
          [
            'You do not have permission to perform this action.',
            'Password Expired',
          ].includes(data)
        )
          return Promise.reject(error);

        const tokenRefreshed = await RefreshToken.getInstance().getRefreshToken();

        RefreshToken.getInstance().clearRefreshToken();

        if (!tokenRefreshed) {
          handleLogout();
          return Promise.reject(error);
        }

        return api(error.response.config);
      },
    );
    const user = authService.getUser();
    if (user) setUser(user);
    getBaseUrl().then(setTenantBySession);
  }, []);

  async function handleLogin(
    email: string,
    password: string,
    keepLogged: boolean,
    accessToken: string,
  ) {
    const data = {
      email: email,
      password: password,
      accessToken: accessToken,
    };
    const user: User = authService.getEmptyUser();
    let apiResponse;
    await api
      .post('/auth/', data)
      .then(response => {
        apiResponse = response.status;
        user.isAuthenticated = true;
        user.roles = response.data.user_groups;
        user.permissions = response.data.user_permissions;
        user.token = response.data.access_token;
        user.refreshToken = response.data.refresh_token;
        user.keepLogged = keepLogged;
        // user.name = response.data.user_name;
        authService.saveUser(user);
      })
      .catch(error => {
        if (error.response) {
          apiResponse = error.response.status;
          if (
            apiResponse === 401 &&
            error.response.data?.detail === 'Password Expired'
          ) {
            const token = encodeURIComponent(
              error.response.data?.password_reset_token,
            );

            window.location.href = `/${tenant}/passwordreset/confirm?token=${token}&expired=true`;
            apiResponse = 307;
          }
        }
        console.log(error);
      });
    setUser(user);
    return apiResponse;
  }

  function handleLogout() {
    const removeUser: User = authService.getEmptyUser();
    setUser(removeUser);
    authService.saveUser(removeUser);
  }

  return (
    <Context.Provider
      value={{ user: user, handleLogin, authLoading, handleLogout, tenant }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AuthProvider };
