import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/close-icon-blue.svg';

interface CounterFilters {
  title: string;
  counter: number;
}

interface AppliedFiltersProps {
  counterFilters?: CounterFilters[];
  appliedFilters?: String[];
  className?: string;
  toogleAppliedFilter?: Function;
  hasCloseIcon?: boolean;
  removeSelectedFilter?: Function;
  filterName?: String;
  selectedItems?: any[];
  setSelectedItems?: Function;
  cardContent?: any[];
  setCardContent?: Function;
  handleCard?: Function;
  setSuggestions?: Function;
  suggestions?: any[];
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({
  counterFilters,
  appliedFilters,
  className,
  toogleAppliedFilter,
  hasCloseIcon,
  removeSelectedFilter,
  filterName,
  selectedItems,
  setSelectedItems,
  cardContent,
  setCardContent,
  handleCard,
  setSuggestions,
  suggestions,
}) => {
  const { t } = useTranslation();

  const handleRemoveFilter = (filter: String) => {
    removeSelectedFilter && removeSelectedFilter(filter, filterName);
  };

  return (
    <div className="flex flex-wrap">
      {counterFilters !== undefined &&
        counterFilters.map(
          (filter, key) =>
            filter.counter !== 0 && (
              <div
                key={key}
                className="rounded-full h-6 bg-whitegray-100 text-xs flex items-center px-2 font-light mr-4 mt-4"
              >
                <span>{t(filter.title)}</span>
                <span>({filter.counter})</span>
              </div>
            ),
        )}
      {appliedFilters !== undefined &&
        appliedFilters.map((filter, key) => (
          <div
            key={key}
            className={`rounded-full h-6 bg-whitegray-100 text-xs flex items-center px-4 py-1 mr-4 mt-4 ${className}`}
            onClick={() => {
              setSuggestions
                ? toogleAppliedFilter &&
                  toogleAppliedFilter(
                    key,
                    suggestions,
                    selectedItems,
                    setSelectedItems,
                    cardContent,
                    setCardContent,
                    handleCard,
                    setSuggestions,
                  )
                : toogleAppliedFilter && toogleAppliedFilter(key);
            }}
          >
            <span className="font-bold text-xs text-bluejeans-1000">
              {filter}
            </span>
            {hasCloseIcon && (
              <CloseIcon
                className="h-2 w-2 ml-2 cursor-pointer"
                onClick={() => handleRemoveFilter(filter)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default AppliedFilters;
