export const ST_CONTROL = {
  RC_PENDING: 0,
  WAITING_QUOTE_PROCESS: 1,
  WAITING_ERP_CONNECTION: 5,
  WAITING_CREATION_RFQ_ON_ERP: 10,
  WAITING_SUPPLIER_ANSWER: 20,
  RFQ_CREATION_FAILED: 21,
  IN_PROGRESS: 30,
  WAITING_DECISION: 31,
  RFQ_CANCELED: 40,
  WAITING_ERP_ORDER_CONNECTION: 50,
  WAITING_ERP_ORDER_CREATION: 60,
  ORDER_CREATED_SUCCESSFULLY: 70,
  ORDER_CREATION_FAILED: 71,
  RESOLVED_EXTERNALLY: 100,
};

export const AI_ANSWER_CODE = {
  REWARD_AUTOMATIC: '01',
  NEW_ROUND_AUTOMATIC: '02',
  BUYER_HELP: '03',
  REWARD_MANUAL: '04',
  NEW_ROUND_MANUAL: '05',
  EXTEND_RFQ_DEADLINE: '06',
};

export const RFQ_STATUS = {
  WAITING_RESPONSE: [
    // ST_CONTROL.WAITING_ERP_CONNECTION,
    ST_CONTROL.WAITING_CREATION_RFQ_ON_ERP,
    ST_CONTROL.WAITING_SUPPLIER_ANSWER,
  ],

  FINISHED: [
    ST_CONTROL.RFQ_CANCELED,
    ST_CONTROL.WAITING_ERP_ORDER_CONNECTION,
    ST_CONTROL.WAITING_ERP_ORDER_CREATION,
    ST_CONTROL.ORDER_CREATED_SUCCESSFULLY,
    ST_CONTROL.ORDER_CREATION_FAILED,
  ],

  FAILURE: [ST_CONTROL.RFQ_CREATION_FAILED],
  PENDING: [ST_CONTROL.WAITING_DECISION],
};

export const RC_LIST_RFQ_STATUS = {
  WAITING_RESPONSE: [
    ST_CONTROL.WAITING_ERP_CONNECTION,
    ST_CONTROL.WAITING_CREATION_RFQ_ON_ERP,
    ST_CONTROL.WAITING_SUPPLIER_ANSWER,
  ],

  FINISHED: [
    ST_CONTROL.WAITING_ERP_ORDER_CONNECTION,
    ST_CONTROL.WAITING_ERP_ORDER_CREATION,
    ST_CONTROL.ORDER_CREATED_SUCCESSFULLY,
  ],
  ORDER_FAILED: [ST_CONTROL.ORDER_CREATION_FAILED],
  MANUAL_DECISION: [
    ST_CONTROL.IN_PROGRESS,
    ST_CONTROL.WAITING_DECISION,
    ST_CONTROL.RFQ_CANCELED,
  ],
  RESOLVED_EXTERNALLY: [ST_CONTROL.RESOLVED_EXTERNALLY],
  FAILURE: [ST_CONTROL.RFQ_CREATION_FAILED],
  PENDING: [ST_CONTROL.RC_PENDING, ST_CONTROL.WAITING_QUOTE_PROCESS],
};

export const AI_ANSWER_CODE_STATUS = {
  REWARD: [AI_ANSWER_CODE.REWARD_MANUAL, AI_ANSWER_CODE.REWARD_AUTOMATIC],
  RETRY: [
    AI_ANSWER_CODE.NEW_ROUND_MANUAL,
    AI_ANSWER_CODE.NEW_ROUND_AUTOMATIC,
    AI_ANSWER_CODE.EXTEND_RFQ_DEADLINE,
  ],
  HELP: [AI_ANSWER_CODE.BUYER_HELP],
};

export const AI_ANSWER_CODE_ICONS = {
  REWARD: [AI_ANSWER_CODE.REWARD_MANUAL, AI_ANSWER_CODE.REWARD_AUTOMATIC],
  RETRY: [
    AI_ANSWER_CODE.NEW_ROUND_MANUAL,
    AI_ANSWER_CODE.NEW_ROUND_AUTOMATIC,
    AI_ANSWER_CODE.EXTEND_RFQ_DEADLINE,
  ],
  HELP: [AI_ANSWER_CODE.BUYER_HELP],
};

export const HISTORIC_DATA_STATUS = {
  RFQ_SUCCESS: [
    ST_CONTROL.WAITING_ERP_ORDER_CONNECTION,
    ST_CONTROL.WAITING_ERP_ORDER_CREATION,
    ST_CONTROL.ORDER_CREATED_SUCCESSFULLY,
  ],
  RFQ_ORDER_FAILED: [ST_CONTROL.ORDER_CREATION_FAILED],
  RFQ_RESOLVED_EXTERNALLY: [ST_CONTROL.RESOLVED_EXTERNALLY],
};

export const PURCHASE_REQUISITION_STATUS = {
  RC_PENDING: [ST_CONTROL.RC_PENDING, ST_CONTROL.WAITING_QUOTE_PROCESS],
  RC_QUOTATION: [
    ST_CONTROL.WAITING_ERP_CONNECTION,
    ST_CONTROL.WAITING_CREATION_RFQ_ON_ERP,
    ST_CONTROL.ORDER_CREATED_SUCCESSFULLY,
  ],
  RC_RFQ_CREATION_FAILED: [ST_CONTROL.RFQ_CREATION_FAILED],
  RC_WAITING_DECISION: [
    ST_CONTROL.IN_PROGRESS,
    ST_CONTROL.WAITING_DECISION,
    ST_CONTROL.RFQ_CANCELED,
  ],
  RC_SUCCESS: [
    ST_CONTROL.WAITING_ERP_ORDER_CONNECTION,
    ST_CONTROL.WAITING_ERP_ORDER_CREATION,
    ST_CONTROL.ORDER_CREATED_SUCCESSFULLY,
  ],
  RC_ORDER_CREATION_FAILED: [ST_CONTROL.ORDER_CREATION_FAILED],
  RFQ_RESOLVED_EXTERNALLY: [ST_CONTROL.RESOLVED_EXTERNALLY],
};
