import axios from 'axios';
import authService from './auth-service';

const BACKEND_PATH = process.env.REACT_APP_WEBAPI;
const BACKEND_PATH_PROTOCOL = process.env.REACT_APP_WEBAPI_PROTOCOL;
const BACKEND_ENVIRONMENT = process.env.REACT_APP_BACKEND_ENVIRONMENT;
export const tenantApi = axios.create();

export const fetchTenant = async (tenant: string): Promise<string> => {
  const url = `${BACKEND_PATH_PROTOCOL}://public.${BACKEND_PATH}/tenant/get_tenant/`;
  const response = await tenantApi.get(url, { params: { tenant } });
  const data = response.data;
  sessionStorage.setItem('schema', data.schema_name);
  return data.domains[0].domain;
};

export const getBaseUrl = async (): Promise<string> => {
  let tenant = sessionStorage.getItem('tenant');
  const schema = sessionStorage.getItem('schema');
  const subdomain = window.location.pathname.split('/')[1];

  if (!tenant || subdomain !== schema) {
    try {
      const fullDomain = await fetchTenant(subdomain);
      tenant = fullDomain.split('.')[0];
      sessionStorage.setItem('tenant', tenant);
    } catch {
      sessionStorage.removeItem('tenant');
      return '';
    }
  }

  return `${BACKEND_PATH_PROTOCOL}://${tenant}.${BACKEND_PATH}`;
};

const api = axios.create();
api.defaults.xsrfHeaderName = "X-CSRFTOKEN";
api.defaults.xsrfCookieName = "csrftoken";

api.interceptors.request.use(
  async config => {
    config.baseURL = await getBaseUrl();
    if (authService.getUser())
      config.headers.Authorization = 'Bearer ' + authService.getToken();
    return config;
  },
  error => Promise.reject(error),
);

export default api;
