import React, { createContext, useState } from 'react';

interface ParametersProps {
  field: string;
  description: string;
}

interface ComparativeMapFilterContextData {
  params: ParametersProps[];
  setParams: Function;
}

const Context = createContext<ComparativeMapFilterContextData>(
  {} as ComparativeMapFilterContextData,
);

const FilterProvider: React.FC = ({ children }) => {
  const [params, setParams] = useState<ParametersProps[]>([]);

  return (
    <Context.Provider value={{ setParams, params }}>
      {children}
    </Context.Provider>
  );
};

export { Context, FilterProvider };
