import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.form`
  border-radius: 0.25rem;
  padding-top: 40px;
  height: 84vh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  #input {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 100%;
  }

  #buyerGroup {
    color: #9ba8bb;
  }

  .div-select {
    width: 24%;

    div div {
      margin-top: 11px;
    }
  }
`;

export const Button = styled.button<{ onClick: any }>`
  width: 146px;
  height: 48px;
  background: #105d8a;
  border-radius: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;
