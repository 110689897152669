import styled from 'styled-components';

export const WrapperInput = styled.div`
  display: contents;
`;

export const LabelSpan = styled.span<{ hasError?: boolean }>`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ hasError }) => (hasError ? 'rgba(252, 41, 87)' : '#1d3550')};
  margin-bottom: 11px;
`;

export const Container = styled.div`
  input {
    flex: 1;
    background: transparent;
    border: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ErrorMessage = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(252, 41, 87);
  margin-bottom: 11px;
  height: 20px;
`;
