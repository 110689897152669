import BaseService, { FilterParam, ListParams } from './base-service';
import api from './api';

export interface HistoricDataFilterCounter {
  total: number;
  created_ia: number;
  manual: number;
}

export interface HistoricDataFilterCounterGroup {
  mine: HistoricDataFilterCounter;
  all: HistoricDataFilterCounter;
  [index: string]: HistoricDataFilterCounter;
}

class HistoricDataService extends BaseService {
  exportUrl = '/rfqs/history_base/export_csv/';
  listUrl = '/rfqs/history_base/';

  firstFilterButtons: { [key: string]: FilterParam[] } = {
    mine: [{ key: 'is_mine', value: 'True' }],
    all: [{ key: 'is_mine', value: 'False' }],
  };

  secondFilterButtons: { [key: string]: FilterParam[] } = {
    all: [],
    attended_ia: [{ key: 'is_automation', value: 'True' }],
    created_manually: [{ key: 'is_automation', value: 'False' }],
  };

  filterButtons = {
    0: {
      mine: [{ key: 'is_mine', value: 'True', label: 'mine' }],
      all: [{ key: 'is_mine', value: 'False', label: 'all' }],
    },
    1: {
      all: [],
      attended_ia: [{ key: 'is_automation', value: 'True' }],
      created_manually: [{ key: 'is_automation', value: 'False' }],
    },
  };

  paramsDefault: ListParams = {
    page: 1,
    limit: 10,
    sort: 'num_rfq',
    order: 'asc',
    group_count: 'all',
    filter: [...this.firstFilterButtons.mine, ...this.secondFilterButtons.all],
  };

  filterCountDefault: HistoricDataFilterCounter = {
    total: 0,
    created_ia: 0,
    manual: 0,
  };

  filterCountGroupDefault: HistoricDataFilterCounterGroup = {
    mine: this.filterCountDefault,
    all: this.filterCountDefault,
  };

  async getFiltersCount(
    filters: FilterParam[],
  ): Promise<{
    all: { total: number; created_ia: number; manual: number };
    mine: { total: number; created_ia: number; manual: number };
  }> {
    const query = this.buildUrlFromFilters(filters);
    const response = await api.get(
      `/rfqs/history_base/filters_count/?${query}`,
    );
    const data = response.data;

    const counters = {
      all: {
        total: 0,
        created_ia: 0,
        manual: 0,
      },
      mine: {
        total: 0,
        created_ia: 0,
        manual: 0,
      },
    };

    data.forEach(
      (item: { total_count: number; is_mine: any; created_ai: any }) => {
        const { total_count, is_mine, created_ai } = item;
        counters.all.total += total_count;
        if (is_mine) {
          counters.mine.total += total_count;
        }
        if (created_ai) {
          counters.all.created_ia += total_count;
          if (is_mine) {
            counters.mine.created_ia += total_count;
          }
        } else {
          counters.all.manual += total_count;
          if (is_mine) {
            counters.mine.manual += total_count;
          }
        }
      },
    );
    return counters;
  }
}
export default new HistoricDataService();
