import styled from 'styled-components';

export const Link = styled.div`
  color: rgba(16, 93, 138);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  font-family: Open Sans;
  user-select: none;
`;
