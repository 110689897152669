import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from '../../assets/error-icon.svg';
import { AxiosError } from 'axios';

interface ErrorPageProps {
  error?: AxiosError;
  closeErrorPage: Function;
  tryAgainFunction: Function;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  error,
  closeErrorPage,
  tryAgainFunction,
}) => {
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!error) return;
    const statusCode = error?.response?.status || 500;
    setStatusCode(statusCode);
    switch (statusCode) {
      case 500:
        setMessageError('status_server_error');
        break;
      case 403:
        setMessageError('not_allowed');
        break;
      case 404:
        setMessageError('page_not_found');
        break;
      case 400:
        setMessageError('request_error');
        break;
      default:
        break;
    }
  }, [error]);
  const [messageError, setMessageError] = useState<string>('');

  return (
    <>
      <div className="flex flex-col h-screen items-center justify-center w-full">
        <ErrorIcon />
        <div className="mt-4 text-sm text-yaleblue-900 font-light">
          {`${t('error')} ${statusCode}`}
        </div>
        <div className="mt-6 text-2xl font-semibold text-yaleblue-900">
          {t(messageError)}
        </div>
        {statusCode !== 500 ? (
          <div
            className="mt-14 h-14 w-48 rounded bg-primary text-lg leading-7 font-bold text-white flex items-center justify-center cursor-pointer"
            onClick={() => history.push('/purchase-panel')}
          >
            {t('go_home')}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div
              className="mt-14 h-14 w-48 rounded bg-primary text-lg leading-7 font-bold text-white flex items-center justify-center cursor-pointer"
              onClick={() => tryAgainFunction()}
            >
              {t('try_again')}
            </div>
            <span
              className="mt-8 underline text-sm text-yaleblue-900 font-light cursor-pointer"
              onClick={() => history.push('/purchase-panel')}
            >
              {t('go_home')}
            </span>
          </div>
        )}
        {statusCode === 500 ||
          (statusCode === 400 && (
            <span
              className="mt-8 underline text-sm text-yaleblue-900 font-light cursor-pointer"
              onClick={() => closeErrorPage()}
            >
              {t('go_back')}
            </span>
          ))}
      </div>
    </>
  );
};

export default ErrorPage;
