import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { forEach } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TOptions, StringMap } from 'i18next';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import Loading from '../../../components/Loading/Loading';

import * as S from './HistoricData.style';
import '../../../index.css';
import PowerBIService from '../../../services/power-bi-service';

interface HomeHistoricDataProps {
  errorPage: Function;
}

interface PowerBIReportToken {
  PBIReportToken: string;
  ReportId: string;
  EmbeddedUrl: string;
  TTL: number;
}

const HomeHistoricData: FC<HomeHistoricDataProps> = ({ errorPage }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pbiReportToken, setpbiReportToken] = useState<PowerBIReportToken>();

  useEffect(() => {
    setLoading(true);

    PowerBIService.getPbiReportToken()
      .then(data => {
        setpbiReportToken(data);
      })
      .catch((error: any) => errorPage(error))
      .finally(() => setLoading(false));

    setLoading(true);
  }, [t]);

  return (
    <S.Container>
      {pbiReportToken ? (
        pbiReportToken.ReportId ? (
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              id: pbiReportToken.ReportId,
              embedUrl: pbiReportToken.EmbeddedUrl,
              accessToken: pbiReportToken.PBIReportToken,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            // eventHandlers={
            //   new Map([
            //         ['loaded', function () {console.log('Report loaded');}],
            //         ['rendered', function () {console.log('Report rendered');}],
            //         ['error', function (event) {console.log(event.detail);}],
            //         ['visualClicked', () => console.log('visual clicked')],
            //         ['pageChanged', (event) => console.log(event)],
            //     ])
            // }

            cssClassName={'reportClass'}
            getEmbeddedComponent={embeddedReport => {
              PowerBIService.setEmbedReport(embeddedReport);
            }}
          />
        ) : (
          'PowerBI Report not configured'
        )
      ) : (
        <Loading />
      )}
    </S.Container>
  );
};

export default HomeHistoricData;
