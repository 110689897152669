/* eslint-disable import/no-duplicates */
import React, { useState, useContext } from 'react';

import {
  Container,
  QuotationItems,
  Columns,
} from './ComparativeMapHistoricItem.style';
import { ReactComponent as ChavronUpIcon } from '../../assets/icon-chavron-down.svg';
import { ReactComponent as ChavronDownIcon } from '../../assets/icon-chavron-up.svg';
import { useTranslation } from 'react-i18next';
import { parseISO, format, isValid, isDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Context } from '../../Context/ComparativeMapFilters';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down-blue-icon.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrow-up-blue-icon.svg';
import TooltipLabel from '../TooltipLabel/TooltipLabel';
interface Params {
  [index: string]: string;
}

interface LinesProps {
  suppliers: Suppliers[];
}

interface Suppliers {
  supplier_name: string;
  supplier_code: string;
  supplier_nickname: string;
  total_values: number[];
  payment_descriptions: PaymentDescription[];
  items: SupplierItems[];
}
interface PaymentDescription {
  payment_condition: string;
  incoterm: string;
  freight_value: number;
  total_value_rfq: number;
}

interface SupplierItems {
  item_id: number;
  name: string;
  values: string[];
  items_params: ItemParams[];
  was_awarded: boolean[];
}

interface ItemParams {
  qto: number | null;
  vuo: number | null;
  ipi: number | null;
  ipp: number | null;
  icm: number | null;
  icp: number | null;
  pzo: string | null;
  vto: string | null;
  dsc: number | null;
  vup: number | null;
  vtf: number | null;
  mar: string | null;
  nc1: number | null;
  nc2: number | null;
  iva: number | null;
  ent: string | null;
  por: number | null;
  vut: number | null;
  unm: number | null;
  lte: number | null;
  vtp: number | null;
  dtr: string | null;
}

interface Accordions {
  [key: string]: number[];
}

const ComparativeMapHistoricItem: React.FC<LinesProps> = ({ suppliers }) => {
  const { t } = useTranslation();
  const [accordions, setAccordions] = useState<Accordions>({});
  const { params } = useContext(Context);
  const ComparativeMapParams: Params = {
    qto: '-',
    unm: '-',
    vuo: '-',
    vup: '-',
    vut: '-',
    icm: '-',
    icp: '-',
    ipi: '-',
    ipp: '-',
    vto: '-',
    vtp: '-',
    vtf: '-',
    dsc: '-',
    lte: '-',
    ent: '-',
    dtr: '-',
    nc1: '-',
    nc2: '-',
    iva: '-',
    mar: '-',
    por: '-',
    vendor_note: '-',
  };

  function isValidDate(date: string): Boolean {
    if (!isDate(date)) return false;
    const parsedDate = parseISO(date);
    return isValid(parsedDate);
  }

  const verifyParams = (field: string) => {
    const showParams = params.find(pr => pr.field === field);
    if (showParams) {
      return true;
    }
    return false;
  };

  const isAPercentageField = (field: string) => {
    const percentageFields = ['ICM', 'ICP', 'IPI', 'IPP', 'DSC'];
    return percentageFields.includes(field.toUpperCase());
  };

  const formatName = (name: string, stringLength: number) => {
    if (name === undefined) return;
    return name.substring(0, stringLength);
  };

  const handleOpenItemAccordion = (id: number, suppCode: string) => {
    accordions[suppCode].push(id);
    setAccordions({ ...accordions });
  };

  const handleCloseItemAccordion = (id: number, suppCode: string) => {
    if (accordions) {
      accordions[suppCode] = accordions[suppCode].filter(item => item !== id);
      setAccordions({ ...accordions });
    }
  };

  const handleOpenAccordion = (suppCode: string) => {
    Object.assign(accordions, {
      [suppCode]: [],
    });
    setAccordions({ ...accordions });
  };

  const handleCloseAccordion = (suppCode: string) => {
    delete accordions[suppCode];
    setAccordions({ ...accordions });
  };

  const verifyItemAccordion = (suppCode: string, itemId: number) => {
    if (accordions[suppCode]) {
      return accordions[suppCode].includes(itemId);
    }
    return false;
  };

  return (
    <>
      {suppliers.map((supplier, supplierKey) => (
        <Container key={supplierKey} className="flex flex-col mb-2">
          <div className="bg-white flex">
            <Columns key={supplierKey} className="flex pl-5 justify-between">
              <div className="flex items-center w-52">
                <div className="flex w-40 items-center pt-4">
                  <span
                    className="font-default font-bold text-sm text-yaleblue-900"
                    title={supplier.supplier_code.toString()}
                  >
                    <TooltipLabel
                      overlayText={
                        supplier.supplier_nickname.charAt(0).toUpperCase() +
                        supplier.supplier_nickname.slice(1).toLowerCase()
                      }
                      className={'w-full leading-4 text-xs'}
                      keyText={formatName(supplier.supplier_nickname, 15) || ''}
                    />
                  </span>
                  <span className="text-xs text-yaleblue-900 underline ml-2">
                    {supplier.supplier_code}
                  </span>
                </div>
                <div className="w-4 h-3 ml-2.5">
                  {accordions[supplier.supplier_code] ? (
                    <ChavronDownIcon
                      className="cursor-pointer"
                      onClick={() =>
                        handleCloseAccordion(supplier.supplier_code)
                      }
                    />
                  ) : (
                    <ChavronUpIcon
                      className="cursor-pointer"
                      onClick={() =>
                        handleOpenAccordion(supplier.supplier_code)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="flex">
                {supplier.total_values.map((value, key) => (
                  <div
                    key={key}
                    className="flex items-center mx-5 py-4 bg-basic-gray-transparent w-40 justify-center"
                  >
                    <span className="text-yaleblue-800 text-sm font-semibold">{`R$ ${value}`}</span>
                  </div>
                ))}
              </div>
            </Columns>
          </div>
          {accordions[supplier.supplier_code] && (
            <>
              <div className="flex border-t-2">
                <div className="w-52 ml-5" />
                {supplier.payment_descriptions.map((payment, key) => (
                  <div
                    key={key}
                    className="flex text-xs w-40 justify-center mx-5"
                  >
                    <div className="flex items-center py-2">
                      <div>
                        <div className="leading-5">
                          <span className="text-supplier-accordion-gray font-light mr-2">
                            {t('payment_condition')}
                          </span>
                          <span className="text-bluejeans-500 font-bold">
                            {payment.payment_condition}
                          </span>
                        </div>
                        <div className="leading-5">
                          <span className="text-supplier-accordion-gray font-light mr-2">
                            {t('Incoterm')}
                          </span>
                          <span className="text-bluejeans-500 font-bold">
                            {payment.incoterm}
                          </span>
                        </div>
                        <div className="leading-5">
                          <span className="text-supplier-accordion-gray font-light mr-2">
                            {t('total_value_freight')}
                          </span>
                          <span className="text-bluejeans-500 font-bold">
                            {payment.freight_value}
                          </span>
                        </div>
                        <div className="leading-5">
                          <span className="text-supplier-accordion-gray font-light mr-2">
                            {t('total_value_rfq')}
                          </span>
                          <span className="text-bluejeans-500 font-bold">
                            {payment.total_value_rfq}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col rounded-b-lg text-xs w-full border-t-2">
                {supplier.items.map((item, key) => (
                  <>
                    <div className="flex" key={key}>
                      <div key={key} className="flex ml-5 pt-4 w-52">
                        <span className="text-xs text-yaleblue-900 w-40">
                          <TooltipLabel
                            overlayText={
                              item.name.charAt(0).toUpperCase() +
                              item.name.slice(1).toLowerCase()
                            }
                            className={'w-full leading-4 text-xs'}
                            keyText={formatName(item.name, 30) || ''}
                          />
                        </span>
                        {verifyItemAccordion(
                          supplier.supplier_code,
                          item.item_id,
                        ) ? (
                          <div className="w-4 h-3 ml-2.5">
                            <ArrowUp
                              className="cursor-pointer mt-1"
                              onClick={() =>
                                handleCloseItemAccordion(
                                  item.item_id,
                                  supplier.supplier_code,
                                )
                              }
                            />
                          </div>
                        ) : (
                          <div className="w-3 h-4 ml-2.5">
                            <ArrowDown
                              className="cursor-pointer mt-1 "
                              onClick={() =>
                                handleOpenItemAccordion(
                                  item.item_id,
                                  supplier.supplier_code,
                                )
                              }
                            />
                          </div>
                        )}
                      </div>

                      {item.values.map((value, valueKey) => (
                        <div
                          key={valueKey}
                          className="items-center justify-center"
                        >
                          <div className="bg-basic-gray-transparent flex items-center justify-center mx-5 py-4 w-40">
                            <span className="text-xs text-yaleblue-900 leading-5 mr-1">
                              {`R$ ${value}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex">
                      <div className="w-52 ml-5" />
                      {verifyItemAccordion(
                        supplier.supplier_code,
                        item.item_id,
                      ) &&
                        item.items_params.map((param, index) => {
                          if (param) {
                            Object.entries(param).forEach(item => {
                              const key = item[0].toString();
                              let value = item[1]?.toString() || '-';

                              if (isValidDate(value as string)) {
                                value = format(new Date(value as string), 'P', {
                                  locale: ptBR,
                                });
                              }
                              if (
                                Object.keys(ComparativeMapParams).includes(key)
                              ) {
                                Object.assign(ComparativeMapParams, {
                                  [key]: value,
                                });
                              }
                              if (
                                key === 'custom_params' &&
                                Array.isArray(value)
                              ) {
                                value.forEach(val => {
                                  Object.assign(ComparativeMapParams, {
                                    [val.field]: val.value || '-',
                                  });
                                });
                              }
                            });
                          }

                          return (
                            <div
                              key={`props-content-${index}`}
                              className="flex px-5"
                            >
                              <QuotationItems className="flex h-full flex-col w-40">
                                {Object.entries(ComparativeMapParams).map(
                                  (param, key) => (
                                    <>
                                      {verifyParams(param[0]) && (
                                        <div
                                          key={`value-${key}`}
                                          className="flex"
                                        >
                                          <span
                                            title={param[0]}
                                            className="ml-5 text-sm text-yaleblue-800 font-light w-1/5 truncate"
                                          >
                                            {param[0].toUpperCase()}
                                          </span>
                                          <span className="ml-4 text-sm text-yaleblue-800 font-normal">
                                            {isAPercentageField(param[0])
                                              ? param[1] + '%'
                                              : param[1]}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ),
                                )}
                              </QuotationItems>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </Container>
      ))}
    </>
  );
};

export default ComparativeMapHistoricItem;
