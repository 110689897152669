/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as FilterIcon } from '../../assets/filter-icon.svg';
import { ReactComponent as FilterIconSelec } from '../../assets/filter-icon-selec.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { useTranslation } from 'react-i18next';
import AppliedFilters from '../AppliedFilters/AppliedFilter';
import PageTab from '../PageTab/PageTab';
import FilterInput from '../FilterInput/FilterInput';
import CustomInputNumber from '../CustomInputNumber/CustomInputNumber';

import {
  FilterButton,
  FilterModal,
  TopbarModal,
  FooterModal,
  ScrollabeContent,
} from './Filters.style';
import Button from '../Button/Button';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { format } from 'date-fns';
// import {ReactComponent as CheckBoxUnchecked, ReactComponent as CheckBoxChecked} from "*.svg";
interface FilterFields {
  fieldsList: FieldsList[];
  hasSla: boolean;
}

interface FieldsList {
  field: string;
  type: string;
  placeholder?: string;
  url?: string;
  options?: DropdownContent[] | any[];
}
interface FiltersProps {
  filterFields: FilterFields;
  handleResponse: Function;
  className?: string;
  handleOptions?: Function;
  type?: string;
}
interface DropdownContent {
  label: string;
  value: string | number | (string | number)[];
}

interface Sla {
  [index: string]: number;
}

interface CounterFilters {
  title: string;
  counter: number;
}
interface FormContent {
  [index: string]: string[];
}

interface CurrentFilter {
  [index: string]: string;
}

interface AppliedFilter {
  [index: string]: [string];
}

const Filters: React.FC<FiltersProps> = ({
  filterFields,
  handleResponse,
  className,
  handleOptions,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [filterContent, setFilterContent] = useState<FormContent>({});
  const [selectedOptions, setSelectedOptions] = useState<CurrentFilter>();
  const [appliedFilters, setAppliedFilters] = useState<FormContent>({});
  const [totalFilters, setTotalFilters] = useState(0);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();

  const loadFilterContent = useCallback(
    function () {
      const newFilterContent: FormContent = {};
      const appliedFilter: FormContent = {};
      let filterUrl = null;
      let filterLabelUrl = null;
      filterUrl =
        sessionStorage.getItem(type + '_list_general_filter') ||
        localStorage.getItem(type + '_list_general_filter');
      filterLabelUrl =
        sessionStorage.getItem('rfq_list_general_filter_label') ||
        localStorage.getItem('rfq_list_general_filter_label');
      // Cache

      const cacheFilters = {} as AppliedFilter;
      const cacheFilterLabels = {} as AppliedFilter;
      if (filterUrl) {
        let count = 0;
        filterUrl.split('&').forEach(value => {
          const filterParams = value.split('=');
          const fieldName = filterParams[0];
          if (fieldName in cacheFilters) {
            cacheFilters[fieldName].push(filterParams[1]);
          } else {
            cacheFilters[fieldName] = [filterParams[1]];
          }
          count++;
          const index = counterFilters.findIndex(
            item => item.title === fieldName,
          );
          incrementFilterCounter(fieldName, index);
        });
        setTotalFilters(count);
      }

      if (filterLabelUrl) {
        filterLabelUrl.split('&').forEach(value => {
          const filterParams = value.split('=');
          const fieldName = filterParams[0];
          if (fieldName in cacheFilterLabels) {
            cacheFilterLabels[fieldName].push(filterParams[1]);
          } else {
            cacheFilterLabels[fieldName] = [filterParams[1]];
          }
        });
      }
      filterFields.fieldsList.forEach(item => {
        if (item.field in cacheFilters) {
          Object.assign(newFilterContent, {
            [item.field]: cacheFilters[item.field],
          });
          Object.assign(appliedFilter, {
            [item.field]: cacheFilterLabels[item.field],
          });
        } else {
          Object.assign(newFilterContent, { [item.field]: [] });
          Object.assign(appliedFilter, { [item.field]: [] });
        }
      });
      setFilterContent(newFilterContent);
      setAppliedFilters(appliedFilter);
    },
    [filterFields.fieldsList, selectedOptions],
  );

  useEffect(() => {
    loadFilterContent();
    const handleClick = (e: Event) => {
      if (!node.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const toggleActionClose = () => {
    setIsOpen(!isOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const toogleTab = () => {};

  const [counterFilters, setCounterFilters] = useState<CounterFilters[]>([]);

  const clearFilters = () => {
    Object.keys(filterContent).forEach(item => {
      Object.assign(filterContent, { [item]: [] });
      Object.assign(appliedFilters, { [item]: [] });
    });
    setFilterContent({ ...filterContent });
    setAppliedFilters({ ...appliedFilters });

    setSelectedOptions({});
    setCounterFilters([]);
    setIsClearFilters(true);
    setFilterDate(null);
    setTotalFilters(0);
  };

  const toogleOptions = (
    option: string | number | any[],
    field: string,
    label: string,
    action: string,
  ) => {
    if (action === 'selectOne') {
      const newFilter: FormContent = filterContent;
      newFilter[field][0] = option.toString();
      let selected = selectedOptions;
      if (!selected) {
        selected = {};
      }
      Object.assign(selected, { [field]: label });
      setFilterContent({ ...newFilter });
      setSelectedOptions({ ...selected });
      setIsClearFilters(false);
      const index = counterFilters.findIndex(item => item.title === field);
      if (index < 0) {
        incrementFilterCounter(field, index);
      }
    } else {
      addFilterOnList(option.toString(), field, label);
    }
  };

  const toogleSlaDays = (sla: Sla) => {
    Object.assign(filterContent, { sla_days: [sla] });
    setIsClearFilters(false);
    const index = counterFilters.findIndex(item => item.title === 'sla_days');
    if (index < 0) {
      incrementFilterCounter('sla_days', index);
    }
  };

  const toogleSlaHours = (sla: Sla) => {
    Object.assign(filterContent, { sla_hours: [sla] });
    setIsClearFilters(false);
    const index = counterFilters.findIndex(item => item.title === 'sla_hours');
    if (index < 0) {
      incrementFilterCounter('sla_hours', index);
    }
  };

  const handleFilterDate = (date: Date, field: string) => {
    const newFilter: FormContent = filterContent;
    newFilter[field][0] = format(new Date(date), 'P');
    setFilterContent({ ...newFilter });
    setFilterDate(date);
    const index = counterFilters.findIndex(item => item.title === field);
    if (index < 0) {
      incrementFilterCounter(field, index);
    }
  };

  const onSubmit = async () => {
    let url = '';
    let labelUrl = '';
    Object.entries(filterContent).forEach((filter, index) => {
      if (filter[1].length !== 0 && index !== 0 && url !== '') {
        url = url + '&';
      }
      filter[1].forEach((filterValue, key) => {
        if (key === filter[1].length - 1) {
          url = url + filter[0] + '=' + filterValue;
        } else {
          url = url + filter[0] + '=' + filterValue + '&';
        }
      });
    });

    Object.entries(appliedFilters).forEach((filter, index) => {
      if (
        filter[1] &&
        filter[1].length !== 0 &&
        index !== 0 &&
        labelUrl !== ''
      ) {
        labelUrl = labelUrl + '&';
      }
      if (filter[1]) {
        filter[1].forEach((filterValue, key) => {
          if (key === filter[1].length - 1) {
            labelUrl = labelUrl + filter[0] + '=' + filterValue;
          } else {
            labelUrl = labelUrl + filter[0] + '=' + filterValue + '&';
          }
        });
      }
    });

    sessionStorage.setItem(type + '_list_general_filter_label', labelUrl);
    localStorage.setItem(type + '_list_general_filter_label', labelUrl);

    toggleActionClose();
    handleResponse(url);
  };

  const addFilterOnList = (option: string, field: string, label: string) => {
    const newFilter: FormContent = filterContent;
    const newAppliedFilter: FormContent = appliedFilters;
    Array.from(document.querySelectorAll('input')).forEach(
      input => (input.value = ''),
    );
    if (!newAppliedFilter[field].includes(label)) {
      newFilter[field].push(option);
      newFilter[field] = newFilter[field].filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      newAppliedFilter[field].push(label);
      newAppliedFilter[field] = newAppliedFilter[field].filter(function (
        elem,
        index,
        self,
      ) {
        return index === self.indexOf(elem);
      });
      setFilterContent({ ...newFilter });
      setAppliedFilters({ ...newAppliedFilter });
      const index = counterFilters.findIndex(item => item.title === field);
      incrementFilterCounter(field, index);
    }
  };

  const incrementFilterCounter = (name: string, index: number) => {
    if (index >= 0) {
      counterFilters[index].counter = counterFilters[index].counter + 1;
    } else {
      counterFilters.push({
        title: name,
        counter: 1,
      });
    }
    setTotalFilters(totalFilters + 1);
    setCounterFilters([...counterFilters]);
  };

  const handleRemoveFilter = (filterValue: string, filterName: string) => {
    const filter: FormContent = filterContent;
    const appliedFilter = appliedFilters;
    const filterIndex = appliedFilters[filterName].findIndex(
      item => item === filterValue,
    );
    filter[filterName].splice(filterIndex, 1);
    appliedFilters[filterName].splice(filterIndex, 1);
    setFilterContent({ ...filter });
    setAppliedFilters({ ...appliedFilter });
    decrementFilterCounter(filterName);
  };

  const decrementFilterCounter = (name: string) => {
    const index = counterFilters.findIndex(item => item.title === name);
    if (index >= 0) {
      counterFilters[index].counter = counterFilters[index].counter - 1;
      setTotalFilters(totalFilters - 1);
      setCounterFilters([...counterFilters]);
    }
  };

  return (
    <div>
      <FilterButton
        className="h-8 bg-whitegray-100 flex items-center justify-center cursor-pointer px-2"
        onClick={toggleActionClose}
        isOpen={isOpen}
        totalFilters={totalFilters}
      >
        {isOpen || totalFilters > 0 ? (
          <FilterIconSelec className="h-4 w-4" />
        ) : (
          <FilterIcon className="h-4 w-4" />
        )}
        {totalFilters > 0 && (
          <span className="text-xs text-white font-semibold pl-2.5">
            {totalFilters}
          </span>
        )}
      </FilterButton>
      <div id="filterModal" ref={node}>
        {isOpen && (
          <div className="flex relative">
            <FilterModal
              className={` ${
                totalFilters > 4 ? '-mt-20' : 'mt-2'
              }  absolute bg-white rounded-lg bg-white z-100 overflow-auto ${className}`}
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <TopbarModal className="w-full h-16 bg-bluejeans-500">
                <div className="flex h-8 items-center justify-end px-3.5">
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={toggleActionClose}
                  />
                </div>
                <PageTab
                  activeTab={t('filter')}
                  tabs={[t('filter')]}
                  backgroundColor={'bg-bluejeans-500'}
                  toggleAction={toogleTab}
                  isFilterComponent={true}
                />
                <div className="pt-6 bg-white" />
              </TopbarModal>
              <div className="flex mt-4 justify-between px-9 items-center">
                <div className="text-sm text-bluejeans-1000">
                  {t('applied_filters')}
                </div>
              </div>
              <div>
                <div className="px-9">
                  <AppliedFilters
                    counterFilters={counterFilters}
                    className={
                      'rounded-full h-6 bg-whitegray-100 text-xs flex items-center px-5 py-4 mr-4 mt-2 font-light'
                    }
                  />
                </div>
              </div>
              <ScrollabeContent className="px-9 mt-2 overflow-y-auto">
                {filterFields.fieldsList.map((filter, key) => (
                  <div key={key}>
                    <div>
                      {filter.type === 'date' ? (
                        <div className="mt-6">
                          <span className="font-default font-normal text-sm text-yaleblue-1000">
                            {t(filter.field)}
                          </span>
                          <div className="text-basic-gray-400 bg-whitegray-100 text-sm border-2 border-color-yaleblue rounded-lg w-full h-10 px-4 flex items-center justify-between">
                            <CustomDatePicker
                              selected={
                                filterDate
                                  ? new Date(filterContent[filter.field][0])
                                  : new Date()
                              }
                              onChange={(date: Date) =>
                                handleFilterDate(date, filter.field)
                              }
                              minDate={new Date()}
                              placeholder="dd/mm/aaaa"
                              inputStyle={'focus:outline-none bg-transparent'}
                              filterInput
                            />
                            <div className="py-2 items-center">
                              <CalendarIcon />
                            </div>
                          </div>
                        </div>
                      ) : filter.type === 'checkbox' ? (
                        <div className="mt-6">
                          <span className="font-default font-normal text-sm text-yaleblue-1000">
                            {t(filter.field)}
                          </span>
                        </div>
                      ) : (
                        <div className="mt-6" id="dropdown">
                          <FilterInput
                            options={filter.options || []}
                            handleOptions={handleOptions}
                            url={filter.url}
                            title={filter.field}
                            placeholder={filter.placeholder}
                            toggleAction={toogleOptions}
                            isCleanedSelectedOption={isClearFilters}
                            selectedItem={
                              selectedOptions
                                ? selectedOptions[filter.field]
                                : null
                            }
                            type={filter.type}
                          />
                        </div>
                      )}
                      {filterContent[filter.field].length !== 0 &&
                        filter.type === 'list' && (
                          <AppliedFilters
                            appliedFilters={appliedFilters[filter.field]}
                            filterName={filter.field}
                            removeSelectedFilter={handleRemoveFilter}
                            hasCloseIcon={true}
                            className={'font-light bg-bluejeans-100'}
                          />
                        )}
                    </div>
                  </div>
                ))}
                <div className="w-full">
                  {filterFields.hasSla && (
                    <div className="mt-6">
                      <span className="font-default font-normal text-sm text-yaleblue-1000">
                        {t('sla_remaining')}
                      </span>
                      <div className="flex">
                        <div>
                          <CustomInputNumber
                            title={'sla_days'}
                            isCleanedFilters={isClearFilters}
                            toggleAction={toogleSlaDays}
                            currentNumber={
                              filterContent.sla_days
                                ? parseInt(filterContent.sla_days[0])
                                : null
                            }
                          />
                        </div>
                        <div className="ml-4">
                          <CustomInputNumber
                            title={'sla_hours'}
                            isCleanedFilters={isClearFilters}
                            toggleAction={toogleSlaHours}
                            currentNumber={
                              filterContent.sla_hours
                                ? parseInt(filterContent.sla_hours[0])
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ScrollabeContent>
              <FooterModal className="flex justify-end items-center px-9 mt-7 py-4">
                <div
                  className="text-sm font-bold text-bluejeans-800 underline cursor-pointer"
                  onClick={clearFilters}
                >
                  {t('clear_filters')}
                </div>
                <Button
                  className="w-28 h-10 flex items-center justify-center bg-primary text-sm text-white font-bold rounded ml-12 cursor-pointer"
                  onClick={() => onSubmit()}
                >
                  {t('apply')}
                </Button>
              </FooterModal>
            </FilterModal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
