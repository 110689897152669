import React, { useEffect, useState } from 'react';
import { Container, Content, TableContainer } from './ShowRfq.style';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../components/Navbar/navbar';
import { ReactComponent as IconBreadcrumb } from '../../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import { format, parseISO } from 'date-fns';
import formatQuotation, {
  Quotation,
} from '../../../helpers/rfqHelpers/FormatQuotation';
import Loading from '../../../components/Loading/Loading';

interface TableContent {
  rfqNumber: string | undefined;
  lastRound: string | undefined;
  responsibleBuyer: string | undefined;
  endDateRfq: string | undefined;
  content: TableContentItems[];
}

interface TableContentItems {
  [index: string]: string | number;
}

const ShowRfq: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [tableContent, setTableContent] = useState<TableContent | null>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  useEffect(() => {
    async function loadRfq(): Promise<void> {
      try {
        const response = await api.get(`/rfqs/${id}/`);
        const rfq = response.data;
        await api
          .get(`/quotations/${rfq.last_quotation_id}/`)
          .then(response => {
            const quotation = response.data;
            const userCreator = rfq.user_creator;
            const formattedQuotation: TableContent = {
              rfqNumber: rfq.num_rfq,
              lastRound: rfq.quotation_num_round,
              responsibleBuyer: `${userCreator?.first_name} - ${
                userCreator?.buyer_group?.name || ''
              }`,
              endDateRfq: format(parseISO(rfq.deadline), 'dd/MM/yyyy'),
              content: [],
            };

            quotation.quotation_items_suppliers.forEach((qis: Quotation) =>
              qis.quotation_items.forEach(qi =>
                formattedQuotation.content.push(
                  formatQuotation(qi, qis.supplier, qis.delivery_term),
                ),
              ),
            );

            setTableContent(formattedQuotation);
            setErrorMessage(rfq.error_messages?.replace(/[[\]']/g, ''));
            showLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    loadRfq();
  }, [id, t]);

  const tableFields = [
    { label: 'quotation_number' },
    { label: 'quotation_item' },
    { label: 'supplier' },
    { label: 'supplier_name' },
    { label: 'supplier_state' },
    { label: 'purchase_requisition' },
    { label: 'item_requisition' },
    { label: 'creation_date_rc' },
    { label: 'urgency' },
    { label: 'sla_deadline' },
    { label: 'quantity' },
    { label: 'material' },
    { label: 'short_description' },
    { label: 'long_description' },
    { label: 'center' },
    { label: 'remittance_date' },
    { label: 'material_origin' },
    { label: 'merchandise_group' },
    { label: 'net_unit_price' },
    { label: 'net_total_price' },
    { label: 'delivery_term' },
    { label: 'lt_delivery' },
    { label: 'last_purchase_unit_price' },
    { label: 'last_purchase_by' },
    { label: 'last_purchase_date' },
    { label: 'ai_suggestion' },
    { label: 'ai_detail' },
  ];

  const tableLeft = ['supplierName', 'shortDescription', 'longDescription'];

  const closeShowRfq = () => {
    history.push('/purchase-panel');
  };

  return (
    <Container className="flex-1 flex-col bg-primary">
      {loading && <Loading />}
      <Navbar pageTitle="" selectedMenu="panel" />
      <div className="flex items-center px-12 xl:px-20">
        <span className="font-default font-normal text-white text-sm">
          {t('purchase_panel')}
        </span>
        <IconBreadcrumb className="h-2 w-2 mx-4" />
        <span className="font-default font-semibold text-white text-sm">
          {t('rfq_detailing')}
        </span>
      </div>
      <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
        <Content className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6 h-screen">
          <div className="flex justify-between">
            <span className="font-default text-xl text-yaleblue-800 font-semibold">
              {t('quotation_detail')}
            </span>
            <CloseIcon
              className="h-3 w-3 cursor-pointer"
              onClick={() => closeShowRfq()}
            />
          </div>
          <div className="w-full bg-bluejeans-500 h-10 rounded-t-sm text-white text-base font-semibold items-center pl-6 mt-10 flex">
            {`RFQ ${tableContent?.rfqNumber || '-'}`}
          </div>
          <div className="border border-t-none px-6 py-2.5">
            <div>
              <span className="text-sm text-yaleblue-800">
                {t('responsible_buyer')}
              </span>
              <span className="text-sm text-yaleblue-800 font-semibold ml-1">
                {tableContent?.responsibleBuyer}
              </span>
            </div>
            <div>
              <span className="text-sm text-yaleblue-800">
                {t('end_date_rfq')}
              </span>
              <span className="text-sm text-yaleblue-800 font-semibold ml-1">
                {tableContent?.endDateRfq}
              </span>
            </div>
            <div>
              <span className="text-sm text-yaleblue-800">{t('round')}</span>
              <span className="text-sm text-yaleblue-800 font-semibold ml-1">
                {tableContent?.lastRound}
              </span>
            </div>
            {errorMessage && (
              <div>
                <span className="text-sm text-yaleblue-800">
                  {t('error') + ':'}
                </span>
                <span className="text-sm text-yaleblue-800 font-semibold ml-1">
                  {errorMessage}
                </span>
              </div>
            )}
          </div>
          <TableContainer className="overflow-x-auto whitespace-nowrap mb-14">
            <table className="w-full mb-4">
              <thead>
                <tr>
                  {tableFields.map((item, key) => (
                    <th
                      key={key}
                      className="border border-yaleblue-transparent rounded-t-xl items-center font-semibold text-yaleblue-800 px-4 justify-between py-4"
                    >
                      <div className="flex">{t(item.label)}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableContent?.content.map((item, key) => (
                  <tr key={key}>
                    {Object.entries(item).map((value, key) => (
                      <td
                        className={`border border-yaleblue-transparent font-semibold text-yaleblue-800 px-4 h-14 text-${
                          tableLeft.includes(value[0]) ? 'left' : 'center'
                        }`}
                        key={key}
                      >
                        <div className="-my-0.5 bg-basic-gray-transparent py-4 px-3 text-base">
                          {value[1] || '-'}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Content>
      </div>
    </Container>
  );
};

export default ShowRfq;
