import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 16.69rem;
  border: 1px solid rgba(42, 78, 118, 0.08);
  box-sizing: border-box;
`;

export const LineContent = styled.div`
  border-top: 1px solid rgba(42, 78, 118, 0.16);
  &:first-child(1) {
    padding-top: 1.25rem;
  }
`;
