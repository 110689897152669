import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.css';

import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/left-arrow-icon.svg';
// eslint-disable-next-line import/no-duplicates
import { getYear, getMonth } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);

interface ContainerProps {
  children: ReactNode;
}

interface DatePickerProps {
  selected: Date | null;
  onChange(
    date: Date | [Date, Date] | /* for selectsRange */ null,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  minDate?: Date;
  inputStyle?: string;
  placeholder?: string;
  filterInput?: boolean;
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  minDate,
  inputStyle,
  placeholder,
  filterInput,
}) => {
  const { t } = useTranslation();
  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <input
        {...props}
        className={inputStyle}
        ref={ref}
        style={{ maxWidth: '6rem' }}
      />
    );
  };
  CustomInput.displayName = 'CustomInput';

  const CustomContainer: React.FC<ContainerProps> = ({ children }) => {
    return (
      <div className="bg-white flex border rounded-lg py-4 px-4 shadow-md">
        <div className="flex border rounded-lg px-6 shadow-md">{children}</div>
      </div>
    );
  };

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  return (
    <>
      <DatePicker
        locale="pt-BR"
        selected={selected}
        onChange={onChange}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        dateFormat="dd/MM/yyyy"
        calendarContainer={CustomContainer}
        minDate={minDate}
        popperPlacement={filterInput ? 'bottom-start' : 'bottom'}
        placeholderText={placeholder}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="m-2 flex bg-white justify-between items-center">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {!prevMonthButtonDisabled && <LeftArrowIcon />}
            </button>
            <div className="flex flex-col">
              <span className="font-default font-semibold text-yaleblue-1000">
                {t(months[getMonth(date)])}
              </span>
              <span className="font-default font-semibold text-yaleblue-1000">
                {getYear(date)}
              </span>
            </div>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {!nextMonthButtonDisabled && <ArrowIcon />}
            </button>
          </div>
        )}
      />
    </>
  );
};

export default CustomDatePicker;
