import { format, parseISO } from 'date-fns';
import moment from 'moment';
import { formatAICodeRFQ } from '../../utils/rfqValues';
import i18n from 'i18next';
import { formatStringToCurrency } from '../../utils/formatValue';
import removeZerosLeft from '../../utils/removeZerosLeft';

export interface Quotation {
  id: number;
  delivery_term: string;
  quotation_items: QuotationItems[];
  supplier: Supplier;
}

interface Supplier {
  code: string;
  social_name: string;
  state: string;
}

interface QuotationItems {
  delivery_term: string;
  item_quotation: string;
  cod_quotation: string;
  id: number;
  item: Item;
  dt_delivery: string;
  mat_origin: string;
  vl_priceunit: string;
  vl_netprice: string;
  minimum_lot: number;
  uf_origin: string;
  lead_time: number;
  answer_code_message: string;
  qt_quantity: string;
  answer_code_AI: string;
}

interface Material {
  cod_material: string;
}

interface ItemPurchaseRequisition {
  id: number;
  num_req: string;
  is_vendor_list: boolean;
  dt_lib_rc: string;
  created_on: string;
}

interface Item {
  id: number;
  item_req: string;
  quantity: number;
  material: Material;
  dt_lib_rc: string;
  dt_delivery: string;
  item_purchase_requisition: ItemPurchaseRequisition;
  merchandise_group: MerchandiseGroup;
  short_description: string;
  long_description: string;
  sla_deadline: string;
  created_on: string;
  is_active: boolean;
  urgency_status: string;
  mercadory_category: string;
  status: string;
  st_control: number;
  branch_region: string;
  dt_shipping: string;
  last_purchase_price: string;
  last_purchase_date: string;
  last_purchase_supplier: Supplier | null;
  last_purchase_minimum_lot: string;
  last_purchase_order_number: string;
  last_purchase_vl_priceunit: string;
  ia_final_decision: string | null;
  ia_final_decision_msg: string | null;

  [key: string]:
    | number
    | string
    | Material
    | MerchandiseGroup
    | ItemPurchaseRequisition
    | boolean
    | Supplier
    | null;
}

interface MerchandiseGroup {
  description: string;
  erp_id: string;
}

interface TableContentItems {
  answerCodeMessage: string;
  aiDetail: string;

  [index: string]: string | number;
}

export default (
  quotation: QuotationItems,
  supplier: Supplier,
  deliveryTerm: string,
): TableContentItems => {
  const ipr = quotation.item.item_purchase_requisition;
  const dtLibRc = quotation.item.dt_lib_rc || ipr.created_on;
  const daysFromToday = moment().to(moment(quotation.item.sla_deadline));
  const vl_priceunit = parseFloat(quotation.vl_priceunit);
  const vl_netprice = parseFloat(quotation.vl_netprice);
  const totalPrice = (vl_netprice * quotation.item.quantity) / vl_priceunit;
  const lastPurchasePrice = parseFloat(quotation.item.last_purchase_price) || 0;
  const lastPurchasePriceUnit =
    parseFloat(quotation.item.last_purchase_vl_priceunit) || 0;
  return {
    quotationNumber:
      quotation.cod_quotation !== null ? quotation.cod_quotation : '-',
    quotationItem: removeZerosLeft(quotation.item_quotation),
    supplierCode: removeZerosLeft(supplier.code),
    supplierName: supplier.social_name,
    supplierState: supplier.state,
    purchaseRequisition: removeZerosLeft(ipr.num_req),
    requisitionItem: removeZerosLeft(quotation.item.item_req),
    creationDateRc: format(parseISO(dtLibRc), 'dd/MM/yyyy'),
    urgency: quotation.item.urgency_status,
    slaDeadline: daysFromToday,
    quantity: quotation.item.quantity,
    material: removeZerosLeft(quotation.item.material.cod_material),
    shortDescription: quotation.item.short_description,
    longDescription: quotation.item.long_description,
    center: quotation.item.branch_region,
    remittanceDate: format(parseISO(quotation.item.dt_delivery), 'dd/MM/yyyy'),
    originMaterial: quotation.mat_origin,
    mercadoryGroup: quotation.item.merchandise_group.erp_id,
    netUnitPrice: formatStringToCurrency(vl_netprice / vl_priceunit),
    totalPrice: formatStringToCurrency(totalPrice),
    deliveryTerm: deliveryTerm,
    leadTime: quotation.delivery_term,
    lastPurchaseUnitPrice: formatStringToCurrency(lastPurchasePrice),
    lastPurchasePriceUnit: lastPurchasePriceUnit,
    lastPurchaseDate:
      quotation.item.last_purchase_date !== null
        ? format(parseISO(quotation.item.last_purchase_date), 'dd/MM/yyyy')
        : '-',
    answerCodeMessage:
      i18n.t(formatAICodeRFQ(quotation.item.ia_final_decision)) || '-',
    aiDetail: quotation.item.ia_final_decision_msg || '-',
  };
};
