import React from 'react';
import {
  WrapperPasswordRules,
  RuleItem,
  RuleText,
} from './PasswordRules.style';
import { useTranslation } from 'react-i18next';

const PasswordRules: React.FC<{
  passwordRule1: boolean;
  passwordRule2: boolean;
  passwordRule3: boolean;
  password: boolean;
}> = ({ passwordRule1, passwordRule2, passwordRule3, password }) => {
  const { t } = useTranslation();

  const passwordIconError = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5155 8.58378C16.5618 4.21165 13.0553 0.630002 8.68318 0.583674C4.31105 0.537347 0.729403 4.04389 0.683076 8.41602C0.636749 12.7881 4.14329 16.3698 8.51542 16.4161C12.8875 16.4624 16.4692 12.9559 16.5155 8.58378ZM2.26638 8.43223C2.30344 4.93462 5.16886 2.12929 8.66647 2.16635C12.1641 2.20341 14.9694 5.06883 14.9323 8.56644C14.8953 12.064 12.0299 14.8694 8.53226 14.8323C5.03465 14.7952 2.22932 11.9298 2.26638 8.43223ZM9.34961 12.4668L7.76636 12.45L7.78314 10.8668L9.36639 10.8836L9.34961 12.4668ZM7.79957 9.28303L9.38281 9.2998L9.43314 4.55007L7.8499 4.5333L7.79957 9.28303Z"
        fill="#D2002F"
      />
    </svg>
  );

  const passwordIconSuccess = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50004 16.42C4.12585 16.42 0.580078 12.8742 0.580078 8.50004C0.580078 4.12585 4.12585 0.580078 8.50004 0.580078C12.8742 0.580078 16.42 4.12585 16.42 8.50004C16.42 12.8742 12.8742 16.42 8.50004 16.42ZM8.50004 14.836C11.9993 14.836 14.836 11.9993 14.836 8.50004C14.836 5.00078 11.9993 2.16407 8.50004 2.16407C5.00078 2.16407 2.16407 5.00078 2.16407 8.50004C2.16407 11.9993 5.00078 14.836 8.50004 14.836ZM7.71043 11.668L4.34999 8.30759L5.46987 7.18771L7.71043 9.42827L12.19 4.94794L13.3106 6.06782L7.71043 11.668Z"
        fill="#009B70"
      />
    </svg>
  );
  return (
    <>
      {(!password && (
        <ul>
          <li>{t('password_rule_1')}</li>
          <li>{t('password_rule_2')}</li>
          <li>{t('password_rule_3')}</li>
        </ul>
      )) || (
        <WrapperPasswordRules>
          <RuleItem>
            {(!passwordRule1 && passwordIconError) || passwordIconSuccess}
            <RuleText error={!passwordRule1}>{t('password_rule_1')}</RuleText>
          </RuleItem>
          <RuleItem>
            {(!passwordRule2 && passwordIconError) || passwordIconSuccess}
            <RuleText error={!passwordRule2}>{t('password_rule_2')}</RuleText>
          </RuleItem>
          <RuleItem>
            {(!passwordRule3 && passwordIconError) || passwordIconSuccess}
            <RuleText error={!passwordRule3}>{t('password_rule_3')}</RuleText>
          </RuleItem>
        </WrapperPasswordRules>
      )}
    </>
  );
};

export default PasswordRules;
