import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  height: 100vh;
`;

export const StyledSVG = styled.svg`
  & path {
    fill: ${props => props.color};
  }
`;

export const ChartContainer = styled.div`
  ::-webkit-scrollbar {
    width: 110.09px;
    height: 8px;
    left: 391.23px;
    top: 737px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c8d0da;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
