/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { FilterDropdown, SelectBox } from './CustomSelectCheckbox.style';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down-blue-icon.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrow-up-blue-icon.svg';
import { ReactComponent as IconSearch } from '../../assets/icon-search-active.svg';
import { ReactComponent as CheckboxChecked } from '../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckboxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import TooltipLabel from '../TooltipLabel/TooltipLabel';

interface DropdownContent {
  label: string;
  value?: string | number;
  code?: string;
  document_number?: string;
}
interface CustomDropdownProps {
  options: DropdownContent[] | [];
  title: string;
  toogleItemOption: Function;
  selectedItems?: DropdownContent[];
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  title,
  toogleItemOption,
  selectedItems,
}) => {
  const [searchedOptions, setSearchedOptions] = useState<DropdownContent[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedOptions, setSelectdOptions] = useState<string[]>([]);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleSearchValues = (value: string) => {
    if (value === '') {
      setSearchedOptions(options);
    } else {
      const searchedOption = options.slice();

      const lowerCasedValue = value.toLowerCase().trim();
      const filteredData = searchedOption.filter(item => {
        return Object.keys(item).some(() =>
          !item.label
            ? false
            : item.label.toString().toLowerCase().includes(lowerCasedValue),
        );
      });
      setSearchedOptions(filteredData);
    }
  };

  const handleSelectOption = (option: DropdownContent) => {
    const selectedOption = selectedOptions.slice();
    selectedOption.push(option.label);
    setSelectdOptions([...selectedOption]);
    toogleItemOption(option, 'select');
  };

  const handleUnselectOption = (option: DropdownContent) => {
    const selectedOption = selectedOptions.slice();
    const index = selectedOption.findIndex(op => op === option.label);
    selectedOption.splice(index, 1);
    setSelectdOptions([...selectedOption]);
    toogleItemOption(option, 'unselect');
  };

  const handleClick = (e: Event) => {
    if (!node.current) {
      return;
    }
    if (node.current.contains(e.target as Node)) {
      setIsOpenDropdown(true);
    } else {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    setSearchedOptions(options);
    if (selectedItems) {
      const newOptions: string[] = [];
      selectedItems.forEach(item => {
        newOptions.push(item.label);
      });
      setSelectdOptions([...newOptions]);
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [options]);

  const toogleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  return (
    <div>
      {isOpenDropdown ? (
        <FilterDropdown className="bg-white rounded-md hover:border-primary">
          <div
            className="flex px-5 py-2.5 items-center justify-between"
            onClick={() => toogleDropdown()}
          >
            <span className="text-yaleblue-1000 text-sm">{title}</span>
            <ArrowUp className="cursor-pointer w-2.5" />
          </div>
          <div className="px-5 pb-4">
            <div className="flex relative">
              <input
                name="search"
                placeholder="Busca"
                onChange={e => handleSearchValues(e.target.value)}
                className="bg-input-active flex h-10 items-center justify-end px-2.5 rounded w-full"
              />
              <IconSearch className="cursor-pointer w-5 h-5 absolute right-4 top-2.5" />
            </div>
          </div>
          {searchedOptions.length > 0 &&
            searchedOptions.map((item, key) => (
              <SelectBox key={key} className="flex px-2 py-2 items-center">
                {selectedOptions.includes(item.label) ? (
                  <CheckboxChecked
                    className="cursor-pointer h-4 w-4"
                    onClick={() => handleUnselectOption(item)}
                  />
                ) : (
                  <CheckboxUnchecked
                    className="cursor-pointer h-4 w-4"
                    onClick={() => handleSelectOption(item)}
                  />
                )}
                <TooltipLabel
                  overlayText={item.label}
                  className={'w-full leading-4 text-xs pl-5'}
                  keyText={item.label.substring(0, 14)}
                />
              </SelectBox>
            ))}
        </FilterDropdown>
      ) : (
        <div
          className="bg-whitegray-100 flex h-10 items-center justify-between px-2 w-40 rounded border border-color-yaleblue"
          onClick={() => toogleDropdown()}
        >
          <span className="text-yaleblue-500 text-sm leading-5">{title}</span>
          <ArrowDown className="cursor-pointer" />
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
