import styled, { css } from 'styled-components';

interface FilterButtonProps {
  isOpen: boolean;
  totalFilters: number;
}

export const FilterButton = styled.div<FilterButtonProps>`
  ${props =>
    props.isOpen || props.totalFilters > 0
      ? css`
          background-color: rgba(16, 93, 138, 1);
        `
      : css`
          background-color: rgba(246, 247, 249, 1);
          border: 2px solid rgba(42, 78, 118, 0.08);
          box-sizing: border-box;
        `}
  border-radius: 0.25rem;
`;

export const FilterModal = styled.form`
  width: 26rem;
  max-width: 26rem;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 100;
`;

export const TopbarModal = styled.div`
  border-radius: 6px 6px 0px 0px;
`;

export const FooterModal = styled.div`
  height: 4.625rem;
  border-top: 2px solid rgba(42, 78, 118, 0.08);
`;

export const CustomInput = styled.div`
  width: 27.38rem;
  height: 2.625rem;
  background-color: #f6f7f9;
  border: 1px solid rgba(42, 78, 118, 0.08);
  border-radius: 0.25rem;
`;

export const ScrollabeContent = styled.div`
  max-height: 20rem;
`;
