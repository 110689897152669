import React, { useEffect, useState } from 'react';
import { ReactComponent as ChavronIcon } from '../../assets/icon-chavron-down.svg';
import { ReactComponent as CheckBoxChecked } from '../../assets/checkbox-checked-icon.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../assets/checkbox-unchecked-icon.svg';
import { Container, Featured, ContentContainer } from './ListItem.style';

interface ListItemProps {
  FeaturedComponent?: React.FC;
  ContentComponent?: React.FC;
  ListComponent?: React.FC;
  className?: string;
  px?: string;
  hasCheckbox?: boolean;
  isSelected?: boolean;
  removeOne?: Function;
  selectOne?: Function;
  disabled?: boolean;
  id?: number;
  item?: Object;
  justify?: string;
}

const ListItem: React.FC<ListItemProps> = ({
  FeaturedComponent,
  ContentComponent,
  ListComponent,
  className = '',
  px,
  isSelected,
  hasCheckbox,
  removeOne,
  selectOne,
  disabled = false,
  item,
  id,
  justify,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<Object>();

  const toggleAction = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    async function loadSelectedItems() {
      item && setSelectedItem(item);
      id && setSelectedId(id);
    }
    loadSelectedItems();
  }, [id, item]);

  return (
    <Container className={`flex flex-col rounded-lg w-full ${className}`}>
      <Featured
        className={`flex flex-row h-14 items-center w-full ${
          justify === 'none' ? '' : 'justify-between'
        } ${px || 'px-4'}`}
        isOpen={isOpen}
        style={{ minHeight: '3.5rem', height: 'fit-content' }}
      >
        {hasCheckbox && (
          <div className="w-4 h-1/2">
            {isSelected ? (
              <CheckBoxChecked
                className="mr-5 cursor-pointer"
                onClick={() =>
                  removeOne ? removeOne(selectedItem || selectedId) : null
                }
              />
            ) : (
              <CheckBoxUnchecked
                className={`mr-5  ${
                  disabled ? 'checkbox-disabled' : 'cursor-pointer'
                }`}
                onClick={() =>
                  selectOne && !disabled
                    ? selectOne(selectedItem || selectedId)
                    : null
                }
              />
            )}
          </div>
        )}
        {FeaturedComponent && <FeaturedComponent />}
        {ContentComponent && (
          <div
            className="h-4 w-4 z-100 cursor-pointer ml-2"
            onClick={toggleAction}
          >
            <ChavronIcon />
          </div>
        )}
      </Featured>
      {isOpen && (
        <ContentContainer className="flex flex-col pt-6 justify-between px-4">
          {ContentComponent && <ContentComponent />}
        </ContentContainer>
      )}
    </Container>
  );
};

export default ListItem;
