interface Options {
  label: string;
  value: string | number;
}

export const formatOptions = (
  items: any[],
  dropdown: string,
  loadedOptions: never[],
): Options[] => {
  const options: Options[] = [];
  if (loadedOptions) {
    loadedOptions.forEach(item => {
      options.push(item);
    });
  }
  switch (dropdown) {
    case 'suppliers':
      items.forEach((item: { social_name: string; id: number }) => {
        const newSupplier = {
          label: item.social_name,
          value: item.id,
        };
        options.push(newSupplier);
      });
      break;
    case 'cod_rc':
      items.forEach((item: { num_req: string }) => {
        const newCodRc = {
          label: item.num_req,
          value: item.num_req,
        };
        options.push(newCodRc);
      });
      break;
    case 'buyer_group_id':
    case 'buyer_groups':
    case 'merchandise_group':
    case 'merchandise_groups':
      items.forEach((item: { id: number; name: string }) => {
        const newGroup = {
          label: item.name,
          value: item.id,
        };
        options.push(newGroup);
      });
      break;
    case 'material':
      items.forEach((item: { cod_material: string }) => {
        const newMaterial = {
          label: item.cod_material,
          value: item.cod_material,
        };
        options.push(newMaterial);
      });
      break;
    case 'materials':
      items.forEach((item: { id: number; cod_material: string }) => {
        const newMaterial = {
          label: item.cod_material,
          value: item.id,
        };
        options.push(newMaterial);
      });
      break;
    case 'region':
      items.forEach((item: { code: string }) => {
        const newRegion = {
          label: item.code,
          value: item.code,
        };
        options.push(newRegion);
      });
      break;
    case 'centers':
      items.forEach(item => {
        const newCenter = {
          label: item.name ?? item.code,
          value: item.id,
        };
        options.push(newCenter);
      });
      break;
    case 'num_req':
      items.forEach((item: { num_req: string }) => {
        const newCodRc = {
          label: item.num_req,
          value: item.num_req,
        };
        options.push(newCodRc);
      });
      break;
    case 'num_rfq':
      items.forEach((item: { num_rfq: string }) => {
        const newRfq = {
          label: item.num_rfq,
          value: item.num_rfq,
        };
        options.push(newRfq);
      });
      break;
    case 'categories':
      items.forEach((item: { erp_id: number; name: string; id: number }) => {
        const newCategory = {
          label: item.erp_id?.toString() + ' - ' + item.name,
          value: item.id,
        };
        options.push(newCategory);
      });
      break;
    case 'code':
      items.forEach((item: { code: string }) => {
        const newSupplierCode = {
          label: item.code,
          value: item.code,
        };
        options.push(newSupplierCode);
      });
      break;
    case 'social_name':
      items.forEach((item: { social_name: string }) => {
        const newSupplierName = {
          label: item.social_name,
          value: item.social_name,
        };
        options.push(newSupplierName);
      });
      break;
    case 'document_number':
      items.forEach((item: { document_number: string }) => {
        const newSupplierDocNumber = {
          label: item.document_number,
          value: item.document_number,
        };
        options.push(newSupplierDocNumber);
      });
      break;
    case 'type_supplier':
      items.forEach((item: { type_supplier: string }) => {
        const newSupplyerType = {
          label: item.type_supplier,
          value: item.type_supplier,
        };
        options.push(newSupplyerType);
      });
      break;
    case 'ia_status_result':
      items.forEach((item: { ia_status_result_code: string }) => {
        const newResult = {
          label: item.ia_status_result_code,
          value: item.ia_status_result_code,
        };
        options.push(newResult);
      });
      break;
    case 'ia_detail':
      items.forEach((item: { ia_status_result: string }) => {
        const newResult = {
          label: item.ia_status_result,
          value: item.ia_status_result,
        };
        options.push(newResult);
      });
      break;
    case 'num_quotation':
      items.forEach((item: { cod_quotation: string; id: number }) => {
        const newQuotation = {
          label: item.cod_quotation,
          value: item.cod_quotation,
        };
        options.push(newQuotation);
      });
      break;
    case 'urgency':
      items.forEach((item: { code: string; description: string }) => {
        const newUrgency = {
          label: `${item.code} - ${item.description}`,
          value: item.code,
        };
        options.push(newUrgency);
      });
      break;
    case 'warehouse':
      items.forEach((item: { id: number; code: string; name: string }) => {
        const newWarehouse = {
          label: `${item.code}`,
          value: item.code,
        };
        options.push(newWarehouse);
      });
      break;
    case 'warehouses':
      items.forEach((item: { id: number; code: string; name: string }) => {
        const newWarehouse = {
          label: item.code,
          value: item.id,
        };
        options.push(newWarehouse);
      });
      break;
    default:
      break;
  }
  return options;
};
