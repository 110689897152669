import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MinusIcon } from '../../assets/minus-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';

interface CustomInputNumberProps {
  title: string;
  toggleAction?: Function;
  isCleanedFilters?: boolean;
  currentNumber?: number | null;
}

const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  title,
  toggleAction,
  isCleanedFilters,
  currentNumber,
}) => {
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    currentNumber && setCounter(currentNumber);
    async function clearCounter() {
      if (isCleanedFilters) setCounter(0);
    }
    clearCounter();
  }, [currentNumber, isCleanedFilters]);

  const handleTitle = () => {
    if (isCleanedFilters || counter <= 0) {
      return t(title);
    }
    return counter;
  };

  const toogleCounter = (action: string) => {
    if (action === 'increment') {
      incrementCounter();
    } else {
      decrementCounter();
    }
  };

  const incrementCounter = () => {
    setCounter(counter + 1);
    toggleAction && toggleAction(counter + 1);
  };

  const decrementCounter = () => {
    if (counter > 0) setCounter(counter - 1);
    toggleAction && toggleAction(counter - 1);
  };

  return (
    <div className="flex mt-3">
      <div
        className="border border-basic-gray-transparent rounded-tl rounded-bl w-11 h-10 flex items-center justify-center cursor-pointer"
        onClick={() => toogleCounter('decrement')}
      >
        <MinusIcon />
      </div>
      <div className="border border-basic-gray-transparent w-11 h-10 flex items-center justify-center text-sm text-basic-gray-400">
        {handleTitle()}
      </div>
      <div
        className="border border-basic-gray-transparent rounded-tr rounded-br w-11 h-10 flex items-center justify-center cursor-pointer"
        onClick={() => toogleCounter('increment')}
      >
        <PlusIcon />
      </div>
    </div>
  );
};

export default CustomInputNumber;
