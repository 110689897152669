import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './TooltipLabel.css';

interface TooltipProps {
  overlayText: string;
  className: string;
  keyText: string;
}

const TooltipLabel: React.FC<TooltipProps> = ({
  overlayText,
  className,
  keyText,
}) => {
  return (
    <div className="relative grid w-fit">
      <Tooltip placement="top" overlay={overlayText} align={{ offset: [0, 4] }}>
        <span className={className}>
          {overlayText.length > 14 ? `${keyText}...` : keyText}
        </span>
      </Tooltip>
    </div>
  );
};

export default TooltipLabel;
