import React from 'react';
import { Container } from './DatePicker.style';

import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

interface DatePickerProps {
  selected: Date | null;
  onChange(
    date: Date | [Date, Date] | /* for selectsRange */ null,
    event: React.SyntheticEvent<unknown> | undefined,
  ): void;
  minDate?: Date;
  placeholder?: string;
  hasError?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  selected,
  onChange,
  minDate,
  placeholder,
  hasError,
}) => {
  return (
    <>
      <Container
        className={`flex items-center border rounded-lg focus-within:border-primary focus:within:border-2 pl-4  ${
          selected ? 'bg-white' : 'bg-whitegray-100'
        }
        ${hasError ? ' border-red-salsa-500 ' : ''}
        `}
      >
        <CustomDatePicker
          selected={selected}
          onChange={onChange}
          inputStyle={
            'bg-transparent text-sm text-bluejeans-800 placeholder-basic-gray-400 focus:outline-none'
          }
          placeholder={placeholder}
          minDate={minDate}
        />
        <div className="border rounded-lg px-2 py-2 items-center">
          <CalendarIcon />
        </div>
      </Container>
    </>
  );
};

export default DatePicker;
