import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

import { Content, ContentContainer } from './ListRfq.style';
import { useTranslation } from 'react-i18next';
import ListItem from '../../../components/ListItem/ListItem';
import FilterButton from '../../../components/FilterButton/FiltterButton';
import Loading from '../../../components/Loading/Loading';
import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
import { ReactComponent as UsersIcon } from '../../../assets/users-icon.svg';
import { ReactComponent as UserIconSelec } from '../../../assets/user-icon-selec.svg';
import { ReactComponent as UsersIconSelec } from '../../../assets/users-icon-selec.svg';
import { formatOptions } from '../../../helpers/OptionsHelpers/FormatOptions';
import ListButton from '../../../components/ListButton/ListButton';
import Filters from '../../../components/Filters/Filters';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeadlineDonut from '../../../components/DeadlineDonut/DeadlineDonut';
import { formatAICodeRFQ } from '../../../utils/rfqValues';
import moment from 'moment';
import removeZerosLeft from '../../../utils/removeZerosLeft';

import RfqService, {
  RfqFilterCounter,
  RfqFilterCounterGroup,
  RfqFilterCounterIaFinalDecision,
  RfqFilterCounterStControl,
} from '../../../services/rfq-service';
import SortIcon from '../../../shared/SortIcon/SortIcon';
import GoTopButton from '../../../shared/GoTopButton/GoTopButton';
import { FilterParam, ListParams } from '../../../services/base-service';
import { ST_CONTROL } from '../../../utils/rfqStatusControl';
import { CustomComponent, CustomFilterPosition } from '../../../styled';
import { checkScroll } from '../utils';

interface Suppliers {
  id: number;
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  pmp: string;
  saving_percent: number;
  deadline: string;
}

interface Material {
  id: number;
  cod_material: string;
  description: string;
}

interface BuyerGroup {
  id: number;
  name: string;
  description: string;
}

interface itemPurchaseRequisition {
  id: number;
  num_req: string;
  is_vendor_list: boolean;
  dt_lib_rc: string;
  created_on: string;
}

interface Items {
  id: number;
  item_req: string;
  quantity: number;
  material: Material;
  item_purchase_requisition: itemPurchaseRequisition;
  short_description: string;
  long_description: string;
  sla_deadline: string;
  created_on: string;
  is_active: boolean;
  urgency_status: string;
  mercadory_category: string;
  status: string;
  st_control: number;
  branch_region: string;
  dt_shipping: string;
  lead_time: number;
  ia_final_decision: string;
  ia_final_decision_msg: string;
  target: string;
  last_purchase_price: string;
  last_purchase_date: string;
  last_purchase_minimum_lot: string;
  last_purchase_order_number: string;
  buyer_group: BuyerGroup | null;

  [key: string]:
    | number
    | string
    | Material
    | itemPurchaseRequisition
    | BuyerGroup
    | boolean
    | null
    | Items[];
}

interface ItemRfq {
  id: number;
  dt_delivery: string;
  dt_lib_rc: string;
  observation: string;
  answer_code_AI: string;
  answer_code_message: string;
  item: Items;

  [key: string]: number | string | Items;
}

interface Rfq {
  id: number;
  num_rfq: string;
  deadline: string;
  created_on: string;
  item_minor_sla: string;
  item_minor_created_at: string;
  suppliers_number: number;
  ia_status_result: string;
  is_automation: boolean;
  is_possible_automation: boolean;
  is_active: boolean;
  supplier_observation: string;
  suppliers: Suppliers[];
  item_rfq: ItemRfq[];
  grp_purchasing: GrpPurchasing;
  item_req_list: Items[];

  [key: string]:
    | number
    | string
    | string[]
    | boolean
    | Suppliers[]
    | ItemRfq[]
    | GrpPurchasing
    | Items[];
}

interface GrpPurchasing {
  id: number;
  name: string;
}

interface RfqContentLine {
  purchase_requisition: Array<String>;
  item_requisition: Array<String>;
  material: Array<string>;
  material_description: Array<string>;
  center: Array<string>;
  urgency: Array<string>;
  sla_deadline: Array<string>;
  ia_orientation: Array<string>;
  ia_detail: Array<string>;
}

interface Options {
  label: string;
  value: string | number;
}

interface ListRfqProps {
  totalRfqs: number;
  errorPage: Function;
}

interface Field {
  label: string;
  sort?: string;
  value?: string;
  size?: string;
  type?: string;
}

const ListRfq: React.FC<ListRfqProps> = ({ errorPage }) => {
  const { t } = useTranslation();
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [materials, setMaterials] = useState<Options[]>([]);
  const [suppliers, setSuppliers] = useState<Options[]>([]);
  const [urgencies, setUrgencies] = useState<Options[]>([]);
  const [rfqs, setRfqs] = useState<Rfq[]>([]);

  const [purchaseRequisitions, setPurchaseRequisitions] = useState<Options[]>(
    [],
  );
  const [numRfq, setNumRfqs] = useState<Options[]>([]);
  const [buyerGroups, setBuyerGroups] = useState<Options[]>([]);
  const [numQuotations, setNumQuotations] = useState<Options[]>([]);
  const [regions, setRegions] = useState<Options[]>([]);

  const [loading, setLoading] = useState(true);

  const [params, setParams] = useState<ListParams>(RfqService.paramsDefault);
  const [hasNext, setHasNext] = useState(true);

  const [filterCount, setFilterCount] = useState<RfqFilterCounter>(
    RfqService.filterCountDefault,
  );

  const [
    filterCountStControl,
    setFilterCountStControl,
  ] = useState<RfqFilterCounterIaFinalDecision>(
    RfqService.filterCountStControlDefault,
  );

  const [
    filterCountGroup,
    setFilterCountGroup,
  ] = useState<RfqFilterCounterGroup>(RfqService.filterCountGroupDefault);

  const [filters, setFilters] = useState<FilterParam[]>([]);

  const [quickFilters, setQuickFilters] = useState<FilterParam[]>([]);

  interface FilterState {
    [key: string]: FilterParam[];
  }

  const initialFilters: FilterState = {
    '0': RfqService.filterButtons[0].mine,
    '1': RfqService.filterButtons[1].awaiting_response,
    '2': RfqService.filterButtons[2].all,
  };

  const [filterState, setFilterState] = useState<FilterState>(initialFilters);
  const fields: Field[] = [
    { label: 'refqs_number', value: 'id', type: 'number', sort: 'num_rfq' },
    {
      label: 'quotation_num_round',
      value: 'quotation_num_round',
      type: 'number',
      sort: 'quotation_num_round',
    },
    {
      label: 'buyer',
      value: 'item_rfq[0].item.item_purchase_requisition.buyer_group.name',
      sort: 'grp_purchasing__name',
    },
    {
      label: 'suppliers_number',
      value: 'suppliers.length',
      type: 'number',
      sort: 'suppliers',
    },
    {
      label: 'items',
      size: 'md:w-1/12 lg:w-1/12',
      value: 'item_req_list.length',
      type: 'number',
      sort: 'item_req_list',
    },
    {
      label: 'sla_deadline',
      value: 'deadline',
      type: 'date',
      sort: 'item_minor_sla',
    },
    {
      label: 'best_value_purchase',
      type: 'number',
    },
    {
      label: 'automation',
      value: 'is_possible_automation',
      sort: 'is_possible_automation',
    },
  ];
  const filterFields = {
    fieldsList: [
      {
        field: 'num_rfq',
        type: 'list',
        placeholder: 'Ex. G950264',
        options: numRfq,
        url: 'rfqs/list_to_filter/?num_rfq=',
      },
      {
        field: 'buyer_group_id',
        type: 'list',
        placeholder: 'select_buyer_group',
        options: buyerGroups,
        url: 'buyer_groups/list_to_filter/?name=',
      },
      {
        field: 'num_quotation',
        type: 'list',
        placeholder: 'Ex. 6004045418',
        options: numQuotations,
        url: 'quotation_suppliers/list_to_filter/?cod_quotation=',
      },
      {
        field: 'suppliers',
        type: 'select',
        placeholder: 'select_supplier',
        options: suppliers,
        url: 'suppliers/list_to_filter/?supplier=',
      },
      {
        field: 'cod_rc',
        type: 'select',
        placeholder: 'Ex. 18831544',
        options: purchaseRequisitions,
        url: 'purchase_requisitions/list_to_filter/?num_req=',
      },
      {
        field: 'urgency',
        type: 'select',
        placeholder: 'select_urgency',
        options: urgencies,
        url: 'sla/list_to_filter/?code=',
      },
      {
        field: 'material',
        type: 'select',
        placeholder: 'Ex. 175239',
        options: materials,
        url: 'materials/list_to_filter/?cod_material=',
      },
      {
        field: 'region',
        type: 'list',
        placeholder: 'select_region',
        options: regions,
        url: 'centers/list_to_filter/?code=',
      },
      {
        field: 'is_automation',
        type: 'select',
        placeholder: 'select_type',
        options: [
          {
            label: 'Yes',
            value: 'True',
          },
          {
            label: 'No',
            value: 'False',
          },
        ],
      },
    ],
    hasSla: true,
  };

  const filterButtons = {
    0: [
      {
        button: 'mine',
        keyText: `${t('my_plural')} (${filterCountGroup.mine.total})`,
        icon: UserIcon,
        iconSelec: UserIconSelec,
      },
      {
        button: 'all',
        keyText: `${t('all')} (${filterCountGroup.all.total})`,
        icon: UsersIcon,
        iconSelec: UsersIconSelec,
      },
    ],
    1: [
      {
        button: 'awaiting_response',
        keyText: `${t('awaiting_response')} (${
          filterCount.st_control.awaiting_response.total
        })`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'answered',
        keyText: `${t('answered')} (${filterCount.st_control.answered.total})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'failure',
        keyText: `${t('failure')} (${filterCount.st_control.failure.total})`,
        icon: null,
        iconSelec: null,
      },
    ],
    2: [
      {
        button: 'all',
        keyText: `${t('all')} (${filterCountStControl.total})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'help_buyer',
        keyText: `${t('help_buyer')} (${filterCountStControl.help})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'new_round',
        keyText: `${t('new_round')} (${filterCountStControl.new_round})`,
        icon: null,
        iconSelec: null,
      },
      {
        button: 'reward',
        keyText: `${t('reward')} (${filterCountStControl.reward})`,
        icon: null,
        iconSelec: null,
      },
    ],
  };

  const [checkCache, setcheckCache] = useState(false);

  useEffect(() => {
    if (!checkCache) return;
    if (!params.page) params.page = 1;
    RfqService.getFiltersCount(filters)
      .then(data => setFilterCountGroup(data))
      .catch(error => errorPage(error));
  }, [errorPage, filters, params]);

  useEffect(() => {
    const filters = params.filter;
    let stControlLabel: keyof RfqFilterCounterStControl = 'answered';

    if (filterCountGroup[params.group_count]) {
      setFilterCount(filterCountGroup[params.group_count]);
    }

    for (const filter of filters) {
      if (filter.label && filter.label in filterCount.st_control) {
        stControlLabel = filter.label as keyof RfqFilterCounterStControl;
      }
    }
    if (filterCount.st_control[stControlLabel]) {
      setFilterCountStControl(filterCount.st_control[stControlLabel]);
    }
  }, [filterCount.st_control, filterCountGroup, params]);
  // Cache for filters
  useLayoutEffect(() => {
    // const first_filter_value =
    //   sessionStorage.getItem('rfq_list_filter_0') ||
    //   localStorage.getItem('rfq_list_filter_0') ||
    //   'mine';
    // const second_filter_value =
    //   sessionStorage.getItem('rfq_list_filter_1') ||
    //   localStorage.getItem('rfq_list_filter_1');
    const general_filter_value =
      sessionStorage.getItem('rfq_list_general_filter') ||
      localStorage.getItem('rfq_list_general_filter');
    // let first_filter;
    // let second_filter;
    let general_filter;
    let button = 'mine';
    // if (first_filter_value) {
    //   first_filter = RfqService.firstFilterButtons[first_filter_value] || [];
    //   setFirstButtonFilter(first_filter);
    //   sessionStorage.setItem('rfq_list_filter_0', first_filter_value);
    // }
    // if (second_filter_value) {
    //   second_filter = RfqService.secondFilterButtons[second_filter_value] || [];
    //   setSecondButtonFilter(second_filter);
    //   sessionStorage.setItem('rfq_list_filter_1', second_filter_value);
    // }
    if (general_filter_value) {
      sessionStorage.setItem('rfq_list_general_filter', general_filter_value);
      const filtersString = general_filter_value.split('&');
      general_filter = filtersString.map(function (filter): FilterParam {
        const filterArray = filter.split('=');
        return {
          key: filterArray[0],
          value: filterArray[1] || '',
        };
      });
      setFilters(general_filter);
    }
    // const filters: FilterParam[] = (first_filter || []).concat(
    //   second_filter || [],
    //   general_filter || [],
    // );
    const quickFilters: FilterParam[] = [];
    for (const order in RfqService.filterButtons) {
      if (
        Object.prototype.hasOwnProperty.call(RfqService.filterButtons, order)
      ) {
        const filterStringValue =
          sessionStorage.getItem('rfq_list_filter_' + order.toString()) ||
          localStorage.getItem('rfq_list_filter_' + order.toString());
        if (filterStringValue) {
          const orderNumber = parseInt(
            order,
          ) as keyof typeof RfqService.filterButtons;

          if (!isNaN(orderNumber) && orderNumber in RfqService.filterButtons) {
            const filterValue = filterStringValue as keyof typeof RfqService.filterButtons[typeof orderNumber];
            if (
              order in RfqService.filterButtons &&
              filterValue in RfqService.filterButtons[orderNumber]
            ) {
              const filter = RfqService.filterButtons[orderNumber][filterValue];

              setFilterState(prevState => ({
                ...prevState,
                [order]: filter,
              }));

              quickFilters.push(filter);

              if (orderNumber === 0) {
                button = filterValue;
              }
            }
          }

          sessionStorage.setItem(
            'rfq_list_filter_' + order.toString(),
            filterStringValue,
          );
        }
      }
    }
    setQuickFilters(quickFilters.flat() as []);
    handleCacheFilters(
      [...quickFilters, ...(general_filter || [])].flat() as [],
      button,
    );
    setcheckCache(true);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () =>
      checkScroll(contentRef.current, fetchNextPage),
    );

    return () => {
      window.removeEventListener('resize', () =>
        checkScroll(contentRef.current, fetchNextPage),
      );
    };
  }, []);

  useEffect(() => {
    checkScroll(contentRef.current, fetchNextPage, rfqs.length > 0);
  }, [rfqs]);

  // Fetch Rfqs and Filter Count
  useEffect(() => {
    if (!checkCache) return;
    if (!params.page) params.page = 1;
    setLoading(true);
    RfqService.getList(params)
      .then(data => {
        const results = data.results as Rfq[];
        const filteredResults: Rfq[] = [];

        for (const rfqKey in results) {
          const rfq = results[rfqKey];
          let removeRfq = true;

          for (const rfqItem of rfq.item_req_list) {
            if (rfqItem.st_control !== ST_CONTROL.RESOLVED_EXTERNALLY) {
              removeRfq = false;
              break;
            }
          }

          if (!removeRfq) {
            filteredResults.push(rfq);
          }
        }
        if (params.page === 1) {
          setRfqs(filteredResults);
        } else {
          setRfqs(rfqs => [...rfqs, ...filteredResults]);
        }

        setHasNext(data.next != null);
      })
      .catch(error => errorPage(error))
      .finally(() => setLoading(false));
  }, [errorPage, params, checkCache, filterState]);

  const fetchNextPage = () => {
    if (!hasNext) return;
    setParams(params => ({ ...params, page: params.page + 1 }));
  };

  const fetchSorted = (field: Field) => {
    if (!field.sort) return;

    if (params.sort === field.sort) {
      params.order = params.order === 'asc' ? 'desc' : 'asc';
    } else {
      params.order = 'asc';
      params.sort = field.sort;
    }

    params.page = 1;

    setParams(oldParams => ({ ...oldParams, ...params }));
  };

  type FilterButtons = typeof RfqService.filterButtons;

  type FilterButtonKeys<T> = T extends Record<string, any> ? keyof T : never;

  const toggleFilterButton = <T extends keyof FilterButtons>(
    button: FilterButtonKeys<FilterButtons[T]>,
    order: T,
  ) => {
    if (
      order in RfqService.filterButtons &&
      button in RfqService.filterButtons[order]
    ) {
      // const value = RfqService.filterButtons[order][button];
      const newFilters = {
        ...filterState,
        [order]: RfqService.filterButtons[order][button],
      };

      localStorage.setItem('rfq_list_filter_' + order.toString(), button);
      sessionStorage.setItem('rfq_list_filter_' + order.toString(), button);
      setFilterState(newFilters);
      const filtersArray = Object.values(newFilters);
      const filter = [...filters, ...(filtersArray.flat() as FilterParam[])];
      setParams(prevParams => ({
        ...prevParams,
        filter: filter,
        group_count: order === 0 ? button : prevParams.group_count,
        page: 1,
      }));
      setQuickFilters([...(filtersArray.flat() as FilterParam[])]);
    }
  };

  const handleCacheFilters = (filter: FilterParam[], button: string) => {
    setParams(prevParams => ({
      ...prevParams,
      filter: filter,
      group_count: button,
      page: 1,
    }));
  };

  const handleFilterRequest = (newUrl: string) => {
    localStorage.setItem('rfq_list_general_filter', newUrl);
    sessionStorage.setItem('rfq_list_general_filter', newUrl);
    const filtersString = newUrl.split('&');
    const filters = filtersString.map(function (filter): FilterParam {
      const filterArray = filter.split('=');
      return {
        key: filterArray[0],
        value: filterArray[1] || '',
      };
    });
    setFilters(filters);
    const filter = [...filters, ...quickFilters];
    setParams(prevParams => ({ ...prevParams, filter: filter, page: 1 }));
  };

  const handleLoadedOptions = (
    items: never[],
    dropdown: string,
    loadedOptions: never[],
  ) => {
    const options: Options[] = formatOptions(items, dropdown, loadedOptions);
    switch (dropdown) {
      case 'suppliers':
        setSuppliers([...options]);
        break;
      case 'material':
        setMaterials([...options]);
        break;
      case 'cod_rc':
        setPurchaseRequisitions([...options]);
        break;
      case 'num_rfq':
        setNumRfqs([...options]);
        break;
      case 'buyer_group_id':
        setBuyerGroups([...options]);
        break;
      case 'num_quotation':
        setNumQuotations([...options]);
        break;
      case 'region':
        setRegions([...options]);
        break;
      case 'urgency':
        setUrgencies([...options]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading && <Loading height={'calc(100vh - 88px)'} />}
      <div className="w-full">
        <div className="flex w-full justify-center">
          <div className="w-full pt-5">
            <CustomComponent>
              <CustomFilterPosition>
                <FilterButton
                  buttons={filterButtons[0]}
                  type={'rfq'}
                  order={0}
                  toogleAction={toggleFilterButton}
                />
                <div className="flex ml-5">
                  <FilterButton
                    buttons={filterButtons[1]}
                    type={'rfq'}
                    order={1}
                    toogleAction={toggleFilterButton}
                  />
                </div>
                <div className="ml-5">
                  <Filters
                    filterFields={filterFields}
                    handleResponse={handleFilterRequest}
                    handleOptions={handleLoadedOptions}
                    className={'right-0'}
                    type={'rfq'}
                  />
                </div>
                <div className="flex justify-end w-full">
                  <ListButton
                    keyButtonText={'export_list'}
                    permissionsNeeded={['add_rfq', 'change_rfq']}
                    toogleAction={RfqService.exportCsv}
                    params={params}
                  />
                  {rfqs.length > 0 && (
                    <GoTopButton content={contentRef.current} />
                  )}
                </div>
              </CustomFilterPosition>
              <CustomFilterPosition>
                <FilterButton
                  buttons={filterButtons[2]}
                  type={'rfq'}
                  order={2}
                  toogleAction={toggleFilterButton}
                />
              </CustomFilterPosition>
              <div className="flex px-4 w-full justify-between items-center">
                {fields.map((field, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${field.size || 'flex-1'}`}
                  >
                    <SortIcon
                      order={
                        params.sort === field.sort
                          ? params.order === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => fetchSorted(field)}
                    />
                    <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2">
                      {t(field.label)}
                    </span>
                  </div>
                ))}
                <div className="flex h-4 w-4 ml-2" />
              </div>
            </CustomComponent>
            <Content
              ref={contentRef}
              id="scrollableDiv"
              className="pl-16 flex-1 flex-col overflow-y-auto"
              style={{ height: 'calc(100vh - 236px)' }}
            >
              {rfqs.length === 0 && (
                <div className="mt-6 flex justify-center items-center">
                  <span className="font-default text-sm font-light italic text-300">
                    {t('empty_list')}
                  </span>
                </div>
              )}
              <InfiniteScroll
                dataLength={rfqs?.length || 0}
                hasMore={hasNext}
                next={fetchNextPage}
                loader={<></>}
                scrollableTarget="scrollableDiv"
              >
                {rfqs.map((item, key) => {
                  const FeaturedComponent: React.FC = () => {
                    const labels = [
                      { label: item.num_rfq ?? '', id: item.id },
                      { label: item.quotation_num_round },
                      {
                        label: item.grp_purchasing?.name || '-',
                      },
                      { label: item.suppliers.length },
                      {
                        label: item.item_req_list.length,
                        size: 'md:w-1/12 lg:w-1/12',
                      },
                      {
                        deadline: {
                          sla_deadline: item.item_minor_sla,
                          created_on: item.item_minor_created_at,
                        },
                      },
                      { label: item.best_value_purchase },

                      { label: item.is_possible_automation ? 'Sim' : 'Não' },
                    ];

                    return (
                      <>
                        {labels.map((item, key) => (
                          <span
                            className={`font-default font-normal text-sm text-yaleblue-900 text-left ${
                              item.size || 'flex-1'
                            }`}
                            key={key}
                          >
                            {item.id && (
                              <Link
                                className="underline font-default font-bold text-sm text-bluejeans-800"
                                to={`/quote/show/${item.id}`}
                              >
                                {removeZerosLeft(item.label)}
                              </Link>
                            )}
                            {item.deadline && (
                              <div className="flex items-center">
                                <DeadlineDonut
                                  deadlineDate={item.deadline.sla_deadline}
                                  createdAt={item.deadline.created_on}
                                />
                              </div>
                            )}
                            {!item.id && !item.deadline && (
                              <span>{item.label ? item.label : `-`}</span>
                            )}
                          </span>
                        ))}
                      </>
                    );
                  };

                  const ContentComponent: React.FC = () => {
                    const RfqContentLine: RfqContentLine = {
                      purchase_requisition: [],
                      item_requisition: [],
                      material: [],
                      material_description: [],
                      center: [],
                      urgency: [],
                      sla_deadline: [],
                      ia_orientation: [],
                      ia_detail: [],
                    };
                    for (let i = 0; i < item.item_req_list.length; i++) {
                      const itemRq = item.item_req_list[i];
                      const purchase = itemRq.item_purchase_requisition;
                      const urgency = itemRq.urgency_status;
                      RfqContentLine.purchase_requisition.push(
                        purchase.num_req,
                      );
                      RfqContentLine.item_requisition.push(itemRq.item_req);
                      RfqContentLine.material.push(
                        itemRq.material.cod_material,
                      );
                      const description = itemRq.short_description
                        ? itemRq.short_description.substring(0, 15) + '...'
                        : itemRq.long_description
                        ? itemRq.long_description.substring(0, 15) + '...'
                        : '-';
                      const shortDescription = ` ${description}`;

                      RfqContentLine.material_description.push(
                        shortDescription,
                      );
                      RfqContentLine.center.push(itemRq.branch_region);
                      RfqContentLine.urgency.push(t(urgency));
                      RfqContentLine.sla_deadline.push(
                        moment().to(
                          new Date(
                            Date.parse(itemRq.sla_deadline),
                          ).toDateString(),
                        ),
                      );
                      RfqContentLine.ia_orientation.push(
                        t(formatAICodeRFQ(itemRq.ia_final_decision)),
                      );
                      RfqContentLine.ia_detail.push(
                        `${
                          (itemRq.ia_final_decision_msg &&
                            itemRq.ia_final_decision_msg.substring(0, 14)) ||
                          '-'
                        }...`,
                      );
                    }
                    return (
                      <>
                        <div className="flex flex-row">
                          {Object.entries(RfqContentLine).map((item, key) => (
                            <ContentContainer
                              key={key}
                              className="flex justify-center items-center w-full"
                            >
                              <div className="flex flex-col">
                                <span className="font-default font-normal text-xs text-yaleblue-1000">
                                  {t(item[0])}
                                </span>
                                {item[1].map(
                                  (content: string | number, key: number) => (
                                    <span
                                      key={key}
                                      className="font-default font-normal text-sm text-yaleblue-1000 mt-2.5 truncate"
                                    >
                                      {removeZerosLeft(content || `-`)}
                                    </span>
                                  ),
                                )}
                              </div>
                            </ContentContainer>
                          ))}
                        </div>
                        <div className="flex w-full justify-end mt-12 mb-4">
                          <Link
                            className="underline font-default font-bold text-sm text-bluejeans-800"
                            to={`/rfq/show/${item.id}`}
                          >
                            {t('see_details')}
                          </Link>
                        </div>
                      </>
                    );
                  };

                  return (
                    <ListItem
                      key={key}
                      FeaturedComponent={FeaturedComponent}
                      ContentComponent={ContentComponent}
                      className="mt-2"
                      id={key}
                      hasCheckbox={false}
                    />
                  );
                })}
              </InfiniteScroll>
            </Content>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListRfq;
