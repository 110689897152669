import React from 'react';
import { ReactComponent as SortDescIcon } from '../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../assets/icon-sort-asc.svg';

interface SortIconProps {
  order: string;
  onClick: () => void;
}

const SortIcon: React.FC<SortIconProps> = ({ order, onClick }) => {
  return order === 'asc' ? (
    <SortDescIcon className="cursor-pointer w-3 h-2.5" onClick={onClick} />
  ) : (
    <SortAscIcon className="cursor-pointer w-3 h-2.5" onClick={onClick} />
  );
};

export default SortIcon;
