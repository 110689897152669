import React, { useEffect, useState } from 'react';
import { Container, Content, TableContainer } from './ShowSupplier.style';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../components/Navbar/navbar';
import { ReactComponent as IconBreadcrumb } from '../../../assets/icon-breadcrumb.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-blue.svg';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';
import Loading from '../../../components/Loading/Loading';
import { format } from 'date-fns';

interface TableContent {
  code: string | undefined;
  nickname: string | undefined;
  content: TableContentItems[];
}

interface TableContentItems {
  description: string;
  social_name: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
  address: string;
  zip_code: string;
  district: string;
  phone: string;
  creation_date: string;
  bloq_central: string;
  bloq_emp: string;
  bloq_org: string;
  bloq_pgto: string;
  bloq_total1: string;
  bloq_total2: string;
  contact_name: string;
  delivery_term: string;
  fun_bloq1: string;
  fun_bloq2: string;
  marc_elimi_emp: string;
  marc_elimi_org: string;
  marc_elimi_total: string;
  org_buy: string;
  custom_parameters_formatted: CustomParamsSupplier[];

  [index: string]: string | number | CustomParamsSupplier[];
}

interface Supplier extends TableContentItems {
  id: number;
  code: string;
  nickname: string;

  [key: string]: number | string | CustomParamsSupplier[];
}

interface CustomParamsSupplier {
  field: string;
  description: string;
  value: string;
}

const ShowSupplier: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [tableContent, setTableContent] = useState<TableContent | null>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const showLoader = (load: boolean) => {
    setLoading(load);
  };

  const tableFields = [
    { label: 'description' },
    { label: 'social_name' },
    { label: 'document_number' },
    { label: 'type_supplier' },
    { label: 'status' },
    { label: 'cod_company_erp' },
    { label: 'creation_date' },
    { label: 'state' },
    { label: 'country' },
    { label: 'quality_percent' },
    { label: 'score_serasa' },
    { label: 'PMP' },
    { label: 'saving_percent' },
    { label: 'deadline' },
    { label: 'address' },
    { label: 'zip_code' },
    { label: 'district' },
    { label: 'phone' },
    { label: 'bloq_central' },
    { label: 'bloq_emp' },
    { label: 'bloq_org' },
    { label: 'bloq_pgto' },
    { label: 'bloq_total1' },
    { label: 'bloq_total2' },
    { label: 'contact_name' },
    { label: 'delivery_term' },
    { label: 'fun_bloq1' },
    { label: 'fun_bloq2' },
    { label: 'fun_bloq3' },
    { label: 'marc_elimi_emp' },
    { label: 'marc_elimi_org' },
    { label: 'marc_elimi_total' },
    { label: 'org_buy' },
  ];

  const closeShowSupplier = () => {
    history.push('/suppliers');
  };

  useEffect(() => {
    const customParams = ['fun_bloq3'];

    const formatSupplier = (supplier: Supplier): TableContentItems[] => {
      const skipAttributes = ['id', 'code', 'nickname'];
      const content: TableContentItems[] = [];
      skipAttributes.forEach(attr => delete supplier[attr]);
      supplier.status = t(supplier.status);
      supplier.creation_date = format(new Date(supplier.creation_date), 'P');
      supplier.custom_parameters_formatted.forEach(param => {
        if (customParams.includes(param.field)) {
          supplier[param.field] = param.value;
        }
      });
      content.push(supplier);
      return content;
    };

    async function loadSupplier(): Promise<void> {
      try {
        await api.get(`/suppliers/${id}/`).then(response => {
          const supplier: Supplier = response.data;
          const tableContent: TableContent = {
            code: supplier.code,
            nickname: supplier.nickname,
            content: [],
          };
          tableContent.content = formatSupplier(supplier);
          setTableContent(tableContent);
          showLoader(false);
        });
      } catch (error) {
        console.log(error);
      }
    }

    loadSupplier();
  }, [id, t]);

  return (
    <Container className="flex-1 flex-col bg-primary">
      {loading && <Loading />}
      <Navbar pageTitle="" selectedMenu="suppliers" />
      <div className="flex items-center px-12 xl:px-20">
        <span className="font-default font-normal text-white text-sm">
          {t('suppliers')}
        </span>
        <IconBreadcrumb className="h-2 w-2 mx-4" />
        <span className="font-default font-semibold text-white text-sm">
          {t('supplier')}
        </span>
      </div>
      <div className="flex overflow-y-auto w-full px-12 xl:px-20 justify-center">
        <Content className="w-full flex-col flex flex-1 mt-6 bg-white px-12 pt-6 h-screen">
          <div className="flex justify-between">
            <span className="font-default text-xl text-yaleblue-800 font-semibold">
              {t('supplier_details')}
            </span>
            <CloseIcon
              className="h-3 w-3 cursor-pointer"
              onClick={() => closeShowSupplier()}
            />
          </div>
          <div className="w-full bg-bluejeans-500 h-10 rounded-t-sm text-white text-base font-semibold items-center pl-6 mt-10 flex">
            {tableContent?.code} - {tableContent?.nickname}
          </div>
          <TableContainer className="overflow-x-auto whitespace-nowrap mb-14">
            <table className="w-full mb-4">
              <thead>
                <tr>
                  {tableFields.map((item, key) => (
                    <th
                      key={key}
                      className="border border-yaleblue-transparent rounded-t-xl items-center font-semibold text-yaleblue-800 px-4 justify-between py-4"
                    >
                      <div className="flex">{t(item.label)}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableContent?.content.map((item, key) => (
                  <tr key={key}>
                    {tableFields.map((value, key) => (
                      <td
                        className="border border-yaleblue-transparent font-semibold text-yaleblue-800 px-4 text-center h-14"
                        key={key}
                      >
                        <div className="-my-0.5 bg-basic-gray-transparent py-4 text-base">
                          {item[value.label] || '-'}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Content>
      </div>
    </Container>
  );
};

export default ShowSupplier;
