import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  en: {
    translations: {
      first_name: 'Usuário',
      send_manual: 'Para envio cotação manual',
      pending_purchase: 'Requisições compras pendentes',
      quote_automation: 'Cobertas pelo quote automation',
      rfq_closed: "RFQ's encerradas",
      no_answer_supplier: 'Sem resposta fornecedor',
      manual_decision: 'Decisão manual',
      waiting_response: 'Aguardando Resposta',
      finished: 'Finalizado',
      order_failed: 'Ordem Falhou',
      resolved_externally: 'Resolvido Externamente',
      urgency_need: 'Urgência necessidade',
      suppliers: 'Suppliers',
      dashboard: 'Dashboard',
      history_data: 'Dados históricos',
      supplier_details: 'Supplier Details',
      see_details: 'See details',
      rfq_details: 'RFQ details',
      items_not_found: 'Items not found',
      purchase_panel: 'Purchasing Panel',
      configurations: 'Configurations',
      home: 'Home',
      applied_filters: 'Applied filters',
      add_to_favorites: 'Add to favorites',
      clear_filters: 'Clean filters',
      apply: 'Apply',
      filters: 'Filters',
      recently_used: 'Recently used',
      favorites: 'Favorites',
      supplier_code: 'Supplier code',
      Search: 'Search',
      vendor_list: 'Vendor list',
      code: 'Code',
      social_name: 'Social name',
      nickname: 'Nickname',
      document_number: 'Document number',
      supplier_type: 'Supplier type',
      supplier_status: 'Supplier status',
      uf: 'UF',
      empty_list: 'There is nothing to show',
      export_list: 'Export list',
      iqf: '% IQF (Supplier quality index)',
      serasa_score: 'SERASA SCORE',
      pmp: 'Avarage payment period (PMP)',
      saving: 'Saving %',
      deadline: 'Deadline',
      select_all: 'Select all',
      buyer_group: 'Buyer group',
      material: 'Material',
      region: 'Region',
      category: 'Category',
      merchandise_group: 'Mershandise Group',
      buyer_groups: 'Buyer groups',
      materials: 'Materials',
      categories: 'Categories',
      merchandise_groups: 'Mershandise Groups',
      actions: 'Actions',
      inactive: 'Inactive',
      ation: 'Active',
      inactivate: 'Inactivate',
      activate: 'Activate',
      edit: 'Edit',
      load_vendor_list: 'Load vendor list',
      load_purchaserequisition: 'Load Purchase Requisition',
      create_vendor_list: 'Create Vendor list',
      edit_vendor_list: 'Edit Vendor List',
      creating_vendor_list: 'Creating Vendor list',
      select_category: 'Select Category',
      select_quotations: 'Select Quotations',
      select_st_control: 'Select Status',
      RFQ_SUCCESS: 'Success',
      RFQ_ORDER_FAILED: 'Order Failed',
      RFQ_RESOLVED_EXTERNALLY: 'Resolved Externally',
      purchase_requisition: 'Purchase requisition',
      rfq: "RFQ's",
      requisition_item: 'Requisition item',
      material_description: 'Material description',
      center: 'center',
      urgency: 'Urgency',
      ai_status_result_detail: 'AI detail',
      refqs_number: "RFQ's number",
      buyer: 'buyer',
      suppliers_number: 'Suppliers number',
      items: 'Items',
      ia_status_result: 'AI status result',
      best_value_purchase: 'Best value purchase',
      automation: 'Automation',
      filter: 'Filter',
      rfq_number: 'RFQ number',
      purchase_number: 'Purchase number',
      supplier: 'Supplier',
      my_rfqs: 'My',
      all_rfqs: 'All',
      awaiting_response: 'Awaiting response',
      answered: 'Answered',
      failure: 'Failure',
      sla_deadline: 'SLA deadline',
      suggested_categories: 'Suggested categories',
      bind_buyer_group: 'Bind Buyer Group',
      material_plural: 'Materials',
      bind_material: 'Bind Material',
      suggested_materials: 'Suggested materials',
      import_csv: 'Import CSV',
      select_region: 'Select Region',
      select_warehouse: 'Select Warehouse',
      has_vendor_list: 'Vendor List',
      suggested_regions: 'Suggested regions',
      select_merchandise_group: 'Select Merchandise Group',
      suggested_groups: 'Suggested groups',
      select_suppliers: 'Select suppliers',
      suggested_suppliers: 'Suggested suppliers',
      cancel: 'Cancel',
      confirm_vendor_list: 'Confirm vendor list',
      region_required: 'Region is required',
      requisition: 'Requisition',
      item: 'Item',
      item_with_count: '{{count}} item',
      item_with_count_plural: '{{count}} items',
      description: 'Description',
      merchandise_gp: 'Mercadoria GP',
      remittance_dt: 'Remittance Dt',
      pending: 'Pending',
      all: 'All',
      Yes: 'Yes',
      No: 'No',
      not_selected: 'Not Selected',
      score_serasa: 'Score serasa',
      payment_deadline: 'Payment deadline',
      fqi: 'FQI',
      saving_percent: 'Saving %',
      new_quote: 'New quote',
      creating_new_quote: 'Creating new quote',
      requests: 'Requests',
      requests_selecteds: 'Selecteds requests',
      create_quote: 'Create quote',
      mark_resolved: 'Mark Resolved',
      urgency_: 'Urgency',
      ia_detail: 'AI detail',
      item_requisition: 'Item requisition',
      item_req: 'Item',
      last_purchase_number: 'Last purchase number',
      date: 'Date',
      price: 'Price',
      long_description: 'Long description',
      rc_creation_date: 'RC creation date',
      quantity: 'Quantity',
      status: 'Status',
      by: 'BY',
      last_purchase: 'Last purchase',
      unit_price: 'Unit price',
      add_observation_to_quote: 'Add a observation to that quote',
      observation: 'Observation',
      limit_date: 'Deadline',
      closing_time: 'Closing time',
      note: 'Note',
      attach_file: 'Attach file',
      created_rfq: " RFQ created! See more at RFQ's",
      created_rfq_vendor: " RFQ and Vendor List created! See more at RFQ's",
      confirmation_modal: 'Ok, I got it',
      lead_time: 'Lead Time',
      new_requote: 'New Requote',
      creating_new_requote: 'Creating new requote',
      include_target: 'Include target',
      target: 'Target',
      items_selecteds: 'items selecteds',
      view_by_round: 'View by round',
      history_view: 'History View',
      quotation_round: 'Quotation round',
      closing_date_rfq: 'Closing date RFQ',
      priority: 'Priority',
      resume_suggestion_ai: 'Resume of AI suggestion',
      accept_suggestion_ai: 'Accept suggestion AI',
      email: 'Email',
      password: 'Password',
      new_password: 'Nova Senha',
      confirm_password: 'Confirmação de Nova Senha',
      login: 'Login',
      keep_me_logged: 'Keep me logged',
      forgot_my_password: 'Forgot my password',
      change_password_text:
        'Como esse é o seu primeiro acesso é necessário que você troque a sua senha temporário por uma definitiva abaixo.',
      forgot_my_password_text:
        'Informe abaixo o e-mail vinculado ao seu login e conta e, se estiver correto, você irá receber uma mensagem com as instruções para recuperar a senha. Caso não lembre seu e-mail de acesso, contato o administrador do sistema.',
      expired_password_text:
        'Sua senha expirou. Por favor troque por uma nova.',
      need_help: 'Need_help',
      terms_of_use: 'Terms of use',
      privacy_policy: 'Privacy policy',
      requote_limit_date: 'Requote limit date',
      rfq_closing_time: 'RFQ closing time',
      payment_method: 'Payment method',
      create_new_requote: 'Create new requote',
      requote_created: 'Requote created!',
      requote_created_vendor: 'Requote and Vendor list created!',
      items_deadline_null: 'The remittance Dt and lead time must be filled',
      deadline_null_error: 'The deadline must be filled',
      suppliers_null_error: 'Select at least one supplier',
      items_null_error: 'No request selecteds',
      payment_method_null_error: 'Payment method must be filled',
      logout: 'Logout',
      quotation_detail: 'Quotation detail',
      rewarding_item: 'Rewarding item',
      rewarding_items: 'Rewarding items',
      reward_items: 'Reward Items',
      selected_items: 'Selected items',
      quotation_number: 'Quotation number',
      un: 'UN',
      net_unitary_price: 'Net unitary price',
      requisition_number: 'Requisition number',
      creation_date_rc: 'Creation date RC',
      taxes: 'Taxes',
      tax_substitution: 'Tax substitution',
      icms: 'ICMS',
      ipi: 'IPI',
      country: 'Country',
      request_type: 'Tp request',
      approver_observation: 'Approver observation',
      incoterm: 'Incoterm',
      origin: 'Origin',
      remove_selected: 'Remove selected',
      forgot_password: 'Recuperação de Senha',
      expired_password: 'Senha Expirada',
      round: 'Round',
      responsible_buyer: 'Responsible buyer',
      remaining: 'Remaining',
      historic_data: 'Base histórica',
      short_description: 'Short description',
      buyers: 'Buyers',
      purchase_document: 'Purchase document',
      supplier_name: 'Supplier name',
      request_date: 'Request date',
      payment_condition: 'Payment condition',
      items_by_supplier: 'Items by supplier',
      rfq_detailing: 'RFQ detailing',
      quotation_item: 'Quotation item',
      supplier_state: 'Supplier state',
      remittance_date: 'Remittance date',
      material_origin: 'Material origin',
      net_unit_price: 'Net unit price',
      net_total_price: 'Net total price',
      delivery_term: 'Delivery term',
      lt_delivery: 'Lt delivery',
      last_purchase_unit_price: 'Net unit price (last purchase)',
      last_purchase_by: 'By (last purchase)',
      last_purchase_date: 'Last purchase date',
      ai_suggestion: 'AI suggestion',
      ai_detail: 'AI detail',
      end_date_rfq: 'End date RFQ',
      reward_quotation: 'Reward quotation',
      new_round: 'New round',
      help_buyer: 'Help/buyer',
      invalid: 'Invalid Response',
      reward: 'Reward',
      ai_suggestions: 'AI Suggestions',
      target_value: 'Target value',
      item_baseline_value: 'Baseline value',
      item_rc_evaluation_value: 'Valuation price PR',
      num_gp: 'Num GP',
      VENDOR_NOTE: 'VN',
      order: 'Order',
      unit_value: 'Unit value',
      reward_selecteds: 'Reward selecteds',
      new_selecteds_round: 'New selecteds round',
      cancel_selecteds: 'Cancel selecteds',
      days: 'days',
      shipping_total_value: 'Shipping total value',
      rfq_total_value: 'RFQ Total value',
      reduction: 'reduction',
      continue: 'Continue',
      process_id: 'Process id',
      status_integration: 'Integration status',
      try: 'Try',
      process: 'process',
      integration_panel: 'Integration panel',
      rules: 'Rules',
      users: 'Users',
      error_detail: 'Error detail',
      define_parameters: 'Define parameters',
      define_suppliers: 'Define suppliers',
      define_items: 'Define items',
      total_value: 'Total value',
      type_supplier: 'Supplier type',
      quality_percent: 'Quality percent',
      reduction_percentage: 'Reduction percentage',
      custom_parameters: 'Custom parameters',
      state: 'State',
      num_req: 'Requisition number',
      is_active: 'Is ation',
      purchase_requisition_number: 'Purchase requisition number',
      created_on: 'Created on',
      is_vendor_list: 'Is vendor list',
      urgency_status: 'Urgency status',
      branch_region: 'Branch region',
      warehouse: 'Warehouse',
      last_purchase_price: 'Last purchase price',
      last_purchase_id: 'Last purchase id',
      last_purchase_supplier: 'Last purchase supplier',
      last_purchase_nick_name: 'Last purchase nickname',
      last_purchase_name: 'Last purchase name',
      last_purchase_minimum_lot: 'Last purchase minimum lot',
      last_purchase_vl_priceunit: 'Last purchase value priceunit',
      last_purchase_order_number: 'Last purchase order number',
      mercadory_category: 'Mercadory category',
      performance_by_round: 'Performance by round',
      select_item: 'Select item',
      confirmation_selected_reward_items:
        'You have selected {{count}} item for the award. Are you sure you want to continue?',
      confirmation_selected_reward_items_plural:
        'You have selected {{count}} items for the award. Are you sure you want to continue?',
      parameters_view: 'Parameter views by item',
      check_all: 'Check all',
      uncheck_all: 'Uncheck all',
      remove_unchecked_items: 'Remove unchecked items',
      confirmation_selected_parameters:
        'You have selected {{count}} item for viewing your list. Are you sure you want to continue?',
      confirmation_selected_parameters_plural:
        'You have selected {{count}} items for viewing your list. Are you sure you want to continue?',
      confirmation_selected_all_parameters:
        'You are checking all items for viewing your list. Are you sure you want to continue?',
      show_selecteds: 'Show selecteds',
      ai_suggestions_rounds: 'AI suggestion in the rounds',
      closing: 'Closing',
      total_value_freight: 'Total value freight',
      total_value_rfq: 'Total value RFQ',
      qta: 'QTA',
      vuo: 'VUO',
      ipp: 'IPP',
      icm: 'ICM',
      icp: 'ICP',
      pzo: 'PZO',
      prz: 'PRZ',
      vto: 'VTO',
      dsc: 'DSC',
      vup: 'VUP',
      mar: 'MAR',
      pcd: 'PCD',
      nc1: 'NC1',
      nc2: 'NC2',
      rec: 'REC',
      hours: 'hours',
      creating_new_round: 'Creating new round',
      supplier_note: 'Supplier note',
      create_round: 'Create round',
      last_purchase_nickname: 'Nickname',
      last_purchase_social_name: 'Social name',
      last_purchase_item: 'Item',
      my_plural: 'My',
      confirmation_delete_supplier:
        'Are you sure you want to delete {{supplier_name}} from your list?',
      confirmation_selected_reward_items_list:
        'You are awarding <strong>{{count}}</strong> items from your list. Are you sure you want to continue?',
      mercosur_common_nomenclature: 'MCN',
      value_added_tax: 'VAT',
      delete: 'Delete',
      remove: 'Remove',
      ia_suggestion_reward:
        'Do you want to <strong>perform the actions suggested by the AI</strong> ​​for all items?',
      low: 'Low',
      mid: 'Middle',
      high: 'High',
      manual: 'Manual',
      created_manually: 'Created manually',
      attended_ai: 'Attended AI',
      quote_number: 'Quote number',
      ai_targeting: 'AI targeting',
      is_automation: 'Is automation',
      st_control: 'Status',
      num_rfq: 'RFQ number',
      rounds: 'Rounds',
      shipping_date: 'Remittance Dt',
      condition: 'condition',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      orders: 'Orders',
      reprocess: 'Reprocess',
      sent_to_reprocess: 'Sent to reprocess!',
      request_error: 'Ooops! It happened an error',
      success_upload: 'Arquivo carregado com sucesso!',
      success_create_password: 'Senha criada com sucesso!',
      success_change_password: 'Senha alterada com sucesso!',
      success: 'Success',
      email_send: 'Email enviado com sucesso!',
      purchase_requisition_id: 'Purchase requisition',
      buyer_group_id: 'Buyer group',
      num_quotation: 'Quotation number',
      sla_days: 'Days',
      sla_hours: 'Hours',
      address: 'Address',
      zip_code: 'ZIP Code',
      district: 'District',
      phone: 'Phone',
      bloq_central: 'Central Lock Elimination',
      bloq_emp: 'Accounting for business Lock',
      bloq_org: 'Purchasing Block for Purchasing Organization',
      bloq_pgto: 'Payment Block',
      bloq_total1: 'Accounting Central Lock',
      bloq_total2: 'Buy Central Lock',
      contact_name: 'Seller',
      fun_bloq1: 'Lock Function',
      fun_bloq2: 'Lock Function 2',
      fun_bloq3: 'Company Deletion Lock',
      creation_date: 'Creation date',
      cod_company_erp: 'Company',
      marc_elimi_emp: 'Deletion Marking for Company',
      marc_elimi_org: 'Deletion Marking for Purchasing Organization',
      marc_elimi_total: 'Central Deletion Marking',
      org_buy: 'Purchasing Organization',
      quotation_num_round: 'Round',
      rfq_ia_code_reward_automatic: 'Reward (automatic)',
      rfq_ia_code_reward_manual: 'Reward (manual)',
      rfq_ia_code_new_round_automatic: 'New Round (automatic)',
      rfq_ia_code_new_round_manual: 'New Round (manual)',
      rfq_ia_code_buyer_help: 'Buyer Help',
      rfq_ia_code_extend_rfq_deadline: 'Extend RFQ deadline',
      today: 'Today',
      undefined: 'Undefined',
      select_buyer_group: 'Select Buyer Group',
      select_material: 'Select Material',
      select_supplier_type: 'Select Type of Supplier',
      select_status: 'Select Status',
      select_urgency: 'Select Urgency',
      select_type: 'Select Type',
      select_supplier: 'Select Supplier',
      sla_remaining: 'SLA Remaining',
      select: 'Select...',
      invalid_credencial_error:
        'The e-mail or password credentials are incorrect',
      status_server_error: 'Something is wrong. Try again.',
      not_allowed: 'You don`t have permission to access this page',
      page_not_found: 'Page not found',
      go_home: 'Got to home',
      try_again: 'Try again',
      go_back: 'Go back',
      error: 'Error',
      confirmation_cancel_items:
        'Are you sure you want to cancel the selected items?',
      import_file_with_errors:
        'The file could not be imported because it contains errors. Correct them and try again.',
      validate_suppliers: 'Validate Suppliers',
      validate_groups: 'Validate Groups',
      validate_warehouses: 'Validate Warehouse',
      validate_categories: 'Validate Categories',
      validate_materials: 'Validate Materials',
      validate_merchandise: 'Validate Groups',
      validate_centers: 'Validate Centers',
      validate: 'Validate',
      invalid_fields: 'Invalid fields:',
      fill_one_field: 'Fill in at least one field',
      empty_supplier: '"Suppliers" field is empty in vendor lists',
      rfq_status_cleared: "Items moved to RFQ's",
      warning: 'Warning',
    },
  },
  'pt-BR': {
    translations: {
      name: 'Nome',
      update: 'Atualizar',
      user: 'usuário',
      new_user: 'Novo usuário',
      update_user_error_status: 'Erro ao atualizar status',
      update_user_success_status: 'Status atualizado com sucesso',
      get_user_error: 'Erro ao buscar usuário',
      get_gp_buyer: 'Erro ao buscar grupo comprador',
      update_user_error_message: 'Erro ao atualizar usuário',
      update_user_success_message: 'Usuário atualizado com sucesso',
      add_user_error_message: 'Erro ao criar usuário',
      add_user_success_message: 'Usuário adicionado com sucesso',
      userName: 'Nome do usuário',
      emailUser: 'E-mail do usuário',
      teamUser: 'Equipe do usuário',
      sapNumberUser: 'Número SAP do usuário',
      action: 'Ações',
      user_list: 'Lista de usuários',
      registering_user: 'Cadastrando novo usuário',
      edit_user: 'Editar usuário',
      certificate_identity: 'Empresa',
      general_registration_number: 'Perfil',
      show_profile: 'Ver perfil',
      sapNumber: 'Número SAP',
      team: 'Equipe',
      dateStart: 'De',
      add: 'Adicionar',
      dateFinal: 'Até',
      profile: 'Perfil',
      manual_decision: 'Decisão manual',
      waiting_response: 'Aguardando Resposta',
      finished: 'Finalizado',
      order_failed: 'Ordem Falhou',
      resolved_externally: 'Resolvido Externamente',
      suppliers: 'Fornecedores',
      supplier_details: 'Detalhe do Fornecedor',
      see_details: 'Ver detalhes',
      rfq_details: 'Detalhes da RFQ',
      items_not_found: 'Nenhum item encontrado',
      purchase_panel: 'Painel de compras',
      configurations: 'Configurações',
      home: 'Home',
      applied_filters: 'Filtros Aplicados',
      add_to_favorites: 'Favoritar',
      clear_filters: 'Limpar filtros',
      apply: 'Aplicar',
      filters: 'Filtros',
      recently_used: 'Usados recentemente',
      favorites: 'Favoritos',
      supplier_code: 'Código do fornecedor',
      Search: 'Buscar',
      vendor_list: 'Vendor list',
      code: 'Código',
      social_name: 'Razão social',
      nickname: 'Nome fantasia',
      document_number: 'CNPJ',
      supplier_type: 'Tipo fornecedor',
      supplier_status: 'Status fornecedor',
      uf: 'UF',
      empty_list: 'Não há nada para ser exibido',
      export_list: 'Exportar lista',
      iqf: '% IQF (Índice de Qualidade do Fornecedor)',
      serasa_score: 'SCORE SERASA',
      pmp: 'Prazo Médio de Pgto (PMP)',
      saving: 'Saving R$',
      deadline: 'Prazo de entrega',
      select_all: 'Selecionar tudo',
      buyer_group: 'GP Comprador',
      material: 'Material',
      region: 'Centro',
      category: 'Categoria',
      merchandise_group: 'Grupo de Mercadoria',
      buyer_groups: 'GP Comprador',
      materials: 'Material',
      categories: 'Categoria',
      merchandise_groups: 'Grupo de Mercadoria',
      actions: 'Ações',
      inactive: 'Inativo',
      active: 'Ativo',
      inactivate: 'Inativar',
      activate: 'Ativar',
      edit: 'Editar',
      load_supplier: 'Carregar Fornecedores',
      load_vendor_list: 'Carregar vendor list',
      load_purchaserequisition: 'Carregar Requisição de Compra',
      create_vendor_list: 'Criar Vendor list',
      edit_vendor_list: 'Editar Vendor list',
      creating_vendor_list: 'Criando Vendor list',
      select_category: 'Selecionar Categoria',
      select_quotations: 'Selecionar Cotação',
      select_st_control: 'Selecionar Status',
      RFQ_SUCCESS: 'Sucesso Emissão Pedido',
      RFQ_ORDER_FAILED: 'Falha na Criação do Pedido',
      RFQ_RESOLVED_EXTERNALLY: 'Resolvido no SAP',
      purchase_requisition: 'Requisição de compra',
      rfq: "RFQ's",
      requisition_item: 'Item Requisição',
      material_description: 'Texto breve',
      center: 'Centro',
      urgency: 'Urgência',
      ai_status_result_detail: 'Detalhamento IA',
      refqs_number: "N das RFQ's",
      buyer: 'Comprador',
      suppliers_number: 'N° Forn.',
      items: 'Itens',
      ia_status_result: 'Dir IA',
      best_value_purchase: 'Valor melhor cotação',
      automation: 'Sujeito a automação',
      filter: 'Filtro',
      rfq_number: 'Número da RFQ',
      purchase_number: 'Número da cotação',
      supplier: 'Fornecedor',
      my_rfqs: 'Minhas',
      all_rfqs: 'Todas',
      awaiting_response: 'Aguardando resposta',
      answered: 'Respondidas',
      failure: 'Falhas',
      sla_deadline: 'SLA restante',
      suggested_categories: 'Categorias sugeridas',
      bind_buyer_group: 'Vincular grupo comprador',
      material_plural: 'Materiais',
      bind_material: 'Vincular Material',
      suggested_materials: 'Materiais sugeridos',
      import_csv: 'Importar CSV',
      select_region: 'Selecionar Centro',
      select_warehouse: 'Selecionar Depósito',
      has_vendor_list: 'Vendor List',
      suggested_regions: 'Centros sugeridos',
      select_merchandise_group: 'Escolher Grupo de Mercadoria',
      suggested_groups: 'Grupos sugeridos',
      select_suppliers: 'Selecionar fornecedores',
      suggested_suppliers: 'Fornecedores sugeridos',
      cancel: 'Cancelar',
      save: 'Salvar',
      password_rule_1: 'No mínimo 6 letras;',
      password_rule_2: 'Um caracteres especial (@, $, %, &, @, etc.).',
      password_rule_3: 'Pelo menos um número.',
      password_rules: 'Lembre-se que a sua senha deve conter:',
      confirm_vendor_list: 'Confirmar vendor list',
      region_required: 'Centro é obrigatório',
      requisition: 'Requisição',
      item: 'Item',
      item_with_count: '{{count}} item',
      item_with_count_plural: '{{count}} itens',
      description: 'Descrição',
      merchandise_gp: 'GP mercadoria',
      remittance_dt: 'Dt remessa',
      pending: 'Pendente',
      all: 'Todos',
      Yes: 'Sim',
      No: 'Não',
      not_selected: 'Não selecionado',
      score_serasa: 'Score serasa',
      payment_deadline: 'Prazo médio pagamento',
      fqi: 'IQF',
      saving_percent: 'Saving %',
      new_quote: 'Nova cotação',
      creating_new_quote: 'Criando nova cotação',
      requests: 'Requisições',
      requests_selecteds: 'Requisições selecionadas',
      requests_selected_different_regions:
        '(Centros diferentes selecionados! Por favor selecione os de mesmo Centro)',
      create_quote: 'Criar cotação',
      mark_resolved: 'Marcar resolvido',
      urgency_: 'Urgência',
      ia_orientation: 'Direcionamento IA',
      ia_detail: 'Detalhamento IA',
      item_requisition: 'Item requisição',
      item_req: 'Item',
      last_purchase_number: 'N° Pedido',
      date: 'Data',
      price: 'Preço',
      long_description: 'Descritivo completo',
      rc_creation_date: 'Data de liberação da RC',
      quantity: 'Quantidade',
      status: 'Status',
      by: 'POR',
      last_purchase: 'Última compra',
      unit_price: 'Preço líq unitário',
      add_observation_to_quote: 'Adicionar uma observação a essa cotação',
      observation: 'Observação',
      limit_date: 'Data Encerramento RFQ',
      closing_time: 'Hora de encerramento',
      note: 'Texto de cabeçalho',
      attach_file: 'Anexar arquivo',
      created_rfq: " RFQ criada! Acompanhe na aba `RFQ's`",
      created_rfq_vendor:
        " RFQ e Vendor list criadas! Acompanhe na aba `RFQ's`",
      confirmation_modal: 'Ok, entendi',
      deadline_modal_error:
        'A data de remessa não pode ser menor que a data limite',
      lead_time: 'Lead Time',
      new_requote: 'Nova rodada',
      creating_new_requote: 'Criando nova rodada',
      include_target: 'Adicionar target',
      target: 'Target',
      items_selecteds: 'itens selecionados',
      view_by_round: 'Visualização por rodada',
      history_view: 'Visualização Histórica',
      quotation_round: 'Cotação rodada',
      closing_date_rfq: 'Data encerramento da RFQ',
      priority: 'Prioridade',
      resume_suggestion_ai: 'Resumo da sugestão da IA',
      accept_suggestion_ai: 'Aceitar sugestão da IA',
      email: 'Email',
      password: 'Senha',
      login: 'Entrar',
      keep_me_logged: 'Manter-me logado',
      start_session: 'Login',
      microsoft_account: 'Conta Microsoft',
      forgot_my_password: 'Esqueci minha senha',
      need_help: 'Precisa de ajuda',
      terms_of_use: 'Termos de uso',
      privacy_policy: 'Política de privacidade',
      requote_limit_date: 'Data limite da rodada',
      rfq_closing_time: 'Hora de encerramento da RFQ',
      payment_method: 'Condição de pagamento',
      create_new_requote: 'Criar nova rodada',
      requote_created: 'Nova rodada criada!',
      requote_created_vendor: 'Nova rodada e Vendor list criadas!',
      items_deadline_null:
        'A data de remessa e o lead time devem ser preenchidos',
      deadline_null_error: 'A data limite da rodada deve ser preenchida',
      suppliers_null_error: 'Selecione ao menos um fornecedor',
      items_null_error: 'Não há requisições selecionadas',
      payment_method_null_error: 'Selecione uma condição de pagamento',
      logout: 'Sair',
      quotation_detail: 'Detalhe da cotação',
      rewarding_item: 'Premiando item',
      rewarding_items: 'Premiando itens',
      reward_items: 'Premiar itens',
      selected_items: 'Itens selecionados',
      quotation_number: 'Número da cotação',
      un: 'UN',
      net_unitary_price: 'Preço líq uni',
      requisition_number: 'Número da requisição',
      creation_date_rc: 'Data de liberação da RC',
      taxes: 'Impostos',
      tax_substitution: 'Subs tributária',
      icms: 'ICMS',
      // ipi: 'IPI',
      country: 'País',
      request_type: 'Tp pedido',
      approver_observation: 'Nota cabeçalho',
      incoterm: 'Incoterm',
      origin: 'Origem',
      remove_selected: 'Remover selecionados',
      round: 'Rodada',
      responsible_buyer: 'Comprador responsável',
      remaining: 'Restantes',
      historic_data: 'Base histórica',
      short_description: 'Texto breve',
      buyers: 'Compradores',
      purchase_document: 'Doc Compra',
      supplier_name: 'Nome Forn.',
      request_date: 'Dt pedido',
      payment_condition: 'Condição pgto',
      items_by_supplier: 'Items por fornecedor',
      rfq_detailing: 'Detalhamento da RFQ',
      quotation_item: 'Item Cotação',
      supplier_state: 'UF Fornecedor',
      remittance_date: 'Data remessa',
      material_origin: 'Origem Material',
      net_unit_price: 'Preço Liq Unitário',
      net_total_price: 'Liq total',
      delivery_term: 'Incoterms',
      lt_delivery: 'Lt entrega',
      last_purchase_unit_price: 'Preço Liq unitário (última compra)',
      last_purchase_by: 'Por (última compra)',
      last_purchase_date: 'Data última compra',
      ai_suggestion: 'Direcionamento IA',
      ai_detail: 'Detalhamento IA',
      end_date_rfq: 'Data de encerramento RFQ',
      quotation: 'cotação',
      reward_quotation: 'Premiar quotação',
      new_round: 'Nova rodada',
      help_buyer: 'Ajuda/comprador',
      invalid: 'Sem Resposta Válida',
      reward: 'Premiar',
      ai_suggestions: 'Sugestões da IA',
      target_value: 'Preço target',
      item_baseline_value: 'Valor Baseline',
      item_rc_evaluation_value: 'Preço Avaliação RC',
      num_gp: 'Num GP',
      VENDOR_NOTE: 'VN',
      order: 'Pedido',
      unit_value: 'Valor unitário',
      reward_selecteds: 'Premiar selecionados',
      new_selecteds_round: 'Nova rodada selecionados',
      cancel_selecteds: 'Cancelar Selecionados',
      days: 'dias',
      shipping_total_value: 'Valor frete total',
      rfq_total_value: 'Valor total RFQ',
      reduction: 'de redução',
      continue: 'Continuar',
      process_id: 'Id Processo',
      status_integration: 'Status Integração',
      try: 'Tentativa',
      process: 'Processar',
      integration_panel: 'Painel integração',
      rules: 'Regras',
      users: 'Usuários',
      error_detail: 'Detalhamento do erro',
      define_parameters: 'Definir parâmetros',
      define_suppliers: 'Definir Fornecedores',
      define_items: 'Definir itens',
      total_value: 'Valor total',
      type_supplier: 'Tipo fornecedor',
      quality_percent: 'Percentual de qualidade',
      reduction_percentage: 'Porcentual de redução',
      custom_parameters: 'Parametros customizados',
      state: 'Estado',
      num_req: 'Número da requisição',
      is_active: 'Ativo',
      purchase_requisition_number: 'Número da requisição de compra',
      created_on: 'Criado em',
      is_vendor_list: 'Vendor List',
      urgency_status: 'Urgência',
      branch_region: 'Região',
      warehouse: 'Depósito',
      warehouses: 'Depósitos',
      last_purchase_price: 'Preço última compra',
      last_purchase_id: 'Id última compra',
      last_purchase_supplier: 'Fornecedor  última compra',
      last_purchase_nick_name: 'Nome fantasia última compra',
      last_purchase_name: 'Nome última compra',
      last_purchase_minimum_lot: 'Lote mínimo última compra',
      last_purchase_vl_priceunit: 'Preço unitário última compra',
      last_purchase_order_number: 'Número última compra',
      mercadory_category: 'Categoria do produto',
      performance_by_round: 'Desempenho por rodada',
      select_item: 'Selecionar item',
      confirmation_selected_reward_items:
        'Você selecionou {{count}} item para a premiação Tem certeza que deseja continuar?',
      confirmation_selected_reward_items_plural:
        'Você selecionou {{count}} itens para a premiação Tem certeza que deseja continuar?',
      parameters_view: 'Visualizações de parâmetros por item',
      check_all: 'Marcar todos',
      uncheck_all: 'Desmarcar todos',
      remove_unchecked_items: 'Remover itens desmarcados',
      confirmation_selected_parameters:
        'Você selecionou {{count}} item para visualização da sua lista. Tem certeza que deseja continuar?',
      confirmation_selected_parameters_plural:
        'Você selecionou {{count}} itens para visualização da sua lista. Tem certeza que deseja continuar?',
      confirmation_selected_all_parameters:
        'Você está marcando todos os itens para visualização da sua lista. Tem certeza que deseja continuar?',
      show_selecteds: 'Visualizar selecionados',
      ai_suggestions_rounds: 'Sugestões da IA nas rodadas',
      closing: 'Encerramento',
      total_value_freight: 'Valor total do frete',
      total_value_rfq: 'Valor total RFQ',
      qta: 'QTA',
      // vuo: 'VUO',
      // ipp: 'IPP',
      // icm: 'ICM',
      // icp: 'ICP',
      pzo: 'PZO',
      prz: 'PRZ',
      // vto: 'VTO',
      // dsc: 'DSC',
      // vup: 'VUP',
      // mar: 'MAR',
      pcd: 'PCD',
      // nc1: 'NC1',
      // nc2: 'NC2',
      rec: 'REC',
      hours: 'horas',
      creating_new_round: 'Criando nova rodada',
      supplier_note: 'Nota do fornecedor',
      create_round: 'Criar nova rodada',
      last_purchase_nickname: 'Nome fantasia',
      last_purchase_social_name: 'Razão social',
      last_purchase_item: 'Item',
      last_purchase_minimum: 'POR',
      last_purchase_vl_unit_price: 'Preço líq unitário',
      last_purchase_creation: 'Data',
      last_purchase_supplier_name: 'Fornecedor',
      created_new_round: 'Nova rodada criada',
      my_plural: 'Minhas',
      confirmation_delete_supplier:
        'Você tem certeza que deseja excluir {{supplier_name}} da sua lista?',
      confirmation_selected_reward_items_list:
        'Você está premiando <strong>{{count}}</strong> itens da sua lista. Tem certeza que deseja continuar?',
      mercosur_common_nomenclature: 'NCM',
      value_added_tax: 'IVA',
      delete: 'Excluir',
      remove: 'Remover',
      ia_suggestion_reward:
        'Você deseja <strong>executar as ações sugeridas pela IA</strong> para todos os itens?',
      low: 'Baixa',
      mid: 'Média',
      high: 'Alta',
      normal: 'Normal',
      risk: 'Em risco',
      overdue: 'Vencido',
      manual: 'Manual',
      created_manually: 'Criados manualmente',
      attended_ai: 'Atendidos IA',
      quote_number: 'Número cotação',
      ai_targeting: 'Direcionamento IA',
      is_automation: 'Sujeito a automação',
      st_control: 'Status',
      num_rfq: 'Número RFQ',
      cod_rc: 'Código requisição',
      rounds: 'Rodadas',
      shipping_date: 'Dt remessa',
      condition: 'Condição',
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro',
      orders: 'Pedidos',
      reprocess: 'Reprocessar',
      sent_to_reprocess: 'Enviado para reprocessar',
      request_error: 'Ops, ocorreu um erro ao processar essa solicitação.',
      success: 'Sucesso',
      purchase_requisition_id: 'Requisição de compra',
      buyer_group_id: 'Grupo comprador',
      num_quotation: 'Número da cotação',
      sla_days: 'Dias',
      sla_hours: 'Horas',
      address: 'Endereço',
      zip_code: 'CEP',
      district: 'Bairro',
      phone: 'Telefone',
      send: 'Enviar',
      bloq_central: 'Bloqueio Central Eliminação.',
      bloq_emp: 'Bloqueio Cont. p/Empresa',
      bloq_org: 'Bloqueio de Compras para Org. Compras',
      bloq_pgto: 'Bloq. Pgto.',
      bloq_total1: 'Bloqueio Cont. Central',
      bloq_total2: 'Bloqueio Central de Compra',
      contact_name: 'Vendedor',
      fun_bloq1: 'Função Bloqueio',
      fun_bloq2: 'Função do Bloqueio',
      fun_bloq3: 'Bloqueio eliminação empresa',
      creation_date: 'Data de criação',
      cod_company_erp: 'Empresa',
      marc_elimi_emp: 'Marcação p/eliminação para Empresa',
      marc_elimi_org: 'Marcação p/eliminação para Org. Compras',
      marc_elimi_total: 'Marcação p/eliminação. Central',
      org_buy: 'Org. Compra',
      quotation_num_round: 'Rodada',
      rfq_ia_code_reward_automatic: 'Premiar (automático)',
      rfq_ia_code_reward_manual: 'Premiar (manual)',
      rfq_ia_code_new_round_automatic: 'Nova Rodada (automático)',
      rfq_ia_code_new_round_manual: 'Nova Rodada (manual)',
      rfq_ia_code_buyer_help: 'Ajuda do Comprador',
      rfq_ia_code_extend_rfq_deadline: 'Estender Prazo RFQ',
      today: 'Hoje',
      undefined: 'Indefinido',
      or: 'ou',
      select: 'Selecionar...',
      invalid_credencial_error:
        'As credenciais de e-mail ou senha estão incorretas',
      confirm_password_rule: 'As senhas precisam ser idênticas',
      password_rules_error: 'Crie uma senha forte',
      change_password: 'Troca de Senha',
      status_server_error: 'Algo deu errado. Tente novamente.',
      not_allowed: 'Você não tem permissão para acessar a página solicitada.',
      page_not_found: 'Ops! Página não encontrada.',
      go_home: 'Ir para home',
      try_again: 'Tente novamente',
      go_back: 'Voltar à página anterior',
      go_back_simple: 'Voltar',
      error: 'Erro',
      form_with_empty_fields: 'Existem campos obrigatórios não preenchidos.',
      awarded: 'Premiado',
      unauthorized_access: 'Acesso não autorizado',
      select_buyer_group: 'Selecionar Grupo Comprador',
      select_material: 'Selecionar Material',
      select_supplier_type: 'Selecionar Tipo de Fornecedor',
      select_status: 'Selecionar Status',
      select_urgency: 'Selecionar Urgencia',
      select_type: 'Selecionar Tipo',
      select_supplier: 'Selecionar Fornecedor',
      sla_remaining: 'SLA Restante',
      confirmation_cancel_items:
        'Você tem certeza que deseja cancelar os items selecionados?',
      import_file_with_errors:
        'O arquivo não pode ser importado, pois contém erros. Corrija-os e tente novamente',
      qto: 'Quantidade',
      unm: 'Unidade Medida',
      vuo: 'Valor unitário',
      vup: 'Valor unitário presente',
      vut: 'Valor unitário presente com frete',
      icm: 'ICMS Base ERP',
      icp: 'ICMS Fornecedor',
      ipi: 'IPI Base ERP',
      ipp: 'IPI Fornecedor',
      vto: 'Valor total',
      vtp: 'Valor total presente',
      vtf: 'Valor total com frete',
      dsc: 'Desconto Concedido',
      lte: 'Lead Time em Dias',
      ent: 'Data Remessa Calculada',
      dtr: 'Data Remessa RC',
      nc1: 'NCM Votorantim',
      nc2: 'NCM Fornecedor',
      iva: 'IVA',
      mar: 'Marca',
      por: 'Unidade de Preço',
      validate_suppliers: 'Validar Fornecedores',
      validate_groups: 'Validar Grupos',
      validate_warehouses: 'Validar Depósitos',
      validate_materials: 'Validar Materiais',
      validate_merchandise: 'Validar Grupos',
      validate_centers: 'Validar Centros',
      validate: 'Validar',
      invalid_fields: 'Itens Inválidos',
      fill_one_field: 'Preencha ao menos um dos campos restantes',
      empty_supplier: 'Campo "Fornecedores" vazio nas vendor lists',
      rfq_status_cleared: "Itens disponíveis na aba RFQ's",
      warning: 'Atenção',
    },
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'pt-BR',
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
