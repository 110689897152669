import React from 'react';
import Routes from './routes';
import { AuthProvider } from './Context/Auth';
import { DomainProvider } from './Context/Subdomain/Domain';
// TODO: CHANGE TO "PRIVATE" CONTEXT
import { FilterProvider } from './Context/ComparativeMapFilters';
import { ComparativemapProvider } from './Context/ComparativeMap';

const App: React.FC = () => {
  return (
    <div className="App">
      <AuthProvider>
        <DomainProvider>
          <FilterProvider>
            <ComparativemapProvider>
              <Routes />
            </ComparativemapProvider>
          </FilterProvider>
        </DomainProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
