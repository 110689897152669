import styled, { css } from 'styled-components';
interface TabHeaderProps {
  isActive: boolean;
}
export const Container = styled.div``;

export const TabHeader = styled.div<TabHeaderProps>`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  position: relative;

  ${props =>
    props.isActive &&
    css`
      background-color: #fff;
      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 0.54rem;
        width: 1.25rem;
        bottom: 0;
      }

      &::before {
        left: -1.22rem;
        border-radius: 0 0 1rem 0;
        -webkit-border-radius: 0 0 0.625rem 0;
        box-shadow: 0.625rem 0 0 0 #ffffff;
      }

      &::after {
        right: -1.22rem;
        border-radius: 0 0 0 0.625rem;
        -moz-border-radius: 0 0 0 0.625rem;
        -webkit-border-radius: 0 0 0 0.625rem;
        box-shadow: -0.625rem 0 0 0 #ffffff;
      }
    `}
`;
