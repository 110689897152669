import React, { FC } from 'react';
import * as S from './styled';

type OptionType = {
  label: string;
  value: string;
};

type SelectProp = {
  className?: string;
  id: string;
  defaultOptionText?: string;
  options: OptionType[];
  errorMessage: string;
  labelText: string;
  disabled?: boolean;
  value: string;
  onChange: any;
  onBlur: any;
};

const Select: FC<SelectProp> = ({
  className,
  id,
  defaultOptionText,
  options,
  errorMessage,
  labelText,
  disabled,
  value,
  onChange,
  onBlur,
  ...rest
}) => (
  <S.Container>
    <S.LabelSpan hasError={!!errorMessage}>{labelText}</S.LabelSpan>
    <S.SelectWrapper className={className} {...rest}>
      <S.Select
        className={`floating ${errorMessage ? 'error' : ''}`}
        id={id}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value="">{defaultOptionText}</option>
        {options.map(({ label, value: optValue = '' }) => (
          <option key={optValue} value={optValue}>
            {label}
          </option>
        ))}
      </S.Select>
    </S.SelectWrapper>
    <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
  </S.Container>
);

export default Select;
