/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { AxiosError } from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, Content, ContentContainer } from './ListSuppliers.style';
import { CustomComponent } from '../../../styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SortDescIcon } from '../../../assets/icon-sort-desc.svg';
import { ReactComponent as SortAscIcon } from '../../../assets/icon-sort-asc.svg';
import Navbar from '../../../components/Navbar/navbar';
import ListItem from '../../../components/ListItem/ListItem';
import PageTab from '../../../components/PageTab/PageTab';
import Filters from '../../../components/Filters/Filters';
import Button from '../../../components/Button/Button';
import HiddenFileInput from '../../../components/HiddenFileInput/HiddenFileInput';

import api from '../../../services/api';
import ListButton from '../../../components/ListButton/ListButton';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../components/Loading/Loading';
import { formatOptions } from '../../../helpers/OptionsHelpers/FormatOptions';
import { userHasAnyPermissionOf } from '../../../services/permission-service';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';

import SuppliersService from '../../../services/suppliers-service';
import GoTopButton from '../../../shared/GoTopButton/GoTopButton';
import * as S from './styled.js';
import MultiInput from '../../../components/MultiInput/MultiInput';
import { ReactComponent as SearchIconBlue } from '../../../assets/search-icon-blue.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../../assets/checkbox-unchecked-icon.svg';
import { ReactComponent as CheckBoxChecked } from '../../../assets/checkbox-checked-icon.svg';
import { useForm } from 'react-hook-form';
import { handleFieldsValidate } from '../../../utils/fieldsValidation';
import ReactLoading from 'react-loading';
import { MessageModal, checkScroll } from '../../PurchasePanel/utils';

interface BaseObject {
  id: number;
  description: string;
  name: string;
}

interface Supplier extends BaseObject {
  code: string;
  social_name: string;
  nickname: string;
  document_number: string;
  type_supplier: string;
  status: string;
  state: string;
  country: string;
  quality_percent: number;
  score_serasa: number;
  PMP: number;
  saving_percent: number;
  deadline: string;
  [key: string]: number | string;
}

interface BuyerGroupsProps extends BaseObject {
  name: string;
}

interface WarehousesProps extends BaseObject {
  code: string;
}

interface SortFieldProps {
  name: string;
  value: string;
}

interface MaterialsProps extends BaseObject {
  cod_material: string;
}
interface VendorList {
  id: number;
  active: boolean;
  regions: Center[];
  suppliers: Supplier[];
  categories: BaseObject[];
  buyer_groups: BuyerGroupsProps[];
  materials: MaterialsProps[];
  merchandise_groups: BaseObject[];
  warehouses: WarehousesProps[];
  [key: string]: number | string | Boolean | BaseObject[] | Center[];
}

interface Field {
  label: string;
  sort: string;
  value?: string;
  size?: string;
  type?: string;
}

interface VendorListObj {
  id: number;
  active: boolean;
  regions: Center;
  suppliers: Supplier;
  categories: BaseObject;
  buyer_groups: BuyerGroupsProps;
  materials: MaterialsProps;
  merchandise_groups: BaseObject;
  warehouses: WarehousesProps;
}

interface Center {
  id: number;
  code: string;
  name: string;
}

interface Options {
  label: string;
  value: string | number;
}

interface MultiInputOptions {
  id: number;
  value: string;
}

const ListSuppliers: React.FC = () => {
  const { t } = useTranslation();
  const fields: Field[] = [
    {
      label: 'code',
      size: 'md:w-1/12 lg:w-1/12',
      type: 'number',
      sort: 'code',
    },
    { label: 'social_name', size: 'w-3/12', sort: 'social_name' },
    { label: 'nickname', sort: 'nickname' },
    { label: 'document_number', type: 'number', sort: 'document_number' },
    { label: 'supplier_type', value: 'type_supplier', sort: 'type_supplier' },
    { label: 'supplier_status', value: 'status', sort: 'status' },
    { label: 'uf', value: 'state', size: 'md:w-1/12 lg:w-1/12', sort: 'state' },
  ];

  const vendorListFields: Field[] = [
    {
      label: 'Id',
      sort: 'Id',
      size: 'md:w-1/36 lg:w-1/36',
    },
    {
      label: 'buyer_groups',
      size: 'md:w-1/12 lg:w-1/12',
      sort: 'buyer_groups',
    },
    { label: 'materials', size: 'md:w-1/12 lg:w-1/12', sort: 'materials' },
    { label: 'region', size: 'md:w-1/12 lg:w-1/12', sort: 'region' },
    { label: 'categories', size: 'md:w-1/12 lg:w-1/12', sort: 'categories' },
    {
      label: 'merchandise_groups',
      size: 'md:w-2/12 lg:w-2/12',
      sort: 'merchandise_groups',
    },
    {
      label: 'warehouses',
      size: 'md:w-1/12 lg:w-1/12',
      sort: 'warehouses',
    },
    { label: 'suppliers', size: 'md:w-5/24 lg:w-5/24', sort: 'suppliers' },
    { label: 'active', size: 'md:w-1/24 lg:w-1/24', sort: 'active' },
    { label: 'actions', size: 'md:w-1/24 lg:w-1/24', sort: 'actions' },
  ];

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem('active_tab') || 'suppliers',
  );
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [suppliersList, setSuppliersList] = useState<any[]>([]);
  const [suppliersTotal, setSuppliersTotal] = useState(0);
  const [vendorListsTotal, setVendorListsTotal] = useState(0);
  const [vendorListSanitized, setVendorListSanitized] = useState<
    VendorListObj[]
  >([]);
  const [vendorLists, setVendorLists] = useState<VendorList[]>([]);
  const [sortField, setSortField] = useState<SortFieldProps>({
    name: '',
    value: '',
  });
  const [currentSuppliers, setCurrentSuppliers] = useState(0);
  const [currentVendorLists, setCurrentVendorLists] = useState(0);
  const [message, setMessage] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [supplierCode, setSupplierCode] = useState<Options[]>([]);
  const [socialName, setSocialName] = useState<Options[]>([]);
  const [documentNumber, setDocumentNumber] = useState<Options[]>([]);
  const [supplierType, setSupplierType] = useState<Options[]>([]);
  const [buyerGroups, setBuyerGroups] = useState<Options[]>([]);
  const [materials, setMaterials] = useState<Options[]>([]);
  const [regions, setRegions] = useState<Options[]>([]);
  const [categories, setCategories] = useState<Options[]>([]);
  const [merchandiseGroups, setMerchandiseGroups] = useState<Options[]>([]);
  const [warehouses, setWarehouses] = useState<Options[]>([]);
  const [sortUrlSuppliers, setSortUrlSuppliers] = useState('');
  const [filterUrl, setFilterUrl] = useState('');
  const [responseError, setResponseError] = useState<AxiosError | null>();
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [selectedVendorLists, setSelectedVendorLists] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [itemsToRemove, setItemsToRemove] = useState<any[]>([]);
  const [suppliersOptions, setSuppliersOptions] = useState<MultiInputOptions[]>(
    [],
  );
  const [suppliersOptionsRemove, setSuppliersOptionsRemove] = useState<
    MultiInputOptions[]
  >([]);
  const [allSelected, setAllSelected] = useState(false);
  const [blackList, setBlackList] = useState<any[]>([]);
  const [typedOptionSupplier, setTypedOptionSupplier] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const { register } = useForm();
  const [inputValue, setInputValue] = useState('');
  const [modalType, setModalType] = useState('');

  const selectVendorList = (id: number) => {
    setSelectedVendorLists([...selectedVendorLists, id]);
  };

  const removeVendorList = (id: number) => {
    const filtered = selectedVendorLists.filter(item => {
      return item !== id;
    });
    setSelectedVendorLists(filtered);
    if (allSelected) {
      setBlackList([...blackList, id]);
    }
  };

  const selectItem = (id: number) => {
    setItemsToRemove([...itemsToRemove, id]);
  };

  const removeItem = (id: number) => {
    const filtered = itemsToRemove.filter(item => {
      return item !== id;
    });
    setItemsToRemove(filtered);
  };

  useEffect(() => {
    const updatedList: any[] = [];
    vendorLists.forEach(item => {
      if (selectedVendorLists.includes(item.id)) {
        item.suppliers.forEach(sup => {
          if (!updatedList.includes(sup.code + ' - ' + sup.social_name)) {
            updatedList.push(sup.code + ' - ' + sup.social_name);
          }
        });
      }
    });
    setSuppliersList(updatedList);
    setItemsToRemove(
      itemsToRemove.map(sup => {
        if (updatedList.includes(sup)) return sup;
        else return '';
      }),
    );
  }, [selectedVendorLists]);

  const handleLoadedSuppliers = (items: never[], loadedOptions: never[]) => {
    const options: MultiInputOptions[] = [];

    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { social_name: string; id: number; code: string }) => {
      const newSupplier = {
        id: item.id,
        value: item.code + ' - ' + item.social_name,
      };
      options.push(newSupplier);
    });
    setSuppliersOptions([...options]);
  };

  const handleLoadedSuppliersRemove = (
    items: never[],
    loadedOptions: never[],
  ) => {
    const options: MultiInputOptions[] = [];
    if (loadedOptions) {
      loadedOptions.forEach(item => {
        options.push(item);
      });
    }
    items.forEach((item: { social_name: string; id: number; code: string }) => {
      const newSupplier = {
        id: item.id,
        value: item.code + ' - ' + item.social_name,
      };
      options.push(newSupplier);
    });
    setSuppliersOptionsRemove([...options]);
  };

  const handleSelectedOptions = (selectedOptions: MultiInputOptions[]) => {
    setSelectedItems([...selectedOptions]);
  };

  const handleSelectedOptionsRemove = (
    selectedOptions: MultiInputOptions[],
  ) => {
    if (!suppliersList.includes(selectedOptions[0].value)) {
      setSuppliersList([selectedOptions[0].value, ...suppliersList]);
    }
    setItemsToRemove([selectedOptions[0].value, ...itemsToRemove]);
    setSuppliersOptionsRemove([]);
    setInputValue('');
  };

  const received_tab = useLocation<{
    active_tab: string;
  }>();

  const showLoader = (load: boolean) => {
    setLoading(load);
  };
  const sanitizeVendorList = (response: VendorList[]) => {
    const newVendorListSanitized: VendorListObj[] = [];

    response.forEach((item: VendorList) => {
      item.buyer_groups.forEach((item_buyer_groups: BuyerGroupsProps) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: item_buyer_groups,
          categories: { id: 0, description: '', name: '' },
          materials: {
            cod_material: '',
            id: 0,
            description: '',
            name: '',
          },
          merchandise_groups: {
            id: 0,
            description: '',
            name: '',
          },
          warehouses: {
            id: 0,
            name: '',
            description: '',
            code: '',
          },
          regions: {
            id: 0,
            code: '',
            name: '',
          },
          suppliers: {
            code: '',
            social_name: '',
            nickname: '',
            document_number: '',
            type_supplier: '',
            status: '',
            state: '',
            country: '',
            quality_percent: 0,
            score_serasa: 0,
            PMP: 0,
            saving_percent: 0,
            deadline: '',
            id: 0,
            description: '',
            name: '',
          },
        });
      });

      item.categories.forEach((item_categories: BaseObject) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: {
            id: 0,
            description: '',
            name: '',
          },
          categories: item_categories,
          materials: { cod_material: '', id: 0, description: '', name: '' },
          merchandise_groups: {
            id: 0,
            description: '',
            name: '',
          },
          warehouses: {
            id: 0,
            description: '',
            name: '',
            code: '',
          },
          regions: {
            id: 0,
            code: '',
            name: '',
          },
          suppliers: {
            code: '',
            social_name: '',
            nickname: '',
            document_number: '',
            type_supplier: '',
            status: '',
            state: '',
            country: '',
            quality_percent: 0,
            score_serasa: 0,
            PMP: 0,
            saving_percent: 0,
            deadline: '',
            id: 0,
            description: '',
            name: '',
          },
        });
      });

      item.materials.forEach((item_materials: MaterialsProps) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: {
            id: 0,
            description: '',
            name: '',
          },
          categories: { id: 0, description: '', name: '' },
          materials: item_materials,
          merchandise_groups: {
            id: 0,
            description: '',
            name: '',
          },
          warehouses: {
            id: 0,
            description: '',
            name: '',
            code: '',
          },
          regions: {
            id: 0,
            code: '',
            name: '',
          },
          suppliers: {
            code: '',
            social_name: '',
            nickname: '',
            document_number: '',
            type_supplier: '',
            status: '',
            state: '',
            country: '',
            quality_percent: 0,
            score_serasa: 0,
            PMP: 0,
            saving_percent: 0,
            deadline: '',
            id: 0,
            description: '',
            name: '',
          },
        });
      });

      item.merchandise_groups.forEach((item_merchandise_groups: BaseObject) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: {
            id: 0,
            description: '',
            name: '',
          },
          categories: { id: 0, description: '', name: '' },
          materials: { cod_material: '', id: 0, description: '', name: '' },
          merchandise_groups: item_merchandise_groups,
          regions: {
            id: 0,
            code: '',
            name: '',
          },
          warehouses: {
            id: 0,
            description: '',
            name: '',
            code: '',
          },
          suppliers: {
            code: '',
            social_name: '',
            nickname: '',
            document_number: '',
            type_supplier: '',
            status: '',
            state: '',
            country: '',
            quality_percent: 0,
            score_serasa: 0,
            PMP: 0,
            saving_percent: 0,
            deadline: '',
            id: 0,
            description: '',
            name: '',
          },
        });
      });

      item.regions.forEach((item_regions: Center) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: {
            id: 0,
            description: '',
            name: '',
          },
          categories: { id: 0, description: '', name: '' },
          materials: { cod_material: '', id: 0, description: '', name: '' },
          merchandise_groups: {
            id: 0,
            description: '',
            name: '',
          },
          warehouses: {
            id: 0,
            description: '',
            name: '',
            code: '',
          },
          regions: item_regions,
          suppliers: {
            code: '',
            social_name: '',
            nickname: '',
            document_number: '',
            type_supplier: '',
            status: '',
            state: '',
            country: '',
            quality_percent: 0,
            score_serasa: 0,
            PMP: 0,
            saving_percent: 0,
            deadline: '',
            id: 0,
            description: '',
            name: '',
          },
        });
      });

      item.suppliers.forEach((item_suppliers: Supplier) => {
        newVendorListSanitized.push({
          active: item.active,
          id: item.id,
          buyer_groups: {
            id: 0,
            description: '',
            name: '',
          },
          categories: { id: 0, description: '', name: '' },
          materials: { cod_material: '', id: 0, description: '', name: '' },
          merchandise_groups: {
            id: 0,
            description: '',
            name: '',
          },
          warehouses: {
            id: 0,
            description: '',
            name: '',
            code: '',
          },
          regions: {
            id: 0,
            code: '',
            name: '',
          },
          suppliers: item_suppliers,
        });
      });
    });

    return orderBy(newVendorListSanitized, 'id', ['asc']);
  };

  useEffect(() => {
    async function loadSuppliers(): Promise<void> {
      showLoader(true);
      await api
        .get('/suppliers/')
        .then(response => {
          const { count, results } = response.data;
          setSuppliers(results);
          setSuppliersTotal(count);
        })
        .catch(setResponseError)
        .finally(() => {
          showLoader(false);
        });
    }
    sessionStorage.setItem('active_tab', activeTab || '');
    if (activeTab === 'suppliers' && suppliersTotal === 0) loadSuppliers();
    if (activeTab === 'vendor_list' && vendorListsTotal === 0)
      loadVendorLists();
  }, [t, received_tab, activeTab]);

  async function loadVendorLists(): Promise<void> {
    showLoader(true);
    await api
      .get(`/vendor_lists/?limit=15&${filterUrl}`)
      .then(response => {
        const { count, results } = response.data;
        setVendorLists(results);
        setVendorListsTotal(count);
        const newVendorListSanitized = sanitizeVendorList(results);
        setVendorListSanitized(newVendorListSanitized);
      })
      .catch(setResponseError)
      .finally(() => {
        showLoader(false);
      });
  }

  const toggleAction = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      activeTab === 'vendor_list'
        ? () => checkScroll(contentRef.current, fetchVendorLists)
        : () => checkScroll(contentRef.current, fetchSuppliers),
    );

    return () => {
      window.removeEventListener(
        'resize',
        activeTab === 'vendor_list'
          ? () => checkScroll(contentRef.current, fetchVendorLists)
          : () => checkScroll(contentRef.current, fetchSuppliers),
      );
    };
  }, []);

  useEffect(() => {
    checkScroll(contentRef.current, fetchVendorLists, vendorLists.length > 0);
  }, [vendorLists]);

  useEffect(() => {
    checkScroll(contentRef.current, fetchSuppliers, suppliers.length > 0);
  }, [suppliers]);

  const fetchSuppliers = async () => {
    if (suppliersTotal < currentSuppliers) return;

    showLoader(true);
    const response = await api.get(
      `/suppliers/?limit=15&offset=${
        currentSuppliers + 15
      }&${sortUrlSuppliers}&${filterUrl}`,
    );
    setCurrentSuppliers(prevCurrentSuppliers => prevCurrentSuppliers + 15);
    setSuppliers(prevSuppliers => [...prevSuppliers, ...response.data.results]);
    showLoader(false);
  };

  const fetchVendorLists = async () => {
    if (vendorListsTotal < currentVendorLists) return;
    const updatedList: any[] = [];
    showLoader(true);
    const response = await api.get(
      `/vendor_lists/?limit=15&offset=${
        currentVendorLists + 15
      }&${sortUrlSuppliers}&${filterUrl}`,
    );
    setCurrentVendorLists(
      prevCurrentVendorLists => prevCurrentVendorLists + 15,
    );
    setVendorLists(prevVendorLists => [
      ...prevVendorLists,
      ...response.data.results,
    ]);
    if (allSelected) {
      response.data.results.forEach((vendor: { id: any }) => {
        updatedList.push(vendor.id);
      });
      setSelectedVendorLists([...selectedVendorLists, ...updatedList]);
    }
    showLoader(false);
  };

  const inactiveItem = async (vl: VendorList) => {
    showLoader(true);
    const response = await api.patch(`/vendor_lists/${vl.id}/`, {
      active: !vl.active,
    });
    const newVendorListSanitized = [...vendorListSanitized];
    vl.active = !vl.active;
    vendorListSanitized.forEach((item, idx: number) => {
      if (item.id === response.data.id) {
        newVendorListSanitized[idx].active = response.data.active;
      }
    });
    setVendorListSanitized([...newVendorListSanitized]);
    showLoader(false);
  };

  const tabs = ['suppliers', 'vendor_list'];

  const filterFields = {
    fieldsList: [
      {
        field: 'code',
        type: 'list',
        placeholder: 'Ex. 26676',
        options: supplierCode,
        url: 'suppliers/list_to_filter/?supplier=',
      },
      {
        field: 'social_name',
        type: 'list',
        placeholder: 'Ex. Fornecedor LTDA.',
        options: socialName,
        url: 'suppliers/list_to_filter/?supplier=',
      },
      {
        field: 'document_number',
        type: 'list',
        placeholder: 'Ex. 16650009000188',
        options: documentNumber,
        url: 'suppliers/list_to_filter/?supplier=',
      },
      {
        field: 'type_supplier',
        type: 'list',
        placeholder: 'select_supplier_type',
        options: supplierType,
        url: 'suppliers/list_to_filter/?type_supplier=',
      },
      {
        field: 'status',
        type: 'select',
        placeholder: 'select_status',
        options: [
          {
            label: 'active',
            value: 'active',
          },
          {
            label: 'inactive',
            value: 'inactive',
          },
        ],
      },
    ],
    hasSla: false,
  };

  const vendorListFilterFields = {
    fieldsList: [
      {
        field: 'buyer_group_id',
        placeholder: 'select_buyer_group',
        type: 'list',
        options: buyerGroups,
        url: 'buyer_groups/list_to_filter/?name=',
      },
      {
        field: 'material',
        placeholder: 'select_material',
        type: 'list',
        options: materials,
        url: 'materials/list_to_filter/?cod_material=',
      },
      {
        field: 'region',
        placeholder: 'select_region',
        type: 'list',
        options: regions,
        url: 'centers/list_to_filter/?code=',
      },
      {
        field: 'categories',
        placeholder: 'select_category',
        type: 'list',
        options: categories,
        url: 'categories/list_to_filter/?description=',
      },
      {
        field: 'merchandise_group',
        placeholder: 'select_merchandise_group',
        type: 'list',
        options: merchandiseGroups,
        url: 'merchandise_groups/list_to_filter/?description=',
      },
      {
        field: 'warehouse',
        placeholder: 'select_warehouse',
        type: 'list',
        options: warehouses,
        url: 'warehouses/list_to_filter/?code=',
      },
      {
        field: 'suppliers',
        placeholder: 'select_suppliers',
        type: 'list',
        options: socialName,
        url: 'suppliers/list_to_filter/?supplier=',
      },
    ],
    hasSla: false,
  };

  async function handleSuppliersFilterRequest(newUrl: string) {
    showLoader(true);
    await api
      .get(`/suppliers/?${newUrl}`)
      .then(resp => {
        setSuppliers(resp.data.results);
        setFilterUrl(newUrl);
        setCurrentSuppliers(0);
      })
      .catch(setResponseError)
      .finally(() => {
        showLoader(false);
      });
  }

  async function handleVendorFilterRequest(newUrl: string) {
    showLoader(true);
    // const listFilters = newUrl.split('&');

    await api
      .get(`/vendor_lists/?limit=15&${newUrl}`)
      .then(resp => {
        // const newVendorListSanitized = sanitizeVendorList(resp.data.results);
        setVendorLists(resp.data.results);
        setFilterUrl(newUrl);
        setCurrentVendorLists(0);
        setVendorListsTotal(resp.data.count);
        setSelectedVendorLists([]);
        setAllSelected(false);
        setBlackList([]);

        // let vendorListCategoriesFiltered: VendorListObj[] = [];
        // let vendorListMerchandiseGroupFiltered: VendorListObj[] = [];
        // let vendorListMaterialsFiltered: VendorListObj[] = [];
        // let vendorListRegionsFiltered: VendorListObj[] = [];
        // let vendorListSuppliersFiltered: VendorListObj[] = [];
        // let vendorListBuyerGroupIdFiltered: VendorListObj[] = [];
        //
        // if (!newUrl) {
        //   setVendorListSanitized(newVendorListSanitized);
        // } else {
        //   listFilters.forEach(filterName => {
        //     if (filterName.includes('buyer_group_id')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListBuyerGroupIdFiltered = newVendorListSanitized.filter(
        //         item =>
        //           item.buyer_groups.id === parseInt(filterNameSplitted[1], 10),
        //       );
        //     }
        //
        //     if (filterName.includes('categories')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListCategoriesFiltered = newVendorListSanitized.filter(
        //         item =>
        //           item.categories.id === parseInt(filterNameSplitted[1], 10),
        //       );
        //     }
        //
        //     if (filterName.includes('merchandise_group')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListMerchandiseGroupFiltered = newVendorListSanitized.filter(
        //         item =>
        //           item.merchandise_groups.id ===
        //           parseInt(filterNameSplitted[1], 10),
        //       );
        //     }
        //
        //     if (filterName.includes('material')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListMaterialsFiltered = newVendorListSanitized.filter(
        //         item => item.materials.cod_material === filterNameSplitted[1],
        //       );
        //     }
        //
        //     if (filterName.includes('region')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListRegionsFiltered = newVendorListSanitized.filter(
        //         item => item.regions.code === filterNameSplitted[1],
        //       );
        //     }
        //
        //     if (filterName.includes('suppliers')) {
        //       const filterNameSplitted = filterName.split('=');
        //       vendorListSuppliersFiltered = newVendorListSanitized.filter(
        //         item =>
        //           item.suppliers.id === parseInt(filterNameSplitted[1], 10),
        //       );
        //     }
        //   });
        //
        //   const teste = [
        //     ...vendorListCategoriesFiltered,
        //     ...vendorListMerchandiseGroupFiltered,
        //     ...vendorListMaterialsFiltered,
        //     ...vendorListRegionsFiltered,
        //     ...vendorListSuppliersFiltered,
        //     ...vendorListBuyerGroupIdFiltered,
        //   ];
        //
        //   setVendorListSanitized(orderBy(teste, 'id', ['asc']));
        // }
      })
      .catch(setResponseError)
      .finally(() => {
        showLoader(false);
      });
  }

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  function openModal(text: string) {
    setMessage(t(text));
    setIsOpen(true);
  }

  const handleChangeFile = async (e: any) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const fileBase64: any = await toBase64(file);
    const csv = fileBase64.split(',')[1];
    let data = csv;
    if (fileExtension === 'csv') {
      data = data.split('/')[1];
      if (!data) {
        data = csv;
      }
    }
    let url = '';

    if (activeTab === 'suppliers') {
      url = 'bulk_import/upload_csv/?model=Supplier';
    } else {
      url = 'vendor_list_csv/upload_csv/';
    }
    showLoader(true);
    await api
      .post(url, {
        data: data,
      })
      .then(response => {
        setModalType('success');
        openModal('success_upload');
      })
      .catch(error => {
        setModalType('error');
        console.log(error);
        console.log();
        console.log(typeof error);
        if (
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          'data' in error.response &&
          error.response.data
        ) {
          if (
            'no_field_errors' in error.response.data &&
            error.response.data.no_field_errors.length > 0
          ) {
            openModal(error.response.data.no_field_errors);
          } else if (
            'errorList' in error.response.data &&
            error.response.data.errorList.length > 0
          ) {
            openModal(error.response.data.errorList);
          } else {
            console.log(error.response.data.message);
            openModal(error.response.data.message.replace(':', ''));
          }
        } else {
          if (typeof error === 'string') {
            openModal(error);
          } else {
            openModal('Unknown Error');
          }
        }
      })
      .finally(() => {
        e.target.value = null;
      });
    showLoader(false);
  };

  const handleSort = async (field: Field) => {
    showLoader(true);
    let sort = sortField.value === 'asc' ? 'desc' : 'asc';
    if (sortField.name !== field.label) {
      sort = 'desc';
    }
    setSortField({ name: field.label, value: sort });

    const url = `order_by=${field.sort.toLowerCase()}-${sort}`;
    setSortUrlSuppliers(url);

    if (!vendorListFields.includes(field)) {
      await api.get(`/suppliers/?${url}&${filterUrl}`).then(response => {
        setSuppliers(response.data.results);
        setCurrentSuppliers(0);
      });
    } else {
      await api.get(`/vendor_lists/?${url}&${filterUrl}`).then(response => {
        setVendorLists(response.data.results);
        setCurrentVendorLists(0);
      });
    }
    showLoader(false);
  };

  const handleLoadedOptions = (
    items: never[],
    dropdown: string,
    loadedOptions: never[],
  ) => {
    const options: Options[] = formatOptions(items, dropdown, loadedOptions);
    switch (dropdown) {
      case 'suppliers':
        setSocialName([...options]);
        break;
      case 'buyer_group_id':
        setBuyerGroups([...options]);
        break;
      case 'material':
        setMaterials([...options]);
        break;
      case 'region':
        setRegions([...options]);
        break;
      case 'categories':
        setCategories([...options]);
        break;
      case 'merchandise_group':
        setMerchandiseGroups([...options]);
        break;
      case 'warehouse':
        setWarehouses([...options]);
        break;
      case 'code':
        setSupplierCode([...options]);
        break;
      case 'social_name':
        setSocialName([...options]);
        break;
      case 'document_number':
        setDocumentNumber([...options]);
        break;
      case 'type_supplier':
        setSupplierType([...options]);
        break;
      default:
        break;
    }
  };

  const closeErrorPage = () => setResponseError(null);

  const tryAgainFunction = () => window.location.reload();

  const handleAddItems = async (field: string) => {
    showLoader(true);
    const payload = {
      items: selectedItems.map(item => {
        return item.id;
      }),
      add: field,
      all_selected: allSelected,
      black_list: blackList,
    };
    try {
      const response = await api.put(
        `/vendor_lists/update_with_relations/${selectedVendorLists}/?${filterUrl}`,
        payload,
      );
      if (response.status === 200) {
        setSelectedVendorLists([]);
        setSelectedItems([]);
        setCurrentVendorLists(0);
        setVendorListsTotal(0);
        setAllSelected(false);
        loadVendorLists();
      }
    } catch (error) {
      showLoader(false);
      setModalType('error');
      openModal('request_error');
      console.log(error);
    }
  };

  const handleRemoveItems = async (field: string) => {
    showLoader(true);
    const payload = {
      items: itemsToRemove.map(item => {
        return item.split(' ')[0];
      }),
      remove: field,
      all_selected: allSelected,
      black_list: blackList,
    };
    try {
      const response = await api.put(
        `/vendor_lists/update_with_relations/${selectedVendorLists}/?${filterUrl}`,
        payload,
      );
      if (response.data.length) {
        setModalType('warning');
        openModal(`${t('empty_supplier')} ${response.data}`);
      }
      if (response.status === 200) {
        setSelectedVendorLists([]);
        setSelectedItems([]);
        setItemsToRemove([]);
        setCurrentVendorLists(0);
        setVendorListsTotal(0);
        setAllSelected(false);
        loadVendorLists();
      }
    } catch (error) {
      showLoader(false);
      setModalType('error');
      openModal('request_error');
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    setAllSelected(!allSelected);
    if (allSelected) {
      setBlackList([]);
      setSelectedVendorLists([]);
      return;
    }
    const updatedList: any[] = [];
    vendorLists.forEach(vendor => {
      updatedList.push(vendor.id);
    });
    setSelectedVendorLists(updatedList);
  };

  return (
    <>
      {responseError ? (
        <ErrorPage
          error={responseError}
          closeErrorPage={closeErrorPage}
          tryAgainFunction={tryAgainFunction}
        />
      ) : (
        <Container className="flex-1 flex-col bg-primary h-screen">
          <Navbar pageTitle={activeTab} selectedMenu="suppliers" />
          <PageTab
            tabs={tabs}
            activeTab={activeTab}
            toggleAction={toggleAction}
            backgroundColor="bg-primary"
            className=""
          >
            {loading && <Loading height={'calc(100vh - 88px)'} />}

            <div className="pt-2 w-full flex flex-col">
              {activeTab === 'suppliers' && (
                <div className="flex h-full w-full justify-center">
                  <div className="w-full">
                    <CustomComponent>
                      <div className="flex justify-between mb-3">
                        <Filters
                          filterFields={filterFields}
                          handleResponse={handleSuppliersFilterRequest}
                          handleOptions={handleLoadedOptions}
                        />
                        <ListButton
                          keyButtonText={'load_supplier'}
                          permissionsNeeded={[
                            'add_supplier',
                            'change_supplier',
                          ]}
                          onClick={() => inputRef.current.click()}
                          upload
                        />
                        <HiddenFileInput
                          id="upload"
                          ref={inputRef}
                          onChange={handleChangeFile}
                          accept=".xlsx, .csv"
                        />
                        <ListButton
                          keyButtonText={'export_list'}
                          permissionsNeeded={[
                            'add_supplier',
                            'change_supplier',
                          ]}
                          toogleAction={SuppliersService.exportCsv}
                        />
                        {suppliers.length > 0 && (
                          <GoTopButton content={contentRef.current} />
                        )}
                      </div>
                      <div className="flex px-4 w-full justify-between items-center">
                        {fields.map((field, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${
                              field.size || 'flex-1'
                            }`}
                          >
                            {sortField.name === field.label ? (
                              sortField.value === 'asc' ? (
                                <SortDescIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => handleSort(field)}
                                />
                              ) : (
                                <SortAscIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => handleSort(field)}
                                />
                              )
                            ) : (
                              <SortDescIcon
                                className="cursor-pointer w-3 h-2.5"
                                onClick={() => handleSort(field)}
                              />
                            )}
                            <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-2">
                              {t(field.label)}
                            </span>
                          </div>
                        ))}
                        <div className="flex h-3 w-4" />
                      </div>
                    </CustomComponent>
                    <Content
                      id="scrollableDiv"
                      ref={contentRef}
                      className="pl-16 overflow-y-auto"
                      style={{ height: 'calc(100vh - 164px)' }}
                    >
                      {suppliers.length === 0 && (
                        <div className="mt-6 flex justify-center items-center">
                          <span className="font-default text-sm font-light italic text-300">
                            {t('empty_list')}
                          </span>
                        </div>
                      )}
                      <InfiniteScroll
                        dataLength={currentSuppliers}
                        hasMore={true}
                        next={fetchSuppliers}
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                      >
                        {suppliers.map((item, key) => {
                          const FeaturedComponent: React.FC = () => {
                            const labels = [
                              { label: item.code, size: 'md:w-1/12 lg:w-1/12' },
                              { label: item.social_name, size: 'w-3/12' },
                              { label: item.nickname },
                              { label: item.document_number },
                              { label: item.type_supplier },
                              { label: item.status, lowercase: true },
                              {
                                label: item.state,
                                size: 'md:w-1/12 lg:w-1/12',
                              },
                            ];

                            return (
                              <>
                                {labels.map((item, key) => (
                                  <span
                                    className={`font-default font-normal text-sm text-yaleblue-900 text-left ${
                                      item.size || 'flex-1'
                                    } ${!item.lowercase ? 'uppercase' : ''}`}
                                    key={key}
                                  >
                                    {`${t(item.label.toLowerCase())}`}
                                  </span>
                                ))}
                              </>
                            );
                          };

                          const ContentComponent: React.FC = () => {
                            const contentItems = [
                              {
                                keyLabelItem: 'iqf',
                                value: item.quality_percent
                                  ? `${item.quality_percent}%`
                                  : '-',
                              },
                              {
                                keyLabelItem: 'serasa_score',
                                value: item.score_serasa,
                              },
                              {
                                keyLabelItem: 'pmp',
                                value: item.PMP,
                              },
                              {
                                keyLabelItem: 'saving',
                                value: item.saving_percent
                                  ? `${item.saving_percent}%`
                                  : '-',
                              },
                              {
                                keyLabelItem: 'deadline',
                                value: item.deadline ?? '-',
                              },
                            ];

                            return (
                              <>
                                <div className="flex flex-row justify-between">
                                  {contentItems.map((item, key) => (
                                    <ContentContainer
                                      className="flex justify-center items-center h-24 w-full"
                                      key={key}
                                    >
                                      <div className="flex flex-col text-center">
                                        <span className="font-default font-normal text-xs text-yaleblue-1000">
                                          {t(item.keyLabelItem)}
                                        </span>
                                        <span className="font-default font-normal text-sm text-yaleblue-1000">
                                          {item.value ? `${item.value}` : '-'}
                                        </span>
                                      </div>
                                    </ContentContainer>
                                  ))}
                                </div>
                                <div className="flex w-full justify-end mb-2">
                                  <Link
                                    className="underline font-default font-bold text-sm text-bluejeans-800"
                                    to={`/supplier/show/${item.id}`}
                                  >
                                    {t('see_details')}
                                  </Link>
                                </div>
                              </>
                            );
                          };

                          return (
                            <ListItem
                              key={key}
                              FeaturedComponent={FeaturedComponent}
                              ContentComponent={ContentComponent}
                              className="my-2"
                              id={key}
                              hasCheckbox={false}
                            />
                          );
                        })}
                      </InfiniteScroll>
                    </Content>
                  </div>
                </div>
              )}
              {activeTab === 'vendor_list' && (
                <div className="h-full w-full flex">
                  <div className="w-3/4">
                    <div className="px-14 ml-0.5">
                      <div className="flex justify-between">
                        <Filters
                          filterFields={vendorListFilterFields}
                          handleResponse={handleVendorFilterRequest}
                          handleOptions={handleLoadedOptions}
                        />
                        <div className="flex justify-between">
                          <ListButton
                            keyButtonText={'load_vendor_list'}
                            permissionsNeeded={[
                              'add_vendorlist',
                              'change_vendorlist',
                            ]}
                            onClick={() => inputRef.current.click()}
                            upload
                          />
                          <HiddenFileInput
                            id="upload"
                            ref={inputRef}
                            onChange={handleChangeFile}
                            accept=".xlsx, .csv"
                          />
                          {userHasAnyPermissionOf([
                            'add_vendorlist',
                            'change_vendorlist',
                          ]) ? (
                            <div className="flex justify-center h-10 ">
                              <div className="flex w-full px-20 justify-end">
                                <Link to="/vendor-lists/new">
                                  <div className="px-4 h-8 flex items-center justify-center bg-bluejeans-800 text-sm text-white font-bold rounded ml-12">
                                    {t('create_vendor_list')}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ) : null}
                          {vendorLists.length > 0 && (
                            <GoTopButton content={contentRef.current} />
                          )}
                        </div>
                      </div>
                      <div className="flex w-full justify-between items-center">
                        {!allSelected ? (
                          <div
                            className="flex cursor-pointer"
                            onClick={handleSelectAll}
                          >
                            <div className="w-0.5">
                              <CheckBoxUnchecked />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="flex cursor-pointer"
                            onClick={handleSelectAll}
                          >
                            <div className="w-0.5">
                              <CheckBoxChecked />
                            </div>
                          </div>
                        )}
                        {vendorListFields.map((field, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${
                              field.size || 'flex-1'
                            }`}
                          >
                            {field.label ===
                            'actions' ? null : sortField.name ===
                              field.label ? (
                              sortField.value === 'asc' ? (
                                <SortDescIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => handleSort(field)}
                                />
                              ) : (
                                <SortAscIcon
                                  className="cursor-pointer w-3 h-2.5"
                                  onClick={() => handleSort(field)}
                                />
                              )
                            ) : (
                              <SortDescIcon
                                className="cursor-pointer w-3 h-2.5"
                                onClick={() => handleSort(field)}
                              />
                            )}
                            <span className="font-default select-none font-bold text-sm text-bluejeans-800 ml-1">
                              {t(field.label)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Content
                      id="scrollableDiv"
                      ref={contentRef}
                      className="pl-10 overflow-y-auto"
                      style={{ height: 'calc(100vh - 176px)' }}
                    >
                      {vendorLists.length === 0 && (
                        <div className="mt-6 flex justify-center items-center">
                          <span className="font-default text-sm font-light italic text-300">
                            {t('empty_list')}
                          </span>
                        </div>
                      )}
                      <InfiniteScroll
                        dataLength={currentVendorLists}
                        hasMore={true}
                        next={fetchVendorLists}
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                      >
                        {vendorLists.map((item, key) => {
                          const VendorListFeaturedComponent: React.FC = () => {
                            const labels = [
                              {
                                label: item.id.toString(),
                                size: 'md:w-1/24 lg:w-1/24 ml-2',
                              },
                              {
                                label: item.buyer_groups
                                  ? item.buyer_groups
                                      .map(obj => obj.name)
                                      .join(', ')
                                  : '-',
                                size: 'md:w-7/72 lg:w-7/72',
                              },
                              {
                                label: item.materials
                                  ? item.materials
                                      .map(obj =>
                                        parseInt(
                                          obj.cod_material,
                                          10,
                                        ).toString(),
                                      )
                                      .join(', ') ?? '-'
                                  : '-',
                                size: 'md:w-4/36 lg:w-4/36',
                              },
                              {
                                label: item.regions
                                  ? item.regions.map(obj => obj.code).join(', ')
                                  : '-',
                                size: 'md:w-7/72 lg:w-7/72',
                              },
                              {
                                label: item.categories
                                  ? item.categories
                                      .map(obj => obj.name)
                                      .join(', ')
                                  : '-',
                                size: 'md:w-7/72 lg:w-7/72',
                              },
                              {
                                label: item.merchandise_groups
                                  ? item.merchandise_groups
                                      .map(obj => obj.name)
                                      .join(', ')
                                  : '-',
                                size: 'md:w-6/36 lg:w-6/36',
                              },
                              {
                                label: item.warehouses
                                  ? item.warehouses
                                      .map(obj => obj.code)
                                      .join(', ')
                                  : '-',
                                size: 'md:w-7/72 lg:w-7/72 ml-2',
                              },
                              {
                                label: item.suppliers
                                  ? item.suppliers
                                      .map(obj => obj.code)
                                      .join(', ')
                                  : '-',
                                lowercase: true,
                                size: 'md:w-3/24 lg:w-3/24 ml-1',
                              },
                            ];

                            return (
                              <>
                                {labels.map((item, key) => (
                                  <span
                                    className={`font-default font-normal text-xs text-yaleblue-900 text-left ${
                                      item.size || 'flex-1'
                                    } ${!item.lowercase ? 'uppercase' : ''}`}
                                    key={key}
                                  >
                                    {`${t(item.label.toLowerCase())}`}
                                  </span>
                                ))}
                                <span
                                  className={`font-default font-normal text-sm text-yaleblue-900 text-left ${
                                    item.size || 'flex-1'
                                  } ${!item.lowercase ? 'uppercase' : ''}`}
                                >
                                  <S.Link onClick={() => inactiveItem(item)}>
                                    {item.active
                                      ? t('inactivate')
                                      : t('activate')}
                                  </S.Link>
                                </span>
                                <span
                                  className={`font-default font-normal text-sm text-yaleblue-900 text-left ${
                                    item.size || 'flex-1'
                                  } ${!item.lowercase ? 'uppercase' : ''}`}
                                >
                                  <S.Link
                                    onClick={() =>
                                      history.push(
                                        `/vendor-lists/edit/${item.id}`,
                                      )
                                    }
                                  >
                                    {t('edit')}
                                  </S.Link>
                                </span>
                              </>
                            );
                          };

                          return (
                            <ListItem
                              key={key}
                              FeaturedComponent={VendorListFeaturedComponent}
                              // ContentComponent={ContentComponent}
                              className="my-2"
                              id={item.id}
                              px="pr-1 pl-4"
                              hasCheckbox={true}
                              selectOne={selectVendorList}
                              removeOne={removeVendorList}
                              isSelected={selectedVendorLists.includes(item.id)}
                              justify="none"
                            />
                          );
                        })}
                      </InfiniteScroll>
                    </Content>
                  </div>
                  <div
                    className="w-1/4 flex flex-col"
                    style={{ height: 'calc(100vh - 100px)' }}
                  >
                    <div className="pl-4 flex items-end">
                      <span className="font-default mb-0.5 mr-3 font-semibold text-lg text-yaleblue-800">
                        {t('add')} {t('suppliers')}
                      </span>
                      {isLoading === 'suppliers' ? (
                        <ReactLoading
                          type={'spin'}
                          color={'#3D6F9B'}
                          height={28}
                          width={22}
                        />
                      ) : (
                        <Button
                          className="mb-1 ml-20 h-8"
                          onClick={() =>
                            handleFieldsValidate(
                              setIsLoading,
                              t,
                              typedOptionSupplier,
                              'suppliers/list_to_filter/?supplier=',
                              setMessage,
                              setIsOpen,
                              selectedItems,
                              setSelectedItems,
                            ).finally(() => setTypedOptionSupplier(''))
                          }
                        >
                          {t('validate')}
                        </Button>
                      )}
                    </div>
                    <div className="h-flex pl-2 pr-1">
                      <div>
                        <MultiInput
                          type={'select'}
                          register={register}
                          icon={SearchIconBlue}
                          name={'suppliers'}
                          options={suppliersOptions}
                          placeholder={t('select_suppliers')}
                          autoComplete="off"
                          showSelectedItems={true}
                          url={'suppliers/list_to_filter/?supplier='}
                          loadOptions={handleLoadedSuppliers}
                          selectedOptions={selectedItems}
                          handleSelectedOptions={handleSelectedOptions}
                          typedOption={typedOptionSupplier}
                          setTypedOption={setTypedOptionSupplier}
                        />
                        <div className="h-1/2">
                          {selectedVendorLists.length > 0 &&
                            selectedItems.length > 0 && (
                              <Button
                                className="mt-2 ml-2 h-8"
                                onClick={() => handleAddItems('supplier')}
                              >
                                {t('add')}
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 pl-2 pr-1 flex items-end">
                      <span className="ml-2 mr-4 font-default font-semibold text-lg text-yaleblue-800">
                        {t('remove')} {t('suppliers')}
                      </span>
                      {isLoading === 'remove_suppliers' ? (
                        <ReactLoading
                          type={'spin'}
                          color={'#3D6F9B'}
                          height={26}
                          width={22}
                        />
                      ) : (
                        <Button
                          className="mt-1 mb-0.5 ml-20 h-8"
                          onClick={() =>
                            handleFieldsValidate(
                              setIsLoading,
                              t,
                              inputValue,
                              'suppliers/list_to_filter/?supplier=',
                              setMessage,
                              setIsOpen,
                              suppliersList,
                              setSuppliersList,
                              setItemsToRemove,
                              itemsToRemove,
                              () => null,
                              'remove',
                            ).finally(() => setInputValue(''))
                          }
                        >
                          {t('validate')}
                        </Button>
                      )}
                    </div>
                    <div className="ml-2 mr-1 mt-0.5">
                      {suppliersList.length > 0 ? (
                        <MultiInput
                          type={'select'}
                          register={register}
                          icon={SearchIconBlue}
                          name={'suppliers'}
                          options={suppliersOptionsRemove}
                          placeholder={t('select_suppliers')}
                          autoComplete="off"
                          showSelectedItems={false}
                          url={'suppliers/list_to_filter/?supplier='}
                          loadOptions={handleLoadedSuppliersRemove}
                          selectedOptions={selectedItems}
                          handleSelectedOptions={handleSelectedOptionsRemove}
                          typedOption={inputValue}
                          setTypedOption={setInputValue}
                          currentList={suppliersList}
                        />
                      ) : (
                        <Container
                          className={`flex h-10 items-center justify-between border border-color-yaleblue bg-whitegray-100 rounded-lg`}
                        >
                          <span className="text-sm ml-4 text-gray-400">
                            {t('empty_list')}
                          </span>
                        </Container>
                      )}
                    </div>
                    <div className="overflow-auto h-flex ml-2 mr-1">
                      {suppliersList.length > 0 &&
                        suppliersList.map((item, index) => {
                          const SuppliersFeaturedComponent: React.FC = () => {
                            return (
                              <>
                                <span
                                  className={`mb-0.5 font-default font-normal text-sm text-yaleblue-900 text-left flex-1 ml-2 h-1/2`}
                                >
                                  {item}
                                </span>
                              </>
                            );
                          };
                          if (
                            inputValue
                              .toLowerCase()
                              .split(',')
                              .some(subValue =>
                                item.toLowerCase().includes(subValue.trim()),
                              )
                          ) {
                            return (
                              <ListItem
                                key={index}
                                FeaturedComponent={SuppliersFeaturedComponent}
                                className="my-1 h-12"
                                id={item}
                                hasCheckbox={true}
                                selectOne={selectItem}
                                removeOne={removeItem}
                                isSelected={itemsToRemove.includes(item)}
                                justify="none"
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                    </div>
                    <div className="py-1">
                      {selectedVendorLists.length > 0 &&
                        suppliersList.length > 0 && (
                          <Button
                            className="ml-4 mb-1 h-8"
                            onClick={() => handleRemoveItems('supplier')}
                          >
                            {t('remove_selected')}
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
                // <div className="h-full w-full flex flex-col">
                //   <div className="flex justify-center">
                //     <div className="w-full">
                //       {vendorListSanitized.length > 0 && (
                //         <GoTopButton content={contentRef.current} />
                //       )}
                //       <div className="px-16 mb-2">
                //         <div className="flex justify-between">
                //           <Filters
                //             filterFields={vendorListFilterFields}
                //             handleResponse={handleVendorFilterRequest}
                //             handleOptions={handleLoadedOptions}
                //           />
                //           <ListButton
                //             keyButtonText={'load_vendor_list'}
                //             permissionsNeeded={[
                //               'add_vendorlist',
                //               'change_vendorlist',
                //             ]}
                //             onClick={() => inputRef.current.click()}
                //             upload
                //           />
                //
                //           <HiddenFileInput
                //             id="upload"
                //             ref={inputRef}
                //             onChange={handleChangeFile}
                //             accept=".csv"
                //           />
                //         </div>
                //       </div>
                //       <ContentVendorList
                //         id="scrollableDiv"
                //         ref={contentRef}
                //         className="overflow-y-auto px-16"
                //       >
                //         {vendorListSanitized.length === 0 && (
                //           <div className="mt-6 flex justify-center items-center">
                //             <span className="font-default text-sm font-light italic text-300">
                //               {t('empty_list')}
                //             </span>
                //           </div>
                //         )}
                //         <TableMUI
                //           columns={[
                //             { title: 'id', field: 'id' },
                //             {
                //               title: t('buyer_groups'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.buyer_groups)
                //                   ? rowData.buyer_groups.name
                //                     ? rowData.buyer_groups.name
                //                     : '-'
                //                   : '-',
                //             },
                //             {
                //               title: t('materials'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.materials)
                //                   ? rowData.materials.cod_material
                //                     ? rowData.materials.cod_material
                //                     : '-'
                //                   : '-',
                //             },
                //             {
                //               title: t('region'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.regions)
                //                   ? rowData.regions.code
                //                     ? rowData.regions.code
                //                     : '-'
                //                   : '-',
                //             },
                //             {
                //               title: t('categories'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.categories)
                //                   ? rowData.categories.name
                //                     ? rowData.categories.name
                //                     : '-'
                //                   : '-',
                //             },
                //             {
                //               title: t('merchandise_groups'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.merchandise_groups)
                //                   ? rowData.merchandise_groups.name
                //                     ? rowData.merchandise_groups.name
                //                     : '-'
                //                   : '-',
                //             },
                //             // {
                //             //   title: t('warehouse'),
                //             //   render: (rowData: VendorListObj) =>
                //             //     !isEmpty(rowData.warehouse)
                //             //       ? rowData.warehouse
                //             //         ? rowData.warehouse
                //             //         : '-'
                //             //       : '-',
                //             // },
                //
                //             {
                //               title: t('suppliers'),
                //               render: (rowData: VendorListObj) =>
                //                 !isEmpty(rowData.suppliers)
                //                   ? rowData.suppliers.code
                //                     ? rowData.suppliers.code
                //                     : '-'
                //                   : '-',
                //             },
                //             {
                //               title: t('active'),
                //               render: (rowData: VendorListObj) => (
                //                 <S.Link onClick={() => inactiveItem(rowData)}>
                //                   {rowData.active
                //                     ? t('inactivate')
                //                     : t('activate')}
                //                 </S.Link>
                //               ),
                //             },
                //             {
                //               title: t('actions'),
                //               render: (rowData: VendorListObj) => (
                //                 <S.Link
                //                   onClick={() =>
                //                     history.push(
                //                       `/vendor-lists/edit/${rowData.id}`,
                //                     )
                //                   }
                //                 >
                //                   {t('edit')}
                //                 </S.Link>
                //               ),
                //             },
                //           ]}
                //           data={vendorListSanitized}
                //         />
                //       </ContentVendorList>
                //     </div>
                //   </div>
                //   {userHasAnyPermissionOf([
                //     'add_vendorlist',
                //     'change_vendorlist',
                //   ]) ? (
                //     <div className="flex justify-center items-center h-20 py-">
                //       <div className="flex w-full px-20 justify-end">
                //         <Link to="/vendor-lists/new">
                //           <div className="px-4 h-10 flex items-center justify-center bg-bluejeans-800 text-sm text-white font-bold rounded ml-12">
                //             {t('create_vendor_list')}
                //           </div>
                //         </Link>
                //       </div>
                //     </div>
                //   ) : null}
                // </div>
              )}
            </div>
          </PageTab>
          <MessageModal
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setIsOpen}
            message={message}
            type={modalType}
          ></MessageModal>
        </Container>
      )}
    </>
  );
};

export default ListSuppliers;
