import styled, { css } from 'styled-components';

interface FeaturedProps {
  isOpen: boolean;
}

export const Container = styled.div`
  border: 2px solid rgba(42, 78, 118, 0.08);
  box-sizing: border-box;
`;

export const Featured = styled.div<FeaturedProps>`
  .checkbox-disabled > path {
    fill: #9d9d9d; !important;
  }

  ${props =>
    props.isOpen &&
    css`
      background-color: rgba(176, 201, 252, 0.16);
    `}
`;

export const ContentContainer = styled.div`
  border-top: 2px solid rgba(42, 78, 118, 0.08);
`;

export const Content = styled.div`
  border-right: 2px solid #e7eaef;
  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    border-right: 0;
    justify-content: flex-end;
  }
`;
