import styled, { css } from 'styled-components';

interface CircleIndicatorProps {
  color: string;
}

export const SvgContainer = styled.svg`
  margin: 0 auto;
  border-radius: 50%;
  display: block;
`;

export const CircleTrack = styled.circle`
  fill: transparent;
  stroke: #b9c2d8;
  stroke-width: 26;
`;

export const CircleIndicator = styled.circle<CircleIndicatorProps>`
  fill: transparent;
  stroke: #a00404;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray 0.3s ease;
  ${props =>
    props.color === 'low' &&
    css`
      stroke: #00ab84;
    `}
  ${props =>
    props.color === 'medium' &&
    css`
      stroke: #ffc94d;
    `}
    ${props =>
    props.color === 'high' &&
    css`
      stroke: #f4364c;
    `}
`;
