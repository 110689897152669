import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 98px;
`;

export const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 20px 60px;
  margin-bottom: 16px;
  min-height: 190px;
`;

export const CardContainer = styled.div`
  display: flex;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  position: relative;
`;

export const CardTitle = styled.h1`
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 20px;
  color: #1d3550;
  margin-bottom: 52px;
`;

export const CardTableLine = styled.div`
  border-bottom: 2px solid rgba(42, 78, 118, 0.08);
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0b3e5c;
  display: flex;
  padding-bottom: 4px;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    svg,
    img {
      margin-right: 12px;
    }
  }
`;

export const CardSubtitle = styled.h2`
  font-family: 'Segoe UI Light';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1d3550;
  width: 150px;
  text-align: center;
  margin-top: 4px;
`;

export const CardTable = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  background-color: rgba(200, 218, 253, 0.08);
  border: 1px solid rgba(42, 78, 118, 0.16);
  font-family: 'Segoe UI Light';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1d3550;
  width: 150px;
`;

export const CardTableRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(42, 78, 118, 0.16);
`;

export const CardTableColumn = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(42, 78, 118, 0.16);

  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 39px;
  line-height: 1.1;
`;

export const ChartWrapper = styled.div`
  position: absolute;
  right: 187px;
  top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 8px;
  }

  span {
    font-family: 'Segoe UI Light';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #1d3550;
  }
`;
