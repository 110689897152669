/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDropdown, OptionsList, Search } from './CustomDropdown.style';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down-gray-icon.svg';
import { ReactComponent as AddIcon } from '../../assets/add-icon.svg';
import { ReactComponent as IconSearch } from '../../assets/icon-search-active.svg';
import api from '../../services/api';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputLoading from '../../components/InputLoading/Loading';

interface DropdownContent {
  label: string;
  value?: string | number;
  code?: string;
  document_number?: string;
}
interface CustomDropdownProps {
  options: DropdownContent[] | [];
  title: string;
  toggleAction: Function;
  isCleanedSelectedOption?: boolean;
  selectedItem?: string | null;
  handleOptions?: Function;
  url?: string;
  type?: string;
  hasError?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  title,
  toggleAction,
  isCleanedSelectedOption,
  selectedItem,
  handleOptions,
  url,
  type,
  hasError,
}) => {
  const [searchedOptions, setSearchedOptions] = useState<DropdownContent[]>([]);
  const { t } = useTranslation();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>();
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [totalData, setTotalData] = useState(0);
  const [currentData, setCurrentData] = useState(0);

  const [typedOption, setTypedOption] = useState('');

  const searchValues = async () => {
    if (typedOption.length >= 3 && url) {
      try {
        await api.get(`${url}${typedOption}`).then(response => {
          handleOptions && handleOptions(response.data.results, title);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const debounce = useCallback(_.debounce(searchValues, 700), [typedOption]);

  const onChange = (value: string) => {
    setTypedOption(value);
  };

  useEffect(() => {
    debounce();

    return debounce.cancel;
  }, [typedOption, debounce]);

  const toogleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const toogleSelectedOption = (option: DropdownContent) => {
    const action = type === 'list' ? 'selectMany' : 'selectOne';
    if (option.code) {
      toggleAction(option.code, title);
      if (action === 'selectOne') setSelectedOption(option.code);
    } else if (option.value || option.value === 0) {
      toggleAction(option.value, title, option.label, action);
      if (action === 'selectOne') setSelectedOption(option.value.toString());
    } else {
      toggleAction(option.label, title);
      if (action === 'selectOne') setSelectedOption(option.label);
    }
    toogleDropdown();
  };

  const handleClick = (e: Event) => {
    if (!node.current) {
      return;
    }
    if (node.current.contains(e.target as Node)) {
      setIsOpenDropdown(true);
    } else {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    if (isCleanedSelectedOption) {
      setSelectedOption(null);
    } else {
      selectedItem && setSelectedOption(selectedItem);
    }
    setSearchedOptions(options);
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [options, selectedItem, isCleanedSelectedOption]);

  const isCleanedFilters = (option: string) => {
    if (isCleanedSelectedOption) {
      return t(title);
    }
    return t(option);
  };

  const fetchData = async () => {
    if (totalData < currentData || !url) return;
    await api
      .get(`/${url}${typedOption}&limit=15&offset=${currentData + 15}`)
      .then(response => {
        const results = response.data.results;
        setCurrentData(prev => prev + 15);
        setTotalData(response.data.count);
        if (results.length > 0) {
          handleOptions && handleOptions(results, title, searchedOptions);
        } else {
          setTotalData(0);
        }
      });
  };

  return (
    <div
      className={`relative border rounded-lg ${
        hasError ? ' border-red-salsa-500 ' : ''
      }    `}
      id="dropdown"
      ref={node}
    >
      {isOpenDropdown ? (
        <FilterDropdown
          className="w-full bg-white overflow-x-auto hover:border-black"
          id="scrollableSelect"
        >
          <div className="relative">
            <Search
              className="px-4 py-2 text-basic-gray-400 text-sm w-full focus:outline-none"
              placeholder={t('Search')}
              name={'search'}
              onChange={e => onChange(e.target.value)}
              autoComplete="off"
            />
            <IconSearch className="absolute top-0 right-4 h-full cursor-pointer" />
          </div>
          <InfiniteScroll
            dataLength={currentData}
            hasMore={true}
            next={fetchData}
            loader={totalData > currentData ? <InputLoading /> : <></>}
            scrollableTarget="scrollableSelect"
          >
            {searchedOptions.length > 0 &&
              searchedOptions.map((item, key) => (
                <OptionsList
                  key={key}
                  className="px-4 py-2 cursor-pointer"
                  onClick={() => toogleSelectedOption(item)}
                >
                  {item.code && (
                    <span className="text-basic-gray-400 text-sm">{`${item.code} - `}</span>
                  )}
                  <span className="text-basic-gray-400 text-sm">
                    {t(item.label)}
                  </span>
                </OptionsList>
              ))}
          </InfiniteScroll>
        </FilterDropdown>
      ) : (
        <div
          className="w-full h-10 rounded bg-whitegray-100 border border-color-yaleblue px-4 flex items-center justify-between rounded-lg "
          onClick={toogleDropdown}
        >
          <span className="text-basic-gray-400 text-sm">
            {selectedOption ? isCleanedFilters(selectedOption) : t(title)}
          </span>
          {type === 'list' ? (
            <AddIcon className="cursor-pointer" />
          ) : (
            <ArrowDown className="cursor-pointer" />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
