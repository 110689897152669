import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const FirstContainer = styled.div`
  background: #32424f;
`;

export const TextError = styled.div`
  color: #32424f;
`;

export const SecondContainer = styled.div`
  background: #f6f7f9;
`;

export const Input = styled.input`
  width: 28.75rem;
`;

export const Button = styled.button`
  width: 28.75rem;
`;

export const Logo = styled.div`
  width: 100vh;
`;
