import api from './api';
import BaseService from './base-service';

export class PowerBIService extends BaseService {
  exportUrl = 'power_bi_report_token';
  listUrl = 'power_bi_report_token';
  private tokenExpirationTime: Date | undefined;
  private report: any;

  constructor() {
    super();
    this.checkTokenAndUpdate();
  }

  getTokenExpirationTime(seconds: number): Date {
    const currentTime = new Date();
    return new Date(currentTime.getTime() + seconds * 1000);
  }

  getPbiReportToken = async () => {
    const response = await api.get('/power_bi_report_token/');
    this.tokenExpirationTime = this.getTokenExpirationTime(response.data.TTL);
    return response.data;
  };

  getNewAccessToken = async () => {
    const response = await api.get('/power_bi_new_access_token/');
    this.tokenExpirationTime = this.getTokenExpirationTime(response.data.TTL);
    return response.data.PBIReportToken;
  };

  setEmbedReport = (embedReport: object) => {
    this.report = embedReport;
  };

  checkTokenAndUpdate = () => {
    setInterval(async () => {
      if (this.tokenExpirationTime !== undefined) {
        const currentTime = Date.now();
        const timeUntilExpiration =
          this.tokenExpirationTime.getTime() - currentTime;
        const timeToUpdate = 10 * 60 * 1000;
        if (timeUntilExpiration <= timeToUpdate) {
          console.log('Updating report access token');
          await this.updateToken();
        }
      }
    }, 60000); // 60 seconds
  };

  private updateToken = async () => {
    // Generate a new embed token or refresh the user Azure AD access token
    const newAccessToken = await this.getNewAccessToken();
    // // Set the new access token
    if (this.report) {
      await this.report.setAccessToken(newAccessToken);
    }
  };
}

export default new PowerBIService();
