import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  height: 70vh;
`;

export const ContentContainer = styled.div`
  border-right: 2px solid #e7eaef;
  justify-content: flex-start;
  padding-left: 1rem;
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0px;
    width: 100%;
  }
`;
