import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './Button.style';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  isLoading,
  ...rest
}) => {
  return (
    <Container
      type="button"
      className={`px-4 flex items-center justify-center text-sm text-white font-bold rounded  ${
        !className?.includes('h-') && 'h-10'
      } ${className} ${isLoading ? 'bg-gray-400' : 'bg-bluejeans-800'}`}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Button;
